import { useState, type ReactElement } from 'react'

import { Selector } from '../Selector'
import { Modal } from '../Modal'
import { Button } from '../Button'

import { deviceStore } from '@/store/device'

interface SupportModalProps {
  closeModal: () => void
  isParentClosing?: boolean
}

export function SupportModal({
  closeModal,
  isParentClosing,
}: SupportModalProps): ReactElement {
  const [selected, setSelected] = useState<
    'CreateEvent' | 'WhatsAppSupport' | 'EmailSupport'
  >()
  const { isMobile } = deviceStore()

  function handleSupportClick(): void {
    if (selected === 'CreateEvent') {
      const msg = 'Olá, gostaria de criar um evento na Gandaya!'
      const url = `https://wa.me/${
        import.meta.env.VITE_SUPPORT_PHONE as string
      }?text=${msg}`
      window.open(url, '_blank')
    } else if (selected === 'WhatsAppSupport') {
      const msg = 'Olá, gostaria de tirar uma dúvida.'
      const url = `https://wa.me/${
        import.meta.env.VITE_SUPPORT_PHONE as string
      }?text=${msg}`
      window.open(url, '_blank')
    } else if (selected === 'EmailSupport') {
      const url = `mailto:${import.meta.env.VITE_SUPPORT_EMAIL as string}`
      window.open(url, '_blank')
    }
  }

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      isMobile={isMobile}
    >
      <div className="flex h-fit w-full flex-col items-center gap-8 p-4 text-black">
        <div className="h-2 w-36 rounded-2xl bg-[#d9d9d9]"></div>
        <div className="flex w-full flex-col gap-2 px-4">
          <Selector
            title={'Crie seu evento conosco'}
            subtitle={'Entenda como funciona a Gandaya para criadores.'}
            isSelected={selected === 'CreateEvent'}
            onClick={(): void => {
              setSelected('CreateEvent')
            }}
            variant={'light'}
          />
          <Selector
            title={'Suporte via Whatsapp'}
            subtitle={
              'Entre em contato com um atendente do nosso suporte por WhatsApp.'
            }
            isSelected={selected === 'WhatsAppSupport'}
            onClick={(): void => {
              setSelected('WhatsAppSupport')
            }}
            variant={'light'}
          />
          <Selector
            title={'Suporte via Email'}
            subtitle={
              'Entre em contato com um atendente do nosso suporte por email.'
            }
            isSelected={selected === 'EmailSupport'}
            onClick={(): void => {
              setSelected('EmailSupport')
            }}
            variant={'light'}
          />
        </div>
        <div className="h-12 w-full px-4">
          <Button
            enabled={selected !== undefined}
            onClick={handleSupportClick}
            text="Ir"
          />
        </div>
      </div>
    </Modal>
  )
}
