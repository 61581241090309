export const SIGNUP_ERROR = 'Erro ao realizar cadastro'

export const VALIDATE_TOKEN_ERROR = 'Erro ao validar token!'

export const EMAIL_NOT_AVAILABLE_ERROR = 'Email em uso, tente outro email'

export const SEND_EMAIL_ERROR = 'Erro ao enviar email, tente novamente!'

export const LOGIN_ERROR = 'Erro ao fazer login, tente novamente!'

export const PROFILE_PICTURE_ERROR = 'Erro ao definir imagem de perfil'

export const GENERATE_REPORT_ERROR = 'Erro ao gerar relatório.'

export const START_DATE_AFTER_MAX_ENTRANCE_DATE =
  'A entrada máxima tem que ser depois do início.'

export const MAX_ENTRANCE_DATE_AFTER_END_DATE =
  'O término tem que ser depois da entrada máxima.'

export const START_DATE_AFTER_END_DATE =
  'O término tem que ser depois do início.'

export const BACKGROUND_COVER_IMAGE_SQUARE =
  'A imagem de capa do evento deve ser quadrada.'

export const BACKGROUND_COVER_IMAGE_MIN_RES =
  'A resolução mínima para a imagem de capa do evento é de 500x500.'

export const ARTIST_IMAGE_MIN_RES =
  'A resolução mínima para a imagem do artista é de 500x500.'

export const INVALID_PIX_KEY = 'Chave PIX inválida.'

export const DUPLICATED_PIX_KEY = 'Esta Chave PIX já foi adicionada.'

export const INVALID_TOKEN =
  'Token inválido. Sem um token válido não é possível criar uma organização.'

export const CREATE_ORGANIZATION = 'Erro ao criar organização. Tente novamente.'

export const EDIT_ORGANIZATION = 'Erro ao editar organização. Tente novamente.'

export const CREATE_TOKEN = 'Erro ao criar token. Tente novamente.'

export const DELETE_WITHDRAWAL =
  'Erro ao deletar saque. Tente novamente mais tarde.'

export const CREATE_WITHDRAWAL =
  'Ocorreu um erro ao criar o saque. Tente novamente.'

export const MINIMUM_WITHDRAWAL_VALUE =
  'O valor mínimo para um saque é de R$ 1,00.'

export const CREATE_WITHDRAWAL_BLOCK =
  'Você já possui uma solicitação de saque em andamento.'

export const INVALID_XLXS_FILE =
  'Arquivo inválido. São aceitos apenas arquivos .xlxs.'

export const CAMPAIGN_NAME_ALREADY_EXISTS =
  'Nome de campanha em uso, utilize outro.'

export const XLSX_FILE_WRONG_FORMAT =
  'A planilha não possui o formato correto. Baixe o template de envio e tente novamente.'

export const CREATE_COMMUNICATION_CAMPAIGN =
  'Erro ao criar campanha. Tente novamente.'

export const CANT_CREATE_EVENT =
  'Não foi possível criar o evento. Tente novamente.'

export const CANT_UPDATE_EVENT =
  'Não foi possível atualizar o evento. Tente novamente.'

export const INVALID_BRAZILLIAN_PHONE =
  'Número de telefone inválido. Certifique-se de inserir DDD + 9 e então seu celular.'

export const USER_NOT_FOUND = 'Usuário não encontrado'

export const CANT_SEARCH_USER = 'Erro ao buscar usuário. Tente novamente.'

export const CANT_CREATE_ORGANIZATION_ROLE =
  'Erro ao criar permissão. Tente novamente.'

export const CANT_CREATE_EVENT_ROLE =
  'Erro ao criar permissão. Tente novamente.'

export const CANT_UPDATE_ORGANIZATION_ROLE =
  'Erro ao atualizar permissões de organização. Tente novamente.'

export const CANT_DELETE_ORGANIZATION_ROLE =
  'Erro ao deletar usuário da organização. Tente novamente.'

export const CANT_UPDATE_EVENT_ROLE =
  'Erro ao atualizar permissões do evento. Tente novamente.'

export const CANT_DELETE_EVENT_ROLE =
  'Erro ao deletar usuário do evento. Tente novamente.'

export const CANT_CREATE_TICKET_SPEC =
  'Não foi possivel criar o ingresso. Tente novamente.'

export const CANT_UPDATE_TICKET_SPEC =
  'Não foi possivel atualizar o ingresso. Tente novamente.'

export const CANT_ADD_CO_HOST = 'Erro ao adicionar Co-host. Tente novamente.'

export const CANT_CHANGE_CO_HOST_VISIBILITY =
  'Erro ao alterar visibilidade. Tente novamente.'

export const CANT_DELETE_CO_HOST = 'Erro ao deletar Co-host. Tente novamente.'

export const SEND_OTP_ERROR =
  'Ocorreu um erro ao enviar o código, tente novamente em alguns instantes.'
