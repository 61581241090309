import { numberToReais } from '@/utils/formatNumber'
import { animate } from 'framer-motion'
import { type ReactElement, useEffect, useRef } from 'react'

import { usePrevious } from 'react-use'

interface AnimatedNumberProps {
  val: number
  className?: string
  animationDuration?: number
  isMoney?: boolean
}

export function AnimatedNumber({
  val,
  className,
  animationDuration = 0.5,
  isMoney = true,
}: AnimatedNumberProps): ReactElement {
  const prev = usePrevious(val)
  const curr = val

  const nodeRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const node = nodeRef.current
    if (node === null) return
    const controls = animate(prev, curr, {
      duration: animationDuration,
      ease: [0.42, 0, 0.58, 1],
      onUpdate(value) {
        node.textContent = isMoney
          ? `${numberToReais(value ?? 0, 2)}`
          : `${value?.toFixed(0)}`
      },
    })

    return () => {
      controls.stop()
    }
  }, [prev, curr])

  return <div ref={nodeRef} className={className} />
}
