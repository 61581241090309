import { type ReactNode, type ReactElement } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

interface SnackBarDesktopWrapperProps {
  children: ReactNode
  isError: boolean
}

export function SnackBarDesktopWrapper({
  children,
  isError,
}: SnackBarDesktopWrapperProps): ReactElement {
  return (
    <AnimatePresence>
      {isError && (
        <motion.div
          initial={{ x: 320 }}
          animate={{ x: 0 }}
          exit={{ x: 320 }}
          transition={{ duration: 0.5, ease: 'backInOut' }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}
