import { useEffect, useState } from 'react'
import useSWR from 'swr'

import { fetcher } from '../../services/axios'

import { type GetOrganizationInfosByUserResponse } from '@/types/Organization'

export const useGetOrganizationInfosByUser = (): {
  getOrganizationInfosByUser: GetOrganizationInfosByUserResponse[] | undefined
  refreshOrganizationInfosByUser: () => Promise<void>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(true)
  const { data, error, mutate } = useSWR<
    GetOrganizationInfosByUserResponse[],
    string
  >(`/organizations/`, fetcher)

  useEffect(() => {
    setIsLoading(!(data !== undefined || error !== undefined))
  }, [data, error])

  async function refreshOrganizationInfosByUser(): Promise<void> {
    await mutate()
  }

  return {
    getOrganizationInfosByUser: data,
    refreshOrganizationInfosByUser,
    isLoading,
  }
}
