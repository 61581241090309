import { type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button } from '@/components/Global/Button'

export function PageNotFound(): ReactElement {
  const navigate = useNavigate()

  return (
    <div className="flex h-full flex-col items-center justify-center bg-transparent p-8">
      <h1 className="mb-8 text-center text-3xl text-white">
        Não encontramos essa página 🙁
      </h1>
      <div className="absolute bottom-8 h-12 w-4/5 max-w-[300px]">
        <Button
          className="font-black"
          onClick={() => {
            navigate(-1)
          }}
          text="VOLTAR"
          enabled
        />
      </div>
    </div>
  )
}
