import { type ReactElement } from 'react'
import * as z from 'zod'

import { ticketStore } from '@/store/tickets'
import { errorStore } from '@/store/error'
import { useCurrentEvent } from '@/store/eventMenu'

import {
  usePostCreateTicketSpec,
  usePutUpdateTicketSpec,
} from '@/hooks/Tickets/TicketSpec'

import { currencyMask } from '@/utils/currencyMask'
import { numberToReais } from '@/utils/formatNumber'

import { Button } from '@/components/Global/Button'
import { Form } from '@/components/Global/Form/Form'
import { FormInput } from '@/components/Global/Form/FormInput'
import { FormTextAreaCounter } from '@/components/Global/Form/FormTextAreaCounter'

import { CANT_UPDATE_TICKET_SPEC, CANT_CREATE_TICKET_SPEC } from '@/errors'

const createTicketSpecSchema = z.object({
  name: z
    .string()
    .min(3, { message: 'O nome do ingresso precisa ter pelo menos 3 letras.' }),
  price: z
    .string()
    .nonempty({ message: 'O campo não pode ser vazio' })
    .transform((data: string) => {
      const valueWithoutCurrency = data
        .replace('R$', '')
        .replace(/[.]/g, '')
        .replace(',', '.')
      return Number(valueWithoutCurrency)
    }),
  limitPerSale: z.string().transform((data) => Number(data)),
  limitByUser: z.string().transform((data) => Number(data)),
  extraDescription: z
    .string()
    .max(100, { message: 'Você passou do limite máximo.' }),
})

type FormData = z.infer<typeof createTicketSpecSchema>

interface TicketSpecFormProps {
  closeModal: () => void
}

export function TicketSpecForm({
  closeModal,
}: TicketSpecFormProps): ReactElement {
  const {
    currentBatch,
    currentTicketSpec,
    addTicketSpec,
    updateTicketSpec: updateTicketSpecStore,
  } = ticketStore()
  const { currentEvent } = useCurrentEvent()
  const { showError } = errorStore()

  let defaultValues: Record<string, string | number> | null = null
  if (currentTicketSpec !== undefined) {
    defaultValues = {
      name: currentTicketSpec.description,
      price: numberToReais(currentTicketSpec.price, 2),
      limitPerSale:
        currentTicketSpec.numberOfAvailableTickets !== null
          ? currentTicketSpec.numberOfAvailableTickets.toString()
          : '',
      limitByUser:
        currentTicketSpec.userLimit !== null
          ? currentTicketSpec.userLimit.toString()
          : '',
      extraDescription:
        currentTicketSpec.extraDescription !== null
          ? currentTicketSpec.extraDescription?.toString()
          : '',
    }
  }
  const { createTicketSpec, isLoading: isLoadingCreateTicketSpec } =
    usePostCreateTicketSpec()
  const { updateTicketSpec, isLoading: isLoadingUpdateTicketSpec } =
    usePutUpdateTicketSpec()

  async function handleUpdateTicketSpec(data: FormData): Promise<void> {
    const response = await updateTicketSpec({
      ticketSpecId: currentTicketSpec!.id,
      description: data.name,
      numberOfAvailableTickets: data.limitPerSale,
      price: data.price,
      userLimit: data.limitByUser,
      extraDescription: data.extraDescription,
    })
    if (!response.status) {
      showError(CANT_UPDATE_TICKET_SPEC)

      return
    }
    if (response.ticketSpec !== undefined) {
      updateTicketSpecStore(response.ticketSpec)
    }
  }

  async function handleCreateTicketSpec(data: FormData): Promise<void> {
    const response = await createTicketSpec({
      eventId: currentEvent!.id,
      batchId: currentBatch?.id ?? undefined,
      description: data.name,
      numberOfAvailableTickets: data.limitPerSale,
      price: data.price,
      userLimit: data.limitByUser,
      extraDescription: data.extraDescription,
    })
    if (!response.status) {
      showError(CANT_CREATE_TICKET_SPEC)
      return
    }

    if (response.ticketSpec !== undefined) {
      addTicketSpec(response.ticketSpec)
    }
  }

  async function handleCreateOrUpdateBatch(data: FormData): Promise<void> {
    if (currentTicketSpec === undefined) {
      await handleCreateTicketSpec(data)
    } else {
      await handleUpdateTicketSpec(data)
    }
    closeModal()
  }

  return (
    <Form
      formSchema={createTicketSpecSchema}
      className="mt-8 flex w-4/5 flex-col gap-4"
      handleModalClose={(data: FormData): void => {
        void handleCreateOrUpdateBatch(data)
      }}
      defaultValues={defaultValues ?? undefined}
      formMode="all"
    >
      <FormInput
        type="text"
        id="name"
        placeholder="Nome do Ingresso *"
        name="name"
        autoCorrect="off"
        autoCapitalize="none"
        isInputTitle={true}
      />
      <FormInput
        label={'Preço *'}
        type="text"
        inputMode="numeric"
        id="price"
        placeholder="Ex: R$ 230,00"
        name="price"
        onChangeFunction={currencyMask}
        autoCorrect="off"
        autoCapitalize="none"
      />
      <FormInput
        label={'Limite de vendas'}
        type="number"
        id="limitPerSale"
        placeholder="Ex: 300"
        name="limitPerSale"
        autoCorrect="off"
        autoCapitalize="none"
      />
      <FormInput
        label={'Limite por CPF'}
        type="number"
        id="limitByUser"
        placeholder="Ex: 3"
        name="limitByUser"
        autoCorrect="off"
        autoCapitalize="none"
      />
      <FormTextAreaCounter
        label={'Informações adicionais'}
        id="extraDescription"
        placeholder="Ex: Lote solidário"
        name="extraDescription"
        autoCorrect="off"
        autoCapitalize="none"
        maxLength={100}
      />

      <div className="mt-2 h-12 w-full max-w-[474px] self-center">
        <Button
          type="submit"
          enabled={!isLoadingCreateTicketSpec && !isLoadingUpdateTicketSpec}
          text="Salvar"
          isLoading={isLoadingCreateTicketSpec || isLoadingUpdateTicketSpec}
        />
      </div>
    </Form>
  )
}
