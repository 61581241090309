import { type pageOptions } from '@/store/wrapper'

export function getCurrentPage(path: string): pageOptions {
  if (path === '/') return 'home'
  if (path === '/create-event') return 'createEvent'
  if (path === '/team') return 'team'
  if (path === '/team/add/organization') return 'createOrganizationRole'
  if (path === '/team/add/event') return 'createEventRole'
  if (path.includes('/team/')) {
    if (path.includes('/event/')) return 'userEventPermissions'
    return 'userGlobalPermissions'
  }
  if (path === '/event-preview') return 'createEventPreview'
  if (path === '/communication-campaigns') return 'communicationCampaigns'
  if (path === '/communication-campaigns/create')
    return 'createCommunicationCampaign'
  if (path.includes('/create-organization')) return 'createOrganization'
  if (path.includes('/organizations')) return 'editOrganization'
  if (path.includes('/events')) {
    if (path.includes('tickets')) return 'tickets'
    if (path.includes('links')) return 'links'
    if (path.includes('list')) return 'list'
    if (path.includes('grant')) return 'grant'
    if (path.includes('edit')) return 'editEvent'
    if (path.includes('analytics')) return 'analytics'
    if (path.includes('preview')) return 'editEventPreview'
    if (path.includes('finance')) return 'finance'
    if (path.includes('check-in')) return 'checkIn'
    if (path.includes('co-hosts')) return 'coHosts'
    return 'event'
  }
  return 'other'
}
