import { motion } from 'framer-motion'
import { useEffect, useState, type ReactElement } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  createEventStore,
  useAllStatesOfCreateEvent,
} from '@/store/createEvent'
import {
  updateEventStore,
  useAllStatesOfUpdateEvent,
} from '@/store/updateEvent'

import { usePostCreateEvent, usePutUpdateEvent } from '@/hooks/CreateEvent/api'

import { datesToFormat } from '@/utils/formatData'
import { isTruthy } from '@/utils/validation'

import { EventBase } from '@/compositions/EventBase/EventBase'

import {
  type ICreateEventStoreState,
  type IUpdateEventStoreState,
} from '@/types/CreateUpdateEvent'
import { type IEventData } from '@/types/Event'

import { useCurrentEvent } from '@/store/eventMenu'
import { organizationStore, useCurrentOrganization } from '@/store/organization'
import { errorStore } from '@/store/error'

import { CANT_CREATE_EVENT, CANT_UPDATE_EVENT } from '@/errors'

import { IphoneFrameMask } from '@/components/Global/IphoneFrameMask'
import { CreateEventLoaderModal } from '@/components/CreateEvent/CreateEventLoaderModal'
import { BsFillArrowLeftCircleFill } from 'react-icons/bs'

export function EventPreview(): ReactElement {
  const [showCreateEventLoaderModal, setShowCreateEventLoaderModal] =
    useState(false)
  const [actionSuccess, setActionSuccess] = useState(false)
  const [isParentClosing, setIsParentClosing] = useState(false)

  const navigate = useNavigate()

  const { typeOperation, reset } = updateEventStore()
  const { updateAnyState: updateAnyStateCreateEvent } = createEventStore()
  const { refreshOrganizations, isRefreshing } = organizationStore()

  const { createEvent, isLoading: isLoadingCreateEvent } = usePostCreateEvent()
  const { updateEvent, isLoading: isLoadingUpdateEvent } = usePutUpdateEvent()

  let formStates: ICreateEventStoreState | IUpdateEventStoreState

  const formStatesCreateEvent = useAllStatesOfCreateEvent()
  const formStatesUpdateEvent = useAllStatesOfUpdateEvent()

  const { currentEvent } = useCurrentEvent()
  const { alias } = useParams()

  const { showError } = errorStore()

  if (typeOperation === 'Create') {
    formStates = formStatesCreateEvent
  } else {
    formStates = formStatesUpdateEvent
  }

  useEffect(() => {
    if (currentEvent === null && typeOperation === 'Update') {
      navigate(-1)
    }
  }, [])

  const eventData: IEventData = {
    id: typeOperation === 'Update' ? currentEvent?.id : undefined,
    imageKey:
      formStates.backgroundArtImage !== null
        ? formStates.backgroundArtImage.isLocalImage
          ? formStates.backgroundArtImage.url
          : `${import.meta.env.VITE_S3 as string}/${
              formStates.backgroundArtImage.url
            }`
        : '',
    titleImageKey:
      formStates.titleImage !== null
        ? formStates.titleImage.isLocalImage
          ? formStates.titleImage.url
          : `${import.meta.env.VITE_S3 as string}/${formStates.titleImage.url}`
        : undefined,
    title: formStates.eventName!,
    date: {
      startDate: formStates.startDate,
      maxEntranceDate: formStates.maxEntranceDate,
      endDate: formStates.endDate,
    },
    location:
      formStates.location !== undefined
        ? {
            name: formStates.location.name!,
            address: formStates.location.address!,
            latitude: formStates.location.latitude!,
            longitude: formStates.location.longitude!,
          }
        : undefined,
    description: formStates.eventDescription!,
    spotify: formStates.spotifyPlaylistId ?? undefined,
    terms: formStates.terms!,
    lineup:
      formStates.lineUp?.map((item, index) => {
        return {
          id: index,
          avatarImageKey: item.isLocalImage
            ? item.artistImageUrl
            : `${import.meta.env.VITE_S3 as string}/${item.artistImageUrl}`,
          name: item.artistName,
        }
      }) ?? [],
    faq:
      formStates.FAQs?.map((item) => {
        return {
          id: item.id,
          answer: item.answer,
          question: item.question,
        }
      }) ?? [],
    partners:
      formStates.partners?.map((item) => {
        return {
          imageKey: item.isLocalImage
            ? item.url
            : `${import.meta.env.VITE_S3 as string}/${item.url}`,
        }
      }) ?? [],
    reactions: [
      {
        activeUserReactionId: 1,
        count: 10,
        emoji: '',
      },
    ],
    lowestTicketPrice: 10,
  }

  const { currentOrganization } = useCurrentOrganization()

  async function handleClick(): Promise<void> {
    let response
    setShowCreateEventLoaderModal(true)
    if (typeOperation === 'Create') {
      response = await createEvent({
        ...formStates,
        spotifyPlaylist: eventData.spotify ?? null,
        organizationId: currentOrganization!.organizer.organizationId,
        eventType: formStates.type!,
      })
      if (response.status) {
        setActionSuccess(true)
        if (refreshOrganizations !== undefined) await refreshOrganizations()

        setTimeout(navigate, 2000, `/events/${response.data!.alias}`)
        setTimeout(updateAnyStateCreateEvent, 2000, { canReset: true })
      } else {
        setShowCreateEventLoaderModal(false)
        showError(CANT_CREATE_EVENT)
      }
    } else {
      response = await updateEvent({
        ...formStates,
        spotifyPlaylist: eventData.spotify ?? null,
        organizationId: currentOrganization!.organizer.organizationId,
      })
      if (response.status) {
        setActionSuccess(true)
        if (refreshOrganizations !== undefined) await refreshOrganizations()

        setTimeout(navigate, 2000, `/events/${alias}`)
        setTimeout(reset, 2000)
      } else {
        setShowCreateEventLoaderModal(false)
        showError(CANT_UPDATE_EVENT)
      }
    }
  }

  function hasContent(str: string): boolean {
    return !/^\n+$/.test(str)
  }

  return (
    <>
      <div className="size-full">
        <div className="absolute z-20 hidden w-full bg-transparent py-4 pl-4 desktop:block">
          <button
            onClick={() => {
              if (typeOperation === 'Update') {
                navigate(`/events/${alias}/edit`, {
                  state: { fetchData: false, resetStore: false },
                })
              } else {
                navigate(`/create-event`, {
                  state: { fetchData: false, resetStore: false },
                })
              }
            }}
            className="rounded-full text-white transition-colors hover:text-zinc-300"
          >
            <BsFillArrowLeftCircleFill className="size-8 fill-current" />
          </button>
        </div>
        <IphoneFrameMask>
          <EventBase.Root>
            <div className="flex h-fit w-full flex-col gap-2 bg-background-main p-4 pb-0">
              <EventBase.Image
                imageKey={eventData?.imageKey}
                imgClassName="rounded-lg desktop:rounded-t-[50px]"
              />
              <div className="relative z-10 w-full">
                <EventBase.Brief
                  title={eventData?.title}
                  date={datesToFormat(
                    eventData.date.startDate!,
                    eventData.date.endDate!,
                  )}
                  location={eventData?.location?.name ?? ''}
                  titleImage={eventData?.titleImageKey}
                  highlighted
                  background="bg-background-main"
                  variant="white"
                />
              </div>
            </div>
            <motion.div
              className="w-full"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.2, ease: 'easeInOut' }}
            >
              <div className="flex flex-col gap-8">
                {isTruthy(eventData?.description) && (
                  <EventBase.Description text={eventData?.description} />
                )}
                {isTruthy(eventData?.lineup) &&
                  (eventData?.lineup?.length ?? 0) > 0 && (
                    <EventBase.Bit title="Lineup" className="px-0">
                      <EventBase.LineUp artists={eventData?.lineup} />
                    </EventBase.Bit>
                  )}
                {isTruthy(eventData?.spotify) && (
                  <EventBase.Playlist spotify={eventData?.spotify} />
                )}
                {(isTruthy(eventData?.location) ||
                  isTruthy(eventData?.terms) ||
                  isTruthy(eventData?.faq)) && (
                  <EventBase.Bit title="Infos">
                    <>
                      <EventBase.Info.Time eventData={eventData} />
                      {isTruthy(eventData?.location?.name) && (
                        <EventBase.Info.Location
                          name={eventData?.location!.name}
                          fullAddress={eventData?.location!.address}
                          latitude={eventData?.location!.latitude}
                          longitude={eventData?.location!.longitude}
                        />
                      )}
                      {isTruthy(eventData?.terms) &&
                        hasContent(eventData.terms!) && (
                          <EventBase.Info.Terms terms={eventData?.terms} />
                        )}
                      {isTruthy(eventData?.faq) &&
                        eventData.faq!.length > 0 && (
                          <EventBase.Info.Faq
                            questionsAnswers={eventData?.faq}
                          />
                        )}
                    </>
                  </EventBase.Bit>
                )}
                {isTruthy(eventData?.partners) &&
                  eventData.partners!.length > 0 && (
                    <EventBase.Bit title="Parceiros" className="px-0">
                      <EventBase.Partners partners={eventData?.partners} />
                    </EventBase.Bit>
                  )}
              </div>
            </motion.div>
          </EventBase.Root>

          <EventBase.Footer
            title={'A partir de'}
            value={'R$ -'}
            subtitle={''}
            buttonText={'Publicar'}
            buttonEnabled={
              !isLoadingCreateEvent && !isLoadingUpdateEvent && !isRefreshing
            }
            buttonOnClick={() => {
              void handleClick()
            }}
            isLoading={
              isLoadingCreateEvent || isLoadingUpdateEvent || isRefreshing
            }
          />
        </IphoneFrameMask>
      </div>
      {showCreateEventLoaderModal && (
        <CreateEventLoaderModal
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(setShowCreateEventLoaderModal, 400, false)
          }}
          isParentClosing={isParentClosing}
          actionSuccess={actionSuccess}
          type={typeOperation}
        />
      )}
    </>
  )
}
