import { type ReactElement } from 'react'
import { BsFillArrowLeftCircleFill } from 'react-icons/bs'
import { useNavigate, useParams } from 'react-router-dom'
import { tv } from 'tailwind-variants'

import { wrapperStore } from '@/store/wrapper'
import { useCurrentEvent } from '@/store/eventMenu'

import { isTruthy } from '@/utils/validation'
import { resolvePathWithParams } from '@/utils/resolvePathWithParams'

interface HeaderProps {
  variant: 'desktop' | 'mobile'
}

export function Header({ variant }: HeaderProps): ReactElement {
  const { header, showHeaderButton } = wrapperStore()
  const { currentEvent } = useCurrentEvent()

  const params = useParams()
  const navigate = useNavigate()

  const containerVariant = tv({
    base: 'flex p-4 bg-dark-black items-center h-20 w-full',
    variants: {
      background: {
        desktop: 'rounded-t-md justify-between',
        mobile: 'justify-between',
      },
    },
  })

  const titleSubtitleContainerVariant = tv({
    base: 'flex flex-col gap-0 overflow-hidden',
    variants: {
      background: {
        desktop: 'items-start',
        mobile: 'items-end',
      },
    },
  })

  return (
    <div className={containerVariant({ background: variant })}>
      {variant === 'mobile' && (
        <button
          onClick={() => {
            if (isTruthy(header.backButtonPath)) {
              const path = resolvePathWithParams(header.backButtonPath, params)
              navigate(path, { state: { fetchData: false, resetStore: false } })
            } else {
              navigate(-1)
            }
          }}
          className="pr-4"
        >
          <BsFillArrowLeftCircleFill className="size-8" fill="#ffffff" />
        </button>
      )}
      <div className={titleSubtitleContainerVariant({ background: variant })}>
        {isTruthy(header.titleImageKey) ? (
          <img
            src={`${import.meta.env.VITE_S3 as string}/${header.titleImageKey}`}
            alt="Imagem de título"
            className="aspect-auto h-12"
          />
        ) : (
          <div className="flex max-w-full items-center gap-2">
            {isTruthy(header.subtitle) && (
              <img
                src={`${import.meta.env.VITE_S3}/${currentEvent?.imageKey}`}
                alt="Imagem do evento"
                className="aspect-square size-6 rounded-full"
              />
            )}
            <span className="line-clamp-1 w-full text-ellipsis text-nowrap text-lg font-black text-white">
              {header.title}
            </span>
          </div>
        )}

        <span className="text-sm text-dark-dark-gray">{header.subtitle}</span>
      </div>
      {header.button !== undefined &&
        showHeaderButton &&
        variant === 'desktop' &&
        header.button}
    </div>
  )
}
