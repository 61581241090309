import { type ReactElement } from 'react'
import { Link, useParams } from 'react-router-dom'

import { PieChart } from '@/components/Chart/PieChart'

import { type ITicketData } from '@/types/Event'

import { isTruthy } from '@/utils/validation'

interface TicketsCardProps {
  data: ITicketData[]
}

export function TicketsCard({ data }: TicketsCardProps): ReactElement {
  const { alias } = useParams()
  return (
    <>
      <Link
        className="flex w-full items-center justify-between"
        to={`/events/${alias}/tickets`}
      >
        <div className="relative flex w-full flex-col overflow-scroll">
          <header className="pb-4 pl-4 text-base font-bold">Ingressos</header>
          {data.length === 0 && (
            <span className="pl-4 text-sm text-dark-dark-gray">
              Nenhum ingresso criado.
            </span>
          )}
          <div className="relative w-full">
            <section className="flex w-full gap-4 overflow-scroll scroll-smooth pl-4">
              <div className="flex w-max gap-6">
                {data.map((ticketSpec) => {
                  const ticketSpecDescription = isTruthy(
                    ticketSpec.batchDescription,
                  )
                    ? `${ticketSpec.batchDescription} - ${ticketSpec.ticketSpecDescription}`
                    : ticketSpec.ticketSpecDescription
                  return (
                    <div
                      className="flex h-full w-fit items-center gap-2"
                      key={ticketSpecDescription}
                    >
                      {ticketSpec.percentage !== undefined ? (
                        <div>
                          <div className="size-10">
                            <PieChart
                              progress={Math.round(ticketSpec.percentage * 100)}
                              progressColor={'#D5FF5C'}
                              barColor={'#626262'}
                              customStartAngle={90}
                              isPercent={ticketSpec.percentage !== null}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="mr-1 flex items-center justify-center">
                          <span className="w-full text-center text-2xl font-bold shadow-primary-dark">
                            {ticketSpec.numberOfSoldTickets}
                          </span>
                        </div>
                      )}
                      <span className="line-clamp-2 min-w-[80px] max-w-[140px] text-ellipsis text-2xs leading-tight">
                        {ticketSpecDescription}
                      </span>
                    </div>
                  )
                })}
              </div>
            </section>
            <div className="absolute left-0 top-0 z-10 h-full w-5 bg-gradient-to-l from-transparent to-dark-black" />
            <div className="absolute -right-0.5 top-0 z-10 h-full w-5 bg-gradient-to-r from-transparent to-dark-black" />
          </div>
        </div>
      </Link>
    </>
  )
}
