import { type Params } from 'react-router-dom'

export function resolvePathWithParams(
  path: string,
  params: Readonly<Params<string>>,
): string {
  return path.replace(/:(\w+)/g, (_, key) => {
    const param: string | undefined = params[key as string]
    return param ?? `:${key}`
  })
}
