import { api } from '@/services/axios'
import { type IGetSearchUsersOfEventReturn, type IUserData } from '@/types/List'
import { type AxiosResponse } from 'axios'
import { useState } from 'react'

export const useGetSearchList = (): {
  searchUsers: (
    searchParam: string,
    alias: string,
    skip?: number,
    take?: number,
  ) => Promise<IGetSearchUsersOfEventReturn>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function searchUsers(
    searchParam: string,
    alias: string,
    page: number = 0,
  ): Promise<IGetSearchUsersOfEventReturn> {
    setIsLoading(true)
    const skip = page * 50
    const take = 50
    const shouldFetch = alias !== undefined && searchParam !== undefined
    if (!shouldFetch) {
      setIsLoading(false)
      return {
        status: false,
      }
    }

    try {
      const response: AxiosResponse<IUserData[]> = await api.get(
        `/admin/events/${alias}/users?term=${searchParam}&skip=${skip}&take=${take}`,
      )
      setIsLoading(false)
      return {
        status: true,
        users: response.data.map((user) => {
          return {
            ...user,
          }
        }),
      }
    } catch (err) {
      setIsLoading(false)
      return {
        status: false,
      }
    }
  }

  return { searchUsers, isLoading }
}
