import { TicketCounter } from '@/components/Global/TicketCounter'
import { type IGrant } from '@/types/Grant'
import { formatPhoneNumber } from '@/utils/formatString'
import { isTruthy } from '@/utils/validation'
import { type ReactElement } from 'react'
import { SlArrowRight } from 'react-icons/sl'
import { twMerge } from 'tailwind-merge'

interface GratUserCardProps {
  grantData: IGrant
  handleOnClick: (user: IGrant) => void
}

export function GrantUserCard({
  grantData,
  handleOnClick,
}: GratUserCardProps): ReactElement {
  function setTicketType(): string {
    const firstString = isTruthy(grantData.products[0].batchDescription)
      ? `${grantData.products[0].batchDescription} - ${grantData.products[0].ticketSpecDescription}`
      : grantData.products[0].ticketSpecDescription

    for (let i = 1; i < grantData.products.length; i++) {
      const currentString = isTruthy(grantData.products[i].batchDescription)
        ? `${grantData.products[i].batchDescription} - ${grantData.products[i].ticketSpecDescription}`
        : grantData.products[i].ticketSpecDescription

      if (currentString !== firstString) {
        return 'Lotes variados'
      }
    }
    return firstString
  }

  function getSumOfQuantityProducts(): number {
    const sum = grantData.products.reduce((acc, prev) => {
      return acc + prev.quantity
    }, 0)
    return sum
  }

  return (
    <div
      className="transition-color flex max-w-full cursor-pointer items-center justify-between gap-4 rounded-md px-2 py-1.5 duration-100 ease-in-out desktop:hover:bg-dark-black"
      onClick={() => {
        handleOnClick(grantData)
      }}
    >
      <div className="flex w-3/4 items-center gap-2">
        <div className="size-12">
          <TicketCounter
            value={getSumOfQuantityProducts()}
            direction={1}
            variant="dark"
            isInfinite={false}
          />
        </div>
        <div className="flex w-full flex-col gap-0.5">
          <span className="truncate text-sm font-bold leading-4 text-white">
            {grantData.claimed
              ? `${grantData.user?.firstName} ${grantData.user?.lastName}`
              : grantData.communication.email ??
                formatPhoneNumber(grantData.communication.number!)}
          </span>

          <span className="truncate text-xs leading-3 text-[#a3a3a3]">
            {setTicketType()}
          </span>
          <span
            className={twMerge(
              'truncate text-xs leading-none',
              grantData.claimed
                ? 'font-bold text-primary-main'
                : 'text-text-terciary',
            )}
          >
            {grantData.claimed ? 'Recebido' : 'Enviado'}
          </span>
        </div>
      </div>
      <button className="flex size-6 items-center justify-center">
        <SlArrowRight color="#7c7c7c" size={12} />
      </button>
    </div>
  )
}
