// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
export function isTruthy(s: unknown | unknown[]): boolean {
  if (s === undefined || s === null || s === '' || s === 0 || s === false)
    return false
  return true
}

export function validateEmail(email: string): boolean {
  // Expressão regular para validar um endereço de e-mail
  const regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

  return regexEmail.test(email)
}

export function validatePhone(phone: string): boolean {
  // Expressão regular para validar um endereço de e-mail
  const regexPhone = /^[+\d() -]*$/

  return regexPhone.test(phone)
}

export function validatePixKey(chave: string): boolean {
  chave = chave.trim()

  // Expressões regulares para validar os formatos
  const regexCelular = /^\+[1-9]{1}[0-9]{1,14}$/
  const regexCpf = /^[0-9]{11}$/
  const regexCnpj = /^[0-9]{14}$/
  const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const regexPixAleatoria = /^[a-zA-Z0-9]{32}$/ // Chave PIX aleatória com exatamente 32 caracteres

  return (
    regexCelular.test(chave) ||
    regexCpf.test(chave) ||
    regexCnpj.test(chave) ||
    regexEmail.test(chave) ||
    regexPixAleatoria.test(chave)
  )
}

export function isValidCPF(cpf: string): boolean {
  cpf = cpf.replace(/\D/g, '') // Remove caracteres não numéricos

  if (cpf.length !== 11 || /^(.)\1+$/.test(cpf)) {
    return false // CPF deve ter 11 dígitos e não pode ser composto por apenas um número repetido
  }

  let sum = 0
  let remainder

  for (let i = 1; i <= 9; i++) {
    sum += parseInt(cpf[i - 1]) * (11 - i)
  }

  remainder = (sum * 10) % 11

  if (remainder === 10 || remainder === 11) {
    remainder = 0
  }

  if (remainder !== parseInt(cpf[9])) {
    return false
  }

  sum = 0

  for (let i = 1; i <= 10; i++) {
    sum += parseInt(cpf[i - 1]) * (12 - i)
  }

  remainder = (sum * 10) % 11

  if (remainder === 10 || remainder === 11) {
    remainder = 0
  }

  return remainder === parseInt(cpf[10])
}

export function isValidCNPJ(cnpj: string): boolean {
  cnpj = cnpj.replace(/\D/g, '') // Remove caracteres não numéricos

  if (cnpj.length !== 14 || /^(.)\1+$/.test(cnpj)) {
    return false // CNPJ deve ter 14 dígitos e não pode ser composto por apenas um número repetido
  }

  let sum = 0
  let position = 5

  for (let i = 0; i < 12; i++) {
    sum += parseInt(cnpj[i]) * position--

    if (position < 2) {
      position = 9
    }
  }

  let remainder = sum % 11

  if (remainder < 2) {
    remainder = 0
  } else {
    remainder = 11 - remainder
  }

  if (remainder !== parseInt(cnpj[12])) {
    return false
  }

  sum = 0
  position = 6

  for (let i = 0; i < 13; i++) {
    sum += parseInt(cnpj[i]) * position--

    if (position < 2) {
      position = 9
    }
  }

  remainder = sum % 11

  if (remainder < 2) {
    remainder = 0
  } else {
    remainder = 11 - remainder
  }

  return remainder === parseInt(cnpj[13])
}

export function handleValidateXlsxFile(file: File): boolean {
  const extension = file.name.split('.').pop()!.toLowerCase()
  if (extension === 'xlsx') return true
  return false
}
