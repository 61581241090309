import { type ReactElement } from 'react'

export function LinkTableHeader(): ReactElement {
  return (
    <div className="flex w-full items-center justify-start p-2">
      <div className="min-w-[80px] max-w-[80px]"></div>

      <div className="flex size-full">
        <div className="w-2/5">
          <span className="line-clamp-1 text-base font-bold">Link</span>
        </div>

        <div className="w-2/5">
          <span className="line-clamp-1 text-base font-bold">Tipo</span>
        </div>

        <div className="w-1/6">
          <span className="line-clamp-1 text-base font-bold">Vendas</span>
        </div>
      </div>

      <div className="min-w-[160px] max-w-[160px]"></div>
    </div>
  )
}
