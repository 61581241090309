import { useState, type ReactElement } from 'react'
import * as z from 'zod'

import {
  linkStore,
  useAllLinks,
  useCurrentLink,
  // useCurrentOperationType,
  useRefreshLinks,
} from '@/store/link'

import { useUpdateLink } from '@/hooks/Link'

import { currencyMask } from '@/utils/currencyMask'
import { percentMask } from '@/utils/percentMask'
import { numberToPercent, numberToReais } from '@/utils/formatNumber'
import { isTruthy } from '@/utils/validation'

import { Form } from '@/components/Global/Form/Form'
import { FormInput } from '@/components/Global/Form/FormInput'
import { Selector } from '@/components/Global/Selector'
import { Button } from '@/components/Global/Button'

import { errorStore } from '@/store/error'

export function LinkDiscountFormUpdate(): ReactElement {
  const createDiscountLink = z.object({
    code: z
      .string()
      .min(2, { message: 'O nome do código precisa ter pelo menos 2 letras.' })
      .refine(
        (value) => {
          const nameExists = allLinks.find((link) => link.code === value)
          if (nameExists !== undefined && currentOperationType === 'create') {
            return false
          } else {
            return true
          }
        },
        {
          message: 'Ja existe um código com esse nome',
        },
      )
      .refine(
        (value) => {
          const regex = /^[a-zA-Z0-9-_]+$/
          if (regex.test(value)) {
            return true
          } else {
            return false
          }
        },
        {
          message: 'O nome do link não pode ter caracteres especiais e espaço',
        },
      ),
    value: z
      .string()
      .nonempty({ message: 'O campo não pode ser vazio' })
      .refine(
        (value) => {
          if (selected === 'Percent') {
            const num = parseFloat(value.replace(',', '.').replace('%', ''))
            return !isNaN(num) && num !== 0
          } else {
            const num = parseFloat(
              value.replace('R$', '').replace(/[.]/g, '').replace(',', '.'),
            )
            return !isNaN(num) && num !== 0
          }
        },
        {
          message: 'O valor não pode ser zero',
        },
      )
      .transform((data: string) => {
        if (selected === 'Fix') {
          const valueWithoutCurrency = data
            .replace('R$', '')
            .replace(/[.]/g, '')
            .replace(',', '.')
          return Number(valueWithoutCurrency)
        }
        const valueWithoutPercent = data.replace('%', '').replace(',', '.')
        return Number(valueWithoutPercent)
      }),
    limitByUser: z
      .string()
      .nullable()
      .transform((data) => (isTruthy(data) ? Number(data) : null)),
  })

  type FormData = z.infer<typeof createDiscountLink>

  const { updateLink, isLoading: isLoadingUpdateLink } = useUpdateLink()

  const { currentLink } = useCurrentLink()
  const { showError } = errorStore()
  const { allLinks } = useAllLinks()
  const { refreshLinks } = useRefreshLinks()
  const { updateLink: updateLinkStore, closeLinkModal } = linkStore()

  const [selected, setSelected] = useState<'Fix' | 'Percent'>(
    currentLink?.discount !== undefined
      ? currentLink.discount.amount !== undefined
        ? 'Fix'
        : 'Percent'
      : 'Fix',
  )

  const [resetField, setResetField] = useState(false)

  let defaultValues: Record<string, string | number> | null = null
  if (currentLink !== null) {
    defaultValues = {
      code: currentLink.code,
      limitByUser: String(currentLink.discount!.limit),
      value:
        currentLink.discount!.amount !== undefined
          ? numberToReais(currentLink.discount!.amount, 2)
          : numberToPercent(currentLink.discount!.percentage * 100, 2),
    }
  }

  const currentOperationType = currentLink !== null ? 'update' : 'create'

  async function handleUpdateDiscountLink(data: FormData): Promise<void> {
    const response = await updateLink({
      linkId: currentLink!.id,
      code: data.code,
      promoLimit: data.limitByUser,
      linkType: 'Discount',
      discount: {
        amount: selected === 'Fix' ? data.value : null,
        percentage: selected === 'Percent' ? data.value / 100 : null,
      },
    })

    if (!response.status) {
      showError('Não foi possível atualizar o link. Tente novamente')

      return
    }
    if (response.link !== undefined) {
      updateLinkStore(response.link)
    }
    refreshLinks()
    closeLinkModal()
  }

  return (
    <Form
      formSchema={createDiscountLink}
      className="flex w-full flex-col gap-4 px-4"
      handleModalClose={(data: FormData) => {
        void handleUpdateDiscountLink(data)
      }}
      defaultValues={defaultValues ?? undefined}
      resetObject={{ value: '' }}
      doReset={resetField}
      handleReset={() => {
        setResetField(false)
      }}
    >
      <FormInput
        type="text"
        id="code"
        placeholder="Código do link *"
        name="code"
        autoCorrect="off"
        autoCapitalize="none"
        isInputTitle={true}
      />
      <div className="flex flex-col gap-4 ">
        <Selector
          title={'Valor fixo'}
          subtitle={'Ofereça um desconto de um valor pré-definido.'}
          isSelected={selected === 'Fix'}
          onClick={(): void => {
            setResetField(true)
            setSelected('Fix')
          }}
          variant={'light'}
        />
        <Selector
          title={'Porcentagem'}
          subtitle={
            'Ofereça um desconto percentual em relação ao valor dos ingressos disponíveis.'
          }
          isSelected={selected === 'Percent'}
          onClick={(): void => {
            setResetField(true)
            setSelected('Percent')
          }}
          variant={'light'}
        />
      </div>
      {selected === 'Fix' ? (
        <FormInput
          label={'Valor *'}
          type="text"
          inputMode="numeric"
          id="value"
          placeholder="ex: R$ 230,00"
          name="value"
          onChangeFunction={currencyMask}
          autoCorrect="off"
          autoCapitalize="none"
        />
      ) : (
        <FormInput
          label={'Percentual *'}
          type="text"
          inputMode="numeric"
          id="value"
          placeholder="ex: 10%"
          name="value"
          onChangeFunction={percentMask}
          onChangeStartCursorFunction={(data) => data - 1}
          onChangeEndCursorFunction={(data) => data - 1}
          autoCorrect="off"
          autoCapitalize="none"
        />
      )}

      <FormInput
        label={'Limite de ingressos'}
        type="number"
        id="limitByUser"
        placeholder="Ex: 3"
        name="limitByUser"
        autoCorrect="off"
        autoCapitalize="none"
      />
      <div className="h-12 w-full max-w-[474px] self-center">
        <Button
          type="submit"
          enabled={!isLoadingUpdateLink}
          text="Salvar"
          isLoading={isLoadingUpdateLink}
        />
      </div>
    </Form>
  )
}
