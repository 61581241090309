import { useState } from 'react'
import { api } from '@/services/axios'
import { type AxiosResponse, type AxiosError } from 'axios'

import { type IUser } from '@/types/Team'

interface IReturn {
  status: number
  users?: IUser[]
}

export const useGetUserSearch = (): {
  search: (user: string) => Promise<IReturn>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function search(user: string): Promise<IReturn> {
    setIsLoading(true)
    const shouldFetch = user !== undefined
    if (!shouldFetch) {
      setIsLoading(false)
      return {
        status: 400,
      }
    }

    try {
      const response: AxiosResponse<IUser[]> = await api.get(
        `/search/users?term=${user}&skip=0&take=1`,
      )
      setIsLoading(false)
      return {
        status: 200,
        users: response.data,
      }
    } catch (err) {
      const error = err as AxiosError
      setIsLoading(false)
      return {
        status: error.response?.status ?? 0,
      }
    }
  }

  return { search, isLoading }
}
