import { useState } from 'react'

import { api } from '@/services/axios'
import { type AxiosError } from 'axios'

interface ICreateTokenResponse {
  status: number
  token?: string
}

export const usePostNewToken = (
  organizationId: number,
): {
  createToken: () => Promise<ICreateTokenResponse>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function createToken(): Promise<ICreateTokenResponse> {
    setIsLoading(true)

    const body = {
      organizationId,
    }

    try {
      const response = await api.post<string>(`/organizationTokens`, body)

      setIsLoading(false)
      return {
        status: 200,
        token: response.data,
      }
    } catch (error) {
      setIsLoading(false)
      const err = error as AxiosError
      return {
        status: err.response?.status ?? 500,
      }
    }
  }

  return {
    createToken,
    isLoading,
  }
}
