import { numberToReais } from './formatNumber'

export function currencyMask(input: string): string {
  const value = input
  const cleanedValue = value.replace(/[^0-9]/g, '').replace(/^0+/, '')
  const number = Number(cleanedValue)
  const finalNumber = number / 100

  return numberToReais(finalNumber, 2)
}
