import { Modal } from '@/components/Global/Modal'
import { useCurrentError } from '@/store/error'
import { type ReactElement } from 'react'
import { GrantCreateForm } from '../GrantCreateForm'
import { deviceStore } from '@/store/device'

interface GrantCreateModalProps {
  closeModal: () => void
  handleModalClose: () => void
  isParentClosing?: boolean
  isLoading?: boolean
}

export function GrantCreateModal({
  closeModal,
  isParentClosing,
  handleModalClose,
}: GrantCreateModalProps): ReactElement {
  const { isError, errorMessage } = useCurrentError()
  const { isMobile } = deviceStore()

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      isError={isError}
      errorMessage={errorMessage}
      isMobile={isMobile}
    >
      <div className="flex size-full flex-col items-center gap-8 px-8 py-4 text-black">
        <GrantCreateForm handleOnClick={handleModalClose} />
      </div>
    </Modal>
  )
}
