import { useState, type ReactElement } from 'react'
import { motion } from 'framer-motion'

import { Button } from '@/components/Global/Button'

import closeFriendsBg from '@/assets/close_friends_bg.jpg'
import openEventBg from '@/assets/open_bg.jpg'

import { TbBarrierBlock } from 'react-icons/tb'
import { FaDollarSign } from 'react-icons/fa6'

import { createEventStore } from '@/store/createEvent'
import { deviceStore } from '@/store/device'

import { useGetCloseFriendsConditions } from '@/hooks/CreateEvent/api'
import { numberToReais, formatNumber } from '@/utils/formatNumber'
import { Spinner } from '@/components/Global/Spinner'
import { twMerge } from 'tailwind-merge'

export function CreateEventSelectType(): ReactElement {
  const [localEventType, setLocalEventType] = useState<
    'CLOSE_FRIENDS' | 'OPEN' | null
  >(null)

  const { setEventType } = createEventStore()
  const { isMobile } = deviceStore()

  const { conditions, isLoading } = useGetCloseFriendsConditions()

  return (
    <div className="flex size-full flex-col items-center justify-center gap-4 p-4 desktop:flex-row">
      <motion.div
        className={twMerge(
          'relative h-fit min-h-[200px] w-full cursor-pointer rounded-md transition-all duration-200 ease-in-out',
          !isMobile && 'h-[400px]',
        )}
        onClick={() => {
          setLocalEventType('CLOSE_FRIENDS')
        }}
        style={{ perspective: 1000 }}
      >
        <motion.div
          className={twMerge(
            'relative size-full rounded-md',
            isMobile ? 'h-[200px]' : 'h-[400px]',
          )}
          initial={{ rotateY: 0 }}
          animate={{
            rotateY: localEventType === 'CLOSE_FRIENDS' ? 180 : 0,
            height: localEventType === 'CLOSE_FRIENDS' ? '400px' : '100%',
          }}
          transition={{ duration: 0.6 }}
          style={{ transformStyle: 'preserve-3d' }}
        >
          <div
            className="absolute inset-0 flex rotate-0 flex-col justify-end overflow-hidden rounded-md bg-dark-black"
            style={{ backfaceVisibility: 'hidden' }}
          >
            <img
              src={closeFriendsBg}
              alt="Close friends background"
              className="absolute -z-10 size-full object-cover brightness-75"
            />
            <span className="px-4 text-xl font-bold">Close Friends</span>
            <span className="mb-2.5 mt-0.5 px-4 text-xs leading-4 text-white">
              Chega de pedir PIX para os amigos em grupo do WhatsApp! Crie um
              evento fechado chamando amigos próximos e conhecidos, crie
              ingressos e controle suas vendas. Confira as condições clicando
              aqui.
            </span>
          </div>
          <div
            className="absolute inset-0 flex flex-col justify-between rounded-md border-2 border-primary-main bg-dark-black/20 p-4"
            style={{
              backfaceVisibility: 'hidden',
              transform: 'rotateY(180deg)',
            }}
          >
            {isLoading ? (
              <div className="flex size-full items-center justify-center">
                <Spinner
                  borderWidth="border-[3px]"
                  borderColor="border-primary-main/50"
                  bottomBorderColor="border-b-primary-main"
                  width="w-8"
                  height="h-8"
                />
              </div>
            ) : (
              <div className="flex flex-col gap-4">
                <span className="text-xl font-bold">Condições</span>
                <div className="flex w-full flex-col gap-2">
                  <div className="flex w-full flex-col justify-between gap-2 rounded-md bg-background-main p-4">
                    <div className="flex items-center gap-2 font-bold">
                      <FaDollarSign size={28} color="#D5FF5C" />
                      <span>Taxas</span>
                    </div>
                    {conditions?.paymentMethods.length === 1 &&
                    conditions.paymentMethods[0].type === 'PIX' ? (
                      <span className="text-xs">
                        Pagamento apenas por{' '}
                        <span className="text-md font-bold">PIX</span> com taxa{' '}
                        <span className="text-md font-bold">
                          {formatNumber(
                            (conditions.paymentMethods[0].fee ?? 0) * 100,
                          )}
                          %
                        </span>
                        . A taxa será descontada do valor do ingresso.
                      </span>
                    ) : (
                      <div>
                        <span className="pb-0.5 text-xs">Pagamento por: </span>
                        <ul>
                          {conditions?.paymentMethods.map((method, index) => {
                            const feeName =
                              method.type === 'PIX'
                                ? 'PIX'
                                : method.type === 'CREDITCARD'
                                  ? 'Cartão de crédito'
                                  : 'Boleto'
                            return (
                              <li key={index} className="ml-2 text-xs">
                                <strong>{feeName}</strong>
                                {` com taxa de `}
                                <strong>
                                  {formatNumber((method.fee ?? 0) * 100)}%
                                </strong>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="flex w-full flex-col justify-between gap-2 rounded-md bg-background-main p-4">
                    <div className="flex items-center gap-2 font-bold">
                      <TbBarrierBlock size={32} color="#D5FF5C" />
                      <span>Limitações</span>
                    </div>
                    <span className="text-xs">
                      Faturamento máximo de{' '}
                      <span className="text-md font-bold">
                        {numberToReais(conditions?.maxRevenue ?? 0, 2)}
                      </span>{' '}
                      e quantidade limite de{' '}
                      <span className="text-md font-bold">
                        {conditions?.ticketLimit ?? 0}
                      </span>{' '}
                      pessoas. *
                    </span>
                  </div>
                </div>
                <span className="text-xs">
                  * Caso um dos valores seja extrapolado, passaremos a cobrar a
                  taxa integral de 10% sobre as vendas{' '}
                  <strong>seguintes</strong>.
                </span>
              </div>
            )}

            <Button
              text="Confirmar"
              enabled
              className="h-8 w-[100px] px-4 text-sm"
              spinnerHeight="h-6"
              spinnerWidth="w-6"
              onClick={() => {
                setEventType('CLOSE_FRIENDS')
              }}
            />
          </div>
        </motion.div>
      </motion.div>
      <div
        className={twMerge(
          'relative flex w-full cursor-pointer flex-col justify-end overflow-hidden rounded-md transition-all duration-100 ease-in-out',
          localEventType === 'OPEN' && 'border-2 border-primary-main',
          isMobile ? 'h-[200px]' : 'h-[400px]',
        )}
        onClick={() => {
          setLocalEventType('OPEN')
        }}
      >
        <img
          src={openEventBg}
          alt="Open event background"
          className="absolute size-full object-cover brightness-75"
        />
        <div className="z-10 flex size-full flex-col justify-end p-4">
          <span className="text-xl font-bold">Evento Aberto</span>
          <span className="mb-2.5 mt-0.5 text-xs leading-4 text-white">
            Uma plataforma profissional com todas as funcionalidades (e mais um
            pouco) pra fazer a gestão completa do seu evento!
          </span>
          <motion.div
            className="flex w-full"
            initial={{ opacity: 0, height: '0px' }}
            animate={
              localEventType === 'OPEN'
                ? { opacity: 1, height: '32px' }
                : { opacity: 0, height: '0px' }
            }
            transition={{ duration: 0.15 }}
            key="open-confirm"
          >
            <Button
              text="Confirmar"
              enabled
              className="h-8 w-[100px] px-4 text-sm"
              spinnerHeight="h-6"
              spinnerWidth="w-6"
              onClick={() => {
                setEventType('OPEN')
              }}
            />
          </motion.div>
        </div>
      </div>
    </div>
  )
}
