import { useState } from 'react'
import { type AxiosResponse } from 'axios'
import { api } from '@/services/axios'

import {
  type CreateEvent,
  type CreateEventDTO,
} from '@/types/CreateUpdateEvent'

import { isTruthy } from '@/utils/validation'
import { handleImageSize } from '@/utils/formatImage'

interface PostReturnProps {
  status: boolean
  data?: CreateEventDTO
  message?: string
}

export const usePostCreateEvent = (): {
  createEvent: (body: CreateEvent) => Promise<PostReturnProps>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function createEvent(body: CreateEvent): Promise<PostReturnProps> {
    setIsLoading(true)

    const formData = new FormData()
    const backgroundImage = await handleImageSize(
      1024,
      1024,
      1,
      body.backgroundArtImage!.key!,
    )
    formData.append('imageKey', backgroundImage)
    formData.append('name', body.eventName!)

    if (body.eventDescription !== undefined) {
      formData.append('description', body.eventDescription)
    }
    if (body.startDate !== undefined) {
      formData.append('startDate', body.startDate)
    }
    if (body.maxEntranceDate !== undefined) {
      formData.append('maxEntranceDate', body.maxEntranceDate)
    }

    if (body.endDate !== undefined) {
      formData.append('endDate', body.endDate)
    }

    formData.append('organizationId', String(body.organizationId))

    if (body.titleImage !== null) {
      formData.append('titleImage', body.titleImage.key!)
    }

    if (body.sticker !== null) {
      const stickerImage = await handleImageSize(
        1024,
        1024,
        1,
        body.sticker.key!,
      )
      formData.append('sticker', stickerImage)
    }

    if (isTruthy(body.location)) {
      const locationJSON = {
        address: body.location!.address,
        name: body.location!.name,
        latitude: body.location?.latitude,
        longitude: body.location?.longitude,
      }
      formData.append('location', JSON.stringify(locationJSON))
    }

    if (body.spotifyPlaylist !== null) {
      formData.append('playlist', body.spotifyPlaylist)
    }

    if (body.FAQs !== null) {
      formData.append('faq', JSON.stringify(body.FAQs))
    }

    if (body.terms !== null) {
      formData.append('terms', body.terms)
    }

    if (body.lineUp !== null) {
      for (const artist of body.lineUp) {
        if (artist.artistImage !== null) {
          const artistImage = await handleImageSize(
            1024,
            1024,
            1,
            artist.artistImage,
          )
          formData.append(`lineup`, artistImage, `${artist.artistName}`)
        }
      }
    }

    if (body.partners !== null) {
      for (const partner of body.partners) {
        const partnerImage = await handleImageSize(1024, 1024, 1, partner.key!)
        formData.append(`partners`, partnerImage)
      }
    }

    if (body.eventType !== null) {
      formData.append('eventType', body.eventType)
    }

    try {
      const response: AxiosResponse<CreateEventDTO> = await api.post(
        `/admin/events`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )

      setIsLoading(false)

      return {
        status: true,
        data: response.data,
      }
    } catch (error) {
      setIsLoading(false)
      return {
        status: false,
        message: 'Evento não pôde ser criado',
      }
    }
  }

  return {
    createEvent,
    isLoading,
  }
}
