import { useState } from 'react'
import { type AxiosResponse } from 'axios'
import { api } from '@/services/axios'

interface PutResponseProps {
  code?: string
}

interface PutReturnProps {
  status: boolean
  code?: string
  message?: string
}

export const usePutSwitchCheckInCodeState = (
  alias: string,
): {
  switchCheckInCodeState: (body: { code: string }) => Promise<PutReturnProps>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function switchCheckInCodeState(body: {
    code: string
  }): Promise<PutReturnProps> {
    setIsLoading(true)
    try {
      const response: AxiosResponse<PutResponseProps> = await api.put(
        `/admin/events/${alias}/check-in-code`,
        body,
      )

      setIsLoading(false)

      return {
        status: true,
        code: response.data.code,
      }
    } catch (error) {
      setIsLoading(false)
      return {
        status: false,
        message: 'O estado do código não pôde ser alterado',
      }
    }
  }

  return {
    switchCheckInCodeState,
    isLoading,
  }
}
