import { type ReactElement, useState, createRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import { twMerge } from 'tailwind-merge'

import { CircularProgress } from '@/components/Global/CircularProgress'
import { Button } from '@/components/Global/Button'

import { FiEdit2 } from 'react-icons/fi'
import { BsCheckLg } from 'react-icons/bs'

import { VALIDATE_TOKEN_ERROR } from '@/errors'

import { userStore } from '@/store/user'
import { routingStore } from '@/store/routing'

import { usePostRequestPhoneOTP, usePostVerifyPhoneOTP } from '@/hooks/Auth'

import { isTruthy } from '@/utils/validation'
import { formatPhoneNumber } from '@/utils/formatString'

interface TwoFactorProps {
  phoneNumber?: string
  variant: 'phone' | 'email'
  communicationMethod: 'SMS' | 'WHATSAPP'
  setState: (
    state:
      | 'login'
      | 'twoFactorPhone'
      | 'signup'
      | 'profilePicture'
      | 'communicationMethod',
  ) => void
  showError: (errorMessage: string, screenTime?: number) => void
  setPhoneNumberValidationKey: (v: string) => void
}

export function TwoFactor({
  phoneNumber = '',
  variant,
  communicationMethod,
  setState,
  showError,
  setPhoneNumberValidationKey,
}: TwoFactorProps): ReactElement {
  const [twoFactorValue, setTwoFactorValue] = useState<string[]>([
    '',
    '',
    '',
    '',
  ])
  const [countdown, setCountdown] = useState<number>(30)
  const [currentIntervalId, setCurrentIntervalId] = useState<NodeJS.Timeout>()
  const [resetFocus, setResetFocus] = useState(false)

  const refs = [
    createRef<HTMLInputElement>(),
    createRef<HTMLInputElement>(),
    createRef<HTMLInputElement>(),
    createRef<HTMLInputElement>(),
  ]

  const navigate = useNavigate()

  const countryCode = phoneNumber.slice(0, 3)
  const number = phoneNumber.slice(3)

  const { loginUser, setUserIsLogged } = userStore()
  const { toPath, setToPath, setShowLogin } = routingStore()

  const { postVerifyPhoneOtp, isLoading: isLoadingPhoneVerification } =
    usePostVerifyPhoneOTP()
  const { postRequestPhoneOtp } = usePostRequestPhoneOTP()

  const inputStyle =
    'transition-color h-16 w-12 rounded-xl border-2 bg-white p-2 text-center text-2xl font-black duration-100 ease-in-out focus:border-primary-main focus:outline-none'

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ): void => {
    const pattern = /^[0-9]$/
    if (pattern.test(event.target.value)) {
      setTwoFactorValue((prevState) => {
        const newState: string[] = [...prevState]
        newState[index] = event.target.value
        return newState
      })
      if (refs[index + 1] !== undefined) {
        refs[index + 1].current?.focus()
      }
    } else if (event.target.value === '') {
      setTwoFactorValue((prevState) => {
        const newState: string[] = [...prevState]
        newState[index] = event.target.value
        return newState
      })
    }
  }

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ): void => {
    if (event.key === 'Backspace' && twoFactorValue[index] === '') {
      if (index > 0 && refs[index - 1] !== undefined) {
        refs[index - 1].current?.focus()
      }
    }
  }

  async function handleClickValidatePhone(): Promise<void> {
    const validationResponse = await postVerifyPhoneOtp({
      phoneNumber,
      code: twoFactorValue.join(''),
    })

    if (validationResponse.status === 401) {
      setPhoneNumberValidationKey(validationResponse.phoneNumberValidationKey!)
      setState('signup')
      return
    }
    if (validationResponse.status === 200 && validationResponse.user != null) {
      const {
        id,
        username,
        firstName,
        lastName,
        email,
        phoneNumber,
        imageKey,
        cpf,
        verifiedEmail,
      } = validationResponse.user

      loginUser({
        id,
        username,
        firstName,
        lastName,
        email,
        imageKey,
        phoneNumber,
        balance: 0,
        cpf,
        verifiedEmail,
      })
      setShowLogin(false)
      setUserIsLogged(true)
      if (toPath === '') {
        navigate('/')
      } else if (toPath === 'none') {
        // pass
      } else if (toPath === '/memories/') {
        navigate(`/memories/${username}`)
      } else {
        navigate(toPath)
      }
      setToPath('')
      return
    }

    showError(VALIDATE_TOKEN_ERROR)
    refs[0].current?.focus()
    setTwoFactorValue(['', '', '', ''])
    setResetFocus(true)
  }

  function startTimer(): void {
    // 1. Clear the current interval
    // 2. Create a new interval
    // 3. Set the current interval id
    // 4. Clear the new interval after 30 seconds

    clearInterval(currentIntervalId)
    const intervalId = setInterval(() => {
      setCountdown((prev) => prev - 1)
    }, 1000)
    setCurrentIntervalId(intervalId)
    setTimeout(() => {
      clearInterval(intervalId)
    }, 31000)
  }

  useEffect(() => {
    startTimer()
  }, [])

  useEffect(() => {
    if (resetFocus) {
      refs[0].current?.focus()
      setResetFocus(false)
    }
  }, [resetFocus])

  return (
    <div className="relative my-10 flex h-80 w-full flex-col items-center justify-between p-4">
      <div className="flex items-center">
        {isTruthy(phoneNumber) && variant === 'phone' && (
          <h1 className="mr-4 text-center text-xl text-text-dark">
            {countryCode === '+55'
              ? `🇧🇷 ${countryCode} ${formatPhoneNumber(number)}`
              : `🌎 ${phoneNumber}`}
          </h1>
        )}
        <FiEdit2
          className="hover:cursor-pointer"
          color="#232323"
          size={20}
          onClick={() => {
            setState('login')
          }}
        />
      </div>

      <div className="flex gap-4">
        {refs.map((ref, index) => {
          return (
            <input
              key={index}
              className={inputStyle}
              type="number"
              value={twoFactorValue[index]}
              onChange={(e) => {
                handleInputChange(e, index)
              }}
              ref={ref}
              maxLength={1}
              inputMode="numeric"
              max={9}
              onKeyDown={(e) => {
                handleKeyDown(e, index)
                if (e.key === 'Enter' && twoFactorValue[3] !== '') {
                  void handleClickValidatePhone()
                }
              }}
              autoFocus={index === 0}
            />
          )
        })}
      </div>

      <div className="flex w-full flex-col items-center">
        <span className="text-sm text-text-dark">
          {`Digite o código que foi enviado por ${
            variant === 'phone'
              ? communicationMethod === 'WHATSAPP'
                ? 'WhatsApp'
                : 'SMS'
              : 'e-mail'
          }.`}
        </span>
        <div className="mt-1 flex w-full items-center justify-center gap-2">
          <button
            className={twMerge(
              'transition-color z-20 w-full rounded-full py-1 text-end text-xs font-bold duration-100 ease-in-out',
              countdown > -1 ? 'text-text-secondary' : 'text-text-dark',
            )}
            onClick={() => {
              setCountdown(30)
              startTimer()
              void postRequestPhoneOtp(phoneNumber, communicationMethod)
            }}
            disabled={countdown > -1}
          >
            <span className="text-nowrap">Reenviar código</span>
          </button>
          <div className="relative">
            <CircularProgress
              size={30}
              progressColor="#D5FF5C"
              bgProgressColor="#12121230"
              strokeWidth={3}
              percentValue={countdown <= -1 ? 0 : -countdown / 30}
              value={countdown <= -1 ? undefined : countdown}
              valueClassName="text-xs font-bold text-text-dark"
            />
            {countdown <= -1 && (
              <motion.div
                className="absolute left-[47%] top-1/2 -translate-x-1/2 -translate-y-1/2"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
              >
                <BsCheckLg size={20} color="#000" />
              </motion.div>
            )}
          </div>
          <button
            className={twMerge(
              'transition-color z-20 w-full rounded-full py-1 text-start text-xs font-bold duration-100 ease-in-out',
              countdown > -1 ? 'text-text-secondary' : 'text-text-dark',
            )}
            onClick={() => {
              setCountdown(30)
              startTimer()
              if (communicationMethod === 'WHATSAPP') {
                void postRequestPhoneOtp(phoneNumber, 'SMS')
              } else {
                void postRequestPhoneOtp(phoneNumber, 'WHATSAPP')
              }
            }}
            disabled={countdown > -1}
          >
            <span className="text-nowrap">
              Enviar por{' '}
              {communicationMethod === 'WHATSAPP' ? 'SMS' : 'WhatsApp'}
            </span>
          </button>
        </div>
      </div>
      <div className="h-12 w-40">
        <Button
          onClick={() => {
            void handleClickValidatePhone()
          }}
          enabled={twoFactorValue[3] !== '' && !isLoadingPhoneVerification}
          text="Validar"
          className="text-2xl font-black disabled:bg-dark-light-gray"
          isLoading={isLoadingPhoneVerification}
        />
      </div>
    </div>
  )
}
