import {
  type ReactElement,
  useRef,
  useState,
  useEffect,
  type FormEvent,
} from 'react'

import { useCurrentError } from '@/store/error'
import { deviceStore } from '@/store/device'

import { Button } from '@/components/Global/Button'
import { Modal } from '@/components/Global/Modal'

import {
  type ICreateEventStoreState,
  type IUpdateEventStoreState,
} from '@/types/CreateUpdateEvent'

import { isTruthy } from '@/utils/validation'

interface CreateEventCreateFAQsModalProps {
  closeModal: () => void
  handleModalClose: () => void
  isParentClosing?: boolean
  isLoading?: boolean
  formStates: ICreateEventStoreState | IUpdateEventStoreState
  updateAnyState:
    | ((update: Partial<ICreateEventStoreState>) => void)
    | ((update: Partial<IUpdateEventStoreState>) => void)
}

export function CreateEventCreateFAQsModal({
  closeModal,
  isParentClosing,
  handleModalClose,
  formStates,
  updateAnyState,
}: CreateEventCreateFAQsModalProps): ReactElement {
  const [question, setQuestion] = useState('')
  const [answer, setAnswer] = useState('')

  const [questionError, setQuestionError] = useState('')
  const [answerError, setAnswerError] = useState('')

  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  const { isError, errorMessage } = useCurrentError()
  const { isMobile } = deviceStore()

  function handleFormSubmit(e: FormEvent): void {
    e.preventDefault()
    if (!checkQuestion() || !checkAnswer()) return
    if (formStates.FAQs === null) {
      const faqs = []
      faqs?.push({
        question,
        answer,
      })
      updateAnyState({ FAQs: faqs })
    } else {
      const currentFaqs = formStates.FAQs
      currentFaqs?.push({
        question,
        answer,
      })
      updateAnyState({ FAQs: currentFaqs })
    }

    handleModalClose()
  }

  function checkQuestion(): boolean {
    if (question.length <= 3) {
      setQuestionError('A pergunta deve ter pelo menos 3 letras.')
      return false
    } else {
      setQuestionError('')
      return true
    }
  }

  function checkAnswer(): boolean {
    if (answer.length <= 3) {
      setAnswerError('A resposta deve ter pelo menos 3 letras.')
      return false
    } else {
      setAnswerError('')
      return true
    }
  }

  useEffect(() => {
    if (textAreaRef.current !== null) {
      textAreaRef.current.style.height = '0px'
      const scrollHeight = textAreaRef.current.scrollHeight

      textAreaRef.current.style.height = scrollHeight + 'px'
    }
  }, [textAreaRef, answer])

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      isError={isError}
      errorMessage={errorMessage}
      isMobile={isMobile}
    >
      <div className="flex h-fit w-full flex-col items-center gap-8 px-8 py-4 text-black">
        <div className="flex w-full flex-col">
          <strong className="mb-4 text-3xl font-black">FAQs</strong>
          <form
            onSubmit={(e) => {
              handleFormSubmit(e)
            }}
            className="flex flex-col "
          >
            <input
              type="text"
              placeholder="Pergunta"
              value={question}
              onChange={(e) => {
                setQuestion(e.target.value)
              }}
              onBlur={() => {
                if (isTruthy(answer)) {
                  checkQuestion()
                }
              }}
              className="appearance-none border-none pb-2 text-3xl font-medium leading-7 outline-none placeholder:text-3xl placeholder:font-bold placeholder:text-text-terciary placeholder:opacity-80 focus:border-0"
            />
            <textarea
              className="transition-color ease-in-outplaceholder:text-text-terciary h-[75px] max-h-[200px] min-h-[75px] w-full resize-none appearance-none overflow-scroll rounded-lg border-2 border-dark-light-gray p-2 text-sm outline-none duration-200 placeholder:opacity-80 focus:border-primary-main focus:border-x-primary-main focus:border-t-primary-main"
              placeholder="Resposta"
              ref={textAreaRef}
              value={answer}
              onChange={(e) => {
                setAnswer(e.target.value)
              }}
              onBlur={() => {
                if (isTruthy(answer)) {
                  checkAnswer()
                }
              }}
            />
            <div className="flex h-8 w-full flex-col">
              {isTruthy(questionError) && (
                <span className="whitespace-nowrap text-xs font-extrabold text-tonal-red">
                  {questionError}
                </span>
              )}

              {isTruthy(answerError) && (
                <span className="whitespace-nowrap text-xs font-extrabold text-tonal-red">
                  {answerError}
                </span>
              )}
            </div>

            <div className="mt-1 flex h-12 w-full">
              <Button enabled={true} text="Salvar" />
            </div>
          </form>
        </div>
      </div>
    </Modal>
  )
}
