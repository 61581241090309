import { useState } from 'react'
import { type AxiosError } from 'axios'
import { api } from '@/services/axios'

interface DeleteReturnProps {
  status: number
}

export const useDeleteEventRole = (
  eventAlias: string,
): {
  deleteEventRole: (userId: number) => Promise<DeleteReturnProps>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function deleteEventRole(userId: number): Promise<DeleteReturnProps> {
    setIsLoading(true)

    try {
      await api.delete(`/admin/events/${eventAlias}/roles/${userId}`)

      setIsLoading(false)
      return {
        status: 200,
      }
    } catch (error) {
      setIsLoading(false)
      const err = error as AxiosError
      return {
        status: err.response?.status ?? 500,
      }
    }
  }

  return {
    deleteEventRole,
    isLoading,
  }
}
