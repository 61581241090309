import { useState, type ReactElement, useEffect } from 'react'
import { useAnimate } from 'framer-motion'

import { useCurrentError, errorStore } from '@/store/error'
import { deviceStore } from '@/store/device'

import { useGetPositionByAddress } from '@/hooks/GoogleMaps'

import { Modal } from '@/components/Global/Modal'
import { Button } from '@/components/Global/Button'
import { MiniMap } from '@/components/Global/Minimap'

import {
  type ICreateEventStoreState,
  type IUpdateEventStoreState,
} from '@/types/CreateUpdateEvent'
import { LuInfo } from 'react-icons/lu'
import { Tooltip } from 'react-tooltip'

interface PositionProps {
  latitude: number
  longitude: number
}

interface CreateEventSelectLocationModalProps {
  closeModal: () => void
  handleModalClose: () => void
  isParentClosing?: boolean
  isLoading?: boolean
  formStates: ICreateEventStoreState | IUpdateEventStoreState
  updateAnyState:
    | ((update: Partial<ICreateEventStoreState>) => void)
    | ((update: Partial<IUpdateEventStoreState>) => void)
}

export function CreateEventSelectLocationModal({
  closeModal,
  isParentClosing,
  handleModalClose,
  formStates,
  updateAnyState,
}: CreateEventSelectLocationModalProps): ReactElement {
  const [address, setAddress] = useState(formStates.location?.address ?? '')
  const [locationName, setLocationName] = useState(
    formStates.location?.name ?? '',
  )
  const [position, setPosition] = useState<PositionProps | null>(null)
  const [isLoadingSearch, setIsLoadingSearch] = useState(false)

  const { isError, errorMessage } = useCurrentError()
  const { isMobile } = deviceStore()

  const [scope, animate] = useAnimate()

  const { getPositionByAddress } = useGetPositionByAddress()
  const { showError } = errorStore()

  async function handleSearchLocation(): Promise<void> {
    setIsLoadingSearch(true)
    const { data, status } = await getPositionByAddress(address)
    setIsLoadingSearch(false)
    const result = data?.results

    if (result?.length === 0) {
      showError('Nenhum local encontrado.')
      return
    }

    if (status && result !== undefined) {
      const latitude = result[0].geometry.location.lat
      const longitude = result[0].geometry.location.lng

      setAddress(result[0].formatted_address)
      setPosition({
        latitude,
        longitude,
      })
      handleSetPosition()
    }
  }

  function handleSave(): void {
    updateAnyState({
      location: {
        name: locationName,
        address,
        latitude: position?.latitude,
        longitude: position?.longitude,
      },
    })
    handleModalClose()
  }

  function handleSetPosition(): void {
    void animate(
      '.maps',
      { height: '180px' },
      { duration: 0.2, ease: 'easeInOut' },
    )
  }

  useEffect(() => {
    if (
      formStates.location?.latitude !== undefined &&
      formStates.location?.longitude !== undefined &&
      formStates.location?.latitude !== null &&
      formStates.location?.longitude !== null
    ) {
      setPosition({
        latitude: formStates.location?.latitude,
        longitude: formStates.location?.longitude,
      })
      setTimeout(handleSetPosition, 600)
    }
  }, [])

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      isError={isError}
      errorMessage={errorMessage}
      isMobile={isMobile}
    >
      <div className="flex h-fit w-full flex-col items-center gap-8 p-4 text-black">
        <div
          className="flex w-full flex-col items-center justify-center"
          ref={scope}
        >
          <h1 className="w-full pb-5 text-2xl font-black leading-none tracking-tight">
            Local do evento
          </h1>
          <div className="w-full pb-2">
            <div className="flex items-center gap-1.5 pb-1.5">
              <label
                className="block font-medium tracking-tight"
                htmlFor="event-name"
              >
                Nome
              </label>
              <a
                className="text-xs font-bold"
                data-tooltip-id="unique-users-tooltip"
                data-tooltip-content="Adicione um nome ao local do seu evento."
              >
                <LuInfo color="black" size={16} />
              </a>
              <Tooltip id="unique-users-tooltip" />
            </div>
            <input
              id="event-name"
              type="text"
              placeholder="Espaço das Américas..."
              autoCorrect="off"
              autoCapitalize="none"
              onChange={(e) => {
                setLocationName(e.target.value)
              }}
              value={locationName}
              className="mb-2 w-full rounded-md bg-white text-4xl font-bold leading-7 tracking-tight outline-none placeholder:text-4xl placeholder:font-black placeholder:text-text-terciary placeholder:opacity-50"
            />
          </div>
          <div className="w-full">
            <label
              className="block w-full pb-0.5 font-medium tracking-tight"
              htmlFor="event-address"
            >
              Endereço
            </label>
            <input
              id="event-address"
              type="text"
              placeholder="Rua Quatá, 300..."
              autoCorrect="off"
              autoCapitalize="none"
              onChange={(e) => {
                setAddress(e.target.value)
              }}
              value={address}
              className="transition-color mb-4 flex h-11 w-full items-center rounded-none border-2 border-x-0 border-t-0 border-b-primary-main bg-white py-1.5 font-normal leading-7 outline-none duration-200 ease-in-out placeholder:font-medium placeholder:text-text-terciary placeholder:opacity-50 focus:rounded-lg focus:border-2 focus:border-primary-main focus:px-2"
            />
          </div>
          <div className="maps h-max-[340px] mb-4 flex h-0 w-full items-center justify-center overflow-hidden">
            {position !== null && (
              <div className="relative w-full overflow-hidden rounded-lg ">
                <MiniMap
                  latitude={position.latitude}
                  longitude={position.longitude}
                />
              </div>
            )}
          </div>

          <div className="flex h-[104px] w-full flex-col gap-2">
            <Button
              enabled={address !== ''}
              text={'Buscar'}
              className="bg-dark-light-gray"
              isLoading={isLoadingSearch}
              onClick={handleSearchLocation}
            />
            <Button
              enabled={
                position !== null && locationName !== '' && address !== ''
              }
              text={'Salvar'}
              onClick={handleSave}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}
