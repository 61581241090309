import { type IPermissionEnum } from '@/types/Team'

interface IPermisionDescription {
  title: string
  subtitle: string
  key: IPermissionEnum
  warning?: string
}

interface IEventPermissionsDescriptions {
  FINANCE: IPermisionDescription[]
  ANALYTICS: IPermisionDescription[]
  LIST: IPermisionDescription[]
  TICKETS: IPermisionDescription[]
  GRANT: IPermisionDescription[]
  LINK: IPermisionDescription[]
}

interface IOrganizationPermissionsDescriptions {
  FINANCE: IPermisionDescription[]
  ANALYTICS: IPermisionDescription[]
  MANAGEMENT: IPermisionDescription[]
  COMMUNICATION_CAMPAIGN: IPermisionDescription[]
}

export const eventPermissionsDescriptions: IEventPermissionsDescriptions = {
  FINANCE: [
    {
      title: 'Visualizador',
      subtitle:
        'O usuário será capaz de ver os dados financeiros de todos os eventos.',
      key: 'VIEWER',
    },
    {
      title: 'Editor',
      subtitle: 'O usuário poderá solicitar saques e pagamentos.',
      key: 'EDITOR',
      warning:
        'Esta opção exige que o usuário tenha a permissão "Editar organização" ativada',
    },
    {
      title: 'Sem acesso',
      subtitle:
        'O usuário não poderá acessar nada sobre o financeiro dos eventos.',
      key: 'FORBIDDEN',
    },
  ],
  ANALYTICS: [
    {
      title: 'Visualizador',
      subtitle:
        'O usuário será capaz de ver os dados analíticos de todos os eventos.',
      key: 'VIEWER',
    },
    {
      title: 'Sem acesso',
      subtitle:
        'O usuário não poderá acessar nada sobre os analíticos dos eventos.',
      key: 'FORBIDDEN',
    },
  ],
  LIST: [
    {
      title: 'Visualizador',
      subtitle:
        'O usuário será capaz de ver a lista de convidados de todos os eventos.',
      key: 'VIEWER',
    },
    {
      title: 'Editor',
      subtitle:
        'O usuário poderá validar ingressos ou cancelar convites de usuários de todos os eventos.',
      key: 'EDITOR',
    },
    {
      title: 'Sem acesso',
      subtitle:
        'O usuário não poderá acessar nada sobre a lista de usuários dos eventos.',
      key: 'FORBIDDEN',
    },
  ],
  TICKETS: [
    {
      title: 'Visualizador',
      subtitle: 'O usuário será capaz de ver os lotes de todos os eventos.',
      key: 'VIEWER',
    },
    {
      title: 'Editor',
      subtitle: 'O usuário poderá criar e editar lotes de todos os eventos.',
      key: 'EDITOR',
    },
    {
      title: 'Sem acesso',
      subtitle: 'O usuário não poderá acessar nada sobre os lotes dos eventos.',
      key: 'FORBIDDEN',
    },
  ],
  GRANT: [
    {
      title: 'Visualizador',
      subtitle: 'O usuário será capaz de ver as cortesias de todos os eventos.',
      key: 'VIEWER',
    },
    {
      title: 'Editor',
      subtitle:
        'O usuário poderá enviar e cancelar cortesias de todos os eventos.',
      key: 'EDITOR',
    },
    {
      title: 'Sem acesso',
      subtitle:
        'O usuário não poderá acessar nada sobre as cortesias dos eventos.',
      key: 'FORBIDDEN',
    },
  ],
  LINK: [
    {
      title: 'Visualizador',
      subtitle:
        'O usuário será capaz de ver os links gerados de todos os eventos.',
      key: 'VIEWER',
    },
    {
      title: 'Editor',
      subtitle:
        'O usuário poderá criar, editar e deletar links de todos os eventos.',
      key: 'EDITOR',
    },
    {
      title: 'Sem acesso',
      subtitle: 'O usuário não poderá acessar nada sobre os links dos eventos.',
      key: 'FORBIDDEN',
    },
  ],
}

export const organizationPermissionsDescriptions: IOrganizationPermissionsDescriptions =
  {
    FINANCE: [
      {
        title: 'Visualizador',
        subtitle:
          'O usuário será capaz de ver os dados financeiros da organização.',
        key: 'VIEWER',
      },
      {
        title: 'Editor',
        subtitle: 'O usuário poderá solicitar saques e pagamentos.',
        key: 'EDITOR',
      },
      {
        title: 'Sem acesso',
        subtitle:
          'O usuário não poderá acessar nada sobre o financeiro da organização.',
        key: 'FORBIDDEN',
      },
    ],
    ANALYTICS: [
      {
        title: 'Visualizador',
        subtitle:
          'O usuário será capaz de ver os dados analíticos de toda a organização.',
        key: 'VIEWER',
      },
      {
        title: 'Sem acesso',
        subtitle:
          'O usuário não poderá acessar nada sobre os analíticos da organização.',
        key: 'FORBIDDEN',
      },
    ],
    MANAGEMENT: [
      {
        title: 'Visualizador',
        subtitle:
          'O usuário será capaz de ver quais usuários fazem parte da organização e quais as suas permissões.',
        key: 'VIEWER',
      },
      {
        title: 'Editor',
        subtitle:
          'O usuário poderá ver, editar, adicionar ou remover outros usuários da sua organização.',
        key: 'EDITOR',
      },
      {
        title: 'Sem acesso',
        subtitle:
          'O usuário não poderá acessar nada sobre as informações de quem faz parte da sua organização.',
        key: 'FORBIDDEN',
      },
    ],
    COMMUNICATION_CAMPAIGN: [
      {
        title: 'Visualizador',
        subtitle:
          'O usuário será capaz de ver todas as campanhas de comunicação da organização.',
        key: 'VIEWER',
      },
      {
        title: 'Editor',
        subtitle:
          'O usuário poderá ver, editar e criar campanhas de comunicação na sua organização.',
        key: 'EDITOR',
      },
      {
        title: 'Sem acesso',
        subtitle:
          'O usuário não poderá acessar nada sobre as campanhas de comunicação da sua organização.',
        key: 'FORBIDDEN',
      },
    ],
  }
