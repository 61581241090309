import { useState } from 'react'
import { type AxiosResponse } from 'axios'

import { api } from '@/services/axios'

import { type ITicketSpec } from '@/types/Tickets'

interface CreateTicketSpec {
  eventId: number
  batchId?: number
  numberOfAvailableTickets: number
  description: string
  price: number
  userLimit: number
  extraDescription?: string
}

interface PostReturnProps {
  status: boolean
  ticketSpec?: ITicketSpec
}

export const usePostCreateTicketSpec = (): {
  createTicketSpec: (body: CreateTicketSpec) => Promise<PostReturnProps>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function createTicketSpec(
    body: CreateTicketSpec,
  ): Promise<PostReturnProps> {
    setIsLoading(true)
    try {
      const response: AxiosResponse<ITicketSpec> = await api.post(
        `/admin/ticketSpecs/`,
        body,
      )

      setIsLoading(false)

      return {
        status: true,
        ticketSpec: response.data,
      }
    } catch (error) {
      setIsLoading(false)
      return {
        status: false,
      }
    }
  }

  return {
    createTicketSpec,
    isLoading,
  }
}
