import {
  type ICreateEventStoreState,
  type FAQs,
  type LineUp,
  type Partners,
} from '@/types/CreateUpdateEvent'
import { create } from 'zustand'
import {
  type StateStorage,
  createJSONStorage,
  devtools,
  persist,
} from 'zustand/middleware'
import localForage from 'localforage'
import { isTruthy } from '@/utils/validation'

interface ICreateEventStoreActions {
  getCompletePercentual: () => number
  reset: () => void
  resetCurrentLineup: () => void
  updateAnyState: (update: Partial<ICreateEventStoreState>) => void
  deleteArtistOfLineUp: (artist: LineUp) => void
  deleteFaq: (faq: FAQs) => void
  deletePartner: (partner: Partners) => void
  deleteLocation: () => void
  setEventType: (eventType: 'CLOSE_FRIENDS' | 'OPEN') => void
}

const initialState: ICreateEventStoreState = {
  alias: null,
  type: null,
  eventName: null,
  backgroundArtImage: null,
  titleImage: null,
  startDate: undefined,
  maxEntranceDate: undefined,
  endDate: undefined,
  location: {
    name: null,
    address: null,
    latitude: null,
    longitude: null,
  },
  eventDescription: undefined,
  lineUp: null,
  spotifyPlaylist: null,
  spotifyPlaylistId: null,
  terms: null,
  FAQs: null,
  partners: null,
  sticker: null,
  currentArtistImage: null,
  currentArtistImageUrl: null,
  currentArtistName: null,
  canReset: false,
  canAddArtist: false,
}

export const createEventStore = create<
  ICreateEventStoreState & ICreateEventStoreActions
>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        updateAnyState: (update: Partial<ICreateEventStoreState>) => {
          set((prevState) => ({
            ...prevState,
            ...update,
          }))
        },
        getCompletePercentual: () => {
          const states = get()

          const itemsToBeFilled = [
            states.eventName,
            states.backgroundArtImage,
            states.titleImage,
            states.startDate,
            states.maxEntranceDate,
            states.endDate,
            states.location?.name,
            states.location?.address,
            states.eventDescription,
            states.lineUp,
            states.spotifyPlaylist,
            states.terms,
            states.FAQs,
            states.partners,
            states.sticker,
          ]

          const total = itemsToBeFilled.length
          const notNulls = itemsToBeFilled.filter((item) =>
            isTruthy(item),
          ).length

          return notNulls / total
        },
        reset: () => {
          set(initialState)
        },
        resetCurrentLineup: () => {
          set({
            currentArtistImage: null,
            currentArtistImageUrl: null,
            currentArtistName: null,
          })
        },
        deleteArtistOfLineUp: (artist: LineUp) => {
          const allStates = get()
          const currentLineUp = allStates.lineUp ?? []
          const lineUpFiltered = currentLineUp?.filter((item) => {
            if (
              item.artistImage === artist.artistImage &&
              item.artistImageUrl === artist.artistImageUrl
            ) {
              return false
            }
            return item
          })
          set({
            lineUp: lineUpFiltered.length < 1 ? null : lineUpFiltered,
          })
        },
        deleteFaq: (faq: FAQs) => {
          const allStates = get()
          const currentFAQs = allStates.FAQs ?? []
          const FAQsFiltered = currentFAQs?.filter((item) => {
            if (item.question === faq.question) {
              return false
            }
            return item
          })
          set({
            FAQs: FAQsFiltered?.length < 1 ? null : FAQsFiltered,
          })
        },
        deletePartner: (partner: Partners) => {
          const allStates = get()
          const currentPartners = allStates.partners ?? []
          const partnersFiltered = currentPartners?.filter((item) => {
            if (item.key === partner.key && item.url === partner.url) {
              return false
            }
            return item
          })
          set({
            partners: partnersFiltered,
          })
        },
        deleteLocation: () => {
          set({
            location: undefined,
          })
        },
        setEventType: (eventType: 'CLOSE_FRIENDS' | 'OPEN') => {
          set({
            type: eventType,
          })
        },
      }),
      {
        name: 'createEvent-storage',
        storage: createJSONStorage(() => {
          return localForage as StateStorage
        }),
      },
    ),
  ),
)

export const useAllStatesOfCreateEvent = (): ICreateEventStoreState => {
  return createEventStore((state) => {
    const onlyStatesArray = Object.entries(state).filter((item) => {
      if (typeof item[1] !== 'function') {
        return item
      }

      return false
    })

    return Object.fromEntries(onlyStatesArray) as ICreateEventStoreState
  })
}
