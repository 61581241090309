import { type ReactElement } from 'react'
import { BsPlusCircle } from 'react-icons/bs'

import { ticketStore } from '@/store/tickets'
import { useUserPermissions } from '@/store/user'

import { TicketSpecCard } from '../../TicketSpecCard'
import { BatchCardHeader } from './BatchCardHeader'

import { hasClearence } from '@/utils/rbac'

import { type IFollower, type IBatch } from '@/types/Tickets'

interface BatchCardProps {
  openTicketSpecModal: () => void
  openConfirmDeletionModal: () => void
  openBatchModal: () => void
  batch: IBatch
  ticketSpecDropDownData: IFollower[]
  batchDropDownData: IFollower[]
}

export function BatchCard({
  openTicketSpecModal,
  openConfirmDeletionModal,
  openBatchModal,
  batch,
  ticketSpecDropDownData,
  batchDropDownData,
}: BatchCardProps): ReactElement {
  const { setCurrentBatch, setCurrentTicketSpec } = ticketStore()
  const { eventPermissions } = useUserPermissions()

  const canEditBatch = hasClearence(eventPermissions.tickets, 'EDITOR')

  const filteredBatchDropDownData = batchDropDownData.filter(
    (batchData) => batchData.id !== batch.id,
  )

  return (
    <div className="flex w-full flex-col rounded-lg bg-dark-black p-4 pb-0">
      {batch !== undefined && (
        <main className="flex flex-col gap-4">
          <BatchCardHeader
            batch={batch}
            batchDropDownData={filteredBatchDropDownData}
            handleEditBatch={() => {
              setCurrentBatch(batch)
              openBatchModal()
            }}
            handleDeleteBatch={() => {
              setCurrentTicketSpec(undefined)
              setCurrentBatch(batch)
              openConfirmDeletionModal()
            }}
          />
          {batch.ticketSpecs?.length === 0 ? (
            <div className="flex w-full flex-col items-center justify-between p-2">
              <span className="text-xs">Adicione um ingresso a esse lote.</span>
            </div>
          ) : (
            <div className="flex flex-wrap justify-between gap-4">
              {batch.ticketSpecs?.map((ticketSpec) => {
                const filteredTicketSpecDropDownData =
                  ticketSpecDropDownData.filter(
                    (spec) => spec.id !== ticketSpec.id,
                  )

                return (
                  <div
                    key={ticketSpec.id}
                    className="w-full desktop:w-[calc(50%-0.5rem)]"
                  >
                    <TicketSpecCard
                      key={ticketSpec.id}
                      ticketSpec={ticketSpec}
                      openTicketSpecModal={() => {
                        setCurrentTicketSpec(ticketSpec)
                        openTicketSpecModal()
                      }}
                      deleteTicketSpec={() => {
                        setCurrentBatch(undefined)
                        setCurrentTicketSpec(ticketSpec)
                        openConfirmDeletionModal()
                      }}
                      dropDownData={filteredTicketSpecDropDownData}
                      variant={'small'}
                    />
                  </div>
                )
              })}
            </div>
          )}
        </main>
      )}
      {canEditBatch && (
        <div className="flex w-full justify-center py-4">
          <button
            onClick={() => {
              setCurrentBatch(batch)
              setCurrentTicketSpec(undefined)
              openTicketSpecModal()
            }}
          >
            <BsPlusCircle className="size-7" />
          </button>
        </div>
      )}
    </div>
  )
}
