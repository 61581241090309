import { type ReactElement } from 'react'

import { useCurrentError } from '@/store/error'
import { deviceStore } from '@/store/device'
import { useCurrentLink } from '@/store/link'

import { Modal } from '@/components/Global/Modal'
import { LinkUTMFormUpdate } from '../LinkUTMForm/LinkUTMFormUpdate'
import { LinkUTMFormCreate } from '../LinkUTMForm/LinkUTMFormCreate'

interface LinkUTMModalProps {
  closeModal: () => void
  isParentClosing?: boolean
}

export function LinkUTMModal({
  closeModal,
  isParentClosing,
}: LinkUTMModalProps): ReactElement {
  const { isError, errorMessage } = useCurrentError()
  const { isMobile } = deviceStore()
  const { currentLink } = useCurrentLink()

  const currentOperationType = currentLink !== null ? 'update' : 'create'

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      isError={isError}
      errorMessage={errorMessage}
      isMobile={isMobile}
    >
      <div className="flex h-fit w-full flex-col items-center gap-8 p-4 text-black">
        {currentOperationType === 'update' ? (
          <LinkUTMFormUpdate />
        ) : (
          <LinkUTMFormCreate />
        )}
      </div>
    </Modal>
  )
}
