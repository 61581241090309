import { type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import { Modal } from '../../Global/Modal'
import { OrganizationsModalCard } from '../OrganizationsModalCard'

import { deviceStore } from '@/store/device'

import { type OrganizationData } from '@/types/Organization'

import { FiPlusCircle } from 'react-icons/fi'

interface TeamsModalProps {
  closeModal: () => void
  currentOrganizationId: number | undefined
  allOrganizations: OrganizationData[] | null
  isParentCLosing?: boolean
}

export function OrganizationsModal({
  closeModal,
  allOrganizations,
  currentOrganizationId,
  isParentCLosing,
}: TeamsModalProps): ReactElement {
  const { isMobile } = deviceStore()

  const navigate = useNavigate()

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentCLosing}
      isMobile={isMobile}
    >
      <div className=" flex size-full flex-col gap-2 px-6 pt-8 text-black">
        <div className="flex w-full items-center justify-between">
          <strong className="text-4xl">Equipes</strong>
          <button
            className="flex w-min items-center gap-2 rounded-full bg-primary-main px-4 py-2"
            onClick={() => {
              navigate('/create-organization')
            }}
          >
            <FiPlusCircle size={20} />
            <span className="text-sm font-black">Criar</span>
          </button>
        </div>
        <div className="relative">
          <div className="flex max-h-[400px] flex-col gap-2.5 overflow-scroll py-4">
            {allOrganizations!.map((item) => {
              return (
                <OrganizationsModalCard
                  key={item.organizer.organizationId}
                  organization={item}
                  handleModalClose={closeModal}
                  active={
                    currentOrganizationId === item.organizer.organizationId
                  }
                />
              )
            })}
          </div>
          <div className="absolute left-0 top-0 z-50 h-4 w-full bg-gradient-to-b  from-[#ffffff] to-transparent" />
          <div className="absolute bottom-0 left-0 z-50 h-4 w-full bg-gradient-to-b  from-transparent to-[#ffffff]" />
        </div>
      </div>
    </Modal>
  )
}
