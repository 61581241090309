import { useEffect, useState, type ReactElement } from 'react'

import { AnalyticsTicketsCardSimpleView } from './AnalyticsTicketsCardSimpleView'
import { AnalyticsTicketsCardFullView } from './AnalyticsTicketsCardFullView'

import { useGetTickets } from '@/hooks/Tickets'

import { isTruthy } from '@/utils/validation'

interface AnalyticsBatchCardProps {
  alias: string
}

export function AnalyticsTicketsCard({
  alias,
}: AnalyticsBatchCardProps): ReactElement {
  const [firstLoad, setFirstLoad] = useState(true)

  const { tickets } = useGetTickets(alias)

  useEffect(() => {
    if (isTruthy(tickets) && firstLoad) {
      setFirstLoad(false)
    }
  }, [tickets])

  if (firstLoad)
    return (
      <div className="h-[168px] w-full animate-pulse rounded-lg bg-dark-black" />
    )
  if (tickets!.ticketsSpecs?.length === 0) return <></>
  return (
    <div className="flex w-full flex-col justify-between gap-8 rounded-lg bg-dark-black p-4">
      <header className="flex">
        <span className="text-xl font-black leading-4">Ingressos</span>
      </header>
      <main className="flex">
        {tickets?.managementType === 'SIMPLE' && (
          <AnalyticsTicketsCardSimpleView
            ticketsSpecs={tickets.ticketsSpecs!}
          />
        )}
        {tickets?.managementType === 'FULL' && (
          <AnalyticsTicketsCardFullView batches={tickets.batches!} />
        )}
      </main>
    </div>
  )
}
