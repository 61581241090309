import { api } from '@/services/axios'
import { type AxiosResponse } from 'axios'
import { useState } from 'react'
import { type IBatch } from '@/types/Tickets'

interface CreateBatch {
  eventId: number
  description: string
  numberOfAvailableTickets: number
  userLimit: number
}

interface PostReturnProps {
  status: boolean
  batch?: IBatch
}

export const usePostCreateBatch = (): {
  createBatch: (body: CreateBatch) => Promise<PostReturnProps>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function createBatch(body: CreateBatch): Promise<PostReturnProps> {
    setIsLoading(true)
    try {
      const response: AxiosResponse<IBatch> = await api.post(
        `/admin/batches/`,
        body,
      )

      setIsLoading(false)

      return {
        status: true,
        batch: response.data,
      }
    } catch (error) {
      setIsLoading(false)
      return {
        status: false,
      }
    }
  }

  return {
    createBatch,
    isLoading,
  }
}
