import { api } from '@/services/axios'
import {
  type GetEventDataByEventAlias,
  type EventDTO,
} from '@/types/CreateUpdateEvent'
import { dateToBrazilTZ } from '@/utils/formatData'
import { isTruthy } from '@/utils/validation'
import { type AxiosResponse } from 'axios'
import { useState } from 'react'

export interface IGetEventDataByEventAliasResponse {
  status: boolean
  data?: GetEventDataByEventAlias
  message?: string
}

export const useGetEventDataByEventAlias = (): {
  getEventDataByEventAlias: (
    alias: string,
  ) => Promise<IGetEventDataByEventAliasResponse>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(true)

  async function getEventDataByEventAlias(
    alias: string,
  ): Promise<IGetEventDataByEventAliasResponse> {
    setIsLoading(true)
    const shouldFetch = alias !== undefined
    if (!shouldFetch) {
      setIsLoading(false)
      return {
        status: false,
        message: 'Evento não encontrado',
      }
    }
    try {
      const response: AxiosResponse<EventDTO> = await api.get(
        `/events/${alias}`,
      )

      const data = response.data

      const dataMapped: GetEventDataByEventAlias = {
        eventName: data?.title ?? null,
        eventDescription: data?.description ?? '',
        backgroundArtImage:
          data.imageKey !== null
            ? {
                url: data.imageKey,
                key: null,
                isLocalImage: false,
              }
            : null,
        titleImage:
          data.titleImageKey !== null
            ? {
                key: null,
                url: data.titleImageKey,
                isLocalImage: false,
              }
            : null,
        FAQs: data?.faq ?? null,
        lineUp:
          data?.lineup.map((data) => {
            return {
              artistImageUrl: data.avatarImageKey,
              artistImage: null,
              artistName: data.name,
              artistId: data.id,
              isLocalImage: false,
            }
          }) ?? null,
        partners:
          data?.partners.map((data) => {
            return {
              key: null,
              url: data.imageKey!,
              isLocalImage: false,
              partnerId: data.partnerId,
            }
          }) ?? null,
        spotifyPlaylist: data?.spotify ?? null,
        startDate: isTruthy(data?.date.startDate)
          ? dateToBrazilTZ(data?.date.startDate ?? '')
          : undefined,
        maxEntranceDate: isTruthy(data?.date.maxEntranceDate)
          ? dateToBrazilTZ(data?.date.maxEntranceDate ?? '')
          : undefined,
        endDate: isTruthy(data?.date.endDate)
          ? dateToBrazilTZ(data?.date.endDate ?? '')
          : undefined,
        terms: data?.terms ?? null,
        location: data.location,
        sticker:
          data.stickerKey !== null
            ? {
                url: data?.stickerKey,
                key: null,
                isLocalImage: false,
              }
            : null,
      }
      setIsLoading(false)
      return {
        data: dataMapped,
        status: true,
      }
    } catch (error) {
      setIsLoading(false)
      return {
        status: false,
        message: 'Evento não pôde ser atualizado',
      }
    }
  }

  return {
    getEventDataByEventAlias,
    isLoading,
  }
}
