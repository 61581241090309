import { useState } from 'react'
import { api } from '@/services/axios'
import { type AxiosError } from 'axios'

interface PutReturnProps {
  status: number
}

export const usePutSwitchVisibility = (
  eventAlias: string,
): {
  switchVisibility: (
    username: string,
    body: {
      visibility: 'HIDDEN' | 'VISIBLE'
    },
  ) => Promise<PutReturnProps>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function switchVisibility(
    username: string,
    body: {
      visibility: 'HIDDEN' | 'VISIBLE'
    },
  ): Promise<PutReturnProps> {
    setIsLoading(true)
    try {
      await api.put(`/admin/events/${eventAlias}/hosts/${username}`, body)

      setIsLoading(false)

      return {
        status: 200,
      }
    } catch (error) {
      setIsLoading(false)
      const err = error as AxiosError
      return {
        status: err.response?.status ?? 500,
      }
    }
  }

  return {
    switchVisibility,
    isLoading,
  }
}
