import { type ReactElement } from 'react'

import { DropDown } from '@/components/Global/DropDown'
import { Spinner } from '@/components/Global/Spinner'
import { SwitchButton } from '@/components/Global/SwitchButton'

import { LuInfo } from 'react-icons/lu'
import { Tooltip } from 'react-tooltip'

interface PageviewChartButtonsProps {
  isLoading: boolean
  uniqueUsers: boolean
  setUniqueUsers: (value: boolean) => void
  selectedTimeFrame: {
    value: string
    key: string
    id: number
  }
  setSelectedTimeFrame: (value: {
    value: string
    key: string
    id: number
  }) => void
}

export function PageviewChartButtons({
  isLoading,
  uniqueUsers,
  setUniqueUsers,
  selectedTimeFrame,
  setSelectedTimeFrame,
}: PageviewChartButtonsProps): ReactElement {
  const dropDownTimeFrames = [
    {
      value: 'Últimas 24 horas',
      key: '1day',
      id: 0,
    },
    {
      value: 'Últimos 7 dias',
      key: '7days',
      id: 1,
    },
    {
      value: 'Desde o início',
      key: 'all',
      id: 2,
    },
  ]

  return (
    <div className="flex h-8 items-center gap-5">
      {isLoading && (
        <Spinner
          borderWidth="border-[3px]"
          borderColor="border-primary-main/50"
          bottomBorderColor="border-b-primary-main"
          width="w-4"
          height="h-4"
        />
      )}
      <div className="flex items-center gap-4">
        <div className="flex w-min flex-col gap-1">
          <span className="whitespace-nowrap text-xs font-light">
            Usuários únicos
          </span>
          <div className="flex items-center justify-between">
            <div className="flex w-7 items-center">
              <SwitchButton
                active={uniqueUsers}
                handleOnClick={() => {
                  setUniqueUsers(!uniqueUsers)
                }}
              />
            </div>
            <a
              className="text-xs font-bold"
              data-tooltip-id="unique-users-tooltip"
              data-tooltip-content="Número de usuários diferentes que acessaram a página."
            >
              <LuInfo color="white" size={16} />
            </a>
            <Tooltip id="unique-users-tooltip" />
          </div>
        </div>
      </div>
      <div className="flex h-8 rounded-md bg-background-main px-2">
        <DropDown
          dropDownData={dropDownTimeFrames}
          handleOnChange={(timeFrameId: number) => {
            const newTimeFrame = dropDownTimeFrames.find(
              (item) => item.id === timeFrameId,
            )
            if (newTimeFrame !== undefined) setSelectedTimeFrame(newTimeFrame)
          }}
          value={selectedTimeFrame.value}
        />
      </div>
    </div>
  )
}
