import { useEffect, useState, type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

import { CreateEditOrganizationInput } from '../CreateEditOrganizationInput'
import { CreateEditOrganizationTextArea } from '../CreateEditOrganizationTextArea'
import { CreateEditOrganizationUploadImage } from '../CreateEditOrganizationUploadImage'
import { CreateEditOrganizationPixList } from '../CreateEditOrganizationPixList'
import { CreateEditOrganizationPixModal } from '../CreateEditOrganizationPixModal'
import { CreateEditOrganizationCropModal } from '../CreateEditOrganizationCropModal'
import { Button } from '@/components/Global/Button'

import { deviceStore } from '@/store/device'

import {
  type ICreateOrganization,
  type IOrganization,
  type IPIX,
} from '@/types/CreateEditOrganization'

import { isTruthy, isValidCNPJ, isValidCPF } from '@/utils/validation'
import { formatCpfCnpj } from '@/utils/formatString'

const newOrganizationSchema = z.object({
  name: z
    .string()
    .min(3, { message: 'O nome da equipe deve possuir mais que 3 caracteres.' })
    .max(100),
  email: z.string().email({ message: 'Email inválido.' }),
  document: z
    .string()
    .refine(
      (value) => {
        const cleanedValue = value.replace(/[^\d]/g, '')
        return cleanedValue.length === 11 || cleanedValue.length === 14
      },
      {
        message: 'Quantidade inválida de números.',
      },
    )
    .refine(
      (value) => {
        const cleanedValue = value.replace(/[^\d]/g, '')
        return isValidCPF(cleanedValue) || isValidCNPJ(cleanedValue)
      },
      {
        message: 'Documento inválido.',
      },
    ),
  description: z.string(),
})

type FormData = z.infer<typeof newOrganizationSchema>

interface CreateEditOrganizationFormProps {
  actionType: string
  defaultValues: IOrganization | null
  token?: string
  isLoading: boolean
  handleCreateOrganization: (body: ICreateOrganization) => void
  handleUpdateOrganization: (body: ICreateOrganization) => void
  organizationType: 'SOLO' | 'TEAM' | null
}

export function CreateEditOrganizationForm({
  defaultValues,
  actionType,
  token,
  isLoading,
  handleCreateOrganization,
  handleUpdateOrganization,
  organizationType,
}: CreateEditOrganizationFormProps): ReactElement {
  const [showCreatePixModal, setShowCreatePixModal] = useState(false)
  const [showCropModal, setShowCropModal] = useState(false)
  const [isParentClosing, setIsParentClosing] = useState(false)
  const [pixKeyToEdit, setPixKeyToEdit] = useState<IPIX | null>(null)

  const [pixKeys, setPixKeys] = useState<IPIX[]>(defaultValues?.pixKeys ?? [])

  const [uploadedImageUrl, setUploadedImageUrl] = useState<string>()
  const [croppedImage, setCroppedImage] = useState<File>()
  const [croppedImageUrl, setCroppedImageUrl] = useState<string>()

  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(newOrganizationSchema),
    mode: 'all',
    defaultValues: {
      name: defaultValues?.name,
      email: defaultValues?.email,
      document: defaultValues?.document.value,
      description: defaultValues?.description,
    },
  })

  const { isMobile } = deviceStore()

  const canCreateOrEdit =
    isTruthy(watch('name')) &&
    isTruthy(watch('email')) &&
    isTruthy(watch('document')) &&
    pixKeys.length > 0 &&
    (isTruthy(croppedImage) || isTruthy(defaultValues?.profileImage))

  const onSubmit: SubmitHandler<FormData> = (data) => {
    const body = {
      ...data,
      pixKeys,
      profileImage: croppedImage!,
      document: {
        value: data.document.replace(/[^\d]/g, ''),
        type: (data.document.length === 14 ? 'CNPJ' : 'CPF') as 'CNPJ' | 'CPF',
      },
      token,
      organizationType: organizationType as 'SOLO' | 'TEAM',
    }

    if (actionType === 'edit') handleUpdateOrganization(body)
    if (actionType === 'create') handleCreateOrganization(body)
  }

  function addPixKey(pixKey: IPIX): void {
    setPixKeys([...pixKeys, pixKey])
  }

  function deletePixKey(key: string): void {
    setPixKeys(pixKeys.filter((pix) => pix.key !== key))
  }

  function updatePixKey(updatedPixKey: IPIX): void {
    const updatedPixKeys = pixKeys.map((pixKey) => {
      if (pixKey.id === updatedPixKey.id) {
        return { ...pixKey, name: updatedPixKey.name, key: updatedPixKey.key }
      }
      return pixKey
    })
    setPixKeys(updatedPixKeys)
  }

  function editPixKey(pixKey: IPIX): void {
    setPixKeyToEdit(pixKey)
    setShowCreatePixModal(true)
  }

  useEffect(() => {
    setValue('name', defaultValues?.name ?? '')
    setValue('email', defaultValues?.email ?? '')
    setValue('document', defaultValues?.document.value ?? '')
    setValue('description', defaultValues?.description ?? '')

    if (isTruthy(defaultValues?.profileImage)) {
      if (typeof defaultValues?.profileImage === 'string') {
        setCroppedImageUrl(
          `${import.meta.env.VITE_S3 as string}/${defaultValues.profileImage}`,
        )
      }
    }
  }, [defaultValues])
  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex h-fit min-h-dvh w-full flex-col items-center gap-2 px-4 pb-8 pt-4 desktop:flex-row-reverse desktop:items-start desktop:gap-4 desktop:pb-4"
      >
        <div className="flex min-h-full w-full flex-col gap-8 rounded-md bg-[#181818] p-4 desktop:bg-[#212121]">
          <span className="mb-6 text-3xl font-black">
            {actionType === 'create'
              ? 'Crie sua equipe'
              : 'Atualize os dados da organização'}
          </span>
          <CreateEditOrganizationInput
            name="name"
            register={register}
            label="Nome da equipe"
            watch={watch}
            error={errors.name?.message}
          />
          <CreateEditOrganizationInput
            name="email"
            register={register}
            label="Email do responsável"
            watch={watch}
            error={errors.email?.message}
          />
          <CreateEditOrganizationInput
            name="document"
            register={register}
            label="CNPJ/CPF"
            watch={watch}
            error={errors.document?.message}
            disabled={
              (actionType === 'create' && organizationType === 'SOLO') ||
              actionType === 'edit'
            }
            onChangeFunction={(value) => {
              const formattedValue = formatCpfCnpj(value as string)
              return formattedValue
            }}
          />
          {organizationType === 'TEAM' && (
            <CreateEditOrganizationTextArea
              name="description"
              register={register}
              label="Sobre"
              error={errors.description?.message}
              extraDescription="Escreva um breve texto sobre a sua equipe"
            />
          )}
          {!isMobile && (
            <div className="flex size-full h-max flex-col justify-between gap-24">
              <CreateEditOrganizationPixList
                openCreatePixModal={() => {
                  setShowCreatePixModal(true)
                }}
                pixKeys={pixKeys}
                deletePixKey={deletePixKey}
                editPixKey={editPixKey}
              />
              <div className="flex h-10 gap-4">
                <Button
                  enabled
                  text={actionType === 'create' ? 'Cancelar' : 'Voltar'}
                  className="bg-dark-light-gray text-base"
                  onClick={(e) => {
                    e.preventDefault()
                    navigate(-1)
                  }}
                />
                <Button
                  enabled={!isLoading && canCreateOrEdit}
                  text={
                    actionType === 'create'
                      ? 'Criar equipe'
                      : 'Atualizar equipe'
                  }
                  className="text-base"
                  isLoading={isLoading}
                  type="submit"
                />
              </div>
            </div>
          )}
        </div>
        {isMobile && (
          <CreateEditOrganizationPixList
            openCreatePixModal={() => {
              setShowCreatePixModal(true)
            }}
            pixKeys={pixKeys}
            deletePixKey={deletePixKey}
            editPixKey={editPixKey}
          />
        )}
        <CreateEditOrganizationUploadImage
          handleOpenCropModal={() => {
            setShowCropModal(true)
          }}
          setUploadedImageUrl={setUploadedImageUrl}
          croppedImage={croppedImage}
          croppedImageUrl={croppedImageUrl}
          setCroppedImage={setCroppedImage}
          setCroppedImageUrl={setCroppedImageUrl}
        />
        {isMobile && (
          <div className="flex w-full gap-2">
            <div className="h-12 w-min">
              <Button
                text={actionType === 'create' ? 'Cancelar' : 'Voltar'}
                className="bg-dark-light-gray px-8 text-base"
                enabled
                onClick={(e) => {
                  e.preventDefault()
                  navigate(-1)
                }}
              />
            </div>
            <div className="h-12 w-full">
              <Button
                text={
                  actionType === 'create' ? 'Criar equipe' : 'Atualizar equipe'
                }
                className="text-base"
                isLoading={isLoading}
                enabled={!isLoading && canCreateOrEdit}
                type="submit"
              />
            </div>
          </div>
        )}
      </form>
      {showCreatePixModal && (
        <CreateEditOrganizationPixModal
          isParentClosing={isParentClosing}
          closeModal={() => {
            setPixKeyToEdit(null)
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(setShowCreatePixModal, 400, false)
          }}
          pixKeys={pixKeys}
          addPixKey={addPixKey}
          pixKeyToEdit={pixKeyToEdit}
          updatePixKey={updatePixKey}
        />
      )}
      {showCropModal && (
        <CreateEditOrganizationCropModal
          isParentClosing={isParentClosing}
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(setShowCropModal, 400, false)
            setTimeout(setUploadedImageUrl, 400, undefined)
          }}
          uploadedImageUrl={uploadedImageUrl!}
          setCroppedImage={setCroppedImage}
          setCroppedImageUrl={setCroppedImageUrl}
        />
      )}
    </>
  )
}
