import { useState, useEffect } from 'react'
import useSWR from 'swr'

import { fetcher } from '@/services/axios'

import { type ICloseFriendsConditions } from '@/types/CreateUpdateEvent'

export const useGetCloseFriendsConditions = (): {
  conditions: ICloseFriendsConditions | undefined
  isLoading: boolean
  refresh: () => void
} => {
  const [isLoading, setIsLoading] = useState(true)
  const { data, error, mutate } = useSWR<ICloseFriendsConditions, string>(
    `admin/events/close-friends/conditions`,
    fetcher,
  )

  useEffect(() => {
    setIsLoading(!(data !== undefined || error !== undefined))
  }, [data, error])

  function refresh(): void {
    void mutate()
  }

  return {
    conditions: data,
    isLoading,
    refresh,
  }
}
