import { type ReactElement, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { PeekAnalytics } from '@/components/PeekAnalytics'
import { Loading } from '@/components/Global/Loading/Loading'
import { EventIconMenu } from '@/components/Event/EventMenu/EventIconMenu'
import { EventCardsMenu } from '@/components/Event/EventMenu/EventCardsMenu'
import { EventExportReport } from '@/components/Event/EventExportReport'
import { CopyEventLink } from '@/components/Event/CopyEventLink'

import { useGetUserEventPermissions } from '@/hooks/Layout'
import {
  useGetMenuAnalyticsByEventAlias,
  useGetRevenueByEventAlias,
} from '@/hooks/Event'

import { type GetMenuAnalyticsByEventAlias } from '@/types/Event'

import { useCurrentEvent } from '@/store/eventMenu'
import { wrapperStore } from '@/store/wrapper'
import { deviceStore } from '@/store/device'
import { useUserPermissions, userStore } from '@/store/user'

import { hasClearence } from '@/utils/rbac'

export function Event(): ReactElement {
  const [cardsData, setCardsData] = useState<GetMenuAnalyticsByEventAlias>()

  const { alias } = useParams()
  const navigate = useNavigate()

  const { currentEvent } = useCurrentEvent()
  const { setTitle, setSubtitle } = wrapperStore()
  const { isMobile } = deviceStore()
  const { eventPermissions, setEventPermissions } = useUserPermissions()
  const { username } = userStore()

  const { getRevenueByEventAlias, isLoading: isLoadingGetRevenueByEventAlias } =
    useGetRevenueByEventAlias(alias!)

  const {
    getMenuAnalyticsByEventAlias,
    isLoading: isLoadingGetMenuAnalyticsByEventAlias,
  } = useGetMenuAnalyticsByEventAlias(alias!)

  const { getUserEventPermissions, isLoading: isLoadingPermissions } =
    useGetUserEventPermissions()

  const areDependenciesLoading =
    isLoadingGetRevenueByEventAlias || isLoadingGetMenuAnalyticsByEventAlias

  async function handleFetchEventRoles(eventAlias: string): Promise<void> {
    const response = await getUserEventPermissions(eventAlias, username ?? '')

    if (response.status === 200 && response.roles?.event !== undefined) {
      setEventPermissions(response.roles?.event)
    }
  }

  useEffect(() => {
    if (
      getRevenueByEventAlias !== undefined &&
      getMenuAnalyticsByEventAlias !== undefined
    ) {
      if (currentEvent === undefined) {
        navigate('/access-denied')
        return
      }

      setCardsData(getMenuAnalyticsByEventAlias)
    }
  }, [getRevenueByEventAlias, getMenuAnalyticsByEventAlias])

  useEffect(() => {
    if (currentEvent?.numberOfSoldTickets !== undefined) {
      const sub =
        currentEvent.numberOfSoldTickets === 1
          ? `${currentEvent.numberOfSoldTickets} ingresso`
          : `${currentEvent.numberOfSoldTickets} ingressos`
      setSubtitle(sub)
    }
    setTitle(currentEvent?.name ?? '')
  }, [currentEvent])

  useEffect(() => {
    if (alias !== undefined) {
      void handleFetchEventRoles(alias)
    }
  }, [alias, username])

  const canViewFinance = hasClearence(eventPermissions.finance, 'VIEWER')

  if (areDependenciesLoading || isLoadingPermissions) return <Loading />

  return (
    <div className="flex size-full flex-col gap-4 pb-10">
      <main className="flex w-full flex-col gap-6 py-4">
        {canViewFinance && (
          <div className="h-[188px] px-4">
            <PeekAnalytics data={getRevenueByEventAlias} />
          </div>
        )}
        {isMobile && <EventIconMenu alias={alias!} />}
        {cardsData !== undefined && (
          <EventCardsMenu alias={alias!} cardsData={cardsData} />
        )}
        <div className="col grid gap-2 px-4 desktop:grid-cols-2">
          <EventExportReport alias={alias} />
          {!isMobile && <CopyEventLink alias={alias} />}
        </div>
      </main>
    </div>
  )
}
