import { useState, type ReactElement } from 'react'

import { CoHostCard } from '@/components/CoHosts/CoHostCard'
import { AddCoHostModal } from '@/components/CoHosts/AddCoHostModal'
import { Loading } from '@/components/Global/Loading/Loading'
import { CoHostsConfirmDeletionModal } from '@/components/CoHosts/CoHostsConfirmDeletionModal'

import { useGetCoHosts } from '@/hooks/CoHost'

import { useCurrentEvent } from '@/store/eventMenu'

import { type IUser } from '@/types/CoHosts'

export function CoHosts(): ReactElement {
  const [showAddCoHostModal, setShowAddCoHostModal] = useState(false)
  const [showConfirmDeletionModal, setShowConfirmDeletionModal] =
    useState(false)
  const [isParentClosing, setIsParentClosing] = useState(false)
  const [coHostToDelete, setCoHostToDelete] = useState<IUser>()

  const { currentEvent } = useCurrentEvent()

  const { coHosts, isLoading, refresh } = useGetCoHosts(
    currentEvent?.alias ?? '',
  )

  function handleDeleteCoHost(coHost: IUser): void {
    setCoHostToDelete(coHost)
    setShowConfirmDeletionModal(true)
  }

  if (isLoading) return <Loading />
  return (
    <div className="flex size-full flex-col gap-8 px-4 py-8">
      <button
        className="w-fit rounded-md bg-primary-main px-8 py-2 text-sm font-bold text-dark-black"
        onClick={() => {
          setShowAddCoHostModal(true)
        }}
      >
        Adicionar co-host
      </button>
      <div className="flex flex-col gap-4 desktop:flex-row desktop:flex-wrap">
        {coHosts?.map((coHost) => {
          return (
            <div key={coHost.id} className="w-full desktop:w-[calc(50%-8px)]">
              <CoHostCard
                coHostData={coHost}
                handleDeleteCoHost={handleDeleteCoHost}
              />
            </div>
          )
        })}
      </div>
      {showAddCoHostModal && (
        <AddCoHostModal
          isParentClosing={isParentClosing}
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(setShowAddCoHostModal, 400, false)
          }}
          refresh={refresh}
        />
      )}
      {showConfirmDeletionModal && (
        <CoHostsConfirmDeletionModal
          isParentClosing={isParentClosing}
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(setShowConfirmDeletionModal, 400, false)
          }}
          coHostData={coHostToDelete as IUser}
          refresh={refresh}
        />
      )}
    </div>
  )
}
