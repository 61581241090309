import { api } from '@/services/axios'
import { type AxiosError } from 'axios'
import { useState } from 'react'

interface DeleteReturnProps {
  status: number
}

export const useDeleteWithdrawalById = (
  alias: string,
): {
  deleteWithdrawal: (id: number) => Promise<DeleteReturnProps>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function deleteWithdrawal(id: number): Promise<DeleteReturnProps> {
    setIsLoading(true)

    const body = {
      status: 'CANCELLED',
    }

    try {
      await api.put(`/admin/withdrawals/${alias}/${id}`, body)

      setIsLoading(false)
      return {
        status: 200,
      }
    } catch (error) {
      setIsLoading(false)
      const err = error as AxiosError
      return {
        status: err.response?.status ?? 500,
      }
    }
  }

  return {
    deleteWithdrawal,
    isLoading,
  }
}
