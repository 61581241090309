import { type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

import { isTruthy } from '@/utils/validation'

interface EventBitProps {
  title?: string
  subtitle?: string
  children: ReactElement
  className?: string
}

export function EventBit({
  title,
  subtitle,
  children,
  className,
}: EventBitProps): ReactElement {
  return (
    <div>
      <div className="flex flex-col px-4">
        {isTruthy(title) && <h1 className="text-2xl text-white">{title}</h1>}
        {isTruthy(subtitle) && (
          <span className="text-xs leading-3 text-text-secondary">
            {subtitle}
          </span>
        )}
      </div>
      <div className={twMerge('mt-4 flex flex-col gap-2 px-4', className)}>
        {children}
      </div>
    </div>
  )
}
