import { EventFaq } from '../EventFaq/EventFaq'
import { EventLocation } from '../EventLocation/EventLocation'
import { EventTerms } from '../EventTerms/EventTerms'
import { EventTime } from '../EventTime/EventTime'

export const EventInfo = {
  Time: EventTime,
  Location: EventLocation,
  Terms: EventTerms,
  Faq: EventFaq,
}
