import { useState, useEffect } from 'react'
import useSWR from 'swr'
import { fetcher } from '@/services/axios'

import { type IDefaultProfilePicture } from '@/types/Auth/useProfilePicture'

export const useGetDefaultProfilePictures = (): {
  isLoading: boolean
  defaultProfilePictures: IDefaultProfilePicture[] | undefined
} => {
  const [isLoading, setIsLoading] = useState(true)

  const { data, error } = useSWR<IDefaultProfilePicture[], string>(
    `/users/profile-picture/default`,
    fetcher,
  )

  useEffect(() => {
    setIsLoading(!(data !== undefined || error !== undefined))
  }, [data, error])

  const defaultProfilePictures = data ?? undefined

  return {
    isLoading,
    defaultProfilePictures,
  }
}
