import { type ReactElement } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { motion } from 'framer-motion'

import { type Event } from '@/types/Organization'
import { twMerge } from 'tailwind-merge'

interface SideBarEventButtonProps {
  event: Event
}

export function SideBarEventButton({
  event,
}: SideBarEventButtonProps): ReactElement {
  const { alias } = useParams()

  const navigate = useNavigate()

  const eventIsSelected = alias === event.alias

  return (
    <button
      key={event.eventId}
      onClick={() => {
        navigate(`/events/${event.alias}`)
      }}
      className={twMerge(
        'flex items-center justify-between gap-1 p-1.5 transition-all duration-100 ease-in-out',
        eventIsSelected && 'rounded-md bg-dark-black',
      )}
    >
      <div className="flex items-center gap-2">
        <img
          src={`${import.meta.env.VITE_S3 as string}/${
            event.backgroundArtImageKey
          }`}
          alt="Organization image"
          className="aspect-square size-6 rounded-[4px]"
        />
        <span
          className={twMerge(
            'line-clamp-1 text-ellipsis text-start text-xs font-medium text-white',
            eventIsSelected && 'font-bold',
          )}
        >
          {event.name}
        </span>
        {event.eventType === 'CLOSE_FRIENDS' && (
          <div className="rounded-full bg-dark-light-gray px-1.5 text-2xs font-black text-dark-black">
            CF
          </div>
        )}
      </div>
      {eventIsSelected && (
        <div>
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
            transition={{ duration: 0.2 }}
            className="size-2 rounded-full bg-primary-main"
          />
        </div>
      )}
    </button>
  )
}
