import { api } from '@/services/axios'
import { type ILink, type UpdateLink } from '@/types/Links'
import { type AxiosResponse } from 'axios'
import { useState } from 'react'

interface PutReturnProps {
  status: boolean
  link?: ILink
  message?: string
}

export const useUpdateLink = (): {
  updateLink: (body: UpdateLink) => Promise<PutReturnProps>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function updateLink(body: UpdateLink): Promise<PutReturnProps> {
    setIsLoading(true)
    try {
      const response: AxiosResponse<ILink> = await api.put(
        `/admin/links/${body.linkId}`,
        body,
      )

      setIsLoading(false)

      return {
        status: true,
        link: response.data,
      }
    } catch (error) {
      setIsLoading(false)
      return {
        status: false,
        message: 'Link não pôde ser atualizado',
      }
    }
  }

  return {
    updateLink,
    isLoading,
  }
}
