import { useState, type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

import { Button } from '@/components/Global/Button'
import { Spinner } from '@/components/Global/Spinner'

import { TbCircleXFilled } from 'react-icons/tb'
import { TiPlus } from 'react-icons/ti'
import { FaRandom } from 'react-icons/fa'

import { type IDefaultProfilePicture } from '@/types/Auth/useProfilePicture'

interface SelectImageProps {
  defaultImages: IDefaultProfilePicture[]
  isLoadingDefaultImages: boolean
  selectedImageId: number | null
  uploadedImageUrl: string
  setSelectedImageId: (id: number | null) => void
  uploadedImage: File | undefined
  openFileExplorer: () => void
  resetImageData: () => void
  handleUploadImage: () => void
  isUploadingImage: boolean
}

export function SelectImage({
  defaultImages,
  isLoadingDefaultImages,
  selectedImageId,
  uploadedImageUrl,
  setSelectedImageId,
  uploadedImage,
  openFileExplorer,
  resetImageData,
  handleUploadImage,
  isUploadingImage,
}: SelectImageProps): ReactElement {
  const [numberOfLoadedImages, setNumberOfLoadedImages] = useState<number>(0)

  function handleRandomSelect(): void {
    const randomNumber = Math.floor(Math.random() * defaultImages.length)
    const randomSelectedImage = defaultImages[randomNumber]
    setSelectedImageId(randomSelectedImage.imageId)
  }

  return (
    <>
      <h1 className="text-xl font-black">Escolha uma foto de perfil.</h1>
      <span className="font-light ">(ou adicione a sua)</span>
      <div className="gap-auto relative grid w-full grid-cols-4 gap-2 py-8">
        {numberOfLoadedImages < defaultImages.length ||
          (isLoadingDefaultImages && (
            <div className="absolute z-30 flex size-full items-center justify-center bg-white">
              <Spinner
                width="h-12"
                height="w-12"
                borderWidth="border-[5px]"
                borderColor="border-[#232323]/50"
                bottomBorderColor="border-b-[#232323]"
              />
            </div>
          ))}
        {defaultImages.map((imageOption) => {
          return (
            <div
              key={imageOption.imageId}
              className="size-full hover:cursor-pointer"
              onClick={() => {
                setSelectedImageId(imageOption.imageId)
              }}
            >
              <img
                src={`${import.meta.env.VITE_S3 as string}/${imageOption.key}`}
                alt="Foto de perfil padrão"
                className={twMerge(
                  'aspect-square h-full w-full rounded-full transition-all ease-in-out ',
                  selectedImageId === imageOption.imageId &&
                    'scale-110 border-2 border-white drop-shadow-lg',
                )}
                onLoad={() => {
                  setNumberOfLoadedImages((prev) => prev + 1)
                }}
              />
            </div>
          )
        })}
        {numberOfLoadedImages === defaultImages.length && (
          <>
            {uploadedImage === undefined ? (
              <div
                className="flex aspect-square size-full items-center justify-center rounded-full bg-dark-dark-gray hover:cursor-pointer"
                onClick={openFileExplorer}
              >
                <TiPlus color="white" size={28} />
              </div>
            ) : (
              <div
                onClick={() => {
                  setSelectedImageId(null)
                }}
                className="relative hover:cursor-pointer"
              >
                <img
                  src={uploadedImageUrl}
                  alt="User picture"
                  className={twMerge(
                    'aspect-square h-full w-full rounded-full object-cover object-center transition-all ease-in-out',
                    selectedImageId === null &&
                      'scale-110 border-2 border-white drop-shadow-lg',
                  )}
                />
                <div
                  className="absolute -bottom-2 -right-2 z-10 rounded-full bg-white"
                  onClick={resetImageData}
                >
                  <TbCircleXFilled size={32} color="#FF5C5C" />
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <div className="flex w-full justify-between">
        <div className="mt-2 h-10 w-32">
          <Button
            text="Salvar"
            enabled={
              (uploadedImage !== undefined || selectedImageId !== null) &&
              !isUploadingImage
            }
            isLoading={isUploadingImage}
            onClick={() => {
              handleUploadImage()
            }}
            className="text-xl font-black disabled:bg-dark-light-gray"
          />
        </div>
        {defaultImages.length > 1 && (
          <button
            onClick={() => {
              handleRandomSelect()
            }}
            className="flex size-10 items-center justify-center rounded-full bg-dark-light-gray hover:cursor-pointer"
          >
            <FaRandom size={20} color="#656565" />
          </button>
        )}
      </div>
    </>
  )
}
