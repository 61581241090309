import { useState } from 'react'
import { type AxiosError } from 'axios'
import { api } from '@/services/axios'

import { type IPageViewData } from '@/types/Analytics'

interface GetReturnProps {
  data?: IPageViewData[]
  status: number
}

export const useGetPageViews = (
  alias: string,
): {
  getPageViews: (
    uniqueUsers: boolean,
    timeFrame: 'all' | '7days' | '1day',
  ) => Promise<GetReturnProps>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(true)

  async function getPageViews(
    uniqueUsers: boolean,
    timeFrame: 'all' | '7days' | '1day',
  ): Promise<GetReturnProps> {
    setIsLoading(true)
    try {
      const response = await api.get<IPageViewData[]>(
        `/admin/analytics/${alias}/page-view?distinct=${uniqueUsers}&period=${timeFrame}`,
      )
      setIsLoading(false)
      return {
        data: response.data,
        status: 200,
      }
    } catch (error) {
      setIsLoading(false)
      const err = error as AxiosError
      return {
        status: err.response?.status ?? 500,
      }
    }
  }

  return { getPageViews, isLoading }
}
