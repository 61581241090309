import { useState, useEffect } from 'react'
import useSWR from 'swr'

import { fetcher } from '@/services/axios'

import { type IUser } from '@/types/Team'

export const useGetTeamMembers = (
  organizationId: number,
): {
  teamMembers: IUser[] | undefined
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(true)
  const shouldFetch = organizationId !== undefined
  const { data, error } = useSWR<IUser[], string>(
    shouldFetch && `/organizations/${organizationId}/users`,
    fetcher,
  )

  useEffect(() => {
    setIsLoading(!(data !== undefined || error !== undefined))
  }, [data, error])

  return {
    teamMembers: data,
    isLoading,
  }
}
