import { useState, type ReactElement, useEffect, useRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { Button } from '@/components/Global/Button'

import { grantStore } from '@/store/grant'

import { formatPhoneNumber } from '@/utils/formatString'
import { validateEmail, validatePhone } from '@/utils/validation'
import { HiMiniPlusCircle } from 'react-icons/hi2'

interface GrantCreateFormProps {
  handleOnClick: () => void
}

export function GrantCreateForm({
  handleOnClick,
}: GrantCreateFormProps): ReactElement {
  const [newGrantContact, setNewGrantContact] = useState<string[]>([])
  const [errorMessage, setErrorMessage] = useState('')
  const [textareaInput, setTextareaInput] = useState('')

  const [inputIsValid, setInputIsValid] = useState(false)

  const divRef = useRef<HTMLDivElement>(null)
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const [showFade, setShowFade] = useState(false)

  const {
    addEmailOrPhoneToList,
    removeEmailOrPhoneToList,
    resetEmailOrPhoneList,
    emailOrPhoneList,
  } = grantStore()

  function checkInputIsValid(value: string): boolean {
    let inputIsValidFlag = true
    // Check if the string is empty
    if (value === '') {
      setErrorMessage('')
      setInputIsValid(false)
      return false
    }
    // Split the string into an array of strings
    const values = value.split('\n').filter((v) => v !== '')

    for (const v of values) {
      // Check if is wither phone or email
      if (!validateEmail(v) && !validatePhone(v)) {
        setInputIsValid(false)
        setErrorMessage(`"${v}" é um email ou telefone inválido.`)
        inputIsValidFlag = false
        return false
      }

      // PHONE
      if (validatePhone(v)) {
        // Check if has minimum length
        const regexCleanPhone = /[^0-9]/g
        const filteredPhone = v.replace(regexCleanPhone, '')
        if (filteredPhone.length < 10) {
          setInputIsValid(false)
          setErrorMessage(`"${v}" é um telefone inválido.`)
          inputIsValidFlag = false
          return false
        }
      }

      // Check if value is already in list
      const regex2 = /[^0-9+]/g
      const filteredV = v.replace(regex2, '')
      if (
        emailOrPhoneList.includes(filteredV) ||
        emailOrPhoneList.includes(v) ||
        emailOrPhoneList.includes(v.toLowerCase())
      ) {
        setInputIsValid(false)
        setErrorMessage(`"${v}" já foi adicionado.`)
        inputIsValidFlag = false
        return false
      }
    }

    if (!inputIsValidFlag) return inputIsValidFlag

    setInputIsValid(true)
    setErrorMessage('')
    setNewGrantContact(values)
    return true
  }

  useEffect(() => {
    textareaRef.current?.focus()
    resetEmailOrPhoneList()
  }, [])

  useEffect(() => {
    if (
      divRef.current?.clientHeight !== undefined &&
      divRef.current?.clientHeight > 175
    ) {
      setShowFade(true)
    }
  }, [emailOrPhoneList])

  useEffect(() => {
    const handleInput = (event: Event): void => {
      const target = event.target as HTMLTextAreaElement
      target.style.height = 'auto'
      target.style.height = `${target.scrollHeight}px`
    }

    const textarea = textareaRef.current
    textarea?.addEventListener('input', handleInput)

    return () => {
      textarea?.removeEventListener('input', handleInput)
    }
  }, [])

  return (
    <div className="flex w-full flex-col">
      <span className="text-3xl font-black">Convidados</span>
      <div className="mt-4 flex items-end">
        <div className="flex w-full flex-col gap-1">
          <span className="text-sm font-black leading-3">
            Email ou telefone
          </span>
          <textarea
            className="w-full resize-none appearance-none border-2 border-primary-main px-2 py-1 text-base font-medium outline-none duration-200 ease-in-out placeholder:placeholder:text-sm placeholder:font-bold focus:rounded-lg "
            placeholder="Ex.: cortesia@gandaya.dance"
            ref={textareaRef}
            style={{ maxHeight: '165px', overflow: 'auto', minHeight: '60px' }}
            value={textareaInput}
            onChange={(e) => {
              checkInputIsValid(e.target.value)
              setTextareaInput(e.target.value)
            }}
          />
          <span className="mb-1 min-h-[16px] text-xs font-bold text-tonal-red">
            {errorMessage}
          </span>
        </div>
        <button
          className="cursor-pointer"
          onClick={() => {
            if (checkInputIsValid(textareaInput)) {
              const cleanNewGrantContact = [
                ...new Set(newGrantContact.map((item) => item.toLowerCase())),
              ]
              cleanNewGrantContact.forEach((v) => {
                let valueToBeAdded = v
                if (validatePhone(v)) {
                  const regex = /[^0-9+]/g
                  valueToBeAdded = v.replace(regex, '')
                }
                addEmailOrPhoneToList(valueToBeAdded)
              })
              if (textareaRef.current !== null) {
                textareaRef.current.style.height = 'auto'
              }
              setNewGrantContact([])
              setTextareaInput('')
              setInputIsValid(false)
            }
          }}
        >
          <HiMiniPlusCircle
            size={26}
            className={twMerge(
              'mb-6 ml-2 rounded-full text-dark-light-gray',
              inputIsValid && 'text-dark-dark-gray',
            )}
          />
        </button>
      </div>
      <div className="relative max-h-44 overflow-hidden">
        {emailOrPhoneList.length !== 0 && (
          <div
            className="flex max-h-44 flex-wrap gap-2 overflow-scroll py-2"
            ref={divRef}
          >
            {emailOrPhoneList.map((emailOrPhone, index) => {
              let value
              if (validateEmail(emailOrPhone)) {
                value = emailOrPhone
              } else {
                if (emailOrPhone.slice(0, 3) === '+55') {
                  value = `+55 ${formatPhoneNumber(emailOrPhone.slice(3))}`
                } else {
                  value = `+55 ${formatPhoneNumber(emailOrPhone)}`
                }
              }

              return (
                <div
                  key={index}
                  className="flex w-min flex-nowrap items-center gap-4 whitespace-nowrap rounded-lg bg-[#f4f4f4] p-2"
                >
                  <span className="w-full font-medium">{value}</span>
                  <div className="flex aspect-square max-h-full items-center justify-center rounded-full bg-dark-dark-gray p-0.5">
                    <button
                      className="size-min rotate-45 cursor-pointer text-3xl font-extralight leading-3 text-white"
                      onClick={() => {
                        removeEmailOrPhoneToList(emailOrPhone)
                      }}
                    >
                      <span>+</span>
                    </button>
                  </div>
                </div>
              )
            })}
          </div>
        )}
        {showFade && (
          <div className="absolute -top-0.5 left-0 z-50 h-2 w-full bg-gradient-to-b from-white to-transparent" />
        )}
        {showFade && (
          <div className="absolute -bottom-0.5 left-0 z-50 h-2 w-full bg-gradient-to-b from-transparent to-white" />
        )}
      </div>
      {emailOrPhoneList.length !== 0 && <div className="h-6" />}
      <div className="h-12">
        <Button
          enabled={emailOrPhoneList.length > 0}
          text="Selecionar ingressos"
          onClick={handleOnClick}
        />
      </div>
    </div>
  )
}
