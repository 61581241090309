import { useState, type ReactElement, useEffect } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { twMerge } from 'tailwind-merge'

import { Modal } from '@/components/Global/Modal'

import { deviceStore } from '@/store/device'

import { BsCheckLg } from 'react-icons/bs'

interface CreateEventLoaderModalProps {
  closeModal: () => void
  isParentClosing: boolean
  actionSuccess: boolean
  type: 'Create' | 'Update'
}

export function CreateEventLoaderModal({
  closeModal,
  isParentClosing,
  actionSuccess,
  type,
}: CreateEventLoaderModalProps): ReactElement {
  const [animationStateIndex, setAnimationStateIndex] = useState(0)
  const { isMobile } = deviceStore()
  const createPhraseList = [
    '☁️ Subindo dados para a nuvem',
    '🚀 Otimizando imagens',
    '🔨 Preparando página personalizada',
    '💳 Adicionando meios de pagamento',
    '🙄 Configurando taxas',
    '📊 Criando ambiente de analíticos',
    '👮‍♂️ Checagens de segurança',
  ]

  const updatePhraseList = [
    '☁️ Subindo dados para a nuvem',
    '🔨 Atualizando página personalizada',
    '💳 Revisando meios de pagamento',
    '🙄 Conferindo taxas',
    '📊 Atualizando ambiente de analíticos',
    '👮‍♂️ Checagens de segurança',
  ]

  const phraseList = type === 'Create' ? createPhraseList : updatePhraseList

  useEffect(() => {
    const timer = setInterval(() => {
      setAnimationStateIndex((prevIndex) => (prevIndex + 1) % phraseList.length)
    }, 1800)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <Modal
      isMobile={isMobile}
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      disableTouchToClose
    >
      <motion.div
        animate={{ height: actionSuccess ? '200px' : '320px' }}
        transition={{ duration: 0.5 }}
      >
        <AnimatePresence mode="wait">
          {actionSuccess ? (
            <motion.div
              className="flex flex-col items-center justify-center gap-8 p-4"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2, ease: 'easeInOut' }}
              key="success"
            >
              <h1 className="text-2xl text-black">
                {`Evento ${type === 'Create' ? 'criado' : 'atualizado'} com sucesso!`}
              </h1>
              <div className="flex items-center justify-center rounded-full bg-primary-main p-4">
                <BsCheckLg size={64} color="#000" />
              </div>
            </motion.div>
          ) : (
            <motion.div
              className="flex size-full flex-col items-center justify-between p-4"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2, ease: 'easeInOut' }}
              key="working"
            >
              <h1 className="text-2xl text-black">
                {`Seu evento está sendo ${type === 'Create' ? 'criado' : 'atualizado'}!`}
              </h1>
              <div className="relative flex h-10 w-full flex-col items-center justify-center p-8">
                <AnimatePresence>
                  {phraseList.map((phrase, index) => {
                    if (index === animationStateIndex) {
                      return (
                        <motion.h1
                          key={phrase}
                          className={twMerge(
                            'absolute text-center text-2xl font-medium text-dark-black',
                            isMobile ? 'text-xl' : 'text-xl',
                          )}
                          initial={{ opacity: 0, y: 30, scale: 0.75 }}
                          animate={{ opacity: 1, y: 0, scale: 1 }}
                          exit={{ opacity: 0, y: -30, scale: 0.75 }}
                          transition={{ duration: 2, ease: 'anticipate' }}
                        >
                          {phrase}
                        </motion.h1>
                      )
                    }
                    return null
                  })}
                </AnimatePresence>
              </div>

              <span className="text-center text-xs text-black">
                Isso pode demorar um pouquinho. Por favor não recarregue essa
                página.
              </span>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </Modal>
  )
}
