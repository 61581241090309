import { type ReactElement } from 'react'

import { Modal } from '@/components/Global/Modal'

import { deviceStore } from '@/store/device'
import { Button } from '@/components/Global/Button'

interface ConfirmationModalProps {
  onConfirm: () => void
  closeModal: () => void
  isParentClosing: boolean
  removeFrom: 'organization' | 'event'
  firstName: string
  lastName: string
  eventName?: string
  organizationName?: string
  isLoading?: boolean
}

export function ConfirmationModal({
  firstName,
  lastName,
  eventName,
  organizationName,
  removeFrom,
  onConfirm,
  closeModal,
  isParentClosing,
  isLoading = false,
}: ConfirmationModalProps): ReactElement {
  const { isMobile } = deviceStore()

  return (
    <Modal
      isMobile={isMobile}
      isParentClosing={isParentClosing}
      closeModal={closeModal}
    >
      <div className="flex w-full flex-col items-center gap-8 text-black">
        <span className="text-3xl font-black">Atenção!</span>
        {removeFrom === 'organization' ? (
          <span className="text-center leading-5">
            Você tem certeza que deseja remover{' '}
            <strong>
              {firstName} {lastName}
            </strong>{' '}
            da organização <strong>{organizationName}</strong>?
          </span>
        ) : (
          <span className="text-center leading-5">
            Você tem certeza que deseja remover{' '}
            <strong>
              {firstName} {lastName}
            </strong>{' '}
            do evento <strong>{eventName}</strong>?
          </span>
        )}
        <div className="flex w-full flex-col gap-4 desktop:h-12 desktop:max-w-[350px] desktop:flex-row">
          <Button
            text="Confirmar"
            enabled={!isLoading}
            onClick={onConfirm}
            isLoading={isLoading}
          />
          <Button
            text="Cancelar"
            enabled
            onClick={closeModal}
            className="bg-tonal-red text-white"
          />
        </div>
      </div>
    </Modal>
  )
}
