import { useRef, type ChangeEvent, type ReactElement } from 'react'

import { UploadIcon } from '@/components/Icons/UploadIcon'
import { DeleteIcon } from '@/components/Icons/DeleteIcon'
import { isTruthy } from '@/utils/validation'

interface CreateEditOrganizationUploadImageProps {
  handleOpenCropModal: () => void
  setUploadedImageUrl: (url: string) => void
  croppedImage?: File
  croppedImageUrl?: string
  setCroppedImage: (croppedImage: File | undefined) => void
  setCroppedImageUrl: (croppedImageUrl: string | undefined) => void
}

export function CreateEditOrganizationUploadImage({
  handleOpenCropModal,
  setUploadedImageUrl,
  croppedImageUrl,
  setCroppedImage,
  setCroppedImageUrl,
}: CreateEditOrganizationUploadImageProps): ReactElement {
  const inputRef = useRef<HTMLInputElement>(null)

  function handleImageChange(event: ChangeEvent): void {
    event.stopPropagation()
    event.preventDefault()

    const target = event.target as HTMLInputElement
    const files = target.files as FileList
    const profileImage = files[0]

    setUploadedImageUrl(URL.createObjectURL(profileImage))

    handleOpenCropModal()
  }

  function openFileExplorer(): void {
    inputRef?.current?.click()
  }

  return (
    <div className="my-8 flex size-full max-w-[200px] flex-col items-center gap-4 rounded-md bg-transparent p-4 desktop:my-0 desktop:bg-[#212121]">
      {isTruthy(croppedImageUrl) ? (
        <div className="relative size-[160px] hover:cursor-pointer">
          <img
            src={croppedImageUrl}
            alt="Nova foto de perfil da organização"
            className="size-full rounded-md object-cover"
            onClick={openFileExplorer}
          />
          <DeleteIcon
            className="absolute -right-2 -top-2"
            onClick={(e) => {
              e.preventDefault()
              setCroppedImage(undefined)
              setCroppedImageUrl(undefined)
              inputRef.current!.value = ''
            }}
          />
        </div>
      ) : (
        <div
          className="flex size-[160px] flex-col items-center justify-center gap-2 rounded-md outline-dashed outline-2 outline-primary-main hover:cursor-pointer"
          onClick={openFileExplorer}
        >
          <UploadIcon iconColor="#fff" className="size-6" />
          <span className="text-center text-xs">
            Adicione uma foto da sua equipe
          </span>
        </div>
      )}

      <div className="flex flex-col items-center">
        <span className="text-xs font-bold desktop:self-start">
          Imagem da equipe (1:1)
        </span>
        <span className="text-center text-xs leading-4 desktop:self-start desktop:text-left">
          A imagem será utilizada no nosso site e aplicativo.
        </span>
      </div>

      <input
        className="hidden"
        ref={inputRef}
        type="file"
        name="profilePicture"
        onChange={(event: ChangeEvent) => {
          handleImageChange(event)
        }}
      />
    </div>
  )
}
