import { type ReactElement } from 'react'
import { createEvent } from 'ics'

import { AiOutlineCalendar } from 'react-icons/ai'

import { type IEventData } from '@/types/Event'

import { datesToFormat, generateEventTime } from '@/utils/formatData'
import { isTruthy } from '@/utils/validation'

interface EventTimeProps {
  eventData: IEventData | undefined
}

export function EventTime({ eventData }: EventTimeProps): ReactElement {
  const handleSave = async (): Promise<void> => {
    const filename = `${eventData?.title ?? 'event'}.ics`
    const eventDate = new Date(eventData?.date.startDate ?? '')

    const file = await new Promise((resolve, reject) => {
      createEvent(
        {
          start: [
            eventDate.getFullYear(),
            eventDate.getMonth() + 1,
            eventDate.getDate(),
            eventDate.getHours(),
            eventDate.getMinutes(),
          ],
          duration: { hours: 6 },
          title: eventData?.title,
          description: eventData?.description,
          location: eventData?.location!.name,
          geo: {
            lat: eventData?.location!.latitude ?? 0,
            lon: eventData?.location!.longitude ?? 0,
          },
          status: 'CONFIRMED',
          busyStatus: 'BUSY',
        },
        (error, value) => {
          if (error != null) {
            reject(error)
          }

          resolve(new File([value], filename, { type: 'text/calendar' }))
        },
      )
    })
    const url = URL.createObjectURL(file as Blob)

    const anchor = document.createElement('a')
    anchor.href = url
    anchor.download = filename

    document.body.appendChild(anchor)
    anchor.click()
    document.body.removeChild(anchor)

    URL.revokeObjectURL(url)
  }

  const { formattedStartDate, formattedEndDate, formattedMaxEntranceDate } =
    generateEventTime(
      eventData?.date.startDate ?? '',
      eventData?.date.endDate ?? '',
      eventData?.date.maxEntranceDate ?? '',
    )

  return (
    <div className="flex w-full items-center rounded-lg bg-dark-black p-4">
      <div className="flex grow flex-col items-start justify-start">
        <span className={`text-base font-bold leading-7 text-primary-main`}>
          {datesToFormat(eventData!.date.startDate!, eventData!.date.endDate!)}
        </span>
        <span className="text-sm font-normal leading-5 text-text-secondary">
          Início {formattedStartDate}
        </span>
        {isTruthy(formattedMaxEntranceDate) && (
          <span className="text-sm font-normal leading-5 text-text-secondary">
            {`Entrada máxima ${formattedMaxEntranceDate}`}
          </span>
        )}
        <span className="text-sm font-normal leading-5 text-text-secondary">
          Término {formattedEndDate}
        </span>
      </div>

      <div
        className="flex size-8 items-center justify-center rounded-full bg-white hover:cursor-pointer"
        onClick={() => {
          void handleSave()
        }}
      >
        <AiOutlineCalendar size={20} color="#232323" />
      </div>
    </div>
  )
}
