import { type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

import { numberToReais } from '@/utils/formatNumber'

import { deviceStore } from '@/store/device'

import { SlArrowRight } from 'react-icons/sl'

interface WithdrawalCardProps {
  title: string
  status: 'PENDING' | 'APPROVED' | 'REJECTED' | 'CANCELLED'
  amount: number
  onClick: () => void
}

export function WithdrawalCard({
  title,
  status,
  amount,
  onClick,
}: WithdrawalCardProps): ReactElement {
  const { isMobile } = deviceStore()

  const withdrawalStatus =
    status === 'PENDING'
      ? 'Em análise'
      : status === 'APPROVED'
        ? 'Aprovado'
        : status === 'REJECTED'
          ? 'Negado'
          : 'Cancelado'

  const colorStatus =
    status === 'PENDING'
      ? 'bg-tonal-orange'
      : status === 'APPROVED'
        ? 'bg-primary-main'
        : status === 'REJECTED'
          ? 'bg-tonal-red'
          : 'bg-tonal-red'

  return (
    <div
      className="mb-4 flex w-full items-center gap-4 rounded-lg bg-secondary-gray p-4 transition-colors duration-100 ease-in-out hover:cursor-pointer desktop:hover:bg-dark-black"
      onClick={onClick}
    >
      <div className="flex w-full items-center justify-between">
        <div className="flex flex-col">
          <span className="font-bold">{title}</span>
          <div className="flex items-center gap-1">
            <div className={twMerge('h-2 w-2 rounded-full ', colorStatus)} />
            <span className="text-xs">{withdrawalStatus}</span>
          </div>
        </div>
        <span className="text-xl font-bold">{numberToReais(amount, 2)}</span>
      </div>
      {isMobile && <SlArrowRight color="#7c7c7c" size={12} />}
    </div>
  )
}
