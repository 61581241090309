import { type ReactElement, useState, useRef, useEffect } from 'react'
import { twMerge } from 'tailwind-merge'

import { Modal } from '@/components/Global/Modal'
import { Button } from '@/components/Global/Button'
import { UploadIcon } from '@/components/Icons/UploadIcon'
import { MemoizedRenderPDF } from '@/components/Finance/MemoizedRenderPDF'

import { isTruthy } from '@/utils/validation'
import { numberToReais } from '@/utils/formatNumber'

import { errorStore, useCurrentError } from '@/store/error'
import { deviceStore } from '@/store/device'

import { usePostCreateWithdrawal } from '@/hooks/Finance'

import { CREATE_WITHDRAWAL, MINIMUM_WITHDRAWAL_VALUE } from '@/errors'

interface CreateExternalWithdrawalModalProps {
  closeModal: () => void
  handleModalClose: () => void
  isParentClosing?: boolean
  alias: string
  balance: number
}

export function CreateExternalWithdrawalModal({
  closeModal,
  handleModalClose,
  isParentClosing,
  alias,
  balance,
}: CreateExternalWithdrawalModalProps): ReactElement {
  const [title, setTitle] = useState('')
  const [amount, setAmount] = useState(0)
  const [description, setDescription] = useState('')
  const [accountKey, setAccountKey] = useState('')
  const [invoice, setInvoice] = useState<File>()
  const [amountBiggerThanBalance, setAmountBiggerThanBalance] = useState(false)

  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  const { createWithdrawal, isLoading } = usePostCreateWithdrawal(alias)

  const { isError, errorMessage } = useCurrentError()
  const { showError } = errorStore()
  const { isMobile } = deviceStore()

  async function handleCreateWithdrawal(): Promise<void> {
    const response = await createWithdrawal({
      type: 'EXTERNAL',
      amount,
      description,
      accountKey,
      invoice,
      receiver: title,
    })

    if (response.status === 200) {
      handleModalClose()
      return
    }
    showError(CREATE_WITHDRAWAL)
  }

  useEffect(() => {
    setAmountBiggerThanBalance(amount > balance)
  }, [amount, balance])

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      isError={isError}
      errorMessage={errorMessage}
      isMobile={isMobile}
    >
      <div className="flex h-fit w-full flex-col items-center gap-8 p-4 text-black">
        <div className="flex w-full flex-col gap-0">
          <input
            type="text"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value)
            }}
            placeholder="Nome do destinatário *"
            className="mb-4 text-3xl outline-none placeholder:text-3xl placeholder:font-black placeholder:text-text-terciary"
          />

          <div className="flex flex-col">
            <span className="font-bold">Valor *</span>
            <input
              type="string"
              value={numberToReais(amount, 2)}
              onChange={(e) => {
                const value = e.target.value
                const cleanedValue = value
                  .replace(/[^0-9]/g, '')
                  .replace(/^0+/, '')
                const number = Number(cleanedValue)
                const finalNumber = number / 100
                setAmount(finalNumber)
              }}
              placeholder="R$ 1.928,32"
              className={twMerge(
                'border-2 border-x-white border-b-primary-main border-t-white py-2 outline-none transition-all duration-200 ease-in-out focus:rounded-lg focus:border-2 focus:border-primary-main focus:px-2',
                amountBiggerThanBalance &&
                  'border-b-tonal-red focus:border-tonal-red',
              )}
              inputMode="numeric"
            />
            <span
              className={twMerge(
                'mt-1 text-xs font-medium transition-colors duration-100 ease-in-out',
                amountBiggerThanBalance ? 'text-tonal-red' : 'text-white',
              )}
            >
              O valor não pode ser maior que o seu saldo.
            </span>
          </div>

          <div className="mb-4 flex flex-col">
            <span className="font-bold">Descrição</span>
            <textarea
              value={description}
              onChange={(e) => {
                setDescription(e.target.value)
              }}
              ref={textAreaRef}
              className="resize-none appearance-none rounded-lg border-2 border-primary-main p-2 text-sm outline-none placeholder:text-text-terciary"
              placeholder="...."
            />
          </div>

          <div className="mb-4 flex flex-col">
            <span className="font-bold">Destinatário (PIX) *</span>
            <input
              type="text"
              value={accountKey}
              onChange={(e) => {
                setAccountKey(e.target.value)
              }}
              placeholder="123.456.789.10"
              className="border-2 border-x-white border-b-primary-main border-t-white py-2 outline-none transition-all duration-200 ease-in-out focus:rounded-lg focus:border-2 focus:border-primary-main focus:px-2"
            />
          </div>

          <label
            htmlFor="invoiceImage"
            className={
              'flex h-32 w-full flex-col items-center justify-center gap-2 rounded-lg bg-dark-light-gray hover:cursor-pointer'
            }
          >
            {!isTruthy(invoice) ? (
              <>
                <UploadIcon iconColor="#181818" className="size-6" />
                <span className="w-3/5 text-center text-xs">
                  Anexe uma foto da Nota Fiscal emitida pelo prestador de
                  serviço. *
                </span>
              </>
            ) : (
              <div className="flex max-h-full w-full items-center justify-start gap-4 p-4">
                <div className="h-24 overflow-hidden rounded-md">
                  <MemoizedRenderPDF file={invoice} height={100} />
                </div>
                <span className="text-sm">{invoice?.name}</span>
              </div>
            )}
            <input
              id="invoiceImage"
              className="hidden"
              type="file"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.files !== null) setInvoice(e.target.files[0])
              }}
              onClick={(e: React.FormEvent<HTMLInputElement>) => {
                const target = e.target as HTMLButtonElement
                target.value = ''
              }}
              accept="application/pdf"
            />
          </label>

          <div className="mt-4 h-12 w-full max-w-[300px] self-center">
            <Button
              enabled={
                isTruthy(title) &&
                isTruthy(amount) &&
                isTruthy(accountKey) &&
                isTruthy(invoice) &&
                !isLoading &&
                !amountBiggerThanBalance
              }
              text="Confirmar"
              onClick={() => {
                if (amount < 1) {
                  showError(MINIMUM_WITHDRAWAL_VALUE)
                  return
                }
                void handleCreateWithdrawal()
              }}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}
