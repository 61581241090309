import { type ReactElement } from 'react'
import { tv } from 'tailwind-variants'

import { organizationStore } from '@/store/organization'
import { type OrganizationData } from '@/types/Organization'

interface TeamsModalCardProps {
  organization: OrganizationData
  active: boolean
  handleModalClose: () => void
}

const variants = tv({
  base: 'relative flex px-4 border-2 py-3 rounded-lg gap-4 items-center justify-between hover:cursor-pointer transition-color duration-200 ease-in-out',
  variants: {
    border: {
      light: 'border-[#f4f4f4] bg-[#f4f4f4]',
      lightSelected: 'border-black bg-[#f4f4f4]',
    },
  },
})

const selectorDivVariants = tv({
  base: 'relative flex size-5 items-center justify-center rounded-full border-2',
  variants: {
    background: {
      light: 'border-[#d9d9d9] bg-[#f4f4f4]',
      lightSelected: 'border-[#a3a3a3] bg-[#f4f4f4] ',
    },
  },
})

const selectorVariants = tv({
  base: 'absolute left-1/2 top-1/2 size-3 -translate-x-1/2 -translate-y-1/2 rounded-full',
  variants: {
    background: {
      light: 'bg-[#f4f4f4] border-[#f4f4f4]',
      lightSelected: 'bg-black border-[#f4f4f4]',
    },
  },
})

export function OrganizationsModalCard({
  organization,
  active,
  handleModalClose,
}: TeamsModalCardProps): ReactElement {
  const { setCurrentOrganization } = organizationStore()

  function handleOnClick(): void {
    setCurrentOrganization(organization)
    handleModalClose()
  }

  const variantSelected = active ? 'lightSelected' : 'light'

  return (
    <button
      className={variants({ border: variantSelected })}
      onClick={handleOnClick}
    >
      <div className="justify-justify-start flex items-center gap-2">
        <img
          src={`${import.meta.env.VITE_S3 as string}/${
            organization.organizer.backgroundArtImageKey
          }`}
          alt="Imagem de capa da organização"
          className="size-12 rounded-full"
        />
        <div className="flex flex-col items-start">
          <span className="text-sm font-bold">
            {organization.organizer.name}
          </span>
          <span className="text-xs">{organization.organizer.role ?? ''}</span>
        </div>
      </div>
      <div className={selectorDivVariants({ background: variantSelected })}>
        <div className={selectorVariants({ background: variantSelected })} />
      </div>
    </button>
  )
}
