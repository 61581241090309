import { useEffect, type ReactElement, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { PermissionSelector } from '@/components/Team/PermissionSelector'
import { PermissionSwitch } from '@/components/Team/PermissionSwitch'
import { PermissionsPreset } from '@/components/Team/PermissionsPreset'
import { EventCard } from '@/components/Team/EventCard'
import { Button } from '@/components/Global/Button'
import { BackButton } from '@/components/Global/BackButton'
import { IconButton } from '@/components/Global/IconButton'
import { ConfirmationModal } from '@/components/Team/ConfirmationModal'
import { Loading } from '@/components/Global/Loading/Loading'

import {
  eventPermissionsDescriptions,
  organizationPermissionsDescriptions,
} from '@/utils/teamPermissionsDescriptions'
import { hasClearence } from '@/utils/rbac'
import { permissionsDeepEqual } from '@/utils/objects'

import {
  useGetMemberGlobalPermissions,
  useGetEventsByUsername,
  useDeleteOrganizationRole,
  usePutUpdateOrganizationRole,
} from '@/hooks/Team'

import { teamStore } from '@/store/team'
import { useCurrentOrganization } from '@/store/organization'
import { deviceStore } from '@/store/device'
import { errorStore } from '@/store/error'
import { useUserPermissions, userStore } from '@/store/user'

import { AiOutlineDelete } from 'react-icons/ai'
import { IoSaveOutline } from 'react-icons/io5'

import {
  CANT_UPDATE_ORGANIZATION_ROLE,
  CANT_DELETE_ORGANIZATION_ROLE,
} from '@/errors'

import { type IPermissions } from '@/types/Team'

export function GlobalUserPermissions(): ReactElement {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const [initialPermissions, setInitialPermissions] = useState<IPermissions>()
  const [isParentClosing, setIsParentClosing] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const navigate = useNavigate()

  const { user, permissions, setPermissions } = teamStore()
  const { username } = userStore()
  const { isMobile } = deviceStore()
  const { currentOrganization } = useCurrentOrganization()
  const { showError } = errorStore()
  const { organizationPermissions } = useUserPermissions()

  const { permissions: permissionsData, isLoading: isLoadingFetch } =
    useGetMemberGlobalPermissions(
      currentOrganization?.organizer.organizationId ?? 0,
      user?.username ?? '',
    )
  const { events } = useGetEventsByUsername(
    currentOrganization?.organizer.organizationId ?? 0,
    user?.username ?? '',
  )
  const { updateOrganizationRole, isLoading: isLoadingUpdate } =
    usePutUpdateOrganizationRole(
      currentOrganization?.organizer.organizationId ?? 0,
    )
  const { deleteOrganizationRole, isLoading: isLoadingDelete } =
    useDeleteOrganizationRole(
      currentOrganization?.organizer.organizationId ?? 0,
    )

  const canEdit = hasClearence(organizationPermissions.access, 'EDITOR')
  const userHasGlobalPermissions = permissionsData?.roles !== undefined
  const isSelfEdit = user?.username === username

  async function handleUpdateOrganizationRole(): Promise<void> {
    const response = await updateOrganizationRole(user?.id ?? 0, {
      roles: permissions,
    })

    if (response.status !== 200) {
      showError(CANT_UPDATE_ORGANIZATION_ROLE)
      return
    }

    setInitialPermissions(permissions)

    // TO-DO adicionar snack bar verde de sucesso
  }

  async function handleDeleteOrganizationRole(): Promise<void> {
    const response = await deleteOrganizationRole(user?.id ?? 0)

    if (response.status !== 200) {
      showError(CANT_DELETE_ORGANIZATION_ROLE)
      return
    }

    navigate('/team')
  }

  useEffect(() => {
    if (permissionsData !== undefined) {
      setPermissions(permissionsData.roles)
      setInitialPermissions(permissionsData.roles)
    }
  }, [permissionsData])

  useEffect(() => {
    if (permissionsDeepEqual(permissions, initialPermissions)) {
      setHasUnsavedChanges(false)
    } else {
      setHasUnsavedChanges(true)
    }
  }, [permissions, initialPermissions])

  if (isLoadingFetch) return <Loading />
  return (
    <div className="relative flex size-full flex-col items-start gap-8 p-4">
      <div className="flex w-full flex-col gap-4">
        {!isMobile && (
          <div className="flex w-full items-center justify-between">
            <BackButton />

            {!isMobile && canEdit && (
              <div className="flex h-7 items-center gap-2">
                {!isSelfEdit && (
                  <div className="h-7 w-24">
                    <IconButton
                      text="Remover"
                      Icon={(props) => <AiOutlineDelete size={20} {...props} />}
                      enabled
                      onClick={() => {
                        setShowConfirmationModal(true)
                      }}
                      variant="red"
                    />
                  </div>
                )}
                <div className="h-7 w-24">
                  <IconButton
                    text="Salvar"
                    Icon={(props) => <IoSaveOutline size={20} {...props} />}
                    enabled={hasUnsavedChanges}
                    onClick={() => {
                      void handleUpdateOrganizationRole()
                    }}
                    variant="green"
                    isLoading={isLoadingUpdate}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        {user !== undefined && (
          <div className="flex w-full flex-col items-end gap-6 desktop:flex-row desktop:items-center desktop:gap-0">
            <div className="flex w-full items-center gap-4">
              <img
                src={`${import.meta.env.VITE_S3 as string}/${user.imageKey}`}
                alt="User profile image"
                className="aspect-square size-20 rounded-full"
              />
              <div className="flex w-full flex-col gap-1.5">
                <span className="text-2xl font-bold leading-6">
                  {user.firstName} {user.lastName}
                </span>
                <span className="text-sm leading-3">@{user.username}</span>
              </div>
            </div>
            {userHasGlobalPermissions && canEdit && (
              <PermissionsPreset
                permissions={permissions}
                setPermissions={setPermissions}
                isGlobalPermission
              />
            )}
          </div>
        )}
      </div>
      {userHasGlobalPermissions && (
        <div className="flex w-full flex-col gap-4 desktop:flex-row">
          <div className="flex w-full break-inside-avoid-column flex-col gap-2">
            <div>
              <span className="text-lg font-black">Permissões nos eventos</span>
            </div>
            <div className="flex w-full flex-col gap-2">
              <PermissionSelector
                permissions={eventPermissionsDescriptions.FINANCE}
                title="Financeiro"
                currentKey={permissions?.event?.finance}
                setCurrentKey={(p) => {
                  if (p === 'EDITOR') {
                    setPermissions({
                      ...permissions,
                      event: { ...permissions.event, finance: p },
                      organization: {
                        ...permissions.organization,
                        settings: true,
                      },
                    })
                  } else {
                    setPermissions({
                      ...permissions,
                      event: { ...permissions.event, finance: p },
                    })
                  }
                }}
                canEdit={canEdit}
              />
              <PermissionSelector
                permissions={eventPermissionsDescriptions.ANALYTICS}
                title="Analíticos"
                currentKey={permissions?.event?.analytics}
                setCurrentKey={(p) => {
                  setPermissions({
                    ...permissions,
                    event: { ...permissions.event, analytics: p },
                  })
                }}
                canEdit={canEdit}
              />
              <PermissionSelector
                permissions={eventPermissionsDescriptions.LIST}
                title="Lista"
                currentKey={permissions?.event?.list}
                setCurrentKey={(p) => {
                  setPermissions({
                    ...permissions,
                    event: { ...permissions.event, list: p },
                  })
                }}
                canEdit={canEdit}
              />
              <PermissionSelector
                permissions={eventPermissionsDescriptions.TICKETS}
                title="Lotes"
                currentKey={permissions?.event?.tickets}
                setCurrentKey={(p) => {
                  setPermissions({
                    ...permissions,
                    event: { ...permissions.event, tickets: p },
                  })
                }}
                canEdit={canEdit}
              />
              <PermissionSelector
                permissions={eventPermissionsDescriptions.GRANT}
                title="Cortesias"
                currentKey={permissions?.event?.grant}
                setCurrentKey={(p) => {
                  setPermissions({
                    ...permissions,
                    event: { ...permissions.event, grant: p },
                  })
                }}
                canEdit={canEdit}
              />
              <PermissionSelector
                permissions={eventPermissionsDescriptions.LINK}
                title="Links"
                currentKey={permissions?.event?.link}
                setCurrentKey={(p) => {
                  setPermissions({
                    ...permissions,
                    event: { ...permissions.event, link: p },
                  })
                }}
                canEdit={canEdit}
              />
              <PermissionSwitch
                title="Validação de ingressos"
                active={permissions?.event?.validation}
                setActive={(state) => {
                  setPermissions({
                    ...permissions,
                    event: { ...permissions.event, validation: state },
                  })
                }}
                canEdit={canEdit}
              />
              <PermissionSwitch
                title="Editar evento"
                active={permissions?.event?.edit}
                setActive={(state) => {
                  setPermissions({
                    ...permissions,
                    event: { ...permissions.event, edit: state },
                  })
                }}
                canEdit={canEdit}
              />
            </div>
          </div>

          <div
            className={twMerge(
              'flex w-full break-inside-avoid-column flex-col gap-2',
              events?.length === 0 && 'pb-20',
            )}
          >
            <div>
              <span className="text-lg font-black">
                Permissões na organização
              </span>
            </div>
            <div className="flex w-full flex-col gap-2">
              <PermissionSelector
                permissions={organizationPermissionsDescriptions.FINANCE}
                title="Financeiro"
                currentKey={permissions?.organization?.finance}
                setCurrentKey={(p) => {
                  setPermissions({
                    ...permissions,
                    organization: { ...permissions.organization, finance: p },
                  })
                }}
                canEdit={canEdit}
              />
              <PermissionSelector
                permissions={organizationPermissionsDescriptions.ANALYTICS}
                title="Analíticos"
                currentKey={permissions?.organization?.analytics}
                setCurrentKey={(p) => {
                  setPermissions({
                    ...permissions,
                    organization: {
                      ...permissions.organization,
                      analytics: p,
                    },
                  })
                }}
                canEdit={canEdit}
              />
              <PermissionSelector
                permissions={organizationPermissionsDescriptions.MANAGEMENT}
                title="Gestão de membros"
                currentKey={permissions?.organization?.access}
                setCurrentKey={(p) => {
                  setPermissions({
                    ...permissions,
                    organization: {
                      ...permissions.organization,
                      access: p,
                    },
                  })
                }}
                canEdit={canEdit}
              />
              <PermissionSelector
                permissions={
                  organizationPermissionsDescriptions.COMMUNICATION_CAMPAIGN
                }
                title="Envio de comunicações"
                currentKey={permissions?.organization?.communicationCampaign}
                setCurrentKey={(p) => {
                  setPermissions({
                    ...permissions,
                    organization: {
                      ...permissions.organization,
                      communicationCampaign: p,
                    },
                  })
                }}
                canEdit={canEdit}
              />
              <PermissionSwitch
                title="Criação de eventos"
                active={permissions?.organization?.eventCreation}
                setActive={(state) => {
                  setPermissions({
                    ...permissions,
                    organization: {
                      ...permissions.organization,
                      eventCreation: state,
                    },
                  })
                }}
                canEdit={canEdit}
              />
              <PermissionSwitch
                title="Editar organização"
                active={permissions?.organization?.settings}
                setActive={(state) => {
                  if (!state && permissions?.event?.finance === 'EDITOR') {
                    setPermissions({
                      ...permissions,
                      organization: {
                        ...permissions.organization,
                        settings: state,
                      },
                      event: { ...permissions.event, finance: 'VIEWER' },
                    })
                  } else {
                    setPermissions({
                      ...permissions,
                      organization: {
                        ...permissions.organization,
                        settings: state,
                      },
                    })
                  }
                }}
                canEdit={canEdit}
              />
            </div>
          </div>
        </div>
      )}
      {events !== undefined && events.length > 0 && (
        <div className="flex w-full flex-col gap-2 pb-20 desktop:pb-4">
          <div>
            <span className="text-lg font-black">Permissões específicas</span>
          </div>
          <div className="w-full columns-1 gap-2 rounded-lg bg-[#232323] p-4 pb-2 desktop:columns-2">
            {events?.map((event) => {
              return (
                <div key={event.alias} className="w-full pb-2">
                  <EventCard
                    name={event.name}
                    imageKey={event.imageKey}
                    alias={event.alias}
                  />
                </div>
              )
            })}
          </div>
        </div>
      )}
      {isMobile && canEdit && userHasGlobalPermissions && (
        <div className="fixed bottom-0 left-0 flex w-full items-center justify-center gap-2 bg-dark-black p-2">
          {!isSelfEdit && (
            <div className="h-12 w-full max-w-[300px]">
              <Button
                text="Remover"
                className="bg-tonal-red text-white"
                enabled={!isLoadingDelete}
                isLoading={isLoadingDelete}
                onClick={() => {
                  void handleDeleteOrganizationRole()
                }}
              />
            </div>
          )}
          <div className="h-12 w-full max-w-[300px]">
            <Button
              text="Salvar"
              enabled={hasUnsavedChanges && !isLoadingUpdate}
              isLoading={isLoadingUpdate}
              onClick={() => {
                void handleUpdateOrganizationRole()
              }}
            />
          </div>
        </div>
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          onConfirm={() => {
            void handleDeleteOrganizationRole()
          }}
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 300, false)
            setTimeout(setShowConfirmationModal, 400, false)
          }}
          isLoading={isLoadingDelete}
          isParentClosing={isParentClosing}
          firstName={user?.firstName ?? ''}
          lastName={user?.lastName ?? ''}
          removeFrom="organization"
          organizationName={currentOrganization?.organizer.name ?? ''}
        />
      )}
    </div>
  )
}
