import { type ReactElement } from 'react'

import { SnackBarMobileWrapper } from './SnackBarMobileWrapper'
import { SnackBarDesktopWrapper } from './SnackBarDesktopWrapper'
import { SnackBarContent } from './SnackBarContent'

interface SnackBarProps {
  isError?: boolean
  message?: string
  offSet?: number
  isMobile: boolean
}

export function SnackBar({
  isError,
  message,
  offSet,
  isMobile,
}: SnackBarProps): ReactElement {
  if (isMobile)
    return (
      <SnackBarMobileWrapper isError={isError!} offSet={offSet}>
        <SnackBarContent message={message} />
      </SnackBarMobileWrapper>
    )
  return (
    <SnackBarDesktopWrapper isError={isError!}>
      <SnackBarContent message={message} />
    </SnackBarDesktopWrapper>
  )
}
