import { type ReactElement } from 'react'

import { SlArrowRight } from 'react-icons/sl'

import { type IUserData } from '@/types/List'

import { isTruthy } from '@/utils/validation'

interface ListUserCardProps {
  user: IUserData
  handleOnClick: (user: IUserData) => void
}

export function ListUserCard({
  user,
  handleOnClick,
}: ListUserCardProps): ReactElement {
  function setTicketType(): string {
    const firstString = isTruthy(user.tickets[0].batchDescription)
      ? `${user.tickets[0].batchDescription} - ${user.tickets[0].ticketSpecDescription}`
      : user.tickets[0].ticketSpecDescription

    for (let i = 1; i < user.tickets.length; i++) {
      const currentString = isTruthy(user.tickets[i].batchDescription)
        ? `${user.tickets[i].batchDescription} - ${user.tickets[i].ticketSpecDescription}`
        : user.tickets[i].ticketSpecDescription

      if (currentString !== firstString) {
        return 'Lotes variados'
      }
    }
    return firstString
  }

  return (
    <div
      className="transition-color flex cursor-pointer items-center gap-4 rounded-lg p-2 duration-100 ease-in-out hover:bg-dark-black"
      onClick={() => {
        handleOnClick(user)
      }}
    >
      <div className="aspect-square w-14 overflow-hidden rounded-full">
        <img
          className="size-full object-cover"
          src={`${import.meta.env.VITE_S3 as string}/${user.imageKey}`}
          alt="Profile image"
        />
      </div>
      <div className="flex w-full items-center justify-between">
        <div className="flex flex-col">
          <span className="text-sm font-bold leading-4 text-white">
            {user.firstName} {user.lastName}
          </span>
          <span className="text-xs leading-4 text-[#a3a3a3]">{user.email}</span>
          <span className="line-clamp-1 w-[97%] text-xs leading-4 text-text-terciary">
            {user.tickets.length > 0 && setTicketType()}
          </span>
        </div>
        <button className="relative size-6">
          <SlArrowRight
            color="#7c7c7c"
            className="absolute right-2 top-1/2 -translate-x-1/2 -translate-y-1/2"
            size={12}
          />
        </button>
      </div>
    </div>
  )
}
