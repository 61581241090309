import { numberToPercent } from './formatNumber'

export function percentMask(input: string): string {
  const value = input
  const cleanedValue = value.replace(/[^0-9]/g, '').replace(/^0+/, '')
  const number = Number(cleanedValue)
  let finalNumber = number / 100
  if (finalNumber > 100) {
    finalNumber = 100
  }

  return numberToPercent(finalNumber, 2)
}
