import { useState } from 'react'
import { api } from '@/services/axios'
import { type AxiosError } from 'axios'

import { type IEventPermissions } from '@/types/Team'

interface ICreateEventRole {
  userId: number
  eventsAlias: string[]
  roles: {
    event: IEventPermissions
  }
}

interface PostReturnProps {
  status: number
}

export const usePostCreateEventRole = (): {
  createEventRole: (body: ICreateEventRole) => Promise<PostReturnProps>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function createEventRole(
    body: ICreateEventRole,
  ): Promise<PostReturnProps> {
    setIsLoading(true)
    try {
      await api.post(`/admin/events/roles/many`, body)

      setIsLoading(false)

      return {
        status: 200,
      }
    } catch (error) {
      setIsLoading(false)
      const err = error as AxiosError
      return {
        status: err.response?.status ?? 500,
      }
    }
  }

  return {
    createEventRole,
    isLoading,
  }
}
