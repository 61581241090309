import { type ITicketSpec } from '@/types/Tickets'

export function sortFragmentedLinkedList(items: ITicketSpec[]): ITicketSpec[] {
  const idToItemMap = new Map<number, ITicketSpec>()
  const itemToChainMap = new Map<ITicketSpec, ITicketSpec[]>()
  const soloItems: ITicketSpec[] = []

  // Build a map for quick lookup by id
  items.forEach((item) => {
    if (item.id !== undefined) {
      idToItemMap.set(item.id, item)
    }
  })

  // Function to detect cycles and build chains
  function dfs(
    item: ITicketSpec,
    currentChain: ITicketSpec[],
    seen: Set<number>,
  ): ITicketSpec[] {
    if (item.id === undefined || seen.has(item.id)) {
      return currentChain
    }

    seen.add(item.id)
    currentChain.push(item)

    if (item.follower?.id !== undefined) {
      const nextItem = idToItemMap.get(item.follower.id)
      if (nextItem !== undefined && !seen.has(nextItem.id!)) {
        dfs(nextItem, currentChain, seen)
      }
    }

    return currentChain
  }

  // Detect cycles and build all chains
  items.forEach((item) => {
    if (!itemToChainMap.has(item)) {
      const chain = dfs(item, [], new Set<number>())
      if (chain.length > 1) {
        chain.forEach((chainItem) => itemToChainMap.set(chainItem, chain))
      } else {
        soloItems.push(item)
      }
    }
  })

  // Collect and sort chains by length
  const chains: ITicketSpec[][] = Array.from(new Set(itemToChainMap.values()))
  chains.sort((a, b) => b.length - a.length)

  // Flatten the sorted chains into a single array
  const sortedItems: ITicketSpec[] = []
  const seen = new Set<ITicketSpec>()

  chains.forEach((chain) => {
    chain.forEach((item) => {
      if (!seen.has(item)) {
        sortedItems.push(item)
        seen.add(item)
      }
    })
  })

  // Append solo items at the end
  soloItems.forEach((item) => {
    if (!seen.has(item)) {
      sortedItems.push(item)
      seen.add(item)
    }
  })

  return sortedItems
}
