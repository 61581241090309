import { api } from '@/services/axios'
import {
  type IGrant,
  type GranteesData,
  type ProductsData,
} from '@/types/Grant'
import { type AxiosResponse } from 'axios'
import { useState } from 'react'

interface CreateGrant {
  eventId: number
  grantees: GranteesData[]
  products: ProductsData[]
}

interface PostReturnProps {
  status: boolean
  data?: IGrant[]
  message?: string
}

export const usePostCreateGrant = (): {
  createGrant: (body: CreateGrant) => Promise<PostReturnProps>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function createGrant(body: CreateGrant): Promise<PostReturnProps> {
    setIsLoading(true)
    try {
      const response: AxiosResponse<IGrant[]> = await api.post(
        `/admin/tickets/grants/`,
        body,
      )

      setIsLoading(false)

      return {
        status: true,
        data: response.data,
      }
    } catch (error) {
      setIsLoading(false)
      return {
        status: false,
        message: 'Cortesia não pôde ser criada',
      }
    }
  }

  return {
    createGrant,
    isLoading,
  }
}
