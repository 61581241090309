import { useState, type ReactElement } from 'react'

import { Modal } from '@/components/Global/Modal'
import { LinkAnalytics } from './LinkAnalytics'
import { LinkUsers } from './LinkUsers'

import { deviceStore } from '@/store/device'
import { LinkToggle } from './LinkToggle'
import { motion } from 'framer-motion'

interface LinkAnalyticsUsersModalProps {
  closeModal: () => void
  isParentClosing: boolean
}

export function LinkAnalyticsUsersModal({
  closeModal,
  isParentClosing,
}: LinkAnalyticsUsersModalProps): ReactElement {
  const [selectedTab, setSelectedTab] = useState<'analytics' | 'users'>(
    'analytics',
  )
  const { isMobile } = deviceStore()

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      isMobile={isMobile}
    >
      <>
        {isMobile ? (
          <div className="flex size-full max-w-full flex-col items-center gap-4 overflow-hidden py-4">
            <LinkToggle selected={selectedTab} setSelected={setSelectedTab} />

            <div className="flex h-[310px] w-[200%] self-start">
              <motion.div
                key="analytics"
                animate={{ x: selectedTab === 'analytics' ? 0 : -500 }}
                className="h-[310px] w-full px-6"
                transition={{ duration: 0.2, ease: 'easeInOut' }}
              >
                <LinkAnalytics />
              </motion.div>

              <motion.div
                key="users"
                animate={{ x: selectedTab === 'users' ? '-100%' : 0 }}
                className="h-[310px] w-full px-6"
                transition={{ duration: 0.2, ease: 'easeInOut' }}
              >
                <LinkUsers />
              </motion.div>
            </div>
          </div>
        ) : (
          <div className="flex w-full gap-8 p-4">
            <LinkAnalytics />
            <LinkUsers />
          </div>
        )}
      </>
    </Modal>
  )
}
