import { type ReactElement } from 'react'

import { Modal } from '@/components/Global/Modal'
import { deviceStore } from '@/store/device'
import { Button } from '@/components/Global/Button'

interface CreateEventConfirmResetModalProps {
  closeModal: () => void
  isParentClosing?: boolean
  resetForm: () => void
}

export function CreateEventConfirmResetModal({
  closeModal,
  isParentClosing,
  resetForm,
}: CreateEventConfirmResetModalProps): ReactElement {
  const { isMobile } = deviceStore()

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      isMobile={isMobile}
    >
      <div className="flex flex-col items-center gap-8 p-4">
        <div className="flex flex-col gap-4">
          <span className="text-3xl font-black text-black">
            Você deseja limpar todos os dados do evento?
          </span>
          <span className="leading-5 text-black">
            Ao remover confirmar, você perderá todos os dados preenchidos para
            este evento. Deseja continuar?
          </span>
        </div>
        <div className="h-12 w-3/5">
          <Button
            text="Confirmar"
            enabled
            className="bg-tonal-red text-white"
            onClick={() => {
              closeModal()
              resetForm()
            }}
          />
        </div>
      </div>
    </Modal>
  )
}
