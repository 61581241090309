import { useState, type ReactElement } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import { useCurrentError, errorStore } from '@/store/error'
import { deviceStore } from '@/store/device'

import { dateToHour } from '@/utils/formatData'
import { timeMask } from '@/utils/timeMask'

import { Modal } from '@/components/Global/Modal'
import { Calendar } from '@/components/Global/Calendar'
import { Button } from '@/components/Global/Button'
import { ToggleGroup } from '@/components/Global/ToggleGroup'

import {
  type ICreateEventStoreState,
  type IUpdateEventStoreState,
} from '@/types/CreateUpdateEvent'

import {
  START_DATE_AFTER_MAX_ENTRANCE_DATE,
  MAX_ENTRANCE_DATE_AFTER_END_DATE,
  START_DATE_AFTER_END_DATE,
} from '@/errors'
import { isTruthy } from '@/utils/validation'

interface CreateEventSelectDateModalProps {
  closeModal: () => void
  handleModalClose: () => void
  isParentClosing?: boolean
  isLoading?: boolean
  formStates: ICreateEventStoreState | IUpdateEventStoreState
  updateAnyState:
    | ((update: Partial<ICreateEventStoreState>) => void)
    | ((update: Partial<IUpdateEventStoreState>) => void)
}

export function CreateEventSelectDateModal({
  closeModal,
  isParentClosing,
  handleModalClose,
  formStates,
  updateAnyState,
}: CreateEventSelectDateModalProps): ReactElement {
  const [currentDateToSelect, setCurrentDateToSelect] = useState('Início *')
  const [startDate, setStartDate] = useState<string | undefined>(
    formStates.startDate,
  )
  const [maxEntranceDate, setMaxEntranceDate] = useState<string | undefined>(
    formStates.maxEntranceDate,
  )
  const [endDate, setEndDate] = useState<string | undefined>(formStates.endDate)

  const [startTime, setStartTime] = useState(dateToHour(formStates.startDate))
  const [maxEntranceTime, setMaxEntranceTime] = useState(
    dateToHour(formStates.maxEntranceDate),
  )
  const [endTime, setEndTime] = useState(dateToHour(formStates.endDate))

  const [hasError, setHasError] = useState(false)

  const { isError, errorMessage } = useCurrentError()
  const { showError } = errorStore()
  const { isMobile } = deviceStore()

  function handleOnClick(): void {
    if (!validateDates()) return
    updateAnyState({
      startDate: startDate ?? undefined,
      maxEntranceDate: maxEntranceDate ?? undefined,
      endDate: endDate ?? undefined,
    })
    handleModalClose()
  }

  function setDate(
    dateString: string,
    date: string,
    setter: React.Dispatch<string>,
  ): void {
    const timeFormated = timeMask(dateString)

    const hour = timeFormated.split(':')[0]
    const minutes = timeFormated.split(':')[1]

    if (Number(minutes) > 59 || Number(hour) > 24) {
      setHasError(true)
      return
    } else {
      setHasError(false)
    }

    let tempDate
    if (date !== undefined) {
      tempDate = new Date(date)
    } else {
      tempDate = new Date()
    }
    tempDate.setHours(Number(hour))
    tempDate.setMinutes(Number(minutes))
    tempDate.setSeconds(0)
    tempDate.setMilliseconds(0)
    setter(tempDate.toISOString())
  }

  function validateDates(): boolean {
    const start = new Date(startDate!)
    const maxEntrance = new Date(maxEntranceDate!)
    const end = new Date(endDate!)

    if (maxEntranceDate !== undefined) {
      if (start > maxEntrance) {
        showError(START_DATE_AFTER_MAX_ENTRANCE_DATE)
        return false
      }
      if (maxEntrance > end) {
        showError(MAX_ENTRANCE_DATE_AFTER_END_DATE)
        return false
      }
    }
    if (start > end) {
      showError(START_DATE_AFTER_END_DATE)
      return false
    }
    return true
  }

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      isError={isError}
      errorMessage={errorMessage}
      isMobile={isMobile}
    >
      <div className="flex h-fit w-full flex-col items-center p-4 text-black">
        <div className="flex w-full flex-col items-center justify-center gap-4">
          <ToggleGroup
            items={['Início *', 'Entrada máxima', 'Término *']}
            current={currentDateToSelect}
            setCurrent={(s: string) => {
              setCurrentDateToSelect(s)
            }}
          />
          <AnimatePresence mode="wait">
            {currentDateToSelect === 'Início *' && (
              <motion.div
                key="inicio"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="flex w-full flex-col gap-4"
              >
                <Calendar
                  selectedDate={startDate}
                  onDateSelected={(date: Date) => {
                    if (isTruthy(startTime)) {
                      const hour = startTime?.split(':')[0]
                      const minutes = startTime?.split(':')[1]

                      date.setHours(Number(hour))
                      date.setMinutes(Number(minutes))
                    }
                    setStartDate(date.toISOString())
                  }}
                  isMandatory
                />
                <div className="flex w-full items-center justify-center gap-2">
                  <span className="font-bold">Horário: </span>
                  <input
                    type="text"
                    placeholder="21:30"
                    name="startTime"
                    autoCorrect="off"
                    autoCapitalize="none"
                    className="w-[60px] rounded-none border-2 border-x-white border-b-primary-main border-t-white px-2 py-1 outline-none transition-all duration-200 ease-in-out focus:rounded-lg focus:border-x-primary-main focus:border-t-primary-main"
                    onChange={(e) => {
                      setStartTime(timeMask(e.target.value))
                    }}
                    value={startTime}
                    onBlur={(e) => {
                      setDate(e.target.value, startDate!, setStartDate)
                    }}
                  />
                </div>
              </motion.div>
            )}
            {currentDateToSelect === 'Entrada máxima' && (
              <motion.div
                key="entradaMaxima"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="flex w-full flex-col gap-4"
              >
                <Calendar
                  selectedDate={maxEntranceDate}
                  onDateSelected={(date: Date) => {
                    if (isTruthy(maxEntranceTime)) {
                      const hour = maxEntranceTime?.split(':')[0]
                      const minutes = maxEntranceTime?.split(':')[1]

                      date.setHours(Number(hour))
                      date.setMinutes(Number(minutes))
                    }
                    setMaxEntranceDate(date.toISOString())
                  }}
                />
                <div className="flex w-full items-center justify-center gap-2">
                  <span className="font-bold">Horário: </span>
                  <input
                    type="text"
                    placeholder="21:30"
                    name="maxEntranceTime"
                    autoCorrect="off"
                    autoCapitalize="none"
                    className="w-[60px] rounded-lg border-2 border-primary-main px-2 py-1 outline-none"
                    onChange={(e) => {
                      setMaxEntranceTime(timeMask(e.target.value))
                    }}
                    value={maxEntranceTime}
                    onBlur={(e) => {
                      setDate(
                        e.target.value,
                        maxEntranceDate!,
                        setMaxEntranceDate,
                      )
                    }}
                  />
                </div>
              </motion.div>
            )}
            {currentDateToSelect === 'Término *' && (
              <motion.div
                key="termino"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="flex w-full flex-col gap-4"
              >
                <Calendar
                  selectedDate={endDate}
                  onDateSelected={(date: Date) => {
                    if (isTruthy(endTime)) {
                      const hour = endTime?.split(':')[0]
                      const minutes = endTime?.split(':')[1]

                      date.setHours(Number(hour))
                      date.setMinutes(Number(minutes))
                    }
                    setEndDate(date.toISOString())
                  }}
                  isMandatory
                />
                <div className="flex w-full items-center justify-center gap-2">
                  <span className="font-bold">Horário: </span>
                  <input
                    type="text"
                    placeholder="21:30"
                    name="endTime"
                    autoCorrect="off"
                    autoCapitalize="none"
                    className="w-[60px] rounded-lg border-2 border-primary-main px-2 py-1 outline-none"
                    onChange={(e) => {
                      setEndTime(timeMask(e.target.value))
                    }}
                    value={endTime}
                    onBlur={(e) => {
                      setDate(e.target.value, endDate!, setEndDate)
                    }}
                  />
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <div className="mb-2 h-6 w-full text-center">
          {hasError && (
            <span className="text-xs font-bold text-tonal-red">
              Horário inválido
            </span>
          )}
        </div>
        <div className="h-12 w-full">
          <Button
            enabled={
              startDate !== undefined && endDate !== undefined && !hasError
            }
            text={'Salvar'}
            onClick={handleOnClick}
            type="submit"
          />
        </div>
      </div>
    </Modal>
  )
}
