import { useState, type ReactElement } from 'react'
import * as z from 'zod'

import { linkStore, useAllLinks, useCurrentLink } from '@/store/link'

import { useCreateManyLinks } from '@/hooks/Link'

import { currencyMask } from '@/utils/currencyMask'
import { percentMask } from '@/utils/percentMask'
import { numberToPercent, numberToReais } from '@/utils/formatNumber'
import { isTruthy } from '@/utils/validation'

import { Form } from '@/components/Global/Form/Form'
import { FormInput } from '@/components/Global/Form/FormInput'
import { Selector } from '@/components/Global/Selector'
import { Button } from '@/components/Global/Button'

import { errorStore } from '@/store/error'
import { useCurrentEvent } from '@/store/eventMenu'
import { LinkMultipleCodesInput } from '../../LinkMultipleCodesInput'

import { type CreateManyLinks } from '@/types/Links'

export function LinkDiscountFormCreate(): ReactElement {
  const [newCodes, setNewCodes] = useState<string[]>([])

  const createDiscountLink = z.object({
    value: z
      .string()
      .nonempty({ message: 'O campo não pode ser vazio' })
      .refine(
        (value) => {
          if (selected === 'Percent') {
            const num = parseFloat(value.replace(',', '.').replace('%', ''))
            return !isNaN(num) && num !== 0
          } else {
            const num = parseFloat(
              value.replace('R$', '').replace(/[.]/g, '').replace(',', '.'),
            )
            return !isNaN(num) && num !== 0
          }
        },
        {
          message: 'O valor não pode ser zero',
        },
      )
      .transform((data: string) => {
        if (selected === 'Fix') {
          const valueWithoutCurrency = data
            .replace('R$', '')
            .replace(/[.]/g, '')
            .replace(',', '.')
          return Number(valueWithoutCurrency)
        }
        const valueWithoutPercent = data.replace('%', '').replace(',', '.')
        return Number(valueWithoutPercent)
      }),
    limitByUser: z
      .string()
      .nullable()
      .transform((data) => (isTruthy(data) ? Number(data) : null)),
  })

  type FormData = z.infer<typeof createDiscountLink>

  const { createManyLinks, isLoading: isLoadingCreateLink } =
    useCreateManyLinks()

  const { currentLink } = useCurrentLink()
  const { allLinks } = useAllLinks()
  const { currentEvent } = useCurrentEvent()
  const { showError } = errorStore()
  const { addLink, closeLinkModal } = linkStore()

  const [selected, setSelected] = useState<'Fix' | 'Percent'>(
    currentLink?.discount !== undefined
      ? currentLink.discount.amount !== undefined
        ? 'Fix'
        : 'Percent'
      : 'Fix',
  )

  const [resetField, setResetField] = useState(false)

  let defaultValues: Record<string, string | number> | null = null
  if (currentLink !== null) {
    defaultValues = {
      code: currentLink.code,
      limitByUser: String(currentLink.discount!.limit),
      value:
        currentLink.discount!.amount !== undefined
          ? numberToReais(currentLink.discount!.amount, 2)
          : numberToPercent(currentLink.discount!.percentage * 100, 2),
    }
  }

  async function handleCreateDiscountLink(data: FormData): Promise<void> {
    const body: CreateManyLinks = {
      eventId: currentEvent!.id,
      codes: newCodes,
      promoLimit: data.limitByUser,
      linkType: 'Discount',
      discount: {
        amount: selected === 'Fix' ? data.value : null,
        percentage: selected === 'Percent' ? data.value / 100 : null,
      },
    }

    const response = await createManyLinks(body)

    if (!response.status) {
      showError('Não foi possível criar o link. Tente novamente')

      return
    }

    if (response.links !== undefined) {
      response.links.forEach((link) => {
        addLink(link)
      })
    }
    closeLinkModal()
  }

  return (
    <Form
      formSchema={createDiscountLink}
      className="flex w-full flex-col gap-4 px-4"
      handleModalClose={(data: FormData) => {
        void handleCreateDiscountLink(data)
      }}
      defaultValues={defaultValues ?? undefined}
      resetObject={{ value: '' }}
      doReset={resetField}
      handleReset={() => {
        setResetField(false)
      }}
    >
      <span className="text-3xl font-bold">Criação de Links de Desconto</span>
      <LinkMultipleCodesInput
        placeholder="Ex.: Desc_Luis, Desc_Maria"
        existingCodes={allLinks.map((link) => link.code)}
        newCodes={newCodes}
        setNewCodes={setNewCodes}
      />
      <div className="flex flex-col gap-2 ">
        <Selector
          title={'Valor fixo'}
          subtitle={'Ofereça um desconto de um valor pré-definido.'}
          isSelected={selected === 'Fix'}
          onClick={(): void => {
            setResetField(true)
            setSelected('Fix')
          }}
          variant={'light'}
        />
        <Selector
          title={'Porcentagem'}
          subtitle={
            'Ofereça um desconto percentual em relação ao valor dos ingressos disponíveis.'
          }
          isSelected={selected === 'Percent'}
          onClick={(): void => {
            setResetField(true)
            setSelected('Percent')
          }}
          variant={'light'}
        />
      </div>
      {selected === 'Fix' ? (
        <FormInput
          label={'Valor *'}
          type="text"
          inputMode="numeric"
          id="value"
          placeholder="ex: R$ 230,00"
          name="value"
          onChangeFunction={currencyMask}
          autoCorrect="off"
          autoCapitalize="none"
        />
      ) : (
        <FormInput
          label={'Percentual *'}
          type="text"
          inputMode="numeric"
          id="value"
          placeholder="ex: 10%"
          name="value"
          onChangeFunction={percentMask}
          onChangeStartCursorFunction={(data) => data - 1}
          onChangeEndCursorFunction={(data) => data - 1}
          autoCorrect="off"
          autoCapitalize="none"
        />
      )}

      <FormInput
        label={'Limite de ingressos'}
        type="number"
        id="limitByUser"
        placeholder="Ex: 3"
        name="limitByUser"
        autoCorrect="off"
        autoCapitalize="none"
      />
      <div className="h-12 w-full max-w-[474px] self-center">
        <Button
          type="submit"
          enabled={!isLoadingCreateLink && newCodes.length > 0}
          text="Criar"
          isLoading={isLoadingCreateLink}
        />
      </div>
    </Form>
  )
}
