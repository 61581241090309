import { type ReactElement } from 'react'

import { isTruthy } from '@/utils/validation'

interface EventBriefProps {
  title?: string
  titleImage?: string
  date: string | undefined
  location: string | undefined
  highlighted?: boolean
  padding?: string
  tags?: string[]
  background?: string
  variant?: 'dark' | 'light' | 'white'
}

export function EventBrief({
  title,
  titleImage,
  date,
  location,
  highlighted = true,
  tags,
  background,
  variant = 'light',
}: EventBriefProps): ReactElement {
  const variants = {
    dark: {
      title: ' text-text-dark',
      date: ' text-text-dark',
    },
    light: {
      title: ' text-text-secondary',
      date: ' text-text-secondary',
    },
    white: {
      title: ' text-text-main',
      date: ' text-text-main',
    },
  }

  return (
    <div
      className={
        `relative z-10 flex max-w-lg flex-col items-start justify-center ` +
        (background !== undefined ? ` ${background}` : '')
      }
    >
      {isTruthy(titleImage) && (
        <img
          src={titleImage}
          alt="Event title image"
          className="mb-1 aspect-auto h-12"
        ></img>
      )}
      {isTruthy(title) && !isTruthy(titleImage) && (
        <span
          className={
            'line-clamp-1 text-ellipsis text-xl font-bold leading-6 ' +
            variants[variant].title
          }
        >
          {title}
        </span>
      )}
      <span
        className={`text-xs font-bold leading-4 ${
          highlighted ? 'text-primary-main' : variants[variant].date
        }`}
      >
        {date}
      </span>
      {isTruthy(location) && (
        <span className="text-xs font-normal leading-4 text-text-secondary">
          {location}
        </span>
      )}
      <div className="leading-3">
        {tags?.map((tag, index) => {
          let end = ''
          if (index !== tags.length - 1) {
            end = ' • '
          }
          return (
            <span className="m-0 text-xs  leading-3 text-white" key={index}>
              {tag} {end}
            </span>
          )
        })}
      </div>
    </div>
  )
}
