import { useState } from 'react'
import { api } from '@/services/axios'
import { type AxiosError } from 'axios'

import { type IEventPermissions } from '@/types/Team'

interface IUpdateEventRole {
  roles: {
    event: IEventPermissions
  }
}

interface PutReturnProps {
  status: number
}

export const usePutUpdateEventRole = (
  eventAlias: string,
): {
  updateEventRole: (
    username: string,
    body: IUpdateEventRole,
  ) => Promise<PutReturnProps>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function updateEventRole(
    username: string,
    body: IUpdateEventRole,
  ): Promise<PutReturnProps> {
    setIsLoading(true)

    const formattedBody = {
      roles: {
        event: {
          finance: body.roles.event.finance,
          analytics: body.roles.event.analytics,
          list: body.roles.event.list,
          tickets: body.roles.event.tickets,
          grant: body.roles.event.grant,
          link: body.roles.event.link,
          validation: body.roles.event.validation,
          edit: body.roles.event.edit,
        },
      },
    }

    try {
      await api.put(
        `/admin/events/${eventAlias}/roles/${username}`,
        formattedBody,
      )

      setIsLoading(false)

      return {
        status: 200,
      }
    } catch (error) {
      setIsLoading(false)
      const err = error as AxiosError
      return {
        status: err.response?.status ?? 500,
      }
    }
  }

  return {
    updateEventRole,
    isLoading,
  }
}
