import { useState, useEffect } from 'react'
import useSWR from 'swr'

import { fetcher } from '@/services/axios'

import { type ICommunicationCampaign } from '@/types/CommunicationCampaign'

export const useGetCommunicationCampaigns = (
  organizationId: number,
): {
  communicationCampaigns: ICommunicationCampaign[] | undefined
  isLoading: boolean
  refresh: () => Promise<ICommunicationCampaign[] | undefined>
} => {
  const [isLoading, setIsLoading] = useState(true)
  const shouldFetch = organizationId !== undefined
  const { data, error, mutate } = useSWR<ICommunicationCampaign[], string>(
    shouldFetch && `/organizations/${organizationId}/campaigns`,
    fetcher,
  )

  useEffect(() => {
    setIsLoading(!(data !== undefined || error !== undefined))
  }, [data, error])

  return { communicationCampaigns: data, isLoading, refresh: mutate }
}
