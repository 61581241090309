import { type ReactElement } from 'react'

import { Modal } from '@/components/Global/Modal'
import { Button } from '@/components/Global/Button'

import { deviceStore } from '@/store/device'

interface CreateEventDeleteItemModalProps {
  executeDeleteFunction: () => void
  closeModal: () => void
  handleModalClose: () => void
  isParentClosing?: boolean
}

export function CreateEventDeleteItemModal({
  executeDeleteFunction,
  closeModal,
  isParentClosing,
  handleModalClose,
}: CreateEventDeleteItemModalProps): ReactElement {
  const { isMobile } = deviceStore()

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      isMobile={isMobile}
    >
      <div className="flex flex-col gap-8 p-4">
        <h1 className="text-2xl text-black">Confirmar deleção</h1>
        <span className="text-base leading-4 text-secondary-gray">
          Ao deletar este item você não terá mais acesso a ele posteriormente.
          Deseja continuar?
        </span>

        <div className="flex h-[104px] flex-col gap-2">
          <Button
            text="Confirmar"
            enabled
            className="bg-tonal-red text-white"
            onClick={() => {
              setTimeout(executeDeleteFunction, 400)
              handleModalClose()
            }}
          />
          <Button
            text="Cancelar"
            enabled
            className="bg-dark-light-gray"
            onClick={handleModalClose}
          />
        </div>
      </div>
    </Modal>
  )
}
