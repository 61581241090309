import { useState } from 'react'

import { api } from '@/services/axios'

import { type ICreateOrganization } from '@/types/CreateEditOrganization'
import { isTruthy } from '@/utils/validation'
import { handleImageSize } from '@/utils/formatImage'

export const usePostCreateOrganization = (): {
  createOrganization: (body: ICreateOrganization) => Promise<boolean>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function createOrganization(
    body: ICreateOrganization,
  ): Promise<boolean> {
    setIsLoading(true)

    const formData = new FormData()

    formData.append('name', body.name)
    formData.append('description', body.description)
    formData.append('email', body.email)
    formData.append('document', JSON.stringify(body.document))

    if (body.profileImage instanceof File) {
      const profileImageCompressed = await handleImageSize(
        1024,
        1024,
        1,
        body.profileImage,
      )
      formData.append('profileImage', profileImageCompressed)
    } else {
      formData.append('profileImage', body.profileImage)
    }

    formData.append('pixKeys', JSON.stringify(body.pixKeys))
    formData.append('organizationType', body.organizationType)

    if (isTruthy(body.token)) formData.append('token', body.token!)

    try {
      await api.post(`/organizations`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      setIsLoading(false)

      return true
    } catch (error) {
      setIsLoading(false)
      return false
    }
  }

  return {
    createOrganization,
    isLoading,
  }
}
