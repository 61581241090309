import { useState, type ReactElement } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { twMerge } from 'tailwind-merge'

import { Selector } from '@/components/Global/Selector'

import { type IPermissionEnum } from '@/types/Team'

interface IPermisionDescription {
  title: string
  subtitle: string
  key: IPermissionEnum
  warning?: string
}

interface PermissionSelectorProps {
  title: string
  permissions: IPermisionDescription[]
  currentKey: IPermissionEnum
  setCurrentKey: (p: IPermissionEnum) => void
  isLoading?: boolean
  canEdit?: boolean
}

export function PermissionSelector({
  title,
  permissions,
  currentKey,
  setCurrentKey,
  isLoading = false,
  canEdit = false,
}: PermissionSelectorProps): ReactElement {
  const [expandPermissions, setExpandPermissions] = useState(false)

  const currentPermissionSelected = permissions.find(
    (permission) => permission.key === currentKey,
  )

  return (
    <div
      className={twMerge(
        'h-fit w-full flex-col items-center justify-between overflow-hidden rounded-lg bg-dark-black p-2 pb-0',
        isLoading && 'h-11 animate-pulse',
      )}
    >
      {!isLoading && (
        <div className="flex w-full items-center justify-between pb-2">
          <span className="ml-1.5 font-bold">{title}</span>
          <button
            className={twMerge(
              'w-32 rounded-md  py-1 text-sm font-medium text-black',
              canEdit
                ? 'cursor-pointer bg-primary-main'
                : 'cursor-not-allowed bg-dark-light-gray',
            )}
            onClick={() => {
              if (canEdit) setExpandPermissions(!expandPermissions)
            }}
          >
            {currentPermissionSelected === undefined
              ? 'Selecionar'
              : currentPermissionSelected?.title}
          </button>
        </div>
      )}
      <AnimatePresence mode="wait">
        {expandPermissions && (
          <motion.div
            initial={{ height: 0, paddingBottom: 0 }}
            animate={{ height: 'auto', paddingBottom: 8 }}
            exit={{ height: 0, paddingBottom: 0 }}
            key="permissions"
            className="flex w-full flex-col gap-2"
            transition={{ duration: 0.2, ease: 'easeInOut' }}
          >
            {permissions.map((permission) => {
              return (
                <Selector
                  key={permission.key}
                  title={permission.title}
                  subtitle={permission.subtitle}
                  isSelected={currentKey === permission.key}
                  onClick={() => {
                    setCurrentKey(permission.key)
                    setTimeout(setExpandPermissions, 100, false)
                  }}
                  variant="dark"
                  warning={permission.warning}
                />
              )
            })}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
