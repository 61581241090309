import { type ReactElement, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { numberToReais } from '@/utils/formatNumber'

import { BsEyeSlash, BsEye } from 'react-icons/bs'

interface HomeBalanceProps {
  balance: number
  textSize?: string
}

export function HomeBalance({
  balance,
  textSize = 'text-2xl',
}: HomeBalanceProps): ReactElement {
  const [showBalance, setShowBalance] = useState(false)

  return (
    <div className="flex w-full flex-col rounded-lg bg-dark-black p-4">
      <span className="text-sm font-normal">Saldo total</span>
      <div className="flex items-center justify-between">
        {showBalance ? (
          <span className={twMerge('font-bold', textSize)}>
            {numberToReais(balance, 2)}
          </span>
        ) : (
          <span className={twMerge('font-bold', textSize)}>R$ •••••••</span>
        )}

        <div
          className="hover:cursor-pointer"
          onClick={() => {
            setShowBalance(!showBalance)
          }}
        >
          {showBalance ? (
            <BsEyeSlash color="white" size={28} />
          ) : (
            <BsEye color="white" size={28} />
          )}
        </div>
      </div>
    </div>
  )
}
