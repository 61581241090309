import { type ReactElement, useState, useEffect, type ChangeEvent } from 'react'

import {
  type ICreateEventStoreState,
  type IUpdateEventStoreState,
} from '@/types/CreateUpdateEvent'

import spotifyLogo from '@/assets/logos_spotify-icon.svg'

interface CreateEventSpotifyURLInputProps {
  formStates: ICreateEventStoreState | IUpdateEventStoreState
  updateAnyState:
    | ((update: Partial<ICreateEventStoreState>) => void)
    | ((update: Partial<IUpdateEventStoreState>) => void)
  URLIsValid: boolean
  setURLIsValid: (status: boolean) => void
}

export function CreateEventSpotifyURLInput({
  formStates,
  updateAnyState,
  URLIsValid,
  setURLIsValid,
}: CreateEventSpotifyURLInputProps): ReactElement {
  const [playlistURL, setPlaylistURL] = useState(formStates.spotifyPlaylist)

  const checkURL = (url: string | null): void => {
    if (url === null || url === '') {
      setURLIsValid(true)
      updateAnyState({
        spotifyPlaylist: null,
        spotifyPlaylistId: null,
      })
      return
    }

    const regexPath = /\/(playlist|artist|track|album)\/(\w+)/
    const regexMatch = url.match(regexPath)

    if (regexMatch?.[0] != null) {
      setURLIsValid(true)

      updateAnyState({
        spotifyPlaylist: playlistURL,
        spotifyPlaylistId: regexMatch?.[0],
      })
    } else {
      setURLIsValid(false)
    }
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const url = event.target.value
    setPlaylistURL(url)
  }

  useEffect(() => {
    checkURL(playlistURL)
  }, [playlistURL])

  useEffect(() => {
    if (playlistURL !== formStates.spotifyPlaylist) {
      setPlaylistURL(formStates.spotifyPlaylist)
    }
  }, [formStates.spotifyPlaylist])

  return (
    <div className="relative flex w-full flex-col gap-2 px-4">
      <div
        className={
          'flex w-full justify-start gap-2 rounded-lg border-2 border-dark-black bg-dark-black px-4 py-4 transition-colors duration-100 ease-linear' +
          (URLIsValid ? '' : ' border-tonal-red')
        }
      >
        <img src={spotifyLogo} alt="logo spotify" />
        <input
          className={
            'w-full appearance-none rounded-lg bg-dark-black text-sm outline-none placeholder:text-[#656565] '
          }
          placeholder="Adicione uma playlist ao evento"
          value={playlistURL ?? ''}
          onChange={handleChange}
        />
      </div>
      {!URLIsValid && (
        <span className="absolute -bottom-5 left-5 text-xs font-bold text-tonal-red">
          URL é invalida
        </span>
      )}
    </div>
  )
}
