import { useState, useEffect } from 'react'
import useSWR from 'swr'

import { fetcher } from '@/services/axios'

import { type ILinkAnalytics } from '@/types/Links'

export const useGetLinkAnalytics = (
  linkId?: number,
): {
  linkAnalytics: ILinkAnalytics | undefined
  isLoading: boolean
  refresh: () => void
} => {
  const [isLoading, setIsLoading] = useState(true)
  const shouldFetch = linkId !== undefined
  const { data, error, mutate } = useSWR<ILinkAnalytics, string>(
    shouldFetch && `/admin/links/${linkId}/analytics`,

    fetcher,
  )

  function refresh(): void {
    void mutate()
  }

  useEffect(() => {
    setIsLoading(!(data !== undefined || error !== undefined))
  }, [data, error])

  return {
    linkAnalytics: data,
    isLoading,
    refresh,
  }
}
