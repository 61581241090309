import { api } from '@/services/axios'
import { type AxiosError } from 'axios'
import { useState } from 'react'

import { type ICreateCommunicationCampaign } from '@/types/CommunicationCampaign'

interface PostReturnProps {
  status: number
}

export const useCreateCommunicationCampaign = (
  organizationId: number,
): {
  createCommunicationCampaign: (
    body: ICreateCommunicationCampaign,
  ) => Promise<PostReturnProps>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function createCommunicationCampaign(
    body: ICreateCommunicationCampaign,
  ): Promise<PostReturnProps> {
    setIsLoading(true)
    try {
      const form = new FormData()

      form.append('name', body.name)
      form.append(
        'communication',
        JSON.stringify({
          title: body.title,
          content: body.content,
        }),
      )
      form.append('type', body.type)
      form.append('scheduledFor', body.scheduledFor)
      form.append('estimatedSubscribers', body.estimatedSubscribers.toString())
      form.append('estimatedPrice', body.estimatedPrice.toString())
      form.append('contactSpreadsheet', body.xlsxFile as File)

      await api.post(`/organizations/${organizationId}/campaigns`, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      return {
        status: 200,
      }
    } catch (error) {
      setIsLoading(false)
      const err = error as AxiosError
      return {
        status: err.response?.status ?? 500,
      }
    }
  }

  return {
    createCommunicationCampaign,
    isLoading,
  }
}
