import imageCompression from 'browser-image-compression'

export async function resizeImage(
  width: number,
  height: number,
  imageFile: File,
): Promise<File> {
  const options = {
    maxWidthOrHeight: Math.min(width, height),
    useWebWorker: true,
  }

  const compressedFile = await imageCompression(imageFile, options)

  return compressedFile
}

export async function handleImageSize(
  maxWidth: number,
  maxHeight: number,
  maxMb: number,
  imageFile: File,
): Promise<File> {
  // Read file size
  let width = 0
  let height = 0
  const reader = new FileReader()
  reader.onload = function (e) {
    const img = new Image()
    img.onload = function () {
      width = img.width
      height = img.height
    }
    img.src = e.target?.result as string
  }
  reader.readAsDataURL(imageFile)

  // Compress if props are exceeded
  if (
    width > maxWidth ||
    height > maxHeight ||
    imageFile.size > maxMb * 10 ** 6
  ) {
    return await resizeImage(maxWidth, maxHeight, imageFile)
  } else {
    return imageFile
  }
}
