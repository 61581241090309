import { type ReactElement } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { DefaultLayout } from '@/layout/DefaultLayout'
import { EventLayout } from '@/layout/EventLayout'

import { Grant } from '@/pages/Grant'
import { CreateEvent } from '@/pages/CreateEvent'
import { Home } from '@/pages/Home'
import { Tickets } from '@/pages/Tickets'
import { Links } from '@/pages/Links'
import { List } from '@/pages/List'
import { Event } from '@/pages/Event'
import { EventPreview } from '@/pages/EventPreview'
import { Analytics } from '@/pages/Analytics'
import { CreateEditOrganization } from '@/pages/CreateEditOrganization'
import { Finance } from '@/pages/Finance'
import { CommunicationCampaigns } from '@/pages/CommunicationCampaigns'
import { CreateCommunicationCampaign } from '@/pages/CreateCommunicationCampaign'
import { CheckIn } from '@/pages/CheckIn'
import { Team } from '@/pages/Team'
import { PageNotFound } from '@/pages/PageNotFound'
import { CreateOrganizationRole } from '@/pages/CreateOrganizationRole'
import { CreateEventRole } from '@/pages/CreateEventRole'
import { GlobalUserPermissions } from '@/pages/GlobalUserPermissions'
import { EventUserPermissions } from '@/pages/EventUserPermissions'
import { CoHosts } from '@/pages/CoHosts'

export function BaseRouter(): ReactElement {
  return (
    <Routes>
      <Route path="/" element={<DefaultLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Navigate to="/" />} />
        <Route path="/create-event" element={<CreateEvent />} />
        <Route path="/event-preview" element={<EventPreview />} />
        <Route path="/communication-campaigns">
          <Route index element={<CommunicationCampaigns />} />
          <Route path="create" element={<CreateCommunicationCampaign />} />
        </Route>
        <Route
          path="/create-organization"
          element={<CreateEditOrganization />}
        />
        <Route
          path="/organizations/:organizationId"
          element={<CreateEditOrganization />}
        />
        <Route path="/team">
          <Route index element={<Team />} />
          <Route path="add">
            <Route path="organization" element={<CreateOrganizationRole />} />
            <Route path="event" element={<CreateEventRole />} />
          </Route>
          <Route path=":username">
            <Route index element={<GlobalUserPermissions />} />
            <Route path="event/:alias" element={<EventUserPermissions />} />
          </Route>
        </Route>
        <Route path="/events/:alias" element={<EventLayout />}>
          <Route index element={<Event />} />
          <Route path="tickets" element={<Tickets />} />
          <Route path="links" element={<Links />} />
          <Route path="list" element={<List />} />
          <Route path="grant" element={<Grant />} />
          <Route path="edit" element={<CreateEvent />} />
          <Route path="analytics" element={<Analytics />} />
          <Route path="preview" element={<EventPreview />} />
          <Route path="finance" element={<Finance />} />
          <Route path="check-in" element={<CheckIn />} />
          <Route path="co-hosts" element={<CoHosts />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  )
}
