import { useState, type ReactElement, useRef, useEffect } from 'react'
import { motion } from 'framer-motion'
import { TiPlus, TiMinus } from 'react-icons/ti'
import { HiExclamationCircle } from 'react-icons/hi'
import { tv } from 'tailwind-variants'
import { twMerge } from 'tailwind-merge'

import { numberToReais } from '@/utils/formatNumber'
import { TicketCounter } from '../TicketCounter'

interface TicketSelectorProps {
  ticketSpecId: number
  title: string
  price: number
  limit?: number
  promo?: string
  hasFee: boolean
  quantitySelected: number
  finalPrice: number
  variant: 'light' | 'dark'
  setQuantity?: (ticketSpecId: number, quantity: number) => void
}

const divVariant = tv({
  base: 'relative flex w-full border-2 justify-between items-center rounded-lg px-4 py-6 transition-color duration-200 ease-in-out',
  variants: {
    background: {
      darkCounterZero: 'border-primary-main',
      dark: ' border-dark-black bg-dark-black',
      isError: 'border-tonal-red',
      light: ' bg-[#f4f4f4] border-[#f4f4f4]',
      lightCounterNotZero: 'border-black',
    },
  },
})

const divErrorStyleVariant = tv({
  base: 'absolute bottom-1.5 left-1.5 flex items-center transition-opacity durantion-200 ease-in-out',
  variants: {
    opacity: {
      isError: ' opacity-100',
      withoutError: 'opacity-0',
    },
  },
})

export function TicketSelector({
  ticketSpecId,
  title,
  price,
  limit,
  promo,
  hasFee,
  quantitySelected,
  finalPrice,
  variant,
  setQuantity,
}: TicketSelectorProps): ReactElement {
  const [isShaking, setIsShaking] = useState(false)
  const [isError, setIsError] = useState(false)
  const [lastClicked, setLastClicked] = useState<number>(0)
  const [direction, setDirection] = useState(0)
  const [ticketCounter, setTicketCounter] = useState<number>(quantitySelected)
  const speedRef = useRef<number>(350)
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null)

  const counterStyle =
    ticketCounter > 0
      ? variant === 'light'
        ? 'lightCounterNotZero'
        : 'darkCounterZero'
      : variant

  const errorStyle = isError ? 'isError' : 'withoutError'

  const shakeAnimation = {
    x: isShaking ? [-100, 100, -80, 80, -60, 60, -40, 40, 0, 0, 0] : 0,
    transition: {
      duration: 0.4,
      ease: 'easeIn',
    },
  }

  function checkAddTicket(): void {
    const now = Date.now()
    if (now - lastClicked < 250) {
      return
    }
    setLastClicked(now)
    if (quantitySelected === limit && limit !== undefined) {
      setIsShaking(true)
      setIsError(true)
      setTimeout(() => {
        setIsShaking(false)
      }, 400)
      setTimeout(() => {
        setIsError(false)
      }, 4000)
    } else {
      setDirection(+1)
    }
  }

  function checkRemoveTicket(): void {
    const now = Date.now()
    if (now - lastClicked < 250) {
      return
    }
    setLastClicked(now)
    if (quantitySelected > 0) {
      setDirection(-1)
    }
  }

  useEffect(() => {
    return () => {
      stopPressAndHoldTicketQuantity()
    } // Quando App é desmontado, devemos parar o contador
  }, [])

  function startPressAndHoldTicketQuantity(direction: number): void {
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current)
    }
    const actuate = (): void => {
      setTicketCounter((prev) => Math.max(0, prev + direction))
      setDirection(direction)

      speedRef.current = Math.max(10, speedRef.current * 0.6)
      clearInterval(intervalRef.current!)
      intervalRef.current = setInterval(actuate, speedRef.current)
    }

    intervalRef.current = setInterval(actuate, speedRef.current)
  }

  function stopPressAndHoldTicketQuantity(): void {
    clearInterval(intervalRef.current!)
    speedRef.current = 350
  }

  useEffect(() => {
    if (setQuantity !== undefined) {
      setQuantity(ticketSpecId, Math.max(0, ticketCounter))
    }
  }, [ticketCounter])

  return (
    <motion.div
      className={twMerge(
        divVariant({
          background: 'light',
        }),
        divVariant({
          background: counterStyle,
        }),
        divVariant({
          background: errorStyle === 'isError' ? 'isError' : counterStyle,
        }),
      )}
      animate={shakeAnimation}
    >
      <div className="flex flex-col">
        {promo !== undefined && (
          <span className="mb-1 w-min rounded-3xl bg-primary-main px-3 py-0.5 text-xs">
            {promo}
          </span>
        )}
        {variant === 'dark' ? (
          <span className="text-sm text-text-secondary">{title}</span>
        ) : (
          <div className="flex flex-col">
            <span className="text-sm  text-text-secondary">{title}</span>
          </div>
        )}

        {promo !== undefined && (
          <span className="relative mt-1 w-min text-xs text-dark-dark-gray">
            <span className="absolute top-1/2 size-full border-t border-dark-dark-gray"></span>
            {numberToReais(price, 2)}
          </span>
        )}
        {variant === 'dark' ? (
          <span className="text-base font-bold text-text-main">
            {numberToReais(finalPrice, 2)} {hasFee ? '+ taxa' : ''}
          </span>
        ) : (
          <span className="text-base font-bold text-dark-black">
            {numberToReais(finalPrice, 2)} {hasFee ? '+ taxa' : ''}
          </span>
        )}
      </div>
      <div className="flex items-center">
        <TiMinus
          color={variant === 'dark' ? 'white' : 'gray'}
          size={16}
          className="mr-2 h-full"
          onClick={(e: React.MouseEvent<SVGElement, MouseEvent>) => {
            e.preventDefault()
            setDirection(-1)
            checkRemoveTicket()
            setTicketCounter((prev) => Math.max(0, prev - 1))
          }}
          onMouseDown={() => {
            startPressAndHoldTicketQuantity(-1)
          }}
          onMouseUp={stopPressAndHoldTicketQuantity}
          onTouchStart={() => {
            startPressAndHoldTicketQuantity(-1)
          }}
          onTouchEnd={stopPressAndHoldTicketQuantity}
          onContextMenu={(e: React.MouseEvent<SVGElement, MouseEvent>) => {
            e.preventDefault()
          }}
        />
        <TicketCounter
          value={ticketCounter}
          setValue={setTicketCounter}
          direction={direction}
          variant="light"
          canEditInput
        />
        <TiPlus
          color={variant === 'dark' ? 'white' : 'gray'}
          size={16}
          className="ml-2 h-full"
          onClick={(e: React.MouseEvent<SVGElement, MouseEvent>) => {
            e.preventDefault()
            setDirection(1)
            setTicketCounter((prev) => Math.max(0, prev + 1))
            checkAddTicket()
          }}
          onMouseDown={() => {
            startPressAndHoldTicketQuantity(1)
          }}
          onMouseUp={stopPressAndHoldTicketQuantity}
          onTouchStart={() => {
            startPressAndHoldTicketQuantity(1)
          }}
          onTouchEnd={stopPressAndHoldTicketQuantity}
          onContextMenu={(e: React.MouseEvent<SVGElement, MouseEvent>) => {
            e.preventDefault()
          }}
        />
      </div>
      <div className={divErrorStyleVariant({ opacity: errorStyle })}>
        <HiExclamationCircle color="#FF5C5C" />
        {variant === 'dark' ? (
          <span className="ml-1 text-xs text-white">
            Você atingiu o número máximo de ingressos do lote.
          </span>
        ) : (
          <span className="ml-1 text-xs text-black">
            Você atingiu o número máximo de ingressos do lote.
          </span>
        )}
      </div>
    </motion.div>
  )
}
