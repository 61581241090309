import axios, { type AxiosError, type AxiosRequestConfig } from 'axios'

export const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL as string,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
})

const debugMode = Number(import.meta.env.VITE_DEBUG)
if (debugMode === 1) {
  // Request interceptor
  api.interceptors.request.use(
    function (config) {
      console.log(`REQUEST CONFIG ${config.url ?? ''}:`, config)
      return config
    },
    async function (error) {
      const err = error as AxiosError
      console.log(`REQUEST ERROR ${err.config?.url ?? ''}:`, error)
      return await Promise.reject(error)
    },
  )

  // Response interceptor
  api.interceptors.response.use(
    function (response) {
      console.log(`RESPONSE DATA ${response.config?.url ?? ''}:`, response.data)
      return response
    },
    async function (error) {
      const err = error as AxiosError
      console.log(`RESPONSE ERROR ${err.config?.url ?? ''}:`, error)
      return await Promise.reject(error)
    },
  )
}

export const fetcher = async <TData>(
  url: string,
  config?: AxiosRequestConfig,
): Promise<TData> =>
  await api.get<TData>(url, config).then((response) => response.data)
