import { useState } from 'react'
import { api } from '@/services/axios'
import { type AxiosError } from 'axios'

import { type IPermissions } from '@/types/Team'

interface IUpdateOrganizationRole {
  roles: IPermissions
}

interface PutReturnProps {
  status: number
}

export const usePutUpdateOrganizationRole = (
  organizationId: number,
): {
  updateOrganizationRole: (
    userId: number,
    body: IUpdateOrganizationRole,
  ) => Promise<PutReturnProps>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function updateOrganizationRole(
    userId: number,
    body: IUpdateOrganizationRole,
  ): Promise<PutReturnProps> {
    setIsLoading(true)

    const formattedBody = {
      roles: {
        organization: {
          finance: body.roles.organization.finance,
          analytics: body.roles.organization.analytics,
          access: body.roles.organization.access,
          communicationCampaign: body.roles.organization.communicationCampaign,
          eventCreation: body.roles.organization.eventCreation,
          settings: body.roles.organization.settings,
        },
        event: {
          finance: body.roles.event.finance,
          analytics: body.roles.event.analytics,
          list: body.roles.event.list,
          tickets: body.roles.event.tickets,
          grant: body.roles.event.grant,
          link: body.roles.event.link,
          validation: body.roles.event.validation,
          edit: body.roles.event.edit,
        },
      },
    }
    try {
      await api.put(
        `/organizations/${organizationId}/users/${userId}`,
        formattedBody,
      )

      setIsLoading(false)

      return {
        status: 200,
      }
    } catch (error) {
      setIsLoading(false)
      const err = error as AxiosError
      return {
        status: err.response?.status ?? 500,
      }
    }
  }

  return {
    updateOrganizationRole,
    isLoading,
  }
}
