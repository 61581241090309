import { useState, type ReactElement, useMemo } from 'react'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'
import dayjs from 'dayjs'
import { format, isSameDay } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import { twMerge } from 'tailwind-merge'
import { AnimatePresence, motion } from 'framer-motion'

import { getWeekDays } from '@/utils/getWeekDays'

interface CalendarWeek {
  week: number
  days: Array<{
    date: dayjs.Dayjs
    disabled: boolean
  }>
}

type CalendarWeeks = CalendarWeek[]

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    }
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
    }
  },
}

interface CalendarProps {
  selectedDate: string | undefined
  onDateSelected: (date: Date) => void
  isMandatory?: boolean
  highlightedFontColor?: string
}

export function Calendar({
  selectedDate,
  onDateSelected,
  isMandatory = false,
  highlightedFontColor = 'text-black',
}: CalendarProps): ReactElement {
  const [currentDate, setCurrentDate] = useState(() => {
    return dayjs().set('date', 1)
  })

  const [direction, setDirection] = useState<number>(0)

  function handlePreviousMonth(): void {
    const previousMonth = currentDate.subtract(1, 'month')

    setDirection(-1)
    setCurrentDate(previousMonth)
  }

  function handleNextMonth(): void {
    const nextMonth = currentDate.add(1, 'month')

    setDirection(+1)
    setCurrentDate(nextMonth)
  }

  const shortWeekDays = getWeekDays({ short: true })

  let currentMonth = format(currentDate.toDate(), 'MMMM', {
    locale: ptBR,
  })
  currentMonth = currentMonth.charAt(0).toUpperCase() + currentMonth.slice(1, 3)
  const currentYear = format(currentDate.toDate(), 'yyyy')

  const calendarWeeks = useMemo(() => {
    const daysInMonthArray = Array.from({
      length: currentDate.daysInMonth(),
    }).map((_, i: number) => {
      return currentDate.set('date', i + 1)
    })

    const firstWeekDay = currentDate.get('day')

    const previousMonthFillArray = Array.from({
      length: firstWeekDay,
    })
      .map((_, i) => {
        return currentDate.subtract(i + 1, 'day')
      })
      .reverse()

    const lastDayInCurrentMonth = currentDate.set(
      'date',
      currentDate.daysInMonth(),
    )
    const lastWeekDay = lastDayInCurrentMonth.get('day')

    const nextMonthFillArray = Array.from({
      length: 7 - (lastWeekDay + 1),
    }).map((_, i) => {
      return lastDayInCurrentMonth.add(i + 1, 'day')
    })

    const calendarDays = [
      ...previousMonthFillArray.map((date) => {
        return { date, disabled: true }
      }),
      ...daysInMonthArray.map((date) => {
        return {
          date,
          disabled: date.endOf('day').isBefore(new Date()),
        }
      }),
      ...nextMonthFillArray.map((date) => {
        return { date, disabled: true }
      }),
    ]

    const calendarWeeks = calendarDays.reduce<CalendarWeeks>(
      (weeks, _, i, original) => {
        const isNewWeek = i % 7 === 0

        if (isNewWeek) {
          weeks.push({
            week: i / 7 + 1,
            days: original.slice(i, i + 7),
          })
        }

        return weeks
      },
      [],
    )

    return calendarWeeks
  }, [currentDate])

  return (
    <div className="flex w-full flex-col gap-4 overflow-hidden px-4 transition-opacity duration-200 ease-in-out">
      <div className="flex w-full items-center justify-between">
        <button onClick={handlePreviousMonth}>
          <BsChevronLeft size={18} style={{ strokeWidth: '1.5px' }} />
        </button>
        <AnimatePresence initial={false} custom={direction}>
          <motion.span
            key={currentMonth}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { duration: 0.15 },
              ease: 'circInOut',
            }}
            className="text-base font-medium text-[#b2b2b2]"
          >
            {currentMonth}{' '}
            <span>
              {currentYear} {isMandatory && ' *'}
            </span>
          </motion.span>
        </AnimatePresence>
        <button onClick={handleNextMonth}>
          <BsChevronRight size={18} style={{ strokeWidth: '1.5px' }} />
        </button>
      </div>
      <div className="h-px w-full bg-[#bdbdbd]" />
      <div className="flex h-52 w-full items-center">
        <AnimatePresence initial={false} custom={direction}>
          <motion.table
            key={currentMonth}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { duration: 0.15 },
              ease: 'circInOut',
            }}
            className="flex w-full flex-col items-center"
          >
            <thead className="w-full px-6">
              <tr className="flex size-full justify-center gap-4">
                {shortWeekDays.map((weekDay) => (
                  <th
                    key={weekDay}
                    className="flex h-7 w-8 items-center justify-center"
                  >
                    {weekDay}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody className="w-full px-6">
              {calendarWeeks.map(({ week, days }) => {
                return (
                  <tr
                    key={week}
                    className="flex size-full justify-center gap-4"
                  >
                    {days.map(({ date, disabled }) => {
                      const currentDate = new Date(date.toDate())
                      const dateSelectedDate = new Date(selectedDate!)

                      return (
                        <td
                          key={date.toString()}
                          className="flex h-7 w-8 items-center justify-center"
                        >
                          <button
                            onClick={() => {
                              const newDate = new Date(date.toDate())
                              onDateSelected(newDate)
                            }}
                            disabled={disabled}
                            className={twMerge(
                              'flex h-full w-full items-center justify-center rounded-md font-medium',
                              isSameDay(currentDate, dateSelectedDate)
                                ? 'bg-primary-main font-bold ' +
                                    highlightedFontColor
                                : 'disabled:text-[#a3a3a3]',
                            )}
                          >
                            {date.get('date')}
                          </button>
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
            {/* </table> */}
          </motion.table>
        </AnimatePresence>
      </div>
    </div>
  )
}
