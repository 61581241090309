import { type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

import { LinkDesktopSearchBar } from './LinkDesktopSearchBar'
import { LinkDesktopDownloadButton } from './LinkDesktopDownloadButton'

import { linkStore } from '@/store/link'

import { GoPlus } from 'react-icons/go'
import { LuClock4, LuDollarSign } from 'react-icons/lu'
import { TiSortAlphabetically } from 'react-icons/ti'

import { type IOptions } from '@/pages/Links'

interface LinkDesktopHeaderProps {
  searchParam: string
  setSearchParam: (s: string) => void
  handleSearch: () => void
  options: IOptions
  setOptions: (options: IOptions) => void
}

export function LinkDesktopHeader({
  searchParam,
  setSearchParam,
  handleSearch,
  options,
  setOptions,
}: LinkDesktopHeaderProps): ReactElement {
  const { setModalToOpen } = linkStore()

  return (
    <div className="flex w-full gap-2">
      <button
        className="flex items-center gap-2 rounded-md border border-transparent bg-dark-black px-2 py-0.5 transition-colors duration-100 ease-in-out hover:border-primary-main"
        onClick={() => {
          setModalToOpen('NewLink')
        }}
      >
        <span className="text-base font-bold">Criar</span>
        <GoPlus size={28} />
      </button>

      <div className="flex items-center gap-4 rounded-md border border-transparent bg-dark-black px-2 py-0.5 pr-0.5">
        <span className="text-base font-bold">Filtrar</span>
        <button
          className={twMerge(
            'relative flex h-8 items-center justify-center rounded-md border border-background-main bg-background-main px-3.5 transition-colors duration-200',
            options.filterZeros && 'border-primary-main',
          )}
          onClick={() => {
            setOptions({
              ...options,
              filterZeros: !options.filterZeros,
            })
          }}
        >
          <span>0</span>
          <div className="absolute left-[11px] top-[15px] h-px w-[15px] -rotate-45 rounded-full bg-white"></div>
        </button>
      </div>

      <div className="flex items-center gap-4 rounded-md border border-transparent bg-dark-black px-2 py-0.5 pr-0.5">
        <span className="text-base font-bold">Ordenar</span>
        <div className="flex gap-1.5">
          <button
            className={twMerge(
              'flex h-8 items-center justify-center rounded-md border border-background-main bg-background-main px-3 transition-colors duration-200',
              options.orderAlphabetically && 'border-primary-main',
            )}
            onClick={() => {
              setOptions({
                ...options,
                orderAlphabetically: !options.orderAlphabetically,
                orderByDate: false,
                orderBySales: false,
              })
            }}
          >
            <TiSortAlphabetically size={20} />
          </button>
          <button
            className={twMerge(
              'flex h-8 items-center justify-center rounded-md border border-background-main bg-background-main px-3 transition-colors duration-200',
              options.orderByDate && 'border-primary-main',
            )}
            onClick={() => {
              setOptions({
                ...options,
                orderByDate: !options.orderByDate,
                orderAlphabetically: false,
                orderBySales: false,
              })
            }}
          >
            <LuClock4 />
          </button>
          <button
            className={twMerge(
              'flex h-8 items-center justify-center rounded-md border border-background-main bg-background-main px-3 transition-colors duration-200',
              options.orderBySales && 'border-primary-main',
            )}
            onClick={() => {
              setOptions({
                ...options,
                orderByDate: false,
                orderAlphabetically: false,
                orderBySales: !options.orderBySales,
              })
            }}
          >
            <LuDollarSign />
          </button>
        </div>
      </div>

      <LinkDesktopSearchBar
        searchParam={searchParam}
        setSearchParam={setSearchParam}
        handleSearch={handleSearch}
      />

      <LinkDesktopDownloadButton />
    </div>
  )
}
