import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

import { type OrganizationData } from '@/types/Organization'

interface OrganizationProps {
  currentOrganization: OrganizationData | null
  allOrganizations: OrganizationData[] | null
  isRefreshing: boolean
  setCurrentOrganization: (organizatioData: OrganizationData) => void
  setAllOrganizations: (allOrganizations: OrganizationData[] | null) => void
  resetOrganizationStore: () => void
  refreshOrganizations?: () => Promise<void>
  setRefreshOrganizations: (refreshOrganizations: () => Promise<void>) => void
}

export const organizationStore = create<OrganizationProps>()(
  devtools(
    persist(
      (set) => ({
        currentOrganization: null,
        allOrganizations: null,
        isRefreshing: false,
        setCurrentOrganization: (organizatioData: OrganizationData) => {
          set({
            currentOrganization: organizatioData,
          })
        },
        setAllOrganizations: (allOrganizations: OrganizationData[] | null) => {
          set({
            allOrganizations,
          })
        },
        resetOrganizationStore: () => {
          set({
            currentOrganization: null,
            allOrganizations: [],
          })
        },
        refreshOrganizations: undefined,
        setRefreshOrganizations: (
          refreshOrganizations: () => Promise<void>,
        ) => {
          set({
            refreshOrganizations: async () => {
              set({ isRefreshing: true })
              await refreshOrganizations()
              set({ isRefreshing: false })
            },
          })
        },
      }),
      {
        name: 'organization-storage',
      },
    ),
  ),
)

export const useCurrentOrganization = (): {
  currentOrganization: OrganizationData | null
  setCurrentOrganization: (organizatioData: OrganizationData) => void
} => {
  return organizationStore((state) => {
    const { currentOrganization, setCurrentOrganization } = state

    return { currentOrganization, setCurrentOrganization }
  })
}

export const useAllOrganization = (): {
  allOrganizations: OrganizationData[] | null
} => {
  return organizationStore((state) => {
    const { allOrganizations } = state

    return { allOrganizations }
  })
}
