import { type ReactElement } from 'react'

import { useJsApiLoader, GoogleMap, MarkerF } from '@react-google-maps/api'
import { Spinner } from '../Spinner'
import Marker from '@/assets/marker.svg'

interface MiniMapProps {
  latitude: number
  longitude: number
}

export function MiniMap({ latitude, longitude }: MiniMapProps): ReactElement {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_GMAPS_API_KEY as string,
  })

  const center = { lat: latitude, lng: longitude }
  const options = {
    mapId: '2d72fdfdf2bd5f84',
    disableDefaultUI: true,
    zoomControl: false,
    clickableIcons: false,
    mapTypeControl: false,
    panControl: false,
    rotateControl: false,
    scaleControl: false,
    streetViewControl: false,
    backgroundColor: '#232323',
  }

  return (
    <>
      {isLoaded ? (
        <div className="flex h-[175px] w-full">
          {isLoaded && latitude !== null && longitude !== null && (
            <GoogleMap
              zoom={17}
              center={center}
              mapContainerStyle={{ width: '100%', height: '100%' }}
              options={options}
            >
              <MarkerF position={center} icon={Marker} />
            </GoogleMap>
          )}
        </div>
      ) : (
        <div className="flex w-full flex-col items-center justify-center">
          <Spinner
            borderWidth="border-4"
            borderColor="border-background-main/50"
            bottomBorderColor="border-b-background-main"
          />
        </div>
      )}
    </>
  )
}
