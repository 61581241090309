import { useState, type ReactElement } from 'react'
import { Tooltip } from 'react-tooltip'
import { twMerge } from 'tailwind-merge'

import { Spinner } from '@/components/Global/Spinner'
import { SwitchButton } from '@/components/Global/SwitchButton'

import { usePutSwitchVisibility } from '@/hooks/CoHost'

import { LuInfo } from 'react-icons/lu'

import { type IUser } from '@/types/CoHosts'

import { useCurrentEvent } from '@/store/eventMenu'
import { userStore } from '@/store/user'
import { errorStore } from '@/store/error'

import { CANT_CHANGE_CO_HOST_VISIBILITY } from '@/errors'

interface CoHostCardProps {
  coHostData: IUser
  handleDeleteCoHost: (coHost: IUser) => void
}

export function CoHostCard({
  coHostData,
  handleDeleteCoHost,
}: CoHostCardProps): ReactElement {
  const [userIsVisible, setUserIsVisible] = useState(
    coHostData.visibility === 'VISIBLE',
  )

  const { currentEvent } = useCurrentEvent()
  const { id: userId } = userStore()
  const { showError } = errorStore()

  const isEventOwner = userId === coHostData.id

  const { switchVisibility, isLoading: isLoadingSwitch } =
    usePutSwitchVisibility(currentEvent?.alias ?? '')

  async function handleSwitchVisibility(): Promise<void> {
    const response = await switchVisibility(coHostData.username, {
      visibility: userIsVisible ? 'HIDDEN' : 'VISIBLE',
    })

    if (response.status !== 200) {
      setUserIsVisible(!userIsVisible)
      showError(CANT_CHANGE_CO_HOST_VISIBILITY)
    }
  }

  return (
    <div className="flex w-full max-w-full items-center justify-between rounded-md bg-dark-black p-4">
      <div
        className={twMerge(
          'flex w-full items-center gap-2',
          isEventOwner ? 'w-full' : 'max-w-[calc(100%-120px)]',
        )}
      >
        <img
          src={`${import.meta.env.VITE_S3 as string}/${
            coHostData.profileImageKey
          }`}
          alt="CoHost"
          className="size-16 rounded-full object-cover"
        />
        <div
          className={twMerge(
            'flex flex-col',
            isEventOwner ? 'w-full' : 'max-w-[calc(100%-70px)]',
          )}
        >
          <span className="line-clamp-1 max-w-full text-ellipsis font-bold">
            {coHostData.firstName} {coHostData.lastName}
          </span>
          <span className="max-w-full truncate text-sm">
            @{coHostData.username}
          </span>
        </div>
      </div>
      {!isEventOwner && (
        <div className="flex flex-col gap-2">
          <div className="flex flex-col">
            <span className="text-nowrap text-xs font-bold">
              Mostrar no evento
            </span>
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-1">
                <SwitchButton
                  active={userIsVisible}
                  handleOnClick={() => {
                    void handleSwitchVisibility()
                    setUserIsVisible(!userIsVisible)
                  }}
                />
                {isLoadingSwitch && (
                  <Spinner
                    borderWidth="border-[2.5px]"
                    borderColor="border-primary-main/50"
                    bottomBorderColor="border-b-primary-main"
                    width="w-4"
                    height="h-4"
                  />
                )}
              </div>
              <a
                className="text-xs font-bold"
                data-tooltip-id="unique-users-tooltip"
                data-tooltip-content="Co-hosts ativados aparecerão na página do seu evento."
              >
                <LuInfo color="white" size={16} />
                <Tooltip id="unique-users-tooltip" />
              </a>
            </div>
          </div>
          <button
            className="h-min w-full rounded-md bg-primary-main py-1 text-center text-xs font-bold text-dark-black"
            onClick={() => {
              handleDeleteCoHost(coHostData)
            }}
          >
            Remover
          </button>
        </div>
      )}
    </div>
  )
}
