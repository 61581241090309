import { type ReactElement } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import { BsArrowUpShort } from 'react-icons/bs'
import { twMerge } from 'tailwind-merge'

interface ScrollToTopProps {
  divRef: HTMLDivElement
  scrolled: boolean
  marginBottom?: string
}

export function ScrollToTop({
  divRef,
  scrolled,
  marginBottom = 'bottom-4',
}: ScrollToTopProps): ReactElement {
  function handleScrollToTop(): void {
    if (divRef !== null) {
      divRef.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  return (
    <AnimatePresence>
      {scrolled ? (
        <motion.button
          key="button"
          onClick={handleScrollToTop}
          className={twMerge(
            'fixed bottom-4 right-4 z-20 flex h-8 w-8 items-center justify-center rounded-full bg-white',
            marginBottom,
          )}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <BsArrowUpShort size={24} color="#181818" />
        </motion.button>
      ) : (
        <></>
      )}
    </AnimatePresence>
  )
}
