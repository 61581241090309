import { type InputHTMLAttributes, type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'
import { tv } from 'tailwind-variants'

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  isSelected: boolean
}

const checkboxVariant = tv({
  base: 'size-full rounded-md transition-color duration-200 ease-in-out border-2 border-solid border-[#d9d9d9] hover:cursor-pointer',
  variants: {
    color: {
      selected: 'bg-primary-main',
      notSelected: 'bg-transparent',
    },
  },
})

export function Checkbox({
  className,
  isSelected,
  ...props
}: CheckboxProps): ReactElement {
  return (
    <input
      type="checkbox"
      {...props}
      className={twMerge(
        'appearance-none',
        checkboxVariant({
          color: isSelected ? 'selected' : 'notSelected',
        }),
      )}
    />
  )
}
