import { type ReactElement } from 'react'
import { useParams } from 'react-router-dom'

import { AnimatedNumber } from '../AnimatedNumber'

import { useGetValidations } from '@/hooks/Analytics'

export function AnalyticsValidationCard(): ReactElement {
  const { alias } = useParams()

  const { validations } = useGetValidations(alias!)

  return (
    <div className="flex w-full flex-col justify-between gap-4 rounded-lg bg-dark-black p-4">
      <header className="flex">
        <span className="text-xl font-black leading-4">
          Ingressos validados
        </span>
      </header>
      <main className="flex items-end">
        <AnimatedNumber
          val={validations?.validatedTickets ?? 0}
          className="text-4xl font-black text-primary-main"
          isMoney={false}
        />
        <span className="text-xl font-bold text-text-secondary">/</span>
        <AnimatedNumber
          val={validations?.totalTickets ?? 0}
          className="text-xl font-bold text-text-secondary"
          isMoney={false}
        />
      </main>
    </div>
  )
}
