import { zodResolver } from '@hookform/resolvers/zod'
import { type ReactElement } from 'react'
import { useForm } from 'react-hook-form'
import * as z from 'zod'

import { cropImageStore } from '@/store/cropImage'
import { useCurrentError, errorStore } from '@/store/error'
import { deviceStore } from '@/store/device'

import { Button } from '@/components/Global/Button'
import { FormInput } from '@/components/Global/Form/FormInput'
import { Modal } from '@/components/Global/Modal'
import { UploadIcon } from '@/components/Icons/UploadIcon'

import {
  type LineUp,
  type ICreateEventStoreState,
  type IUpdateEventStoreState,
} from '@/types/CreateUpdateEvent'

import { ARTIST_IMAGE_MIN_RES } from '@/errors'

interface CreateEventSelectCreateArtistModalProps {
  closeModal: () => void
  handleModalClose: () => void
  handleSubmitData: () => void
  isParentClosing?: boolean
  isLoading?: boolean
  formStates: ICreateEventStoreState | IUpdateEventStoreState
  updateAnyState:
    | ((update: Partial<ICreateEventStoreState>) => void)
    | ((update: Partial<IUpdateEventStoreState>) => void)
  resetCurrentLineup: () => void
}

export function CreateEventSelectCreateArtistModal({
  closeModal,
  isParentClosing,
  handleModalClose,
  handleSubmitData,
  formStates,
  updateAnyState,
  resetCurrentLineup,
}: CreateEventSelectCreateArtistModalProps): ReactElement {
  const { isError, errorMessage } = useCurrentError()
  const { showError } = errorStore()

  const { setCurrentImage, setParamsOfCrop, setCurrentModalOfImage } =
    cropImageStore()
  const { isMobile } = deviceStore()

  const createEventSelectCreateArtistSchema = z.object({
    name: z
      .string()
      .min(2, {
        message: 'O nome do artista precisa ter pelo menos 2 letras.',
      })
      .refine(
        (value) => {
          const artistName = formStates.lineUp?.find(
            (artist) => artist.artistName === value,
          )
          if (artistName !== undefined) {
            updateAnyState({ canAddArtist: false })
            return false
          }
          updateAnyState({ canAddArtist: true })
          return true
        },
        { message: 'O artista já existe' },
      ),
  })

  type FormData = z.infer<typeof createEventSelectCreateArtistSchema>

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(createEventSelectCreateArtistSchema),
    mode: 'onChange',
    defaultValues: {
      name: formStates.currentArtistName ?? '',
    },
  })

  function handleFormSubmit(data: FormData): void {
    if (formStates.lineUp === null) {
      const lineups: LineUp[] = []
      lineups?.push({
        artistImage: formStates.currentArtistImage!,
        artistImageUrl: formStates.currentArtistImageUrl!,
        artistName: data.name,
        isLocalImage: true,
      })
      updateAnyState({ lineUp: lineups })
    } else {
      const currentLineup = formStates.lineUp
      currentLineup.push({
        artistImage: formStates.currentArtistImage!,
        artistImageUrl: formStates.currentArtistImageUrl!,
        artistName: data.name,
        isLocalImage: true,
      })
      updateAnyState({ lineUp: currentLineup })
    }
    resetCurrentLineup()
    handleSubmitData()
  }

  function handleUpload(e: React.ChangeEvent<HTMLInputElement>): void {
    e.preventDefault()

    if (e.target.files !== null) {
      const image = e.target.files[0]
      const reader = new FileReader()

      reader.onloadend = () => {
        const imageUrl = reader.result?.toString()
        if (imageUrl !== undefined) {
          const imageElement = new Image()

          imageElement.src = imageUrl

          imageElement.onload = () => {
            const { width, height } = imageElement

            if (width < 500 || height < 500) {
              showError(ARTIST_IMAGE_MIN_RES)
              return
            }
            setCurrentImage(URL.createObjectURL(image))
            setCurrentModalOfImage('artistImage')
            setParamsOfCrop(false, 36, 36, 1)
            handleModalClose()
          }
        }
      }
      reader.readAsDataURL(image)
    }
  }

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      isError={isError}
      errorMessage={errorMessage}
      isMobile={isMobile}
    >
      <div className="flex h-fit w-full flex-col items-center gap-8 p-4 text-black">
        <div className="flex w-full flex-col px-4">
          <strong className="text-3xl font-black">Artista</strong>
          <form
            className="my-4 flex w-full flex-col gap-4"
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <FormInput
              label={'Nome'}
              type="text"
              id="name"
              placeholder="Ex: Ludmila"
              name="name"
              autoCorrect="off"
              autoCapitalize="none"
              isInputTitle={false}
              register={register}
              error={errors.name?.message}
              onChangeFunction={(value: string) => {
                updateAnyState({
                  currentArtistName: value,
                })
                return value
              }}
            />
            <div className="flex w-full items-center justify-center">
              <label htmlFor="inputArtistImage" className=" size-36">
                {formStates.currentArtistImageUrl !== null ? (
                  <img
                    src={formStates.currentArtistImageUrl}
                    alt="Imagem do artista"
                    className="size-36 rounded-md"
                  />
                ) : (
                  <div className="flex size-full cursor-pointer flex-col items-center justify-center gap-2.5 rounded-md border-2 border-dashed border-dark-black p-4 ">
                    <UploadIcon iconColor="#181818" className="size-4" />
                    <span className=" text-center text-[0.625rem]">
                      Adicione uma foto do artista
                    </span>
                  </div>
                )}

                <input
                  id="inputArtistImage"
                  className="hidden"
                  type="file"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleUpload(e)
                  }}
                  onClick={(e: React.FormEvent<HTMLInputElement>) => {
                    const target = e.target as HTMLButtonElement
                    target.value = ''
                  }}
                />
              </label>
            </div>
            <div className="mt-4 h-12">
              <Button
                text="Salvar"
                enabled={
                  formStates.currentArtistImage !== null &&
                  formStates.canAddArtist
                }
                type="submit"
              />
            </div>
          </form>
        </div>
      </div>
    </Modal>
  )
}
