import { useState, type ReactElement } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { twMerge } from 'tailwind-merge'

import { BsCheckLg } from 'react-icons/bs'
import { IoCopy } from 'react-icons/io5'

interface CopyEventLinkProps {
  alias?: string
}

export function CopyEventLink({ alias }: CopyEventLinkProps): ReactElement {
  const [linkCopied, setLinkCopied] = useState(false)

  async function copyLinkToClipboard(): Promise<void> {
    setLinkCopied(true)
    await navigator.clipboard.writeText(
      `${import.meta.env.VITE_KAYA_URL}/${alias}`,
    )
    setTimeout(setLinkCopied, 3000, false)
  }

  return (
    <div
      className={twMerge(
        'flex items-center rounded-lg border border-primary-main bg-dark-black p-4',
        !linkCopied && 'hover:cursor-pointer',
      )}
      onClick={() => {
        if (!linkCopied) void copyLinkToClipboard()
      }}
    >
      <div className="flex w-full flex-col ">
        <span className="font-bold">Copiar link do evento</span>
        <span className="text-xs">Para compartilhar com todo mundo.</span>
      </div>
      <AnimatePresence mode="wait">
        {linkCopied ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1, ease: 'easeInOut' }}
            key="check"
          >
            <div className="flex aspect-square w-7 items-center justify-center rounded-full bg-primary-main">
              <BsCheckLg color="#181818" size={18} />
            </div>
          </motion.div>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1, ease: 'easeInOut' }}
            key="icon"
          >
            <IoCopy size={24} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
