/* eslint-disable @typescript-eslint/no-explicit-any */
import { type InputHTMLAttributes, type ReactElement, useState } from 'react'
import { type UseFormRegister } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'
import { tv } from 'tailwind-variants'

interface CreateEditOrganizationTextAreaProps
  extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label?: string
  extraDescription?: string
  error?: string
  register?: UseFormRegister<any>
  wrapperDivClassname?: string
}

const inputVariant = tv({
  base: 'flex w-full items-center rounded-none transition-color duration-200 ease-in-out ',
  variants: {
    base: '',
    style: {
      focus:
        'rounded-lg border-primary-main border-x-primary-main border-t-primary-main px-2',
      focusError: 'rounded-lg border-x-tonal-red border-tonal-red',
      base: 'border-dark-dark-gray border-2 rounded-lg px-2 py-1',
      baseError: 'border-tonal-red border-2 rounded-lg',
      title:
        'text-3xl placeholder:text-3xl placeholder:font-black font-bold border-none focus:border',
    },
  },
})

export function CreateEditOrganizationTextArea({
  label,
  register,
  name,
  extraDescription,
  error,
  wrapperDivClassname,
  ...props
}: CreateEditOrganizationTextAreaProps): ReactElement {
  const [inputOnFocus, setInputOnFocus] = useState(false)

  let inputErrorVariation: 'baseError' | 'base' = 'base'
  let inputStyleOnFocus: 'focusError' | 'focus' = 'focus'

  if (error !== undefined) {
    inputErrorVariation = 'baseError'
    if (inputOnFocus) {
      inputStyleOnFocus = 'focusError'
    }
  }

  return (
    <div
      className={twMerge(
        'relative flex flex-col gap-1 pb-4',
        wrapperDivClassname,
      )}
    >
      <div className="mb-2 flex flex-col">
        <label htmlFor={label} className="font-black">
          {label}
        </label>
        <span className="text-sm">{extraDescription}</span>
      </div>
      {register !== undefined && (
        <div
          className={twMerge(
            inputVariant({ style: inputErrorVariation }),
            inputOnFocus && inputVariant({ style: inputStyleOnFocus }),
          )}
          onClick={() => {
            setInputOnFocus(true)
          }}
        >
          <textarea
            className={twMerge(
              'h-24 w-full resize-none bg-transparent py-1.5 font-normal leading-5 outline-none placeholder:font-bold placeholder:text-text-terciary placeholder:opacity-50',
            )}
            {...register(name, {
              onBlur: () => {
                setInputOnFocus(false)
              },
            })}
            placeholder={props.placeholder}
            onFocus={() => {
              setInputOnFocus(true)
            }}
          />
        </div>
      )}

      {error !== null && (
        <span className="absolute -bottom-1 left-1 whitespace-nowrap text-xs font-extrabold text-tonal-red">
          {error}
        </span>
      )}
    </div>
  )
}
