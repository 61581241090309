import { api } from '@/services/axios'
import { useState } from 'react'

interface DeleteReturnProps {
  status: boolean
}

export const useDeleteBatch = (): {
  deleteBatch: (batchId: number) => Promise<DeleteReturnProps>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function deleteBatch(batchId: number): Promise<DeleteReturnProps> {
    setIsLoading(true)
    try {
      await api.delete(`/admin/batches/${batchId}`)

      setIsLoading(false)
      return {
        status: true,
      }
    } catch (error) {
      setIsLoading(false)
      return {
        status: false,
      }
    }
  }

  return {
    deleteBatch,
    isLoading,
  }
}
