import { create } from 'zustand'

interface CropImageStore {
  currentImage: string | null
  isCircularCrop: boolean
  minHeight: number
  minWidth: number
  aspectRatio: number | null
  currentOwnerOfImage:
    | 'backgroundArt'
    | 'titleImage'
    | 'sticker'
    | 'artistImage'
    | 'partners'
    | null
  setCurrentImage: (image: string) => void
  setCurrentModalOfImage: (
    ownerOfImage:
      | 'backgroundArt'
      | 'titleImage'
      | 'sticker'
      | 'artistImage'
      | 'partners'
      | null,
  ) => void
  setParamsOfCrop: (
    isCircularCrop: boolean,
    minHeight: number,
    minWidth: number,
    aspectRatio: number | null,
  ) => void
}

export const cropImageStore = create<CropImageStore>((set) => {
  return {
    currentImage: null,
    isCircularCrop: false,
    minHeight: 200,
    minWidth: 200,
    aspectRatio: 16 / 9,
    currentOwnerOfImage: null,
    setCurrentImage: (image: string) => {
      set({
        currentImage: image,
      })
    },
    setCurrentModalOfImage: (
      ownerOfImage:
        | 'backgroundArt'
        | 'titleImage'
        | 'sticker'
        | 'artistImage'
        | 'partners'
        | null,
    ) => {
      set({
        currentOwnerOfImage: ownerOfImage,
      })
    },
    setParamsOfCrop: (
      isCircularCrop: boolean,
      minHeight: number,
      minWidth: number,
      aspectRatio: number | null,
    ) => {
      set({
        isCircularCrop,
        minHeight,
        minWidth,
        aspectRatio,
      })
    },
  }
})
