import { type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'

import { eventMenuStore } from '@/store/eventMenu'
import { updateEventStore } from '@/store/updateEvent'
import { wrapperStore } from '@/store/wrapper'
import { useUserPermissions } from '@/store/user'

import { hasClearence } from '@/utils/rbac'

export function SideBarEvent(): ReactElement {
  const { currentEvent } = eventMenuStore()
  const { updateAnyState, reset } = updateEventStore()

  const path = `/events/${currentEvent?.alias}`

  const navigate = useNavigate()

  const { currentPage } = wrapperStore()
  const { eventPermissions } = useUserPermissions()
  const { organizationPermissions } = useUserPermissions()

  const canViewFinance = hasClearence(eventPermissions.finance, 'VIEWER')
  const canViewAnalytics = hasClearence(eventPermissions.analytics, 'VIEWER')
  const canViewList = hasClearence(eventPermissions.list, 'VIEWER')
  const canViewBatchs = hasClearence(eventPermissions.tickets, 'VIEWER')
  const canViewGrant = hasClearence(eventPermissions.grant, 'VIEWER')
  const canViewLinks = hasClearence(eventPermissions.link, 'VIEWER')
  const canViewCheckIn = hasClearence(eventPermissions.validation, true)
  const canEditEvent = hasClearence(eventPermissions.edit, true)
  const canAddCoHosts = hasClearence(organizationPermissions.access, 'EDITOR')
  const isCloseFriendsEvent = currentEvent?.eventType === 'CLOSE_FRIENDS'

  return (
    <motion.div
      key="sideBarEvent"
      className="flex h-full min-w-[110px] flex-col items-start gap-1 bg-[#2F2F2F] p-4 pt-6"
      initial={{ x: -100 }}
      animate={{ x: 0 }}
      exit={{ x: 0 }}
      transition={{ duration: 0.25, ease: 'easeInOut' }}
    >
      <button
        className={twMerge(
          'text-xs text-white',
          currentPage === 'event' && 'font-black',
        )}
        onClick={() => {
          navigate(path)
        }}
      >
        Início
      </button>
      {canViewList && (
        <button
          className={twMerge(
            'text-xs text-white',
            currentPage === 'list' && 'font-black',
          )}
          onClick={() => {
            navigate(path + '/list')
          }}
        >
          Lista
        </button>
      )}
      {canEditEvent && (
        <button
          className={twMerge(
            'whitespace-pre text-xs text-white',
            (currentPage === 'editEvent' ||
              currentPage === 'editEventPreview') &&
              'font-black',
          )}
          onClick={() => {
            navigate(path + '/edit', {
              state: { fetchData: true },
            })

            updateAnyState({ typeOperation: 'Update' })
            reset()
          }}
        >
          Editar evento
        </button>
      )}
      {canViewGrant && (
        <button
          className={twMerge(
            'text-xs text-white',
            currentPage === 'grant' && 'font-black',
          )}
          onClick={() => {
            navigate(path + '/grant')
          }}
        >
          Cortesias
        </button>
      )}
      {canViewCheckIn && (
        <button
          className={twMerge(
            'text-xs text-white',
            currentPage === 'checkIn' && 'font-black',
          )}
          onClick={() => {
            navigate(path + '/check-in')
          }}
        >
          Check-in
        </button>
      )}
      {canViewBatchs && (
        <button
          className={twMerge(
            'text-xs text-white',
            currentPage === 'tickets' && 'font-black',
          )}
          onClick={() => {
            navigate(path + '/tickets')
          }}
        >
          Ingressos
        </button>
      )}
      {canViewLinks && (
        <button
          className={twMerge(
            'text-xs text-white',
            currentPage === 'links' && 'font-black',
          )}
          onClick={() => {
            navigate(path + '/links')
          }}
        >
          Links
        </button>
      )}
      {canViewAnalytics && (
        <button
          className={twMerge(
            'text-xs text-white',
            currentPage === 'analytics' && 'font-black',
          )}
          onClick={() => {
            navigate(path + '/analytics')
          }}
        >
          Analíticos
        </button>
      )}
      {canViewFinance && (
        <button
          className={twMerge(
            'text-xs text-white',
            currentPage === 'finance' && 'font-black',
          )}
          onClick={() => {
            navigate(path + '/finance')
          }}
        >
          Financeiro
        </button>
      )}
      {canAddCoHosts && isCloseFriendsEvent && (
        <button
          className={twMerge(
            'text-xs text-white',
            currentPage === 'coHosts' && 'font-black',
          )}
          onClick={() => {
            navigate(path + '/co-hosts')
          }}
        >
          Co-hosts
        </button>
      )}
    </motion.div>
  )
}
