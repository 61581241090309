import axios, { type AxiosResponse } from 'axios'
import { useState } from 'react'

interface GoogleResponse {
  types: string[]
  formatted_address: string
  address_components: Array<{
    short_name: string
    long_name: string
    postcode_localities: string[]
    types: string[]
  }>
  partial_match: boolean
  place_id: string
  postcode_localities: string[]
  geometry: {
    location: {
      lat: number
      lng: number
    }
    location_type: string
    viewport: unknown
    bounds?: number[]
  }
}

interface IGetPositionByAddress {
  results: GoogleResponse[]
}

export interface IGetPositionByAddressResponse {
  status: boolean
  data?: IGetPositionByAddress
  message?: string
}

export const useGetPositionByAddress = (): {
  getPositionByAddress: (
    address: string,
  ) => Promise<IGetPositionByAddressResponse>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function getPositionByAddress(
    address: string,
  ): Promise<IGetPositionByAddressResponse> {
    setIsLoading(true)
    const shouldFetch = address !== undefined
    if (!shouldFetch) {
      setIsLoading(false)
      return {
        status: false,
        message: 'Endereço não informado',
      }
    }
    try {
      const response: AxiosResponse<IGetPositionByAddress> = await axios.get(
        `${
          import.meta.env.VITE_GMAPS_API_ENDPOINT
        }/json?address=+${address}&key=${
          import.meta.env.VITE_GMAPS_API_KEY as string
        }`,
      )
      setIsLoading(false)
      return {
        status: true,
        data: response.data,
      }
    } catch (err) {
      setIsLoading(false)
      return {
        status: false,
        message: 'Erro ao solicitar posição para o google',
      }
    }
  }

  return { getPositionByAddress, isLoading }
}
