import { type ReactNode, useEffect, useState, useRef } from 'react'

import { deviceStore } from '@/store/device'

import IphoneFrame from '@/assets/iphone-frame.png'

interface IphoneFrameMaskProps {
  children: ReactNode
}

export function IphoneFrameMask({
  children,
}: IphoneFrameMaskProps): JSX.Element {
  const [frameIsLoaded, setFrameIsLoaded] = useState(false)
  const frameRef = useRef<HTMLImageElement>(null)
  const contentDiv = useRef<HTMLDivElement>(null)

  const { isMobile, setIsMobile } = deviceStore()

  // Check window size
  useEffect(() => {
    setIsMobile(window.innerWidth <= 474)
    const handleResize = (): void => {
      setIsMobile(window.innerWidth <= 474)
      if (
        frameRef.current?.clientWidth !== undefined &&
        contentDiv.current !== null
      ) {
        contentDiv.current.style.width = `${
          (frameRef.current.clientWidth * 92) / 100
        }px`

        // Border calculation
        // Max value is 62px - when frame has max height

        const relativeBorderRadius = 62 * (frameRef.current.clientHeight / 970)
        contentDiv.current.style.borderRadius = `${relativeBorderRadius}px`
      }
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [frameIsLoaded])

  useEffect(() => {
    if (frameRef.current?.clientWidth !== undefined) {
      contentDiv.current!.style.width = `${
        (frameRef.current.clientWidth * 92) / 100
      }px`
    }
    if (
      frameRef.current?.clientHeight !== undefined &&
      contentDiv.current?.style !== undefined
    ) {
      const relativeBorderRadius = 62 * (frameRef.current.clientHeight / 970)
      contentDiv.current.style.borderRadius = `${relativeBorderRadius}px`
    }
  }, [frameIsLoaded, isMobile])

  return (
    <>
      {isMobile ? (
        children
      ) : (
        <div className="relative z-10 size-full bg-transparent">
          <img
            src={IphoneFrame}
            alt="iPhone frame"
            className="absolute left-1/2 top-1/2 z-10 flex aspect-[237/485] h-[98%] max-h-[970px] -translate-x-1/2 -translate-y-1/2 flex-col items-center"
            ref={frameRef}
            onLoad={() => {
              setFrameIsLoaded(true)
            }}
          />
          <div
            className="absolute left-1/2 top-1/2 z-30 h-[95%] max-h-[936px] -translate-x-1/2 -translate-y-1/2 overflow-hidden rounded-[62px] "
            ref={contentDiv}
          >
            <div className="scrollbar-hide relative size-full overflow-scroll bg-transparent">
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
