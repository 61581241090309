import { useState } from 'react'
import { type AxiosResponse } from 'axios'
import { api } from '@/services/axios'

import { useCurrentEvent } from '@/store/eventMenu'

import { type UpdateEvent } from '@/types/CreateUpdateEvent'

import { isTruthy } from '@/utils/validation'
import { handleImageSize } from '@/utils/formatImage'

interface PostReturnProps {
  status: boolean
  data?: UpdateEvent
  message?: string
}

export const usePutUpdateEvent = (): {
  updateEvent: (body: UpdateEvent) => Promise<PostReturnProps>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  const { currentEvent } = useCurrentEvent()

  async function updateEvent(body: UpdateEvent): Promise<PostReturnProps> {
    setIsLoading(true)

    const formData = new FormData()

    if (body.backgroundArtImage!.key === null) {
      formData.append('imageKey', body.backgroundArtImage!.url)
    } else {
      const backgroundImage = await handleImageSize(
        1024,
        1024,
        1,
        body.backgroundArtImage!.key,
      )

      formData.append('imageKey', backgroundImage)
    }
    formData.append('name', body.eventName!)
    if (body.eventDescription !== undefined) {
      formData.append('description', body.eventDescription ?? '')
    }
    if (body.startDate !== undefined) {
      formData.append('startDate', body.startDate)
    }

    if (body.maxEntranceDate !== undefined) {
      formData.append('maxEntranceDate', body.maxEntranceDate)
    }

    if (body.endDate !== undefined) {
      formData.append('endDate', body.endDate)
    }

    formData.append('organizerId', String(body.organizationId))

    if (body.titleImage !== null) {
      formData.append('titleImage', body.titleImage.key ?? body.titleImage.url)
    }

    if (body.sticker !== null) {
      if (body.sticker.key instanceof File) {
        const stickerImage = await handleImageSize(
          1024,
          1024,
          1,
          body.sticker.key,
        )
        formData.append('sticker', stickerImage)
      } else {
        formData.append('sticker', body.sticker.url)
      }
    }

    if (isTruthy(body.location)) {
      const locationJSON = {
        address: body.location!.address,
        name: body.location!.name,
        latitude: body.location?.latitude,
        longitude: body.location?.longitude,
      }
      formData.append('location', JSON.stringify(locationJSON))
    }

    if (body.spotifyPlaylist !== null) {
      formData.append('playlist', body.spotifyPlaylist)
    }

    if (body.FAQs !== null) {
      formData.append('faq', JSON.stringify(body.FAQs))
    } else {
      formData.append('faq', JSON.stringify([]))
    }

    if (body.terms !== null) {
      formData.append('terms', body.terms)
    }

    if (body.lineUp !== null) {
      const lineupBody: Array<{
        artistImage: File | string
        artistName: string
        artistId: number
      }> = []

      for (const artist of body.lineUp) {
        if (artist.artistImage instanceof File && artist.isLocalImage) {
          const artistImage = await handleImageSize(
            1024,
            1024,
            1,
            artist.artistImage,
          )
          formData.append(`lineup`, artistImage, `${artist.artistName}`)
        } else {
          lineupBody.push({
            artistImage: artist.artistImageUrl,
            artistName: artist.artistName,
            artistId: artist.artistId!,
          })
        }
      }
      formData.append('lineupJSON', JSON.stringify(lineupBody))
    } else {
      formData.append('lineupJSON', JSON.stringify([]))
    }

    if (body.partners !== null) {
      const partnersBody: Array<{
        partnerImage: File | string
        partnerId: number
      }> = []

      for (const partner of body.partners) {
        if (partner.key instanceof File && partner.isLocalImage) {
          const partnerImage = await handleImageSize(1024, 1024, 1, partner.key)
          formData.append(`partners`, partnerImage)
        } else {
          partnersBody.push({
            partnerImage: partner.url,
            partnerId: partner.partnerId!,
          })
        }
      }
      formData.append(`partnersJSON`, JSON.stringify(partnersBody))
    } else {
      formData.append('partnersJSON', JSON.stringify([]))
    }

    try {
      const response: AxiosResponse<UpdateEvent> = await api.put(
        `/admin/events/${currentEvent?.id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )

      setIsLoading(false)

      return {
        status: true,
        data: response.data,
      }
    } catch (error) {
      setIsLoading(false)
      return {
        status: false,
        message: 'Evento não pôde ser atualizado',
      }
    }
  }

  return {
    updateEvent,
    isLoading,
  }
}
