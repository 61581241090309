import { zodResolver } from '@hookform/resolvers/zod'
import React, {
  useEffect,
  type FormHTMLAttributes,
  type ReactElement,
} from 'react'
import { type ValidationMode, useForm } from 'react-hook-form'
import { type z, type ZodType } from 'zod'

interface FormProps<T> extends FormHTMLAttributes<HTMLFormElement> {
  formSchema: ZodType
  doReset?: boolean
  resetObject?: Record<string, string>
  defaultValues?: Record<string, string | number>
  children: Array<ReactElement<{ name: string }>>
  handleModalClose?: (data: T) => void
  handleReset?: () => void
  formMode?: keyof ValidationMode | undefined
}

export function Form<T>({
  formSchema,
  children,
  handleModalClose,
  defaultValues,
  resetObject,
  doReset,
  handleReset,
  formMode = 'onTouched',
  ...props
}: FormProps<T>): ReactElement {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: formMode,
    defaultValues,
  })

  function handleFormSubmit(data: T): void {
    if (handleModalClose !== undefined) {
      handleModalClose(data)
    }
  }

  useEffect(() => {
    if (doReset === true) reset(resetObject)
    if (handleReset !== undefined) handleReset()
  }, [reset, doReset])

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} {...props}>
      {register !== undefined ? (
        children.map((child, index) => {
          if (
            child.type === 'button' ||
            child.type === 'div' ||
            child.type === 'span'
          ) {
            return React.Children.only(child)
          } else {
            return React.createElement(child.type, {
              ...{
                ...child.props,
                register,
                watch,
                error: errors[child.props.name]?.message,
                key: index,
              },
            })
          }
        })
      ) : (
        <div></div>
      )}
    </form>
  )
}
