import { useEffect, useState, type ReactElement } from 'react'
import { useParams, useSearchParams, useNavigate } from 'react-router-dom'

import { Loading } from '@/components/Global/Loading/Loading'
import { CreateEditOrganizationForm } from '@/components/CreateEditOrganization/CreateEditOrganizationForm'
import { CreateEditOrganizationSelectType } from '@/components/CreateEditOrganization/CreateEditOrganizationSelectType'

import { errorStore } from '@/store/error'
import { wrapperStore } from '@/store/wrapper'
import { userStore } from '@/store/user'
import { organizationStore } from '@/store/organization'

import {
  usePostCreateOrganization,
  usePutEditOrganization,
} from '@/hooks/CreateEditOrganization'
import { useGetOrganizationById } from '@/hooks/Organization'

import {
  type ICreateOrganization,
  type IOrganization,
} from '@/types/CreateEditOrganization'

import { CREATE_ORGANIZATION, EDIT_ORGANIZATION } from '@/errors'

export function CreateEditOrganization(): ReactElement {
  const [defaultValues, setDefaultValues] = useState<IOrganization | null>(null)
  const [organizationType, setOrganizationType] = useState<
    'SOLO' | 'TEAM' | null
  >(null)

  const bgImageId = Math.floor(Math.random() * 10) + 1

  const { organizationId } = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const { currentPage } = wrapperStore()
  const { showError } = errorStore()
  const { firstName, lastName, cpf, email, imageKey } = userStore()
  const { refreshOrganizations, setAllOrganizations } = organizationStore()

  const actionType = currentPage === 'createOrganization' ? 'create' : 'edit'

  const { getOrganizationData, isLoading: isLoadingFetchData } =
    useGetOrganizationById(Number(organizationId) ?? 0)
  const { createOrganization, isLoading: isLoadingCreate } =
    usePostCreateOrganization()
  const { editOrganization, isLoading: isLoadingUpdate } =
    usePutEditOrganization(Number(organizationId) ?? 0)

  async function handleGetOrganizationData(): Promise<void> {
    const response = await getOrganizationData()

    if (response.status) {
      setDefaultValues({
        name: response.organizationData?.name ?? '',
        email: response.organizationData?.email ?? '',
        description: response.organizationData?.description ?? '',
        document: {
          value: response.organizationData?.document.value ?? '',
          type: response.organizationData?.document.type as 'CPF' | 'CNPJ',
        },
        profileImage: response.organizationData?.profileImageKey ?? '',
        pixKeys:
          response.organizationData?.organizationKeys.map((pix) => {
            return {
              id: pix.id,
              name: pix.title,
              key: pix.accountKey,
            }
          }) ?? [],
        organizationType: response.organizationData?.organizationType as
          | 'SOLO'
          | 'TEAM',
      })
      setOrganizationType(
        response.organizationData?.organizationType as 'SOLO' | 'TEAM',
      )
    }
  }

  async function handleCreateOrganization(
    body: ICreateOrganization,
  ): Promise<void> {
    const response = await createOrganization(body)

    if (!response) {
      showError(CREATE_ORGANIZATION)
      return
    }
    setAllOrganizations(null)
    if (refreshOrganizations !== undefined) void refreshOrganizations()
    navigate('/')
  }

  async function handleUpdateOrganization(
    body: ICreateOrganization,
  ): Promise<void> {
    const response = await editOrganization(body)

    if (!response) {
      showError(EDIT_ORGANIZATION)
      return
    }
    if (refreshOrganizations !== undefined) void refreshOrganizations()
    navigate('/')
  }

  // Run in case of edit organization
  useEffect(() => {
    if (currentPage === 'editOrganization') {
      void handleGetOrganizationData()
    }
  }, [currentPage])

  // Run in case of solo organization
  useEffect(() => {
    if (organizationType === 'SOLO' && actionType === 'create') {
      setDefaultValues({
        name: `${firstName} ${lastName}`,
        email,
        description: '',
        document: {
          value: cpf,
          type: 'CPF',
        },
        profileImage: imageKey,
        pixKeys: [],
        organizationType: 'SOLO',
      })
    }
  }, [organizationType])

  if (isLoadingFetchData) return <Loading />
  if (organizationType === null)
    return (
      <div className="fixed left-0 top-0 z-0 h-dvh w-dvw bg-black">
        <video
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            display: 'block',
            width: '100vw',
            height: '100vh',
            objectFit: 'cover',
          }}
          src={`https://gandaya.b-cdn.net/bg-videos/v${bgImageId}.mp4`}
          autoPlay
          loop
          muted
          playsInline
          className="bg-black blur-md brightness-50"
        />
        <CreateEditOrganizationSelectType
          setOrganizationType={setOrganizationType}
        />
      </div>
    )
  return (
    <div className="flex size-full">
      <CreateEditOrganizationForm
        defaultValues={defaultValues}
        actionType={actionType}
        token={searchParams.get('token') ?? undefined}
        isLoading={isLoadingCreate || isLoadingUpdate}
        handleCreateOrganization={handleCreateOrganization}
        handleUpdateOrganization={handleUpdateOrganization}
        organizationType={organizationType}
      />
    </div>
  )
}
