import { useState, useEffect } from 'react'
import useSWR from 'swr'

import { fetcher } from '@/services/axios'

import { type IEvent } from '@/types/Team'

export const useGetEventsByUsername = (
  organizationId: number,
  username: string,
): {
  events: IEvent[] | undefined
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(true)
  const shouldFetch = organizationId !== undefined && username !== undefined
  const { data, error } = useSWR<{ events: IEvent[] }, string>(
    shouldFetch && `/organizations/${organizationId}/user/${username}/events`,
    fetcher,
  )

  useEffect(() => {
    setIsLoading(!(data !== undefined || error !== undefined))
  }, [data, error])

  return {
    events: data?.events,
    isLoading,
  }
}
