import { useState, type ReactElement, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'

import { errorStore } from '@/store/error'

import { useGetList, useGetSearchList } from '@/hooks/List'

import { type IUserData } from '@/types/List'

import { SearchBar } from '@/components/Global/SearchBar'
import { ListUserCard } from '@/components/List/ListUserCard'
import { ListUserInfoModal } from '@/components/List/ListUserInfoModal'
import { Spinner } from '@/components/Global/Spinner'

export function List(): ReactElement {
  const [searchTerm, setSearchTerm] = useState('')
  const [isSearching, setIsSearching] = useState(false)
  const [userTicketInfoModalIsOpen, setUserTicketInfoModalIsOpen] =
    useState(false)
  const [isParentClosing, setIsParentClosing] = useState(false)

  const [currentPageGlobal, setCurrentPageGlobal] = useState(0)
  const [currentPageSearch, setCurrentPageSearch] = useState(0)
  const [noNewUsersGlobal, setNoNewUsersGlobal] = useState(false)
  const [noNewUsersSearch, setNoNewUsersSearch] = useState(false)

  const [users, setUsers] = useState<IUserData[]>([])
  const [currentUser, setCurrentUser] = useState<IUserData>()

  const mainDivRef = useRef<HTMLDivElement>(null)

  const { alias } = useParams()

  const { showError } = errorStore()

  const { searchUsers, isLoading: isLoadingSearch } = useGetSearchList()
  const { getUsers } = useGetList()

  async function handleGetUsers(page: number = 0): Promise<void> {
    const response = await getUsers(alias!, page)
    if (response.status === 200) {
      if (response.users !== undefined && response.users?.length < 50) {
        setNoNewUsersGlobal(true)
      }

      setUsers((prev) => [...(prev ?? []), ...(response.users ?? [])])
    } else if (response.status !== 401) {
      showError('Erro ao buscar usuários!')
    }
  }

  async function handleSearch(page: number = 0): Promise<void> {
    setNoNewUsersGlobal(false)
    const response = await searchUsers(searchTerm, alias!, page)
    if (!response.status) {
      showError('Erro ao pesquisar usuários!')
    } else if (response.users?.length === 0 && page === 0) {
      showError('Nenhum usuário encontrado!')
    } else if (response.users !== undefined) {
      if (response.users?.length < 50) setNoNewUsersSearch(true)
      if (page === 0) setUsers(response?.users)
      else setUsers((prev) => [...(prev ?? []), ...(response.users ?? [])])
    }
  }

  function handleOnClick(user: IUserData): void {
    setCurrentUser(user)
    setUserTicketInfoModalIsOpen(true)
  }

  function triggerNewPage(): void {
    if (isSearching) {
      void handleSearch(currentPageSearch + 1)
      setCurrentPageSearch((current) => current + 1)
    } else {
      void handleGetUsers(currentPageGlobal + 1)
      setCurrentPageGlobal((current) => current + 1)
    }
  }

  function updateState(
    currentUser: IUserData,
    ticketsValidated: number[],
  ): void {
    setUsers((prevState) =>
      prevState.map((user) => {
        if (user.id === currentUser.id) {
          const tempTickets = user.tickets.map((ticket) => {
            if (ticketsValidated.includes(ticket.ticketId)) {
              return {
                ...ticket,
                isValidated: true,
              }
            }
            return ticket
          })
          return {
            ...user,
            tickets: tempTickets,
          }
        }
        return user
      }),
    )
  }

  useEffect(() => {
    void handleGetUsers()
  }, [])

  useEffect(() => {
    if (isSearching && searchTerm.length === 0) {
      setUsers([])
      setIsSearching(false)
      setNoNewUsersSearch(false)
      setNoNewUsersGlobal(false)
      setCurrentPageSearch(0)
      setCurrentPageGlobal(0)
      void handleGetUsers()
    }
  }, [isSearching, searchTerm])

  useEffect(() => {
    if (users !== null && users.length > 0) {
      // Instanciate the observer
      const intersectionObserver = new IntersectionObserver((entries) => {
        if (entries.some((entry) => entry.isIntersecting)) {
          triggerNewPage()
        }
      })

      const loadSentry = document.querySelector('#loadSentry')
      if (loadSentry !== null) intersectionObserver.observe(loadSentry)

      return () => {
        intersectionObserver.disconnect()
      }
    }
  }, [users])

  return (
    <div
      ref={mainDivRef}
      className="flex size-full flex-col items-center gap-4"
    >
      <div className="flex w-full max-w-[600px] flex-col gap-4 px-4 pt-4">
        <SearchBar
          placeholder={'Username, email ou celular'}
          searchParam={searchTerm}
          setSearchParam={function (searchParam: string): void {
            setSearchTerm(searchParam)
          }}
          handleSearch={() => {
            setNoNewUsersGlobal(false)
            setNoNewUsersSearch(false)
            setCurrentPageGlobal(0)
            setCurrentPageSearch(0)
            setIsSearching(true)
            void handleSearch()
          }}
          isSearching={isLoadingSearch}
        />
        <div className="flex w-full flex-col flex-wrap gap-4 pb-6">
          {users.length > 0 && (
            <div className="flex w-full flex-col">
              {users.map((user, index) => {
                return (
                  <ListUserCard
                    user={user}
                    handleOnClick={(user: IUserData) => {
                      handleOnClick(user)
                    }}
                    key={index}
                  />
                )
              })}
            </div>
          )}
        </div>
      </div>
      {!noNewUsersGlobal && !noNewUsersSearch && (
        <div
          id="loadSentry"
          className="mb-4 flex w-full items-center justify-center gap-4"
        >
          <Spinner
            borderWidth="border-4"
            borderColor="border-primary-main/50"
            bottomBorderColor="border-b-primary-main"
          />
        </div>
      )}
      {userTicketInfoModalIsOpen && (
        <ListUserInfoModal
          closeModal={() => {
            setIsParentClosing(false)
            setTimeout(setUserTicketInfoModalIsOpen, 400, false)
          }}
          isParentClosing={isParentClosing}
          handleModalClose={() => {
            setIsParentClosing(true)
            setTimeout(setUserTicketInfoModalIsOpen, 400, true)
          }}
          currentUser={currentUser}
          updateState={updateState}
        />
      )}
    </div>
  )
}
