import { parseISO, format, differenceInDays, isSameYear } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { capitalizeString } from './formatString'
import { isTruthy } from './validation'

export function dateToFormat(date: string | undefined): string {
  if (date === undefined || date === '') return ''

  const dateObj = parseISO(date)

  const weekDay = capitalizeString(format(dateObj, 'EEE', { locale: ptBR }))
  const monthDay = capitalizeString(format(dateObj, 'dd', { locale: ptBR }))
  const monthFullName = format(dateObj, 'MMMM', { locale: ptBR })

  const formattedDate = `${weekDay.slice(
    0,
    3,
  )}, ${monthDay} de ${monthFullName}`

  return formattedDate
}

export function dateToHour(date: string | undefined): string {
  if (date === undefined || date === null) return ''

  const dateObj = parseISO(date)
  const hour = format(dateObj, 'HH:mm', { locale: ptBR })
  return hour
}

export function dateToDDMM(date: string | undefined): string {
  if (date === undefined || date === null) return ''

  const dateObj = parseISO(date)
  const hour = format(dateObj, 'dd/MM', { locale: ptBR })
  return hour
}

export function dateToBrazilTZ(date: string): string {
  const dateObj = parseISO(date)
  const dateObjBrazilTZ = format(dateObj, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", {
    locale: ptBR,
  })
  return dateObjBrazilTZ
}

export function fullDateFormat(date: string): string {
  if (date === undefined || date === '') return ''

  const dateObj = parseISO(date)

  const weekDay = capitalizeString(format(dateObj, 'EEE', { locale: ptBR }))
  const monthDay = capitalizeString(format(dateObj, 'dd', { locale: ptBR }))
  const monthFullName = format(dateObj, 'MMMM', { locale: ptBR })

  const time = format(dateObj, 'HH:mm', { locale: ptBR })

  const formattedDate = `${weekDay}, ${monthDay} de ${monthFullName} de ${dateObj.getFullYear()} às ${time}`

  return formattedDate
}

export function simplifiedFullDateFormat(date: string): string {
  if (date === undefined || date === '') return ''

  const dateObj = parseISO(date)

  const formattedDay = format(dateObj, 'dd/MM/yyyy')
  const time = format(dateObj, 'HH:mm', { locale: ptBR })

  const formattedDate = `${formattedDay} às ${time}`

  return formattedDate
}

export function datesToFormat(date1: string, date2: string): string {
  if (!isTruthy(date1) || !isTruthy(date2)) return ''

  const d1 = parseISO(date1)
  const d2 = parseISO(date2)

  const diffDays = Math.abs(differenceInDays(d1, d2))

  if (diffDays <= 1) {
    const dayOfWeek = capitalizeString(format(d1, 'EEE', { locale: ptBR }))
    const dayOfMonth = capitalizeString(format(d1, 'dd', { locale: ptBR }))
    const monthFullName = format(d1, 'MMMM', { locale: ptBR })

    const formattedDate = `${dayOfWeek.slice(
      0,
      3,
    )}, ${dayOfMonth} de ${monthFullName}`
    return formattedDate
  } else if (isSameYear(d1, d2)) {
    return `${format(d1, 'dd/MM')} -> ${format(d2, 'dd/MM')}`
  } else {
    return `${format(d1, 'dd/MM/yyyy')} -> ${format(d2, 'dd/MM/yyyy')}`
  }
}

function formatDate(date: Date, formatString: string): string {
  return format(date, formatString, { locale: ptBR })
}

export function generateEventTime(
  startDate: string,
  endDate: string,
  maxEntranceDate?: string,
): {
  formattedStartDate: string
  formattedMaxEntranceDate: string | undefined
  formattedEndDate: string
} {
  if (!isTruthy(startDate) || !isTruthy(endDate)) {
    return {
      formattedStartDate: '',
      formattedEndDate: '',
      formattedMaxEntranceDate: undefined,
    }
  }

  const d1 = parseISO(startDate)
  const d2 = parseISO(endDate)
  const d3 = isTruthy(maxEntranceDate) ? parseISO(maxEntranceDate!) : undefined

  const diffDays = Math.abs(differenceInDays(d1, d2))

  let formatString: string
  if (diffDays < 2) {
    formatString = "EEE, dd 'de' MMMM, 'às' HH:mm"
  } else if (isSameYear(d1, d2)) {
    formatString = "dd/MM 'às' HH:mm"
  } else {
    formatString = "dd/MM/yyyy 'às' HH:mm"
  }

  const formattedStartDate = formatDate(d1, formatString)
  const formattedEndDate = formatDate(d2, formatString)
  const formattedMaxEntranceDate =
    d3 !== undefined ? formatDate(d3, formatString) : ''

  return {
    formattedStartDate,
    formattedMaxEntranceDate,
    formattedEndDate,
  }
}
