import { useEffect, type ReactElement } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { useGetEventByAlias } from '@/hooks/Event'

import { eventMenuStore } from '@/store/eventMenu'
import { deviceStore } from '@/store/device'

import { Loading } from '@/components/Global/Loading/Loading'
import { Button } from '@/components/Global/Button'
import { wrapperStore } from '@/store/wrapper'

export function EventLayout(): ReactElement {
  const { alias } = useParams()

  const { getEventByAlias: event, isLoading: isLoadingGetEventByAlias } =
    useGetEventByAlias(alias!)

  const { setCurrentEvent } = eventMenuStore()
  const { setSubtitle } = wrapperStore()
  const { isMobile } = deviceStore()

  useEffect(() => {
    if (event !== undefined) {
      setCurrentEvent(event)
      setSubtitle(
        event?.status === 'REJECTED'
          ? 'Evento bloqueado'
          : event?.status === 'PENDING'
            ? 'Evento em análise'
            : '',
      )
    }
  }, [event])

  if (isLoadingGetEventByAlias) return <Loading />
  if (event?.status === 'REJECTED')
    return (
      <div
        className={twMerge(
          'flex size-full w-[500px] flex-col items-center justify-center gap-12',
          isMobile && 'w-4/5',
        )}
      >
        <h1 className="text-2xl">Evento bloqueado</h1>

        <span className="text-center leading-5">
          Seu evento violou uma ou mais políticas da Gandaya. Caso acredite que
          houve algum engano, clique no botão abaixo e entre em contato com
          nosso time de atendimento.
        </span>

        <div className="h-10 w-[200px]">
          <Button
            enabled
            text="Suporte"
            onClick={() => {
              const msg =
                'Olá! Gostaria de falar com o suporte sobre um evento bloqueado.'
              const url = `https://wa.me/${
                import.meta.env.VITE_SUPPORT_PHONE as string
              }?text=${msg}`
              window.open(url, '_blank')
            }}
          />
        </div>
      </div>
    )
  if (event?.status === 'PENDING')
    return (
      <div
        className={twMerge(
          'flex size-full w-[500px] flex-col items-center justify-center gap-12',
          isMobile && 'w-4/5',
        )}
      >
        <h1 className="text-2xl">Evento em análise</h1>

        <span className="text-center leading-5">
          Estamos análisando seu evento para garantir que ele está de acordo com
          as políticas da Gandaya. Caso queira entrar em contato com nosso time
          de atendimento, clique no botão abaixo.
        </span>

        <div className="h-10 w-[200px]">
          <Button
            enabled
            text="Suporte"
            onClick={() => {
              const msg =
                'Olá! Gostaria de falar com o suporte sobre um evento em análise.'
              const url = `https://wa.me/${
                import.meta.env.VITE_SUPPORT_PHONE as string
              }?text=${msg}`
              window.open(url, '_blank')
            }}
          />
        </div>
      </div>
    )
  return <Outlet />
}
