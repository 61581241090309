import { useState } from 'react'
import { type AxiosResponse } from 'axios'

import { api } from '@/services/axios'

import { type ILink, type CreateManyLinks } from '@/types/Links'

interface PostReturnProps {
  status: boolean
  links?: ILink[]
  message?: string
}

export const useCreateManyLinks = (): {
  createManyLinks: (body: CreateManyLinks) => Promise<PostReturnProps>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function createManyLinks(
    body: CreateManyLinks,
  ): Promise<PostReturnProps> {
    setIsLoading(true)
    try {
      const response: AxiosResponse<ILink[]> = await api.post(
        `/admin/links/many`,
        body,
      )

      setIsLoading(false)

      return {
        status: true,
        links: response.data,
      }
    } catch (error) {
      setIsLoading(false)
      return {
        status: false,
        message: 'Link não pôde ser criado',
      }
    }
  }

  return {
    createManyLinks,
    isLoading,
  }
}
