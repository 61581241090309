import { api } from '@/services/axios'
import { type IGetUsersWithGrantOfEvent, type IGrant } from '@/types/Grant'
import { type AxiosResponse } from 'axios'
import { useState } from 'react'

export const useGetSearchGrant = (): {
  searchUsersWithGrant: (
    searchParam: string,
    alias: string,
    skip?: number,
    take?: number,
  ) => Promise<IGetUsersWithGrantOfEvent>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function searchUsersWithGrant(
    searchParam: string,
    alias: string,
    page: number = 0,
  ): Promise<IGetUsersWithGrantOfEvent> {
    const skip = 50 * page
    const take = 50

    setIsLoading(true)

    const shouldFetch = alias !== undefined && searchParam !== undefined
    if (!shouldFetch) {
      setIsLoading(false)
      return {
        status: false,
      }
    }

    try {
      const response: AxiosResponse<IGrant[]> = await api.get(
        `/admin/events/${alias}/grants?term=${searchParam}&skip=${skip}&take=${take}`,
      )
      setIsLoading(false)
      return {
        status: true,
        users: response.data.map((user) => {
          return {
            ...user,
          }
        }),
      }
    } catch (err) {
      setIsLoading(false)
      return {
        status: false,
      }
    }
  }

  return { searchUsersWithGrant, isLoading }
}
