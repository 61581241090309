import { create } from 'zustand'

import {
  type IEventPermissions,
  type IOrganizationPermissions,
} from '@/types/Team'
import { devtools, persist } from 'zustand/middleware'

interface IUser {
  id: number
  username: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  imageKey: string
  balance: number
  cpf: string
  verifiedEmail: boolean
}

interface userProps {
  id: number
  username: string
  firstName: string
  lastName: string
  email: string
  cpf: string
  balance: number
  phoneNumber: string
  imageKey: string
  userIsLogged: boolean
  verifiedEmail?: boolean
  loginUser: (user: IUser) => void
  logoutUser: () => void
  setBalance: (balance: number) => void
  setProfileImageKey: (imageKey: string) => void
  setUserIsLogged: (isLogged: boolean) => void
  eventPermissions: IEventPermissions
  organizationPermissions: IOrganizationPermissions
  setEventPermissions: (permissions: IEventPermissions) => void
  setOrganizationPermissions: (permissions: IOrganizationPermissions) => void
}

export const userStore = create<userProps>()(
  devtools(
    persist(
      (set) => ({
        id: 0,
        username: '',
        email: '',
        firstName: '',
        lastName: '',
        balance: 0,
        imageKey: '',
        phoneNumber: '',
        cpf: '',
        userIsLogged: false as boolean,
        verifiedEmail: undefined,
        eventPermissions: {
          finance: 'FORBIDDEN',
          analytics: 'FORBIDDEN',
          list: 'FORBIDDEN',
          tickets: 'FORBIDDEN',
          grant: 'FORBIDDEN',
          link: 'FORBIDDEN',
          validation: false,
          edit: false,
        },
        organizationPermissions: {
          finance: 'FORBIDDEN',
          analytics: 'FORBIDDEN',
          access: 'FORBIDDEN',
          communicationCampaign: 'FORBIDDEN',
          eventCreation: false,
          settings: false,
        },
        loginUser: (user) => {
          set(() => ({
            ...user,
          }))
        },
        logoutUser: () => {
          set(() => ({
            id: 0,
            username: '',
            firstName: '',
            lastName: '',
            email: '',
            balance: 0,
            phoneNumber: '',
            imageKey: '',
            cpf: '',
            userIsLogged: false,
          }))
        },
        setProfileImageKey: (imageKey) => {
          set(() => ({
            imageKey,
          }))
        },
        setBalance: (balance) => {
          set(() => ({
            balance,
          }))
        },
        setUserIsLogged: (isLogged) => {
          set(() => ({
            userIsLogged: isLogged,
          }))
        },
        setEventPermissions: (permissions) => {
          set(() => ({
            eventPermissions: permissions,
          }))
        },
        setOrganizationPermissions: (permissions) => {
          set(() => ({
            organizationPermissions: permissions,
          }))
        },
      }),
      {
        name: 'user-storage',
      },
    ),
  ),
)

export const useUserPermissions = (): {
  eventPermissions: IEventPermissions
  organizationPermissions: IOrganizationPermissions
  setEventPermissions: (permissions: IEventPermissions) => void
  setOrganizationPermissions: (permissions: IOrganizationPermissions) => void
} => {
  return userStore((state) => {
    const {
      eventPermissions,
      organizationPermissions,
      setEventPermissions,
      setOrganizationPermissions,
    } = state

    return {
      eventPermissions,
      organizationPermissions,
      setEventPermissions,
      setOrganizationPermissions,
    }
  })
}
