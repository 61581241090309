import { type ReactElement } from 'react'

import { useCurrentError } from '@/store/error'
import { deviceStore } from '@/store/device'
import { useCurrentLink } from '@/store/link'

import { Modal } from '@/components/Global/Modal'
import { LinkDiscountFormUpdate } from '../LinkDiscountForm/LinkDiscountFormUpdate'
import { LinkDiscountFormCreate } from '../LinkDiscountForm/LinkDiscountFormCreate'

interface LinkDiscountModalProps {
  closeModal: () => void
  isParentClosing?: boolean
}

export function LinkDiscountModal({
  closeModal,
  isParentClosing,
}: LinkDiscountModalProps): ReactElement {
  const { isError, errorMessage } = useCurrentError()
  const { isMobile } = deviceStore()
  const { currentLink } = useCurrentLink()

  const currentOperationType = currentLink !== null ? 'update' : 'create'

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      isError={isError}
      errorMessage={errorMessage}
      isMobile={isMobile}
    >
      <div className="flex h-fit w-full flex-col items-center gap-8 overflow-scroll px-4 py-5 text-black">
        {currentOperationType === 'update' ? (
          <LinkDiscountFormUpdate />
        ) : (
          <LinkDiscountFormCreate />
        )}
      </div>
    </Modal>
  )
}
