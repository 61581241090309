import { useState, type ReactElement, type ElementType } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import { Modal } from '@/components/Global/Modal'
import { Button } from '@/components/Global/Button'

import { deviceStore } from '@/store/device'
import { errorStore } from '@/store/error'
import { useCurrentEvent } from '@/store/eventMenu'

import { usePostAddCoHost } from '@/hooks/CoHost'
import { useGetUserSearch } from '@/hooks/Team'

import { GoSearch } from 'react-icons/go'

import { type IUser } from '@/types/Team'

import { CANT_SEARCH_USER, USER_NOT_FOUND, CANT_ADD_CO_HOST } from '@/errors'

interface AddCoHostModalProps {
  closeModal: () => void
  isParentClosing: boolean
  refresh: () => void
}

export function AddCoHostModal({
  closeModal,
  isParentClosing,
  refresh,
}: AddCoHostModalProps): ReactElement {
  const [userSearchParam, setUserSearchParam] = useState('')
  const [isSearching, setIsSearching] = useState(false)
  const [userData, setUserData] = useState<IUser | undefined>()

  const { isMobile } = deviceStore()
  const { showError } = errorStore()
  const { currentEvent } = useCurrentEvent()

  const { search } = useGetUserSearch()
  const { addCoHost } = usePostAddCoHost()

  async function handleSearch(): Promise<void> {
    setIsSearching(true)
    const response = await search(userSearchParam)
    if (response.status === 200 && response.users?.length === 0) {
      setIsSearching(false)
      showError(USER_NOT_FOUND)
      return
    } else if (response.status !== 200) {
      setIsSearching(false)
      showError(CANT_SEARCH_USER)
      return
    }
    setIsSearching(false)
    setUserData(response.users?.[0])
  }

  async function handleAddUser(): Promise<void> {
    const response = await addCoHost(
      userData?.id ?? 0,
      currentEvent?.alias ?? '',
    )

    if (response.status !== 200) {
      showError(CANT_ADD_CO_HOST)
      return
    }
    refresh()
    closeModal()
  }

  return (
    <Modal
      isMobile={isMobile}
      closeModal={closeModal}
      isParentClosing={isParentClosing}
    >
      <div className="flex w-full flex-col gap-6 p-4">
        <div className="flex w-full flex-col gap-2 text-dark-black">
          <span className="text-2xl font-black">Buscar usuário</span>
          <span className="leading-5">
            Digite o celular ou e-mail do usuário que deseja adicionar ao seu
            evento.
          </span>
        </div>
        <div className="flex h-11 w-full items-center justify-center gap-4">
          <input
            type="text"
            value={userSearchParam}
            onChange={(e) => {
              setUserSearchParam(e.target.value)
            }}
            className="w-full max-w-[300px] border-b-2 border-x-transparent border-b-primary-main border-t-transparent bg-transparent py-2 text-black outline-none transition-all duration-200 ease-in-out focus:rounded-lg focus:border-2 focus:border-primary-main focus:px-2 disabled:border-b-dark-dark-gray"
            placeholder="marcos@gandaya.io"
            onKeyDown={(e) => {
              if (e.key === 'Enter' && userSearchParam !== '') {
                void handleSearch()
              }
            }}
          />
          <div className="size-10">
            <Button
              icon={GoSearch as ElementType}
              enabled={!isSearching && userSearchParam !== ''}
              onClick={() => {
                void handleSearch()
              }}
              className="rounded-lg"
              isLoading={isSearching}
            />
          </div>
        </div>

        <AnimatePresence mode="wait">
          {userData !== undefined && (
            <motion.div
              initial={{ height: 0 }}
              animate={{ height: 160 }}
              key="next"
              className="flex w-full flex-col items-center"
            >
              <div className="flex w-full max-w-[300px] items-center gap-4 self-center rounded-md bg-dark-light-gray p-2">
                <img
                  src={`${import.meta.env.VITE_S3 as string}/${
                    userData.imageKey
                  }`}
                  alt="User profile image"
                  className="aspect-square size-16 rounded-full"
                />

                <div className="flex w-full flex-col gap-1.5 text-dark-black">
                  <span className="text-base font-bold leading-4">
                    {userData.firstName} {userData.lastName}
                  </span>
                  <span className="text-sm leading-3">
                    @{userData.username}
                  </span>
                </div>
              </div>
              <div className="mt-8 h-12 w-full max-w-[300px] self-center">
                <Button
                  text="Adicionar"
                  enabled
                  onClick={() => {
                    void handleAddUser()
                  }}
                />
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </Modal>
  )
}
