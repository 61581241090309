import { type ReactElement, type ElementType } from 'react'

import { SlArrowRight } from 'react-icons/sl'

import { TicketCounter } from '@/components/Global/TicketCounter'

interface TicketSummaryProps {
  title: string
  subtitle?: string
  quantity: number
  thirdInfo?: string
  fourthInfo?: string
  orderQuantity?: number
  orderCode?: string
  validated?: boolean
  icon?: ElementType
  onClick?: () => void
  onClickIcon?: () => void
  onOpenAnnouncement?: () => void
  variant?: 'dark' | 'light' | 'highlighted' | 'mix'
  counterVariant?: 'dark' | 'light' | 'highlighted'
  isInfinite?: boolean
}

export function TicketSummary({
  title,
  subtitle,
  quantity,
  thirdInfo,
  fourthInfo,
  orderQuantity = 0,
  orderCode = '',
  validated = false,
  icon: Icon,
  onClick,
  onClickIcon,
  onOpenAnnouncement,
  variant = 'light',
  counterVariant = 'light',
  isInfinite,
}: TicketSummaryProps): ReactElement {
  const variants = {
    dark: {
      title: ' text-text-dark',
      subtitle: ' text-text-secondary font-regular',
    },
    light: {
      title: ' text-text-main',
      subtitle: ' text-text-main font-regular',
    },
    mix: {
      title: ' text-text-main',
      subtitle: ' text-text-secondary font-regular',
    },
    highlighted: {
      title: ' text-text-main',
      subtitle: ' text-primary-main font-bold',
    },
  }

  const subtitleStyle =
    'text-xs leading-3 truncate ' + variants[variant].subtitle

  return (
    <div className="flex w-full items-center">
      <div
        className={
          'flex w-min grow items-center gap-4 truncate' +
          (onClick != null ? ' hover:cursor-pointer' : '')
        }
        onClick={onClick}
      >
        <div className="w-12">
          <TicketCounter
            value={quantity}
            direction={1}
            variant={counterVariant}
            isInfinite={isInfinite}
          />
        </div>
        <div className="flex flex-col truncate">
          <span
            className={
              'truncate text-sm font-bold leading-4' + variants[variant].title
            }
          >
            {title}
          </span>
          <span className={subtitleStyle}>{subtitle}</span>
          <div className="flex truncate">
            <span className="mr-1 truncate text-xs font-bold text-text-secondary">
              {thirdInfo}
            </span>
            {fourthInfo !== undefined && orderQuantity === 0 && (
              <span className="truncate text-xs font-normal text-text-secondary">
                {`• ${fourthInfo}`}
              </span>
            )}
          </div>
        </div>
      </div>
      {orderQuantity !== 0 && (
        <div className="ml-2 flex items-center justify-center rounded-lg bg-primary-main px-2 py-1">
          <span className="line-clamp-none w-max text-3xs">
            {orderQuantity}
            {orderQuantity === 1
              ? ' INGRESSO ANUNCIADO'
              : ' INGRESSOS ANUNCIADOS'}
          </span>
        </div>
      )}
      {orderCode !== '' && (
        <div
          className="ml-4 flex items-center justify-center gap-2 rounded-lg bg-primary-main px-2 py-1 hover:cursor-pointer"
          onClick={onOpenAnnouncement}
        >
          <span className="line-clamp-none w-max text-xs font-bold">
            Anunciado
          </span>
          <SlArrowRight
            size={10}
            style={{
              strokeWidth: 50,
            }}
          />
        </div>
      )}
      {validated && (
        <div className="ml-4 flex items-center justify-center gap-4 rounded-lg border border-background-main bg-white px-2 py-1">
          <span className="line-clamp-none w-max text-xs font-bold">
            Validado
          </span>
        </div>
      )}
      {Icon !== undefined && (
        <div
          className="ml-2 flex h-full flex-col items-center justify-center hover:cursor-pointer"
          onClick={onClickIcon}
        >
          <Icon color="white" size={24} />
        </div>
      )}
    </div>
  )
}
