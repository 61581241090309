import { type ReactElement } from 'react'
import { Link, useParams } from 'react-router-dom'

import { type ILinkData } from '@/types/Event'

interface LinkCardProps {
  data: ILinkData[]
}

export function LinkCard({ data }: LinkCardProps): ReactElement {
  const { alias } = useParams()

  const LinksName = {
    UTM: 'UTM',
    Discount: 'Desconto',
    Promo: 'Promo',
  }

  return (
    <>
      <Link
        to={`/events/${alias}/links`}
        className="flex w-full items-center justify-between pl-4"
      >
        <div className="flex flex-col gap-4">
          <header className="text-base font-bold">Links</header>
          <div className="flex gap-6">
            {data?.map((link, index) => {
              return (
                <span className="text-[0.625rem] text-[#959595]" key={index}>
                  <strong className="text-bold mr-1 text-2xl text-white">
                    {link.quantity}
                  </strong>
                  {LinksName[link.type]}
                </span>
              )
            })}
          </div>
        </div>
      </Link>
    </>
  )
}
