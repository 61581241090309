/* eslint-disable @typescript-eslint/no-explicit-any */
import { type InputHTMLAttributes, type ReactElement, useState } from 'react'
import { type UseFormRegister } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'
import { tv } from 'tailwind-variants'

interface CreateEditOrganizationInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label?: string
  error?: string
  register?: UseFormRegister<any>
  onChangeFunction?: (data?: any) => string
  onChangeStartCursorFunction?: (data?: any) => number
  onChangeEndCursorFunction?: (data?: any) => number
  wrapperDivClassname?: string
  watch?: any
}

const inputVariant = tv({
  base: 'flex w-full items-center rounded-none transition-color duration-200 ease-in-out ',
  variants: {
    style: {
      focus:
        'rounded-lg border-x-2 border-primary-main border-x-primary-main border-t-primary-main px-2',
      focusError:
        'px-2 rounded-lg border-x-2 border-x-tonal-red border-tonal-red',
      base: 'border-b-dark-dark-gray border-y-2 border-t-transparent',
      baseError: 'border-b-tonal-red border-y-2  border-t-transparent',
      title:
        'text-3xl placeholder:text-3xl placeholder:font-black font-bold border-none focus:border',
    },
  },
})

export function CreateEditOrganizationInput({
  label,
  register,
  name,
  error,
  onChangeFunction,
  onChangeStartCursorFunction,
  onChangeEndCursorFunction,
  wrapperDivClassname,
  ...props
}: CreateEditOrganizationInputProps): ReactElement {
  const [inputOnFocus, setInputOnFocus] = useState(false)

  let inputErrorVariation: 'baseError' | 'base' = 'base'
  let inputStyleOnFocus: 'focusError' | 'focus' = 'focus'

  if (error !== undefined) {
    inputErrorVariation = 'baseError'
    if (inputOnFocus) {
      inputStyleOnFocus = 'focusError'
    }
  }

  const commonProps = { ...props }
  delete commonProps.watch

  return (
    <div
      className={twMerge(
        'relative flex flex-col gap-1 pb-4',
        wrapperDivClassname,
      )}
    >
      {label != null && (
        <label htmlFor={label} className="font-black">
          {label}
        </label>
      )}
      {register !== undefined && (
        <div
          className={twMerge(
            inputVariant({ style: inputErrorVariation }),
            inputOnFocus && inputVariant({ style: inputStyleOnFocus }),
          )}
          onClick={() => {
            setInputOnFocus(true)
          }}
        >
          <input
            {...commonProps}
            className={twMerge(
              'w-full bg-transparent py-2 font-normal outline-none placeholder:font-bold placeholder:text-text-terciary placeholder:opacity-50 disabled:hover:cursor-not-allowed',
            )}
            {...register(name, {
              onChange: (e: React.FormEvent<HTMLInputElement>) => {
                if (onChangeFunction !== undefined) {
                  e.currentTarget.value = onChangeFunction(
                    e.currentTarget.value,
                  )
                }
                if (onChangeStartCursorFunction !== undefined) {
                  e.currentTarget.selectionStart = onChangeStartCursorFunction(
                    e.currentTarget.selectionStart,
                  )
                }
                if (onChangeEndCursorFunction !== undefined) {
                  e.currentTarget.selectionEnd = onChangeEndCursorFunction(
                    e.currentTarget.selectionEnd,
                  )
                }
              },
              onBlur: () => {
                setInputOnFocus(false)
              },
            })}
            onFocus={() => {
              setInputOnFocus(true)
            }}
          />
        </div>
      )}

      {error !== null && (
        <span className="absolute -bottom-1 left-1 whitespace-nowrap text-xs font-extrabold text-tonal-red">
          {error}
        </span>
      )}
    </div>
  )
}
