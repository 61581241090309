/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useEffect,
  useState,
  type InputHTMLAttributes,
  type ReactElement,
} from 'react'
import { type UseFormRegister, type UseFormWatch } from 'react-hook-form'

import { FormTextArea } from '@/components/Global/Form/FormTextArea'

interface FormTextAreaCounterProps
  extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label?: string
  error?: string
  register?: UseFormRegister<any>
  watch?: UseFormWatch<any>
  isInputTitle?: boolean
  wrapperDivClassname?: string
  maxLength: number
}

export function FormTextAreaCounter(
  props: FormTextAreaCounterProps,
): ReactElement {
  const [counter, setCounter] = useState('')

  useEffect(() => {
    if (props.watch !== undefined) {
      const value = props.watch(props.name) as string
      setCounter(value ?? '')
    }
  }, [props])

  return (
    <div className="flex flex-col">
      <FormTextArea {...props} />
      <div className="flex w-full items-end justify-end">
        <span className="text-sm font-bold leading-4">{counter.length}</span>
        <span className="text-xs leading-4">/{props.maxLength}</span>
      </div>
    </div>
  )
}
