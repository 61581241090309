import { api } from '@/services/axios'
import { useState } from 'react'

interface DeleteReturnProps {
  status: boolean
  message?: string
}

export const useDeleteLink = (): {
  deleteLink: (linkId: number) => Promise<DeleteReturnProps>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function deleteLink(linkId: number): Promise<DeleteReturnProps> {
    setIsLoading(true)
    try {
      await api.delete(`/admin/links/${linkId}`)

      setIsLoading(false)

      return {
        status: true,
      }
    } catch (error) {
      setIsLoading(false)
      return {
        status: false,
        message: 'Link não pôde ser deletado',
      }
    }
  }

  return {
    deleteLink,
    isLoading,
  }
}
