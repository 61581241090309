import { useState, useEffect } from 'react'
import useSWR from 'swr'

import { fetcher } from '@/services/axios'

interface GetResponseProps {
  code: string
  active: boolean
}

export const useGetCheckInCode = (
  alias: string,
): {
  data:
    | {
        code: string
        active: boolean
      }
    | undefined
  isLoading: boolean
  error: string | undefined
} => {
  const [isLoading, setIsLoading] = useState(true)
  const { data, error } = useSWR<GetResponseProps, string>(
    `/admin/events/${alias}/check-in-code`,
    fetcher,
  )

  useEffect(() => {
    setIsLoading(!(data !== undefined || error !== undefined))
  }, [data, error])

  return {
    data,
    isLoading,
    error,
  }
}
