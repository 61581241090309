import { useEffect, type ReactElement } from 'react'
import { BrowserRouter } from 'react-router-dom'

import { Router } from './routes'

import { deviceStore } from '@/store/device'

export function App(): ReactElement {
  const { setIsMobile } = deviceStore()

  // Check window size
  useEffect(() => {
    setIsMobile(window.innerWidth <= 474)
    const handleResize = (): void => {
      setIsMobile(window.innerWidth <= 474)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  )
}
