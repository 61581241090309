import { twMerge } from 'tailwind-merge'
import { tv } from 'tailwind-variants'

interface DeleteIconProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
  variant?: 'light' | 'dark'
}

export function DeleteIcon({
  className,
  variant = 'light',
  ...props
}: DeleteIconProps): React.ReactElement {
  const div = tv({
    base: twMerge(
      'flex aspect-square h-6 w-6 rotate-45 cursor-pointer items-center justify-center rounded-full font-extralight leading-3',
      className,
    ),
    variants: {
      color: {
        light: 'bg-white',
        dark: 'bg-background-main',
      },
    },
  })

  const icon = tv({
    base: 'text-3xl',
    variants: {
      color: {
        light: 'text-background-main',
        dark: 'text-white',
      },
    },
  })

  return (
    <div className={div({ color: variant })} {...props}>
      <span className={icon({ color: variant })}>+</span>
    </div>
  )
}
