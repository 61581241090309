import { useState, useEffect } from 'react'
import useSWR from 'swr'

import { fetcher } from '@/services/axios'

import { type GetSalesByEventAlias } from '@/types/Analytics'

import { isTruthy } from '@/utils/validation'
import { getTimeZone } from '@/utils/timeZone'

export const useGetSalesByEventAlias = (
  alias: string,
  time?: string | undefined,
  spec?: number | undefined,
): {
  getSalesByEventAlias: GetSalesByEventAlias | undefined
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(true)

  const url =
    `/admin/analytics/${alias}/sales` +
    `?timezone=${getTimeZone()}` +
    (isTruthy(time) ? '&time=' + time : '') +
    (isTruthy(spec) ? '&spec=' + spec : '')

  const shouldFetch = alias !== undefined

  const { data, error } = useSWR<GetSalesByEventAlias, string>(
    shouldFetch && url,
    fetcher,
  )

  if (data !== undefined && data.ticketsByDate.length > 0) {
    while (data.ticketsByDate[0].quantity === 0) {
      data.ticketsByDate.shift()
    }
    while (data.ticketsByDate[data.ticketsByDate.length - 1].quantity === 0) {
      data.ticketsByDate.pop()
    }
  }

  useEffect(() => {
    setIsLoading(!(data !== undefined || error !== undefined))
  }, [data, error])

  return { getSalesByEventAlias: data, isLoading }
}
