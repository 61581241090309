import { useState } from 'react'
import { api } from '@/services/axios'
import { type AxiosError } from 'axios'

import { type IPermissions } from '@/types/Team'

interface ICreateOrganizationRole {
  userId: number
  roles: IPermissions
}

interface PostReturnProps {
  status: number
}

export const usePostCreateOrganizationRole = (
  organizationId: number,
): {
  createOrganizationRole: (
    body: ICreateOrganizationRole,
  ) => Promise<PostReturnProps>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function createOrganizationRole(
    body: ICreateOrganizationRole,
  ): Promise<PostReturnProps> {
    setIsLoading(true)
    try {
      await api.post(`/organizations/${organizationId}/users`, body)

      setIsLoading(false)

      return {
        status: 200,
      }
    } catch (error) {
      setIsLoading(false)
      const err = error as AxiosError
      return {
        status: err.response?.status ?? 500,
      }
    }
  }

  return {
    createOrganizationRole,
    isLoading,
  }
}
