import { type ReactElement, useState } from 'react'
import { motion } from 'framer-motion'

import { Login } from './Login'
import { ProfilePicture } from './ProfilePicture'
import { Signup } from './Signup'
import { TwoFactor } from './TwoFactor'
import { CommunicationMethod } from './CommunicationMethod'

interface LoginSignupProps {
  showError: (errorMessage: string, screenTime?: number) => void
}

export function LoginSignup({ showError }: LoginSignupProps): ReactElement {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [communicationMethod, setCommunicationMethod] = useState<
    'SMS' | 'WHATSAPP'
  >('WHATSAPP')

  const [phoneNumberValidationKey, setPhoneNumberValidationKey] = useState('')

  const [state, setState] = useState<
    | 'login'
    | 'twoFactorPhone'
    | 'signup'
    | 'profilePicture'
    | 'communicationMethod'
  >('login')

  return (
    <div className="w-full text-black">
      {state === 'login' ? (
        <motion.div
          key="login"
          initial={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1, ease: 'anticipate' }}
          className="flex size-full flex-col items-center"
        >
          <Login
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            setState={setState}
            showError={showError}
          />
        </motion.div>
      ) : state === 'communicationMethod' ? (
        <motion.div
          key="communicationMethod"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1, ease: 'anticipate' }}
          className="flex size-full flex-col items-center"
        >
          <CommunicationMethod
            phoneNumber={phoneNumber}
            setState={setState}
            showError={showError}
            setCommunicationMethod={setCommunicationMethod}
          />
        </motion.div>
      ) : state === 'twoFactorPhone' ? (
        <motion.div
          key="twoFactorPhone"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1, ease: 'anticipate' }}
          className="flex size-full flex-col items-center"
        >
          <TwoFactor
            phoneNumber={phoneNumber}
            setState={setState}
            showError={showError}
            setPhoneNumberValidationKey={setPhoneNumberValidationKey}
            variant="phone"
            communicationMethod={communicationMethod}
          />
        </motion.div>
      ) : state === 'signup' ? (
        <motion.div
          key="signUp"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1, ease: 'anticipate' }}
          className="flex size-full flex-col items-center"
        >
          <Signup
            phoneNumber={phoneNumber}
            phoneNumberValidationKey={phoneNumberValidationKey}
            setState={setState}
            showError={showError}
          />
        </motion.div>
      ) : (
        <motion.div
          key="profilePicture"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1, ease: 'anticipate' }}
          className="flex size-full flex-col items-center"
        >
          <ProfilePicture />
        </motion.div>
      )}
    </div>
  )
}
