import { useEffect, useState, type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

import { PageviewAreaChart } from './PageviewAreaChart'
import { PageviewChartButtons } from './PageviewChartButtons'
import { AnimatedNumber } from '../AnimatedNumber'

import { useGetPageViews } from '@/hooks/Analytics'

import { type IPageViewData } from '@/types/Analytics'

import { deviceStore } from '@/store/device'

import { simplifiedFullDateFormat } from '@/utils/formatData'

interface AnalyticsPageviewCardProps {
  alias: string
}

export function AnalyticsPageviewCard({
  alias,
}: AnalyticsPageviewCardProps): ReactElement {
  const [uniqueUsers, setUniqueUsers] = useState(false)
  const [renderedData, setRenderedData] = useState<IPageViewData[]>([])
  const [firstLoad, setFirstLoad] = useState(true)
  const [selectedTimeFrame, setSelectedTimeFrame] = useState({
    value: 'Últimas 24 horas',
    key: '1day',
    id: 0,
  })

  const { getPageViews, isLoading } = useGetPageViews(alias)

  const { isMobile } = deviceStore()

  const mappedPageViewData = renderedData.map((data) => ({
    name: simplifiedFullDateFormat(data.date),
    pv: data.quantity,
  }))
  const pageViewSum = renderedData.reduce((acc, curr) => acc + curr.quantity, 0)

  async function handleGetPageViews(): Promise<void> {
    const response = await getPageViews(
      uniqueUsers,
      selectedTimeFrame.key as '1day' | '7days' | 'all',
    )
    if (firstLoad) setFirstLoad(false)
    if (response.data !== undefined) setRenderedData(response.data)
  }

  useEffect(() => {
    void handleGetPageViews()
  }, [uniqueUsers, selectedTimeFrame])

  if (firstLoad)
    return (
      <div className="h-[248px] w-full animate-pulse rounded-lg bg-dark-black" />
    )
  return (
    <div className="flex w-full flex-col justify-between gap-4 rounded-lg bg-dark-black p-4">
      <header className={twMerge('flex justify-between')}>
        <div className="flex w-fit flex-col gap-2">
          <span className="text-xl font-black leading-4">Acessos</span>
          <AnimatedNumber
            val={pageViewSum}
            isMoney={false}
            className="text-lg font-medium leading-4 text-primary-main"
          />
        </div>
        {!isMobile && (
          <PageviewChartButtons
            isLoading={isLoading}
            uniqueUsers={uniqueUsers}
            setUniqueUsers={setUniqueUsers}
            selectedTimeFrame={selectedTimeFrame}
            setSelectedTimeFrame={setSelectedTimeFrame}
          />
        )}
      </header>
      <main className="relative h-40">
        <PageviewAreaChart data={mappedPageViewData} />
      </main>
      <div className="flex w-full flex-col items-end">
        {isMobile && (
          <PageviewChartButtons
            isLoading={isLoading}
            uniqueUsers={uniqueUsers}
            setUniqueUsers={setUniqueUsers}
            selectedTimeFrame={selectedTimeFrame}
            setSelectedTimeFrame={setSelectedTimeFrame}
          />
        )}
      </div>
    </div>
  )
}
