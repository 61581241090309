import { useState, type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import { Modal } from '@/components/Global/Modal'
import { Selector } from '@/components/Global/Selector'
import { Button } from '@/components/Global/Button'

import { deviceStore } from '@/store/device'

interface SelectNewRoleTypeModalProps {
  closeModal: () => void
  isParentClosing: boolean
}

export function SelectNewRoleTypeModal({
  closeModal,
  isParentClosing,
}: SelectNewRoleTypeModalProps): ReactElement {
  const [selectedType, setSelectedType] = useState<
    'EVENT' | 'ORGANIZATION' | ''
  >('')
  const { isMobile } = deviceStore()

  const navigate = useNavigate()

  return (
    <Modal
      isMobile={isMobile}
      closeModal={closeModal}
      isParentClosing={isParentClosing}
    >
      <div className="flex flex-col gap-8 p-4">
        <span className="text-2xl font-black leading-6 text-black">
          Você deseja adicionar esse usuário a:
        </span>
        <div className="flex flex-col gap-2">
          <Selector
            title="Sua organização"
            subtitle="O usuário será capaz de interagir com sua organização e todos os eventos da mesma forma."
            isSelected={selectedType === 'ORGANIZATION'}
            onClick={() => {
              setSelectedType('ORGANIZATION')
            }}
            variant="light"
          />
          <Selector
            title="Eventos específicos"
            subtitle="Liberação de interação para eventos específicos."
            isSelected={selectedType === 'EVENT'}
            onClick={() => {
              setSelectedType('EVENT')
            }}
            variant="light"
          />
        </div>
        <div className="h-12 w-full max-w-[300px] self-center">
          <Button
            enabled={selectedType !== ''}
            text="Confirmar"
            onClick={() => {
              if (selectedType === 'EVENT') navigate('add/event')
              if (selectedType === 'ORGANIZATION') navigate('add/organization')
            }}
          />
        </div>
      </div>
    </Modal>
  )
}
