import { type ReactElement } from 'react'

import { Modal } from '@/components/Global/Modal'
import { deviceStore } from '@/store/device'
import { Button } from '@/components/Global/Button'

import { useDeleteHost } from '@/hooks/CoHost'

import { errorStore } from '@/store/error'
import { useCurrentEvent } from '@/store/eventMenu'

import { type IUser } from '@/types/CoHosts'

import { CANT_DELETE_CO_HOST } from '@/errors'

interface LinkConfirmDeleteModalProps {
  closeModal: () => void
  isParentClosing?: boolean
  coHostData: IUser
  refresh: () => void
}

export function CoHostsConfirmDeletionModal({
  closeModal,
  isParentClosing,
  coHostData,
  refresh,
}: LinkConfirmDeleteModalProps): ReactElement {
  const { isMobile } = deviceStore()
  const { showError } = errorStore()
  const { currentEvent } = useCurrentEvent()

  const { deleteHost, isLoading: isLoadingDelete } = useDeleteHost(
    currentEvent?.alias ?? '',
  )

  async function handleDeleteLink(): Promise<void> {
    const response = await deleteHost(coHostData.id)
    if (response.status !== 200) {
      showError(CANT_DELETE_CO_HOST)
      return
    }
    refresh()
    closeModal()
  }

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      isMobile={isMobile}
    >
      <div className="flex flex-col items-center gap-8 p-4">
        <div className="flex flex-col gap-4">
          <span className="text-3xl font-black text-black">
            Você deseja remover um Co Host?
          </span>
          <span className="leading-5 text-black">
            Ao remover{' '}
            <span className="font-bold">
              {coHostData.firstName} {coHostData.lastName}
            </span>{' '}
            como um Co Host do evento{' '}
            <span className="font-bold">{currentEvent?.name}</span>, ele não
            poderá acessar mais nada instantaneamente. Esta ação não pode ser
            revertida.
          </span>
        </div>
        <div className="h-12 w-3/5">
          <Button
            text="Confirmar"
            enabled={!isLoadingDelete}
            className="bg-tonal-red text-white disabled:cursor-not-allowed disabled:bg-[#A2A2A2] disabled:text-[#656565]"
            isLoading={isLoadingDelete}
            onClick={() => {
              void handleDeleteLink()
            }}
          />
        </div>
      </div>
    </Modal>
  )
}
