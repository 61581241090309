import { useState, type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import { Modal } from '@/components/Global/Modal'
import { Button } from '@/components/Global/Button'
import { Selector } from '@/components/Global/Selector'

import { useCurrentError } from '@/store/error'
import { useCommunicationCampaignStore } from '@/store/communicationCampaign'
import { deviceStore } from '@/store/device'

import { isTruthy } from '@/utils/validation'

interface CreateCommunicationCampaignSelectModalProps {
  closeModal: () => void
  isParentClosing?: boolean
}

export function CreateCommunicationCampaignSelectModal({
  closeModal,
  isParentClosing,
}: CreateCommunicationCampaignSelectModalProps): ReactElement {
  const [newCommunicationCampaignType, setNewCommunicationCampaignType] =
    useState('')

  const { isError, errorMessage } = useCurrentError()

  const { updateProperty, reset } = useCommunicationCampaignStore()
  const { isMobile } = deviceStore()

  const navigate = useNavigate()

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      isError={isError}
      errorMessage={errorMessage}
      isMobile={isMobile}
    >
      <div className="flex h-fit w-full flex-col items-center gap-8 text-black">
        <div className="flex w-full flex-col gap-6 py-8">
          <div className="flex w-4/5 flex-col gap-2 self-center">
            <Selector
              title="Push Notification"
              extraTitle="(R$ 0,20/usuário)"
              subtitle="Impacte seu público com push notifications dentro do app."
              isSelected={newCommunicationCampaignType === 'PUSH'}
              onClick={(): void => {
                setNewCommunicationCampaignType('PUSH')
              }}
              variant={'light'}
            />
            <Selector
              title={'SMS'}
              extraTitle="(R$ 0,30/usuário)"
              subtitle={'Envie um SMS para seu público com a sua campanha.'}
              isSelected={newCommunicationCampaignType === 'SMS'}
              onClick={(): void => {
                setNewCommunicationCampaignType('SMS')
              }}
              variant={'light'}
            />
          </div>
          <div className="h-12 w-1/3 self-center">
            <Button
              enabled={isTruthy(newCommunicationCampaignType)}
              text="Próximo"
              onClick={() => {
                setTimeout(navigate, 400, 'create')
                reset()
                updateProperty('type', newCommunicationCampaignType)
                closeModal()
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}
