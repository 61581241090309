import { type ILink } from '@/types/Links'
import { create } from 'zustand'

interface LinkStoreProps {
  allLinks: ILink[]
  setAllLinks: (links: ILink[]) => void

  currentLink: ILink | null
  setCurrentLink: (link: ILink | null) => void

  modalToOpen:
    | 'NewLink'
    | 'Url'
    | 'UTM'
    | 'Discount'
    | 'TicketSpecPromo'
    | 'AnalyticsUsers'
    | 'Delete'
    | null
  setModalToOpen: (
    type:
      | 'NewLink'
      | 'Url'
      | 'UTM'
      | 'Discount'
      | 'TicketSpecPromo'
      | 'AnalyticsUsers'
      | 'Delete'
      | null,
  ) => void
  isParentClosing: boolean
  setIsParentClosing: (isParentClosing: boolean) => void
  closeLinkModal: () => void

  addLink: (link: ILink) => void
  updateLink: (link: ILink) => void
  removeLink: (link: ILink) => void

  refreshLinks: () => void
  setRefreshLinks: (refreshLinks: () => void) => void

  createLink: (type: 'UTM' | 'Discount' | 'TicketSpecPromo') => void
  editLink: (link: ILink, type: 'UTM' | 'Discount' | 'TicketSpecPromo') => void
}

export const linkStore = create<LinkStoreProps>((set, get) => {
  return {
    allLinks: [],
    setAllLinks: (links: ILink[]) => {
      set({ allLinks: links })
    },
    currentLink: null,
    setCurrentLink: (link: ILink | null) => {
      set({ currentLink: link })
    },
    modalToOpen: null,
    setModalToOpen: (
      type:
        | 'NewLink'
        | 'Url'
        | 'UTM'
        | 'Discount'
        | 'TicketSpecPromo'
        | 'AnalyticsUsers'
        | 'Delete'
        | null,
    ) => {
      set({
        modalToOpen: type,
      })
    },
    isParentClosing: false,
    setIsParentClosing: (isParentClosing: boolean) => {
      set({ isParentClosing })
    },
    closeLinkModal: () => {
      set({ isParentClosing: true })
      setTimeout(set, 400, {
        modalToOpen: null,
        currentLink: null,
        isParentClosing: false,
      })
    },
    createLink: (type: 'UTM' | 'Discount' | 'TicketSpecPromo') => {
      set({ currentLink: null, modalToOpen: type })
    },
    editLink: (link: ILink, type: 'UTM' | 'Discount' | 'TicketSpecPromo') => {
      set({ currentLink: link, modalToOpen: type })
    },
    addLink: (link: ILink) => {
      const { allLinks } = get()
      allLinks.unshift(link)
      set({ allLinks })
    },
    updateLink: (link: ILink) => {
      const { allLinks } = get()
      const newAllLinks = allLinks.map((oldLink) => {
        if (oldLink.id === link.id) {
          return link
        }
        return oldLink
      })
      set({
        allLinks: newAllLinks,
      })
    },
    removeLink: (link: ILink) => {
      const { allLinks } = get()
      const allLinksFiltered = allLinks.filter(
        (oldLink) => oldLink.id !== link.id,
      )
      set({
        allLinks: allLinksFiltered,
      })
    },
    refreshLinks: () => {},
    setRefreshLinks: (refreshLinks: () => void) => {
      set({ refreshLinks })
    },
  }
})

export const useAllLinks = (): {
  allLinks: ILink[]
} => {
  return linkStore((state) => {
    const { allLinks } = state

    return { allLinks }
  })
}

export const useLinkModal = (): {
  isParentClosing: boolean
  modalToOpen:
    | 'NewLink'
    | 'Url'
    | 'UTM'
    | 'Discount'
    | 'TicketSpecPromo'
    | 'AnalyticsUsers'
    | 'Delete'
    | null
  setModalToOpen: (
    type:
      | 'NewLink'
      | 'Url'
      | 'UTM'
      | 'Discount'
      | 'TicketSpecPromo'
      | 'AnalyticsUsers'
      | 'Delete'
      | null,
  ) => void
  setIsParentClosing: (isParentClosing: boolean) => void
} => {
  return linkStore((state) => {
    const { modalToOpen, isParentClosing, setModalToOpen, setIsParentClosing } =
      state

    return { modalToOpen, isParentClosing, setModalToOpen, setIsParentClosing }
  })
}

export const useCurrentLink = (): {
  currentLink: ILink | null
} => {
  return linkStore((state) => {
    const { currentLink } = state

    return { currentLink }
  })
}

export const useRefreshLinks = (): {
  refreshLinks: () => void
  setRefreshLinks: (refreshLinks: () => void) => void
} => {
  return linkStore((state) => {
    const { refreshLinks, setRefreshLinks } = state

    return { refreshLinks, setRefreshLinks }
  })
}
