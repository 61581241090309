import { type ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface SpinnerProps {
  borderWidth: string
  borderColor: string
  bottomBorderColor: string
  width?: string
  height?: string
}

export function Spinner({
  borderWidth,
  borderColor,
  bottomBorderColor,
  width = 'w-8',
  height = 'h-8',
}: SpinnerProps): ReactNode {
  return (
    <div className="m-0 block leading-3">
      <div
        className={twMerge(
          `spinner`,
          width,
          height,
          borderWidth,
          borderColor,
          bottomBorderColor,
        )}
      ></div>
    </div>
  )
}
