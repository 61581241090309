import { useState, type ReactElement } from 'react'
import Lottie from 'lottie-react'
import { twMerge } from 'tailwind-merge'
import { motion } from 'framer-motion'

import { Button } from '@/components/Global/Button'

import tickets from '@/assets/lottie/tickets.json'
import batches from '@/assets/lottie/batches.json'

import { usePutUpdateEventManagementType } from '@/hooks/Event/usePutUpdateEventManagementType'

import { errorStore } from '@/store/error'
import { ticketStore } from '@/store/tickets'
import { useCurrentEvent } from '@/store/eventMenu'

export function TicketsSelectManagementType(): ReactElement {
  const [managementType, setManagementType] = useState<
    'SIMPLE' | 'FULL' | null
  >()

  const { showError } = errorStore()
  const { setManagementType: setManagementTypeStore } = ticketStore()
  const { currentEvent } = useCurrentEvent()

  const { updateEventManagementType, isLoading } =
    usePutUpdateEventManagementType(currentEvent?.alias ?? '')

  async function handleConfirmManegementType(): Promise<void> {
    if (managementType === null) {
      return
    }
    const response = await updateEventManagementType(managementType!)

    if (!response.status) {
      showError(
        'Erro ao definir estilo de gerenciamento de ingressos. Tente novamente.',
      )
      return
    }
    setManagementTypeStore(managementType)
  }

  return (
    <div className="flex size-full flex-col items-center gap-4 desktop:flex-row">
      <div
        className={twMerge(
          'flex h-[40dvh] max-h-[400px] min-h-[250px] w-full cursor-pointer flex-col overflow-hidden rounded-md border-2 border-transparent bg-dark-black p-4 transition-all duration-100 ease-in-out',
          managementType === 'SIMPLE' && 'border-primary-main',
        )}
        onClick={() => {
          setManagementType('SIMPLE')
        }}
      >
        <div className="flex h-full items-center justify-center">
          <Lottie animationData={tickets} loop={true} />
        </div>
        <span className="text-xl font-bold">Criar ingressos simples</span>
        <span className="mb-2.5 mt-0.5 text-xs leading-4 text-[#959595]">
          Crie ingressos diferentes para o seu evento. Insira nome, preço,
          descrição e, se achar necessário, estabeleça limite de vendas para
          cada tipo.
        </span>
        <motion.div
          className="flex w-full"
          initial={{ opacity: 0, height: '0px' }}
          animate={
            managementType === 'SIMPLE'
              ? { opacity: 1, height: '32px' }
              : { opacity: 0, height: '0px' }
          }
          transition={{ duration: 0.15 }}
          key="simple-confirm"
        >
          <Button
            text="Confirmar"
            enabled={!isLoading}
            isLoading={isLoading}
            className="h-8 w-[100px] px-4 text-sm"
            onClick={handleConfirmManegementType}
            spinnerHeight="h-6"
            spinnerWidth="w-6"
          />
        </motion.div>
      </div>
      <div
        className={twMerge(
          'flex h-[40dvh] max-h-[400px] min-h-[250px] w-full cursor-pointer flex-col overflow-hidden rounded-md border-2 border-transparent bg-dark-black p-4 transition-all duration-100 ease-in-out',
          managementType === 'FULL' && 'border-primary-main',
        )}
        onClick={() => {
          setManagementType('FULL')
        }}
      >
        <div className="flex h-full items-center justify-center">
          <Lottie animationData={batches} loop={true} />
        </div>
        <span className="text-xl font-bold">Criar ingressos por lote</span>
        <span className="mb-2.5 mt-0.5 text-xs leading-4 text-[#959595]">
          Escolha essa opção caso o seu evento tenha definições de lotes mais
          complexas - como ingressos diferentes por sexo, idade ou bandeira do
          cartão.
        </span>
        <motion.div
          className="flex w-full"
          initial={{ opacity: 0, height: '0px' }}
          animate={
            managementType === 'FULL'
              ? { opacity: 1, height: '32px' }
              : { opacity: 0, height: '0px' }
          }
          transition={{ duration: 0.15 }}
          key="full-confirm"
        >
          <Button
            text="Confirmar"
            isLoading={isLoading}
            enabled={!isLoading}
            className="h-8 w-[100px] px-4 text-sm"
            onClick={handleConfirmManegementType}
            spinnerHeight="h-6"
            spinnerWidth="w-6"
          />
        </motion.div>
      </div>
    </div>
  )
}
