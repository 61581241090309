import { type ReactElement, useRef } from 'react'

import { Modal } from '@/components/Global/Modal'
import { Button } from '@/components/Global/Button'

import titleImageExample from '@/assets/titleImageExample.png'

import { cropImageStore } from '@/store/cropImage'
import { deviceStore } from '@/store/device'

import {
  type ICreateEventStoreState,
  type IUpdateEventStoreState,
} from '@/types/CreateUpdateEvent'

interface CreateEventTitleImageInfoProps {
  closeModal: () => void
  handleModalClose: () => void
  isParentClosing?: boolean
  setStartCrop: () => void
  updateAnyState:
    | ((update: Partial<ICreateEventStoreState>) => void)
    | ((update: Partial<IUpdateEventStoreState>) => void)
}

export function CreateEventTitleImageInfo({
  closeModal,
  isParentClosing,
  handleModalClose,
  setStartCrop,
}: CreateEventTitleImageInfoProps): ReactElement {
  const { setCurrentImage, setParamsOfCrop, setCurrentModalOfImage } =
    cropImageStore()

  const { isMobile } = deviceStore()

  const divRef = useRef<HTMLInputElement>(null)

  function handleOnSubmit(e: React.ChangeEvent<HTMLInputElement>): void {
    e.preventDefault()

    if (e.target.files !== null) {
      const image = e.target.files[0]
      setCurrentImage(URL.createObjectURL(image))
      setCurrentModalOfImage('titleImage')
      setParamsOfCrop(false, 100, 100, null)
      handleModalClose()
      setTimeout(setStartCrop, 400)
    }
  }

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      isMobile={isMobile}
    >
      <div className="flex flex-col gap-8 p-4">
        <div className="flex flex-col gap-4">
          <h1 className="text-2xl text-black">O que é a imagem de título?</h1>
          <span className="text-base leading-4 text-secondary-gray">
            É um jeito novo de customizar como as pessoas veem o nome do seu
            evento!
          </span>
        </div>

        <div className="flex w-full items-center justify-center">
          <img
            src={titleImageExample}
            alt="Exemplo de imagem de título"
            className="aspect-auto h-80 w-fit rounded-lg"
          />
        </div>

        <div className="h-12">
          <Button
            text="Adicionar imagem título"
            enabled
            onClick={() => {
              divRef.current?.click()
              // handleModalClose()
            }}
          />
        </div>
        <label htmlFor="titleImage" className="hidden">
          <input
            id="titleImage"
            className="hidden"
            type="file"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleOnSubmit(e)
            }}
            ref={divRef}
            onClick={(e: React.FormEvent<HTMLInputElement>) => {
              const target = e.target as HTMLButtonElement
              target.value = ''
            }}
          />
        </label>
      </div>
    </Modal>
  )
}
