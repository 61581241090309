import { useState } from 'react'
import { type AxiosResponse, type AxiosError } from 'axios'
import { api } from '@/services/axios'

import {
  type IVerifyPhoneOtpProps,
  type IVerifyPhoneOtpReturn,
  type IVerifyPhoneOtpResponse,
} from '@/types/Auth/useOTP'

export const usePostVerifyPhoneOTP = (): {
  postVerifyPhoneOtp: (
    verificationObj: IVerifyPhoneOtpProps,
  ) => Promise<IVerifyPhoneOtpReturn>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function postVerifyPhoneOtp(
    verificationObj: IVerifyPhoneOtpProps,
  ): Promise<IVerifyPhoneOtpReturn> {
    setIsLoading(true)

    const { phoneNumber, code } = verificationObj

    const requestBody = {
      phoneNumber,
      code,
      origin: 'ATLAS',
    }

    try {
      const response: AxiosResponse<IVerifyPhoneOtpResponse> = await api.post(
        `/auth/verify-otp`,
        requestBody,
      )
      setIsLoading(false)
      return {
        status: 200,

        user: response.data.user,
      }
    } catch (err) {
      const error = err as AxiosError<IVerifyPhoneOtpResponse>
      setIsLoading(false)

      if (error.response?.status === 401) {
        return {
          status: 401,
          phoneNumberValidationKey:
            error.response.data.phoneNumberValidationKey,
        }
      }
      return {
        status: error.status as number,
      }
    }
  }

  return { postVerifyPhoneOtp, isLoading }
}
