import { useState, type ReactElement, useRef } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { twMerge } from 'tailwind-merge'

import { Modal } from '@/components/Global/Modal'
import { Button } from '@/components/Global/Button'

import { usePostNewToken } from '@/hooks/CreateEditOrganization'

import { useCurrentOrganization } from '@/store/organization'
import { errorStore, useCurrentError } from '@/store/error'
import { deviceStore } from '@/store/device'

import { CopyIcon } from '@/components/Icons/CopyIcon'
import { BsCheckLg } from 'react-icons/bs'

import { CREATE_TOKEN } from '@/errors'

interface InviteProducerModalProps {
  closeModal: () => void
  isParentCLosing?: boolean
}

export function InviteProducerModal({
  closeModal,
  isParentCLosing,
}: InviteProducerModalProps): ReactElement {
  const [showCopyPaste, setShowCopyPaste] = useState(false)
  const [linkCopied, setLinkCopied] = useState(false)
  const [token, setToken] = useState('')

  const linkRef = useRef<HTMLSpanElement>(null)

  const { currentOrganization } = useCurrentOrganization()
  const { showError } = errorStore()
  const { isError, errorMessage } = useCurrentError()
  const { isMobile } = deviceStore()

  const url = `${import.meta.env.VITE_ATLAS_URL}/create-organization?token=${token}`

  const { createToken, isLoading } = usePostNewToken(
    currentOrganization!.organizer.organizationId,
  )

  async function copyLinkToClipboard(): Promise<void> {
    if (linkRef.current !== null) {
      setLinkCopied(true)
      const textToCopy = linkRef.current.textContent
      await navigator.clipboard.writeText(textToCopy ?? '')
      setTimeout(setLinkCopied, 3000, false)
    }
  }

  async function handleCreateToken(): Promise<void> {
    const response = await createToken()
    if (response.status === 200) {
      setShowCopyPaste(true)
      setToken(response.token!)
      return
    }
    showError(CREATE_TOKEN)
  }

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentCLosing}
      isError={isError}
      errorMessage={errorMessage}
      isMobile={isMobile}
    >
      <div className="mb-8 mt-4 flex w-full flex-col items-center gap-8 text-black">
        <h1 className="w-full px-4 text-3xl">Convide um produtor</h1>
        <span className="px-4 leading-5">
          Nesse momento, a nossa rede de parceiros é restrita e{' '}
          <strong>invite only</strong>. Por ser um produtor parceiro, você pode
          convidar outro produtor para fazer a gestão de seus eventos usando a
          <strong> Gandaya</strong>.
        </span>
        <AnimatePresence mode="wait">
          {showCopyPaste ? (
            <motion.div
              className="flex h-12 w-full items-center justify-center"
              key="copyPasteButton"
              initial={{ opacity: 0, x: 300 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <div className="flex w-4/5 items-center justify-between rounded-md border border-dark-dark-gray p-1 pl-2">
                <span className="w-[85%] truncate" ref={linkRef}>
                  {url}
                </span>
                <div
                  className={twMerge(
                    'flex items-center justify-center rounded-md p-2 transition-colors duration-200 ease-in-out hover:cursor-pointer ',
                    linkCopied ? 'bg-primary-dark' : 'bg-primary-main',
                  )}
                  onClick={copyLinkToClipboard}
                >
                  {linkCopied ? (
                    <BsCheckLg size={20} color="white" />
                  ) : (
                    <CopyIcon strokeColor="black" />
                  )}
                </div>
              </div>
            </motion.div>
          ) : (
            <motion.div
              className="h-12 w-4/5"
              onClick={() => {
                void handleCreateToken()
              }}
              key="generateButton"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, x: -300 }}
              transition={{ duration: 0.2 }}
            >
              <Button
                text="Gerar convite"
                enabled={!isLoading}
                isLoading={isLoading}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </Modal>
  )
}
