import { type ReactElement, useState } from 'react'

import { Modal } from '@/components/Global/Modal'
import { Selector } from '@/components/Global/Selector'
import { Button } from '@/components/Global/Button'

import { useCurrentError, errorStore } from '@/store/error'
import { deviceStore } from '@/store/device'

import { isTruthy } from '@/utils/validation'

import { CREATE_WITHDRAWAL_BLOCK } from '@/errors'

interface CreateWithdrawalSelectModalProps {
  closeModal: () => void
  handleModalClose: () => void
  isParentClosing?: boolean
  openCreateInternalModal: () => void
  openCreateExternalModal: () => void
  hasInternalWithdrawalPending: boolean
}

export function CreateWithdrawalSelectModal({
  closeModal,
  handleModalClose,
  isParentClosing,
  openCreateInternalModal,
  openCreateExternalModal,
  hasInternalWithdrawalPending,
}: CreateWithdrawalSelectModalProps): ReactElement {
  const [withdrawalSelected, setWithdrawalSelected] = useState('')

  const { isError, errorMessage } = useCurrentError()
  const { showError } = errorStore()
  const { isMobile } = deviceStore()

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      isError={isError}
      errorMessage={errorMessage}
      isMobile={isMobile}
    >
      <div className="flex h-fit w-full flex-col items-center gap-8 p-4 text-black">
        <div className="flex w-full flex-col gap-4">
          <div className="flex flex-col gap-2">
            <Selector
              title={'Saque'}
              subtitle={'Solicite um saque para uma chave PIX cadastrada.'}
              isSelected={withdrawalSelected === 'internal'}
              onClick={(): void => {
                setWithdrawalSelected('internal')
              }}
              variant={'light'}
            />
            <Selector
              title={'Pagamento'}
              extraTitle="(NF obrigatória)"
              subtitle={
                'Forneça os dados de um prestador de serviço para receber um pagamento.'
              }
              isSelected={withdrawalSelected === 'external'}
              onClick={(): void => {
                setWithdrawalSelected('external')
              }}
              variant={'light'}
            />
          </div>
          <div className="mt-4 h-12 w-full max-w-[300px] self-center">
            <Button
              enabled={isTruthy(withdrawalSelected)}
              text="Próximo"
              onClick={() => {
                if (withdrawalSelected === 'internal') {
                  if (hasInternalWithdrawalPending) {
                    showError(CREATE_WITHDRAWAL_BLOCK)
                    return
                  } else {
                    setTimeout(openCreateInternalModal, 400)
                  }
                }
                if (withdrawalSelected === 'external')
                  setTimeout(openCreateExternalModal, 400)
                handleModalClose()
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}
