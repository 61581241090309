import { useState, type ReactElement } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import { Spinner } from '@/components/Global/Spinner'

import { BsCheckLg } from 'react-icons/bs'
import { BiSolidReport } from 'react-icons/bi'

import { useGetGenerateReport } from '@/hooks/Event'

import { errorStore } from '@/store/error'

import { GENERATE_REPORT_ERROR } from '@/errors'
import { twMerge } from 'tailwind-merge'

interface EventExportReportProps {
  alias?: string
}

export function EventExportReport({
  alias,
}: EventExportReportProps): ReactElement {
  const [reportGenerated, setReportGenerated] = useState(false)

  const { getGenerateReport, isLoading } = useGetGenerateReport(alias!)

  const { showError } = errorStore()

  async function handleGenerate(): Promise<void> {
    const response = await getGenerateReport()
    if (response) {
      setReportGenerated(true)
    } else {
      showError(GENERATE_REPORT_ERROR)
    }
  }

  return (
    <div
      className={twMerge(
        'flex items-center rounded-lg border border-primary-main bg-dark-black p-4',
        !reportGenerated && 'hover:cursor-pointer',
      )}
      onClick={() => {
        if (!reportGenerated) void handleGenerate()
      }}
    >
      <div className="flex w-full flex-col ">
        <span className="font-bold">Exportar dados</span>
        <span className="text-xs">Você receberá a planilha em seu email.</span>
      </div>
      <AnimatePresence mode="wait">
        {isLoading ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1, ease: 'easeInOut' }}
            key="spinner"
          >
            <Spinner
              borderWidth="border-4"
              borderColor="border-primary-main/50"
              bottomBorderColor="border-b-primary-main"
            />
          </motion.div>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1, ease: 'easeInOut' }}
            key="icon"
          >
            {reportGenerated ? (
              <div className="flex aspect-square w-7 items-center justify-center rounded-full bg-primary-main">
                <BsCheckLg color="#181818" size={18} />
              </div>
            ) : (
              <BiSolidReport size={28} />
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
