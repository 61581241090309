import { type ReactElement, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'

import { updateEventStore } from '@/store/updateEvent'
import { useUserPermissions } from '@/store/user'
import { eventMenuStore } from '@/store/eventMenu'

import { HiOutlinePencil } from 'react-icons/hi2'
import { MdList } from 'react-icons/md'
import { LuCopy } from 'react-icons/lu'
import { BsCheckLg } from 'react-icons/bs'
import { FaRegHandPeace } from 'react-icons/fa6'
import { PiScanBold } from 'react-icons/pi'
import { FaRegHandshake } from 'react-icons/fa'

import { hasClearence } from '@/utils/rbac'

interface EventIconMenuProps {
  alias: string
}

export function EventIconMenu({ alias }: EventIconMenuProps): ReactElement {
  const [linkCopied, setLinkCopied] = useState(false)
  const navigate = useNavigate()

  const { organizationPermissions, eventPermissions } = useUserPermissions()
  const { currentEvent } = eventMenuStore()
  const { updateAnyState, reset } = updateEventStore()

  async function copyLinkToClipboard(): Promise<void> {
    setLinkCopied(true)
    setTimeout(setLinkCopied, 3000, false)
    await navigator.clipboard.writeText(
      `${import.meta.env.VITE_KAYA_URL as string}/${alias}`,
    )
  }

  const canEditEvent = hasClearence(eventPermissions.edit, true)
  const canViewList = hasClearence(eventPermissions.list, 'VIEWER')
  const canAddCoHosts = hasClearence(organizationPermissions.access, 'EDITOR')
  const canViewGrant = hasClearence(eventPermissions.grant, 'VIEWER')
  const isCloseFriendsEvent = currentEvent?.eventType === 'CLOSE_FRIENDS'
  const canViewCheckIn = hasClearence(eventPermissions.validation, true)

  return (
    <div className="relative w-full ">
      <div className="flex size-full justify-start gap-4 overflow-y-hidden overflow-x-scroll scroll-smooth px-4 ">
        {canEditEvent && (
          <div
            className="flex cursor-pointer flex-col items-center gap-3"
            onClick={() => {
              navigate(`/events/${alias}/edit`, {
                state: { fetchData: true },
              })
              updateAnyState({ typeOperation: 'Update' })
              reset()
            }}
          >
            <div className="flex size-[84px] items-center justify-center rounded-full border-2 border-primary-main">
              <HiOutlinePencil size={32} />
            </div>
            <span className="text-center text-sm font-bold leading-4">
              Editar evento
            </span>
          </div>
        )}
        {canAddCoHosts && isCloseFriendsEvent && (
          <Link
            to={`/events/${alias}/co-hosts`}
            className="flex flex-col items-center gap-3"
          >
            <div className="flex size-[84px] items-center justify-center rounded-full border-2 border-primary-main">
              <FaRegHandshake size={38} />
            </div>
            <span className="text-sm font-bold leading-3">Co-hosts</span>
          </Link>
        )}
        {canViewList && (
          <Link
            to={`/events/${alias}/list`}
            className="flex flex-col items-center gap-3"
          >
            <div className="flex size-[84px] items-center justify-center rounded-full border-2 border-primary-main">
              <MdList size={32} />
            </div>
            <span className="text-sm font-bold leading-3">Lista</span>
          </Link>
        )}
        {canViewGrant && (
          <Link
            to={`/events/${alias}/grant`}
            className="flex flex-col items-center gap-3"
          >
            <div className="flex size-[84px] items-center justify-center rounded-full border-2 border-primary-main">
              <FaRegHandPeace size={32} />
            </div>
            <span className="text-sm font-bold leading-3">Cortesia</span>
          </Link>
        )}
        <div className="flex flex-col items-center gap-3">
          <div
            className="flex size-[84px] items-center justify-center rounded-full border-2 border-primary-main hover:cursor-pointer"
            onClick={copyLinkToClipboard}
          >
            <AnimatePresence mode="wait">
              {linkCopied ? (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.1, ease: 'easeInOut' }}
                  key="check"
                >
                  <BsCheckLg size={32} />
                </motion.div>
              ) : (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.1, ease: 'easeInOut' }}
                  key="copy"
                >
                  <LuCopy size={32} />
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <span className="text-sm font-bold leading-3">Copiar link</span>
        </div>
        {canViewCheckIn && (
          <Link
            to={`check-in`}
            className="flex w-24 flex-col items-center gap-3"
          >
            <div className="flex size-[84px] items-center justify-center rounded-full border-2 border-primary-main">
              <PiScanBold size={32} />
            </div>
            <span className="text-sm font-bold leading-3">Check-in</span>
          </Link>
        )}
      </div>
      <div className="absolute left-0 top-0 z-10 h-full w-4 bg-gradient-to-l from-transparent to-[#2a2a2a]" />
      <div className="absolute right-0 top-0 z-10 h-full w-4 bg-gradient-to-r from-transparent to-[#2a2a2a]" />
    </div>
  )
}
