import { type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

import { SlArrowRight } from 'react-icons/sl'

import { formatNumber } from '@/utils/formatNumber'
import { simplifiedFullDateFormat } from '@/utils/formatData'

import { deviceStore } from '@/store/device'

interface CommunicationCampaignCardProps {
  name: string
  scheduledFor: string
  status: string
  usersImpacted: number
  onClick?: () => void
}

export function CommunicationCampaignCard({
  name,
  scheduledFor,
  status,
  usersImpacted,
  onClick,
}: CommunicationCampaignCardProps): ReactElement {
  const { isMobile } = deviceStore()
  const campaignStatus =
    status === 'PENDING'
      ? 'Em análise'
      : status === 'APPROVED'
        ? 'Aprovado'
        : status === 'SENT'
          ? 'Enviado'
          : status === 'DENIED'
            ? 'Negado'
            : 'Cancelado'

  const colorStatus =
    status === 'PENDING'
      ? 'bg-tonal-orange'
      : status === 'APPROVED'
        ? 'bg-primary-main'
        : status === 'SENT'
          ? 'bg-primary-main'
          : status === 'DENIED'
            ? 'bg-tonal-red'
            : 'bg-tonal-red'

  return (
    <div
      className="mb-4 flex justify-between rounded-lg bg-secondary-gray p-4 duration-100 ease-in-out hover:cursor-pointer hover:bg-dark-black"
      onClick={onClick}
    >
      <div className="relative flex flex-col gap-1">
        <span className="font-bold text-white">{name}</span>
        <div className="flex flex-col">
          <div className="flex items-center gap-2">
            <div className={twMerge('h-2.5 w-2.5 rounded-full', colorStatus)} />
            <span className="text-xs text-white">{campaignStatus}</span>
          </div>
        </div>
        <span className="text-2xs text-white">
          Agendado para {simplifiedFullDateFormat(scheduledFor)}
        </span>
      </div>
      <div className="flex items-end gap-4">
        <div className="flex items-end gap-1.5">
          <span className="text-xl font-bold leading-5 text-white">
            {formatNumber(usersImpacted)}
          </span>
          <span className="text-xs text-white">impactados</span>
        </div>
        {isMobile && <SlArrowRight color="#7c7c7c" size={12} />}
      </div>
    </div>
  )
}
