import { useState, useEffect } from 'react'
import useSWR from 'swr'

import { fetcher } from '@/services/axios'

import { type IEventPermissions } from '@/types/Team'

interface IEventPermissionsBody {
  roles: {
    event: IEventPermissions
  }
}

export const useGetMemberEventPermissions = (
  eventAlias: string,
  username: string,
): {
  permissions: IEventPermissionsBody | undefined
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(true)
  const shouldFetch = eventAlias !== undefined && username !== undefined
  const { data, error } = useSWR<IEventPermissionsBody, string>(
    shouldFetch && `/admin/events/${eventAlias}/user/${username}`,
    fetcher,
  )

  useEffect(() => {
    setIsLoading(!(data !== undefined || error !== undefined))
  }, [data, error])

  return {
    permissions: data,
    isLoading,
  }
}
