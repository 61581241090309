import { type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

import { Modal } from '@/components/Global/Modal'
import { Button } from '@/components/Global/Button'

import { useCurrentError } from '@/store/error'
import { deviceStore } from '@/store/device'
import { useUserPermissions } from '@/store/user'

import { type ICommunicationCampaign } from '@/types/CommunicationCampaign'

import logo from '@/assets/logo.png'
import messageIcon from '@/assets/message-icon.svg'

import { formatNumber, numberToReais } from '@/utils/formatNumber'
import { fullDateFormat, simplifiedFullDateFormat } from '@/utils/formatData'
import { hasClearence } from '@/utils/rbac'

interface DeleteReturnProps {
  status: number
}

interface CommunicationCampaignSummaryModalProps {
  closeModal: () => void
  isParentClosing?: boolean
  variant: 'VIEW' | 'CREATE'
  campaignData: ICommunicationCampaign
  cancelCampaign?: (id: number) => Promise<DeleteReturnProps>
  refresh?: () => void
  confirmCampaign?: () => void
  isLoading?: boolean
}

export function CommunicationCampaignSummaryModal({
  closeModal,
  isParentClosing,
  variant,
  campaignData,
  cancelCampaign,
  refresh,
  confirmCampaign,
  isLoading = false,
}: CommunicationCampaignSummaryModalProps): ReactElement {
  const { isError, errorMessage } = useCurrentError()

  const { isMobile } = deviceStore()
  const { organizationPermissions } = useUserPermissions()

  const canEdit = hasClearence(
    organizationPermissions.communicationCampaign,
    'EDITOR',
  )

  const campaignStatus =
    campaignData.status === 'PENDING'
      ? 'Em análise'
      : campaignData.status === 'APPROVED'
        ? 'Aprovado'
        : campaignData.status === 'SENT'
          ? 'Enviado'
          : campaignData.status === 'DENIED'
            ? 'Negado'
            : 'Cancelado'

  const colorStatus =
    campaignData.status === 'PENDING'
      ? 'bg-tonal-orange'
      : campaignData.status === 'APPROVED'
        ? 'bg-primary-main'
        : campaignData.status === 'SENT'
          ? 'bg-primary-main'
          : campaignData.status === 'DENIED'
            ? 'bg-tonal-red'
            : 'bg-tonal-red'

  async function handleCancelCampaign(): Promise<void> {
    const response = await cancelCampaign!(campaignData.id!)
    if (response.status === 200) {
      refresh!()
      setTimeout(closeModal, 100)
    }
  }

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      isError={isError}
      errorMessage={errorMessage}
      isMobile={isMobile}
    >
      <div className="flex h-fit w-full flex-col items-center gap-6 p-4 text-black">
        <div className="flex w-full flex-col gap-3">
          <span className="text-2xl font-black">{campaignData.name}</span>

          {variant === 'VIEW' && (
            <div>
              <span className="font-medium">Status</span>
              <section className="mt-1 flex flex-col rounded-lg bg-dark-light-gray p-4">
                <div className="flex items-center gap-1">
                  <div
                    className={twMerge('h-2.5 w-2.5 rounded-full', colorStatus)}
                  />
                  <span className="text-sm font-medium">{campaignStatus}</span>
                </div>
                <span className="text-2xs leading-4">
                  Solicitado em{' '}
                  {simplifiedFullDateFormat(campaignData.createdAt!)}
                </span>
              </section>
            </div>
          )}

          <div>
            <span className="font-medium">
              Preview do{' '}
              {campaignData.type === 'SMS' ? 'SMS' : 'Push Notification'}
            </span>
            <section className="mt-1 flex flex-col gap-2 rounded-lg bg-dark-light-gray p-4">
              <div className="flex items-center gap-2">
                <img
                  src={campaignData.type === 'PUSH' ? logo : messageIcon}
                  className="aspect-square w-8 rounded-lg"
                  alt={
                    campaignData.type === 'PUSH' ? 'Logo Gandaya' : 'Mensagens'
                  }
                />
                <span className="text-sm font-bold">
                  {campaignData.type === 'PUSH' ? 'Gandaya' : 'Nova mensagem'}
                </span>
              </div>
              <div className="flex flex-col">
                <span className="text-sm font-medium">
                  {campaignData.communication.title}
                </span>
                <span className="left-3 mt-0.5 text-xs leading-4">
                  {campaignData.communication.content}
                </span>
              </div>
            </section>
          </div>

          <div>
            <span className="font-medium">Detalhes</span>
            <div className="mt-1 flex flex-col gap-1">
              <section className="flex flex-col gap-1 rounded-lg bg-dark-light-gray p-4">
                <span className="text-sm font-bold leading-4">Data</span>
                <span className="text-xs leading-4">
                  {fullDateFormat(campaignData.scheduledFor)}
                </span>
              </section>
              {variant === 'VIEW' && (
                <section className="flex flex-col rounded-lg bg-dark-light-gray p-4">
                  <span className="text-sm font-bold">Soliticação</span>
                  <span className="text-xs leading-4">
                    {formatNumber(campaignData.estimatedSubscribers)} contatos
                    enviados
                  </span>
                </section>
              )}
            </div>
          </div>

          {variant === 'CREATE' &&
            campaignData.estimatedSubscribers !== undefined &&
            campaignData.estimatedPrice !== undefined && (
              <div>
                <span className="font-black">Estimativa *</span>
                <section className="flex flex-col gap-2 rounded-lg bg-dark-light-gray p-4">
                  <span>
                    <span className="text-lg font-bold">
                      {formatNumber(campaignData.estimatedSubscribers)}
                    </span>{' '}
                    usuários impactados por{' '}
                    <span className="text-lg font-bold">
                      {numberToReais(campaignData.estimatedPrice, 2)}
                    </span>
                  </span>
                  <span className="text-2xs leading-3">
                    * Alguns usuários da planilha podem não ter o aplicativo da
                    Gandaya, por isso o valor final e o número de usuários
                    impactados pode ser diferente.
                  </span>
                </section>
              </div>
            )}

          {variant === 'VIEW' &&
            campaignData.subscribers !== null &&
            campaignData.price !== null && (
              <div>
                <span className="font-black">Custo</span>
                <section className="flex flex-col rounded-lg bg-dark-light-gray p-4">
                  <span>
                    <span className="text-lg font-bold">
                      {formatNumber(campaignData.subscribers!)}
                    </span>{' '}
                    usuários impactados por{' '}
                    <span className="text-lg font-bold">
                      {numberToReais(campaignData.price!, 2)}
                    </span>
                  </span>
                </section>
              </div>
            )}
        </div>

        {variant === 'VIEW' &&
          canEdit &&
          (campaignData.status === 'PENDING' ||
            campaignData.status === 'APPROVED') && (
            <div className="h-12 w-full">
              <Button
                enabled={!isLoading}
                text="Cancelar"
                className="bg-tonal-red text-white disabled:brightness-90"
                onClick={handleCancelCampaign}
                isLoading={isLoading}
              />
            </div>
          )}

        {variant === 'CREATE' && (
          <div className="flex w-full flex-col gap-2">
            <div className="h-12 w-full">
              <Button
                enabled
                text="Editar"
                className="bg-[#A3A3A3] text-white"
                onClick={closeModal}
              />
            </div>
            <div className="h-12 w-full">
              <Button
                enabled={!isLoading}
                text="Confirmar"
                onClick={confirmCampaign}
                isLoading={isLoading}
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}
