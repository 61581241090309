import { motion } from 'framer-motion'
import { useEffect, type ReactElement, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as z from 'zod'

import { errorStore } from '@/store/error'
import { linkStore, useCurrentLink, useRefreshLinks } from '@/store/link'

import { useUpdateLink } from '@/hooks/Link'
import { useGetTickets } from '@/hooks/Tickets'

import { Button } from '@/components/Global/Button'
import { Form } from '@/components/Global/Form/Form'
import { FormInput } from '@/components/Global/Form/FormInput'
import { Spinner } from '@/components/Global/Spinner'
import { TicketSelector } from '@/components/Global/TicketSelector'

interface IFullTicketSpec {
  title: string
  id: number
  price: number
  quantity?: number
  limit: number
}

export function LinkTicketsFormUpdate(): ReactElement {
  const [ticketSpecs, setTicketSpecs] = useState<IFullTicketSpec[]>([])

  const { alias } = useParams()

  const { updateLink, isLoading: isLoadingUpdateLink } = useUpdateLink()

  const { showError } = errorStore()
  const { currentLink } = useCurrentLink()
  const { refreshLinks } = useRefreshLinks()
  const { updateLink: updateLinkStore, closeLinkModal } = linkStore()

  const createBatchLink = z.object({
    code: z
      .string()
      .min(2, { message: 'O nome do código precisa ter pelo menos 2 letras.' })
      .refine(
        (value) => {
          const regex = /^[a-zA-Z0-9-_]+$/
          if (regex.test(value)) {
            return true
          } else {
            return false
          }
        },
        {
          message: 'O nome do link não pode ter caracteres especiais ou espaço',
        },
      ),
  })

  type FormData = z.infer<typeof createBatchLink>

  const { tickets } = useGetTickets(alias!)

  let defaultValues: Record<string, string | number> | null = null
  if (currentLink !== null) {
    defaultValues = {
      code: currentLink.code,
    }
  }

  function updateLimit(ticketSpecId: number, limit: number): void {
    if (ticketSpecs !== undefined) {
      const ticketSpecsUpdated = ticketSpecs.map((ticketSpec) => {
        if (ticketSpec.id === ticketSpecId) {
          ticketSpec.limit = limit
          return ticketSpec
        }
        return ticketSpec
      })
      setTicketSpecs(ticketSpecsUpdated)
    }
  }

  async function handleUpdateBatchLink(data: FormData): Promise<void> {
    const response = await updateLink({
      linkId: currentLink!.id,
      code: data.code,
      linkType: 'TicketSpecPromo',
      ticketSpecsPromo: ticketSpecs
        .filter((item) => item.limit > 0)
        .map((item) => {
          return {
            ticketSpecId: item.id,
            limit: item.limit,
          }
        }),
    })

    if (!response.status) {
      showError('Não foi possivel atualizar o link. Tente novamente')

      return
    }
    if (response.link !== undefined) {
      updateLinkStore(response.link)
    }
    refreshLinks()
    closeLinkModal()
  }

  useEffect(() => {
    if (tickets?.managementType === 'SIMPLE') {
      const result = tickets?.ticketsSpecs?.map((ticketSpec) => {
        const currentLinkTicketSpec = currentLink?.ticketSpecs?.find(
          (item) => item.ticketSpecId === ticketSpec.id,
        )

        return {
          title: ticketSpec.description ?? '',
          id: ticketSpec.id ?? 0,
          price: ticketSpec.price ?? 0,
          limit:
            currentLinkTicketSpec !== undefined
              ? currentLinkTicketSpec.limit
              : 0,
        }
      })
      const sortedResult = result?.sort((ticket1, ticket2) => {
        return ticket1.limit > ticket2.limit ? -1 : 1
      })

      setTicketSpecs(sortedResult ?? [])
    } else if (tickets?.managementType === 'FULL') {
      const result = tickets?.batches?.flatMap((batch) => {
        const ticketSpecsWithBatchDescription = batch.ticketSpecs?.map(
          (ticketSpec) => {
            const currentLinkTicketSpec = currentLink?.ticketSpecs?.find(
              (item) => item.ticketSpecId === ticketSpec.id,
            )
            return {
              title: `${batch.description} - ${ticketSpec.description}`,
              id: ticketSpec.id ?? 0,
              price: ticketSpec.price ?? 0,
              limit:
                currentLinkTicketSpec !== undefined
                  ? currentLinkTicketSpec.limit
                  : 0,
            }
          },
        )

        return [...ticketSpecsWithBatchDescription!]
      })
      const sortedResult = result?.sort((ticket1, ticket2) => {
        return ticket1.limit > ticket2.limit ? -1 : 1
      })

      setTicketSpecs(sortedResult ?? [])
    }
  }, [tickets])

  return (
    <Form
      formSchema={createBatchLink}
      className="flex w-full flex-col gap-4 px-4"
      handleModalClose={(data: FormData) => {
        void handleUpdateBatchLink(data)
      }}
      defaultValues={defaultValues ?? undefined}
    >
      <FormInput
        type="text"
        id="code"
        placeholder="Código do link *"
        name="code"
        autoCorrect="off"
        autoCapitalize="none"
        isInputTitle={true}
      />
      <div className="relative">
        <ul className="relative flex max-h-72 flex-col gap-2 overflow-scroll py-2">
          {ticketSpecs !== undefined ? (
            ticketSpecs.map((ticketSpec) => {
              return (
                <motion.li key={ticketSpec.id}>
                  <TicketSelector
                    ticketSpecId={ticketSpec.id}
                    title={ticketSpec.title}
                    price={ticketSpec.price}
                    quantitySelected={ticketSpec?.limit ?? 0}
                    finalPrice={ticketSpec.price}
                    hasFee={false}
                    variant="light"
                    setQuantity={(ticketSpecId: number, limit: number) => {
                      updateLimit(ticketSpecId, limit)
                    }}
                  />
                </motion.li>
              )
            })
          ) : (
            <div className="flex items-center justify-center">
              <Spinner
                borderWidth="border-4"
                borderColor="border-background-main/50"
                bottomBorderColor="border-b-background-main"
              />
            </div>
          )}
        </ul>
        <div className="absolute left-0 top-0 z-50 h-2 w-full bg-gradient-to-b  from-[#ffffff] to-transparent" />
        <div className="absolute bottom-0 left-0 z-50 h-2 w-full bg-gradient-to-b  from-transparent to-[#ffffff]" />
      </div>

      <div className="mt-4 h-12 w-full max-w-[474px] self-center">
        <Button
          type="submit"
          enabled={!isLoadingUpdateLink}
          text="Salvar"
          isLoading={isLoadingUpdateLink}
        />
      </div>
    </Form>
  )
}
