import { type ReactElement } from 'react'

import { ModalMobile } from './ModalMobile'
import { ModalDesktop } from './ModalDesktop'

interface ModalProps {
  closeModal?: () => void
  children: ReactElement
  disableTouchToClose?: boolean
  isParentClosing?: boolean
  isError?: boolean
  errorMessage?: string
  hasRoundedBorder?: boolean
  isMobile: boolean
  // isParentClosing is responsible for closing the Modal internally before the parent component unmounts it
}

export function Modal(props: ModalProps): ReactElement {
  if (props.isMobile) return <ModalMobile {...props} />
  return <ModalDesktop {...props} />
}
