import { type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import { teamStore } from '@/store/team'

interface EventCardProps {
  imageKey: string
  name: string
  alias: string
}

export function EventCard({
  imageKey,
  name,
  alias,
}: EventCardProps): ReactElement {
  const { setEvent } = teamStore()

  const navigate = useNavigate()

  return (
    <div
      key={imageKey}
      onClick={() => {
        navigate(`event/${alias}`)
        setEvent({ imageKey, name, alias })
      }}
      className="flex w-full break-inside-avoid-column items-center gap-4 rounded-md border border-[#1e1e1e] bg-[#1e1e1e] p-2 transition-colors duration-100 ease-in-out desktop:hover:cursor-pointer desktop:hover:border-primary-main desktop:hover:bg-dark-black "
    >
      <img
        src={`${import.meta.env.VITE_S3 as string}/${imageKey}`}
        alt="Evento"
        className="aspect-square w-16 rounded-[5px]"
      />
      <span className="text-lg font-bold">{name}</span>
    </div>
  )
}
