import { useState, type ReactElement } from 'react'

import { errorStore } from '@/store/error'
import { linkStore, useAllLinks } from '@/store/link'
import { useCurrentEvent } from '@/store/eventMenu'

import { useCreateManyLinks } from '@/hooks/Link'

import { Button } from '@/components/Global/Button'
import { LinkMultipleCodesInput } from '../../LinkMultipleCodesInput'

import { type CreateManyLinks } from '@/types/Links'

export function LinkUTMFormCreate(): ReactElement {
  const [newCodes, setNewCodes] = useState<string[]>([])
  const { createManyLinks, isLoading: isLoadingCreateLink } =
    useCreateManyLinks()

  const { allLinks } = useAllLinks()
  const { currentEvent } = useCurrentEvent()
  const { showError } = errorStore()

  const { addLink, closeLinkModal } = linkStore()

  async function handleCreateUTMLink(): Promise<void> {
    const body: CreateManyLinks = {
      eventId: currentEvent!.id,
      codes: newCodes,
      linkType: 'Track',
    }

    const response = await createManyLinks(body)

    if (!response.status) {
      let errorMessage = ''
      if (newCodes.length === 1)
        errorMessage = 'Não foi possível criar o link. Tente novamente.'
      else errorMessage = 'Não foi possível criar os links. Tente novamente.'

      showError(errorMessage)
      return
    }

    if (response.links !== undefined) {
      response.links.forEach((link) => {
        addLink(link)
      })
    }
    closeLinkModal()
  }

  return (
    <div className="flex w-full flex-col gap-4 px-4">
      <span className="text-3xl font-bold">Criação de Links UTM</span>
      <LinkMultipleCodesInput
        placeholder="Ex.: Aniversariante_Luis, Aniversariante_Maria"
        existingCodes={allLinks.map((link) => link.code)}
        newCodes={newCodes}
        setNewCodes={setNewCodes}
      />
      <div className="h-12 w-full max-w-[66%] self-center">
        <Button
          enabled={!isLoadingCreateLink && newCodes.length > 0}
          text="Criar"
          isLoading={isLoadingCreateLink}
          onClick={handleCreateUTMLink}
        />
      </div>
    </div>
  )
}
