import { useState, useEffect } from 'react'
import useSWR from 'swr'

import { fetcher } from '@/services/axios'

import { type IUser } from '@/types/CoHosts'

export const useGetCoHosts = (
  alias: string,
): {
  coHosts: IUser[] | undefined
  isLoading: boolean
  refresh: () => void
} => {
  const [isLoading, setIsLoading] = useState(true)
  const shouldFetch = alias !== undefined
  const { data, error, mutate } = useSWR<IUser[], string>(
    shouldFetch && `/admin/events/${alias}/hosts/`,
    fetcher,
  )

  useEffect(() => {
    setIsLoading(!(data !== undefined || error !== undefined))
  }, [data, error])

  function refresh(): void {
    void mutate()
  }

  return {
    coHosts: data,
    isLoading,
    refresh,
  }
}
