import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

import { type IPermissions, type IUser, type IEvent } from '@/types/Team'

interface ISelectedEvent {
  id: number
  imageKey: string
  alias: string
}

interface TeamProps {
  user: IUser | undefined
  setUser: (u: IUser) => void
  event: IEvent | undefined
  setEvent: (e: IEvent) => void
  permissions: IPermissions
  setPermissions: (p: IPermissions) => void
  selectedEvents: ISelectedEvent[]
  setSelectedEvents: (e: ISelectedEvent[]) => void
  resetPermissions: () => void
}

export const teamStore = create<TeamProps>()(
  devtools(
    persist(
      (set) => ({
        user: undefined,
        setUser: (u: IUser) => {
          set(() => ({ user: u }))
        },
        event: undefined,
        setEvent: (e: IEvent) => {
          set(() => ({ event: e }))
        },
        permissions: {
          event: {
            finance: '',
            analytics: '',
            list: '',
            tickets: '',
            grant: '',
            link: '',
            validation: false,
            edit: false,
          },
          organization: {
            finance: '',
            analytics: '',
            access: '',
            communicationCampaign: '',
            eventCreation: false,
            settings: false,
          },
        },
        setPermissions: (p: IPermissions) => {
          set(() => ({ permissions: p }))
        },
        resetPermissions: () => {
          set(() => ({
            permissions: {
              event: {
                finance: '',
                analytics: '',
                list: '',
                tickets: '',
                grant: '',
                link: '',
                validation: false,
                edit: false,
              },
              organization: {
                finance: '',
                analytics: '',
                access: '',
                communicationCampaign: '',
                eventCreation: false,
                settings: false,
              },
            },
          }))
        },
        selectedEvents: [],
        setSelectedEvents: (e: ISelectedEvent[]) => {
          set(() => ({ selectedEvents: e }))
        },
      }),
      {
        name: 'add-team-role-storage',
      },
    ),
  ),
)
