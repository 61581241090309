import { useEffect, useState, type ReactElement, useRef } from 'react'

import { TicketCounter } from '@/components/Global/TicketCounter'

import { useGetLinksByEventAlias } from '@/hooks/Link'

import { type ILink } from '@/types/Links'

import { numberToPercent, numberToReais } from '@/utils/formatNumber'
import { isTruthy } from '@/utils/validation'

import { SlArrowDown } from 'react-icons/sl'

interface AnalyticsLinksCardProps {
  alias: string
}

export function AnalyticsLinksCard({
  alias,
}: AnalyticsLinksCardProps): ReactElement {
  const [firstLoad, setFirstLoad] = useState(true)
  const [isShort, setIsShort] = useState(false)
  const [showArrow, setShowArrow] = useState(false)

  const contentRef = useRef<HTMLInputElement>(null)

  const setMaxHeight = (): void => {
    if (contentRef.current != null) {
      const contentElement = contentRef.current
      const contentHeight = contentElement.scrollHeight
      contentElement.style.maxHeight = isShort ? '555px' : `${contentHeight}px`
    }
  }

  const { getLinksByEventAliasResponse: linksData } =
    useGetLinksByEventAlias(alias)

  function returnLabelTypeOfLink(link: ILink): string | undefined {
    if (isTruthy(link.discount)) {
      if (isTruthy(link.discount!.amount)) {
        return `Desconto de ${numberToReais(link.discount!.amount, 2)}`
      } else {
        return `Desconto de ${numberToPercent(
          link.discount!.percentage * 100,
          2,
        )}`
      }
    } else if (isTruthy(link.ticketSpecs)) {
      return `Liberação de ingressos`
    } else {
      return 'UTM'
    }
  }

  useEffect(() => {
    if (isTruthy(linksData) && firstLoad) {
      setFirstLoad(false)
    }
  }, [linksData])

  useEffect(() => {
    setMaxHeight()
  }, [isShort])

  useEffect(() => {
    setMaxHeight()
    if (linksData !== undefined && linksData?.length > 14) {
      setShowArrow(true)
      setIsShort(true)
    } else {
      setShowArrow(false)
    }
  }, [linksData])

  if (firstLoad)
    return (
      <div className="h-[168px] w-full animate-pulse rounded-lg bg-dark-black" />
    )
  if (!isTruthy(linksData) || linksData?.length === 0) return <></>
  return (
    <div className="flex w-full flex-col justify-between gap-4 rounded-lg bg-dark-black p-4">
      <header>
        <span className="text-xl font-black leading-4">Links</span>
      </header>
      <main className={`relative ${showArrow && 'pb-4'}`}>
        <div
          className="flex flex-wrap gap-2 overflow-hidden transition-[max-height] duration-500 ease-in-out"
          ref={contentRef}
        >
          {linksData !== undefined ? (
            linksData
              .sort((a, b) => b.usage - a.usage)
              .map((link, index) => {
                return (
                  <div
                    className="flex w-[49%] items-center justify-between"
                    key={index}
                  >
                    <div className="flex items-center gap-4">
                      <TicketCounter value={link.usage} direction={undefined} />
                      <div className="flex flex-col">
                        <span className="text-sm font-bold">{link.code}</span>
                        <span className="text-[0.625rem] font-medium text-[#a3a3a3]">
                          {returnLabelTypeOfLink(link)}
                        </span>
                      </div>
                    </div>
                  </div>
                )
              })
          ) : (
            <></>
          )}
        </div>
        {linksData !== undefined && linksData?.length > 14 && (
          <>
            <button
              onClick={() => {
                setIsShort(!isShort)
              }}
              className="absolute -bottom-2 z-20 flex w-full items-center justify-center"
            >
              <SlArrowDown
                color="#fff"
                size={20}
                className={
                  'transition-transform duration-500 ease-in-out ' +
                  (isShort ? '' : '-rotate-180')
                }
              />
            </button>
            <div
              className={
                'absolute bottom-0 z-10 h-32 w-full bg-gradient-to-b from-transparent to-dark-black transition-opacity duration-500 ease-linear ' +
                (isShort ? 'opacity-100' : ' opacity-0')
              }
            />
          </>
        )}
      </main>
    </div>
  )
}
