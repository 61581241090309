import { type ReactElement } from 'react'
import { LuMapPin } from 'react-icons/lu'

import { CopyIcon } from '@/components/Icons/CopyIcon'

import {
  type ICreateEventStoreState,
  type IUpdateEventStoreState,
} from '@/types/CreateUpdateEvent'

import { isTruthy } from '@/utils/validation'

interface CreateEventLocationInfoCardProps {
  setSelectLocationModalIsOpen: () => void
  formStates: ICreateEventStoreState | IUpdateEventStoreState
  resetLocation: () => void
}

export function CreateEventLocationInfoCard({
  setSelectLocationModalIsOpen,
  formStates,
  resetLocation,
}: CreateEventLocationInfoCardProps): ReactElement {
  return (
    <div className="relative">
      <div
        className="flex w-full cursor-pointer items-center justify-between gap-4 rounded-lg bg-dark-black p-4"
        onClick={() => {
          setSelectLocationModalIsOpen()
        }}
      >
        <div className="flex flex-col">
          <span className="text-sm font-bold text-primary-main">
            {formStates.location?.name ?? 'Escolha o local do evento'}
          </span>
          <span className="text-xs text-[#a3a3a3]">
            {formStates.location?.address ?? 'Digite o endereço do evento'}
          </span>
        </div>
        <div className="flex gap-1">
          <div className="flex size-8 items-center justify-center rounded-full bg-white">
            <CopyIcon strokeColor="#181818" />
          </div>
          <div className="flex size-8 items-center justify-center rounded-full bg-white">
            <LuMapPin className="text-dark-black" />
          </div>
        </div>
      </div>
      {isTruthy(formStates.location?.name) &&
        isTruthy(formStates.location?.address) && (
          <button
            className="absolute -right-1 -top-1 z-10 flex size-5 rotate-45 items-center justify-center rounded-full bg-white"
            onClick={(e) => {
              e.preventDefault()
              resetLocation()
            }}
          >
            <span className="margin-auto text-2xl font-light text-black">
              +
            </span>
          </button>
        )}
    </div>
  )
}
