import { useState } from 'react'
import { type AxiosError } from 'axios'
import { api } from '@/services/axios'

import { type IVerifyUsernameReturn } from '@/types/Auth/useSignup'

export const usePostVerifyUsername = (): {
  verifyUsername: (username: string) => Promise<IVerifyUsernameReturn>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function verifyUsername(
    username: string,
  ): Promise<IVerifyUsernameReturn> {
    setIsLoading(true)

    const reqBody = {
      username,
    }

    try {
      await api.post('users/username/availability', reqBody)
      setIsLoading(false)
      return {
        status: 200,
        isAvailable: true,
      }
    } catch (err) {
      const error = err as AxiosError
      setIsLoading(false)
      return {
        status: error.status ?? 0,
        isAvailable: false,
      }
    }
  }

  return { verifyUsername, isLoading }
}
