import { useRef, useEffect, useState, type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'
import { motion, useAnimation } from 'framer-motion'

import { errorStore } from '@/store/error'

import { IoIosAddCircleOutline, IoIosCloseCircle } from 'react-icons/io'

interface LinkMultipleCodesInputProps {
  placeholder: string
  existingCodes: string[]
  newCodes: string[]
  setNewCodes: (codes: string[]) => void
}

export function LinkMultipleCodesInput({
  placeholder,
  existingCodes,
  newCodes,
  setNewCodes,
}: LinkMultipleCodesInputProps): ReactElement {
  const [inputValue, setInputValue] = useState<string>('')
  const canAdd = inputValue.length > 0
  const [codesContainerHeight, setCodesContainerHeight] = useState<
    number | string
  >(0)
  const codesContainerRef = useRef<HTMLDivElement>(null)

  const controls = useAnimation()

  const { showError } = errorStore()

  function handleAddCodes(): void {
    if (inputValue === '') return

    let tokens: string[] = []
    if (inputValue.includes(',')) tokens = inputValue.split(',')
    else tokens = inputValue.split('\n')

    const trimmedTokens = tokens.map((token) => token.trim())

    const trimmedTokensNoDuplicates = Array.from(new Set(trimmedTokens))

    for (const token of trimmedTokensNoDuplicates) {
      if (existingCodes.includes(token)) {
        showError(`Código "${token}" em uso.`)
        return
      }

      if (newCodes.includes(token)) {
        showError(`Código "${token}" duplicado na lista.`)
        return
      }

      if (!/^[a-zA-Z0-9-_]+$/.test(token)) {
        showError(`Código "${token}" com espaço ou caracteres especiais.`)
        return
      }
    }

    setInputValue('')
    setNewCodes([...newCodes, ...trimmedTokensNoDuplicates])
  }

  function handleRemoveCode(code: string): void {
    const tempNewCodes = newCodes.filter((c) => c !== code)
    setNewCodes(tempNewCodes)
  }

  async function handleShake(): Promise<void> {
    if (inputValue === '') {
      controls.stop()
      controls.set('reset')
    } else {
      await controls.start('start')
    }
  }

  useEffect(() => {
    if (codesContainerRef?.current !== null) {
      setCodesContainerHeight(codesContainerRef.current.scrollHeight)
    }
  }, [newCodes])

  useEffect(() => {
    void handleShake()
  }, [inputValue])

  return (
    <div className="w-full">
      <div className="flex w-full items-center gap-2">
        <textarea
          className="w-full resize-none rounded-md border-2 border-dark-light-gray p-2 outline-none transition-colors duration-100 ease-in-out placeholder:font-medium placeholder:text-text-terciary focus:border-primary-main"
          placeholder={placeholder}
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value)
          }}
        />
        <motion.div
          variants={{
            start: {
              rotate: [-7, 8, -3, 4, 0],
              scale: [1, 1.05, 1.1, 1.05, 1],
              transition: {
                repeat: Infinity,
                duration: 0.75,
              },
            },
            reset: {
              rotate: 0,
            },
          }}
          animate={controls}
          key="add"
        >
          <IoIosAddCircleOutline
            size={32}
            className={twMerge(
              'transition-colors duration-100 ease-in-out hover:cursor-pointer',
              canAdd ? 'text-black' : 'text-text-terciary',
            )}
            onClick={handleAddCodes}
          />
        </motion.div>
      </div>
      <motion.div
        className="relative h-fit max-h-28 w-full overflow-hidden"
        animate={{ height: codesContainerHeight }}
        transition={{ ease: 'easeInOut', duration: 0.2 }}
      >
        <div className="absolute top-0 h-2 w-full bg-gradient-to-t from-transparent to-white" />
        <div className="absolute bottom-0 h-2 w-full bg-gradient-to-b from-transparent to-white" />
        <div
          className="flex h-fit flex-wrap gap-2 overflow-x-scroll py-2"
          ref={codesContainerRef}
        >
          {newCodes.map((code, index) => {
            return (
              <div
                key={index}
                className="flex h-fit items-center gap-2 rounded-full bg-dark-light-gray py-1 pl-4 pr-2"
              >
                <span>{code}</span>
                <IoIosCloseCircle
                  size={20}
                  className="hover:cursor-pointer"
                  onClick={() => {
                    handleRemoveCode(code)
                  }}
                />
              </div>
            )
          })}
        </div>
      </motion.div>
    </div>
  )
}
