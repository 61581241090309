import { type ReactElement } from 'react'
import { Workbook } from 'exceljs'
import { useParams } from 'react-router-dom'

import { IoCloudDownloadOutline } from 'react-icons/io5'

import { linkStore } from '@/store/link'
import { useCurrentEvent } from '@/store/eventMenu'
import { numberToReais, numberToPercent } from '@/utils/formatNumber'

export function LinkDesktopDownloadButton(): ReactElement {
  const { allLinks } = linkStore()
  const { currentEvent } = useCurrentEvent()

  const { alias } = useParams()

  const handleDownload = async (): Promise<void> => {
    const workbook: Workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Links')

    // Add data to the worksheet
    worksheet.addRow([
      'Link',
      'Tipo',
      'Vendas',
      'Link de venda',
      'Link de rastreio',
    ])
    for (const link of allLinks) {
      const linkType =
        link.discount === undefined && link.ticketSpecs === undefined
          ? 'Track'
          : link.discount !== undefined
            ? link.discount.amount !== undefined
              ? 'Amount'
              : 'Percentage'
            : 'TicketSpecPromo'

      const linkLabel =
        linkType === 'Track'
          ? 'UTM'
          : linkType === 'Amount'
            ? `Desconto de `
            : linkType === 'Percentage'
              ? `Desconto de `
              : 'Liberação de ingressos'

      const linkDiscount =
        linkType === 'Amount'
          ? numberToReais(link.discount!.amount, 2)
          : linkType === 'Percentage'
            ? numberToPercent(link.discount!.percentage * 100, 2)
            : ''

      const sellLink = `${import.meta.env.VITE_KAYA_URL as string}/${alias}?link=${link?.code}`
      const trackLink = `${import.meta.env.VITE_KAYA_URL as string}/links/${alias}/${link?.hash}`

      worksheet.addRow([
        link.code,
        `${linkLabel} ${linkDiscount}`,
        link.usage,
        sellLink,
        trackLink,
      ])
    }

    worksheet.columns.forEach(function (column) {
      let maxLength = 0
      if (column.eachCell === undefined) return
      column.eachCell({ includeEmpty: true }, function (cell) {
        const columnLength =
          cell.value !== null &&
          cell.value !== undefined &&
          typeof cell.value !== 'object'
            ? cell.value.toString().length
            : 10
        if (columnLength > maxLength) {
          maxLength = columnLength
        }
      })
      column.width = maxLength < 10 ? 10 : maxLength
    })

    // Generate buffer from workbook
    const buffer: Buffer = (await workbook.xlsx.writeBuffer()) as Buffer

    // Create a blob from the buffer
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    // Create URL for the blob
    const url = window.URL.createObjectURL(blob)

    // Create a temporary anchor element to trigger the download
    const a = document.createElement('a')
    a.href = url
    a.download = `Links ${currentEvent?.name}.xlsx`
    a.click()

    // Clean up
    window.URL.revokeObjectURL(url)
  }
  return (
    <button
      onClick={handleDownload}
      className="flex items-center gap-4 rounded-md bg-dark-black py-0.5 pl-2 pr-3"
    >
      <span className="text-base font-bold">Exportar</span>
      <IoCloudDownloadOutline size={20} />
    </button>
  )
}
