import { useState } from 'react'
import { api } from '@/services/axios'

export const useGetGenerateReport = (
  alias: string,
): {
  getGenerateReport: () => Promise<boolean>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function getGenerateReport(): Promise<boolean> {
    setIsLoading(true)
    const shouldFetch = alias !== undefined
    if (!shouldFetch) {
      setIsLoading(false)
      return false
    }

    try {
      await api.get(`/admin/events/${alias}/report`)
      setIsLoading(false)
      return true
    } catch (err) {
      setIsLoading(false)
      return false
    }
  }

  return {
    getGenerateReport,
    isLoading,
  }
}
