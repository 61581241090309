import { type Dispatch, type SetStateAction, type ReactElement } from 'react'

import { DeleteIcon } from '@/components/Icons/DeleteIcon'

import DefaultSticker from '@/assets/defaultSticker.svg'

import {
  type ICreateEventStoreState,
  type IUpdateEventStoreState,
} from '@/types/CreateUpdateEvent'
import { isTruthy } from '@/utils/validation'

interface CreateEventStickerProps {
  handleOnStickerSubmit: (e: React.ChangeEvent<HTMLInputElement>) => void
  formStates: ICreateEventStoreState | IUpdateEventStoreState
  updateAnyState:
    | ((update: Partial<ICreateEventStoreState>) => void)
    | ((update: Partial<IUpdateEventStoreState>) => void)
  openDeleteItemModal: () => void
  setExecuteDeleteFunction: Dispatch<SetStateAction<() => void>>
}

export function CreateEventSticker({
  handleOnStickerSubmit,
  formStates,
  updateAnyState,
  openDeleteItemModal,
  setExecuteDeleteFunction,
}: CreateEventStickerProps): ReactElement {
  function handleDeleteSticker(): void {
    updateAnyState({ sticker: null })
  }
  return (
    <div className="flex w-full flex-col gap-4 px-4">
      <div className="flex flex-col">
        <span className="text-2xl font-bold">Sticker</span>
        <span className="text-xs text-[#a3a3a3]">
          O seu sticker é o jeito que os seus convidados vão lembrar do seu
          evento.
        </span>
      </div>
      <div className="flex w-full justify-center">
        <div className="relative h-36">
          <label
            htmlFor="inputStickers"
            className="flex h-36 cursor-pointer items-center justify-center gap-2.5 rounded-lg"
          >
            <img
              src={
                isTruthy(formStates.sticker)
                  ? formStates.sticker!.isLocalImage
                    ? formStates.sticker!.url
                    : `${import.meta.env.VITE_S3 as string}/${
                        formStates.sticker!.url
                      }
               `
                  : DefaultSticker
              }
              alt="sticker"
              className="h-36"
            />

            <input
              id="inputStickers"
              type="file"
              className="hidden"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleOnStickerSubmit(e)
              }}
              onClick={(e: React.FormEvent<HTMLInputElement>) => {
                const target = e.target as HTMLButtonElement
                target.value = ''
              }}
              accept="image/*"
            />
          </label>
          {isTruthy(formStates.sticker) && (
            <DeleteIcon
              className="absolute -right-3 -top-3"
              onClick={() => {
                openDeleteItemModal()
                setExecuteDeleteFunction(() => () => {
                  handleDeleteSticker()
                })
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}
