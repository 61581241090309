import { type IGrant } from '@/types/Grant'
import { create } from 'zustand'

interface GrantProps {
  currentUser: IGrant | null
  allUsers: IGrant[] | null
  emailOrPhoneList: string[]
  setCurrentUser: (currentUser: IGrant) => void
  setAllUsers: (allUsers: IGrant[]) => void
  removeUser: (userId: number) => void
  addEmailOrPhoneToList: (data: string) => void
  removeEmailOrPhoneToList: (data: string) => void
  resetEmailOrPhoneList: () => void
  reset: () => void
}

export const grantStore = create<GrantProps>((set, get) => {
  return {
    currentUser: null,
    allUsers: null,
    emailOrPhoneList: [],
    setCurrentUser: (currentUser: IGrant) => {
      set({ currentUser })
    },
    setAllUsers: (allUsers: IGrant[]) => {
      set({ allUsers })
    },
    reset: () => {
      set({ allUsers: [] })
    },
    removeUser: (userId: number) => {
      const { allUsers } = get()
      const newUserList = allUsers!.filter((user) => user.id !== userId)
      set({ allUsers: newUserList })
    },
    addEmailOrPhoneToList: (data: string) => {
      const { emailOrPhoneList } = get()
      const newList = [...emailOrPhoneList, data]
      set({ emailOrPhoneList: newList })
    },
    removeEmailOrPhoneToList: (data: string) => {
      const { emailOrPhoneList } = get()
      const newList = emailOrPhoneList.filter(
        (emailOrPhone) => emailOrPhone !== data,
      )
      set({ emailOrPhoneList: newList })
    },
    resetEmailOrPhoneList: () => {
      set({ emailOrPhoneList: [] })
    },
  }
})

export const useCurrentUserWithGrant = (): {
  currentUser: IGrant | null
} => {
  return grantStore((state) => {
    const { currentUser } = state

    return { currentUser }
  })
}

export const useAllUsersWithGrant = (): {
  allUsers: IGrant[] | null
} => {
  return grantStore((state) => {
    const { allUsers } = state

    return { allUsers }
  })
}

export const useAllEmailOrPhoneInList = (): {
  emailOrPhoneList: string[]
} => {
  return grantStore((state) => {
    const { emailOrPhoneList } = state

    return { emailOrPhoneList }
  })
}
