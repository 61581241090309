import { useState, type ReactElement } from 'react'
import { type Crop } from 'react-image-crop'

import { cropImageStore } from '@/store/cropImage'
import { useCurrentError } from '@/store/error'
import { deviceStore } from '@/store/device'

import { Button } from '@/components/Global/Button'
import { CropImage } from '@/components/Global/CropImage'
import { Modal } from '@/components/Global/Modal'

import {
  type ICreateEventStoreState,
  type IUpdateEventStoreState,
} from '@/types/CreateUpdateEvent'

interface CreateEventCropImageModalProps {
  closeModal: () => void
  handleModalClose: () => void
  isParentClosing?: boolean
  isLoading?: boolean
  formStates: ICreateEventStoreState | IUpdateEventStoreState
  updateAnyState:
    | ((update: Partial<ICreateEventStoreState>) => void)
    | ((update: Partial<IUpdateEventStoreState>) => void)
}

export function CreateEventCropImageModal({
  closeModal,
  isParentClosing,
  handleModalClose,
  formStates,
  updateAnyState,
}: CreateEventCropImageModalProps): ReactElement {
  const { isError, errorMessage } = useCurrentError()
  const { isMobile } = deviceStore()

  const {
    currentImage,
    isCircularCrop,
    minHeight,
    minWidth,
    currentOwnerOfImage,
    aspectRatio,
  } = cropImageStore()

  const [crop, setCrop] = useState<Crop>()

  function updateCorrectImageType(
    croppedImageUrl: string,
    croppedImage: File,
  ): void {
    switch (currentOwnerOfImage) {
      case 'backgroundArt':
        updateAnyState({
          backgroundArtImage: {
            key: croppedImage,
            url: croppedImageUrl,
            isLocalImage: true,
          },
        })
        break

      case 'artistImage': {
        updateAnyState({
          currentArtistImage: croppedImage,
          currentArtistImageUrl: croppedImageUrl,
        })

        break
      }

      case 'partners': {
        const currentArrayOfPartners = formStates.partners! ?? []
        if (currentArrayOfPartners.length < 1) {
          currentArrayOfPartners.push({
            key: croppedImage,
            url: croppedImageUrl,
            isLocalImage: true,
          })
          updateAnyState({
            partners: currentArrayOfPartners,
          })
        } else {
          currentArrayOfPartners.push({
            key: croppedImage,
            url: croppedImageUrl,
            isLocalImage: true,
          })
          updateAnyState({
            partners: currentArrayOfPartners,
          })
        }

        break
      }

      case 'sticker':
        updateAnyState({
          sticker: {
            key: croppedImage,
            url: croppedImageUrl,
            isLocalImage: true,
          },
        })
        break

      case 'titleImage':
        updateAnyState({
          titleImage: {
            key: croppedImage,
            url: croppedImageUrl,
            isLocalImage: true,
          },
        })
        break
    }
  }

  function handleSubmit(uploadedImageUrl: string): void {
    // Crop the image and save it somewhere
    if (crop !== undefined) {
      const cropped = crop.width !== undefined && crop.height !== undefined
      if (cropped) {
        const image = new Image()
        image.src = uploadedImageUrl
        image.onload = () => {
          const canvas = document.createElement('canvas')
          canvas.width = (crop.width / 100) * image.width
          canvas.height = (crop.height / 100) * image.height
          const ctx = canvas.getContext('2d')

          ctx?.drawImage(
            image,
            (crop.x / 100) * image.width,
            (crop.y / 100) * image.height,
            (crop.width / 100) * image.width,
            (crop.height / 100) * image.height,
            0,
            0,
            (crop.width / 100) * image.width,
            (crop.height / 100) * image.height,
          )

          const croppedImageUrl = canvas.toDataURL('image/png')
          canvas.toBlob((blob) => {
            const croppedImage = new File([blob!], 'croppedImage.png', {
              type: 'image/png',
            })
            updateCorrectImageType(croppedImageUrl, croppedImage)
          })
          // const file =

          // setUploadedImageUrl(croppedImage)
          handleModalClose()
        }
      }
    }
  }

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      isError={isError}
      errorMessage={errorMessage}
      isMobile={isMobile}
    >
      <div className="flex h-fit w-full flex-col items-center gap-4 p-4 text-black">
        <div className="flex w-full flex-col items-center justify-center">
          <div className="mt-4 flex size-full flex-col items-center justify-center gap-4">
            <CropImage
              uploadedImageUrl={currentImage!}
              setCrop={(crop: Crop): void => {
                setCrop(crop)
              }}
              crop={crop}
              isCircularCrop={isCircularCrop}
              minHeight={minHeight}
              minWidth={minWidth}
              aspectRatio={aspectRatio ?? undefined}
            />
            <div className="mt-4 h-12 w-full">
              <Button
                text="Salvar"
                enabled
                onClick={() => {
                  handleSubmit(currentImage!)
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
