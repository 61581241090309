import { useState, useEffect } from 'react'
import useSWR from 'swr'

import { fetcher } from '@/services/axios'

import { type IAverageTicket } from '@/types/Analytics'

export const useGetAverageTicketPrice = (
  alias: string,
): {
  averageTicketPricesData: IAverageTicket[] | undefined
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(true)
  const shouldFetch = alias !== undefined
  const { data, error } = useSWR<IAverageTicket[], string>(
    shouldFetch && `/admin/analytics/${alias}/averageTicket`,
    fetcher,
  )

  useEffect(() => {
    setIsLoading(!(data !== undefined || error !== undefined))
  }, [data, error])

  return { averageTicketPricesData: data, isLoading }
}
