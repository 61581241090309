import { type ReactElement } from 'react'

import { numberToReais } from '@/utils/formatNumber'

interface BalanceCardProps {
  revenue: number
  withdrawal: number
  cashback: number
}

export function BalanceCard({
  revenue,
  withdrawal,
  cashback,
}: BalanceCardProps): ReactElement {
  const balance = revenue * (1 + cashback) - withdrawal

  return (
    <div className="flex w-full flex-wrap gap-x-8 gap-y-2 rounded-lg bg-dark-black p-4">
      <div className="flex flex-col">
        <span className="text-xs text-text-secondary">Saldo</span>
        <span className="text-xl font-black text-white">
          {numberToReais(balance, 2)}
        </span>
      </div>
      <div className="flex flex-col">
        <span className="text-xs text-text-secondary">Saques</span>
        <span className="text-xl font-black text-white">
          {numberToReais(withdrawal, 2)}
        </span>
      </div>
    </div>
  )
}
