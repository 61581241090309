import React, { type ReactElement, useState, useEffect } from 'react'
import { Button } from '@/components/Global/Button'
import { PieChart } from '@/components/Chart/PieChart'
import { useNavigate, useParams, useLocation } from 'react-router-dom'

import { cropImageStore } from '@/store/cropImage'
import {
  createEventStore,
  useAllStatesOfCreateEvent,
} from '@/store/createEvent'
import {
  updateEventStore,
  useAllStatesOfUpdateEvent,
} from '@/store/updateEvent'

import { CreateEventSelectDateModal } from '@/components/CreateEvent/CreateEventSelectDateModal'
import { CreateEventSelectLocationModal } from '@/components/CreateEvent/CreateEventSelectLocationModal'
import { CreateEventSelectCreateArtistModal } from '@/components/CreateEvent/CreateEventCreateArtistModal'
import { CreateEventCreateFAQsModal } from '@/components/CreateEvent/CreateEventCreateFAQsModal'
import { CreateEventUploadBackgroundArtImage } from '@/components/CreateEvent/CreateEventUploadBackgroundArtImage'
import { CreateEventBrief } from '@/components/CreateEvent/CreateEventBrief'
import { CreateEventCropImageModal } from '@/components/CreateEvent/CreateEventCropImageModal'
import { CreateEventDescription } from '@/components/CreateEvent/CreateEventDescription'
import { CreateEventLineupInput } from '@/components/CreateEvent/CreateEventLineupInput'
import { CreateEventSpotifyURLInput } from '@/components/CreateEvent/CreateEventSpotifyURLInput'
import { CreateEventDateInfoCard } from '@/components/CreateEvent/CreateEventDateInfoCard'
import { CreateEventLocationInfoCard } from '@/components/CreateEvent/CreateEventLocationInfoCard'
import { CreateEventTermsCard } from '@/components/CreateEvent/CreateEventTermsCard'
import { CreateEventFAQCard } from '@/components/CreateEvent/CreateEventFAQCard'
import { CreateEventPartners } from '@/components/CreateEvent/CreateEventPartners'
import { CreateEventSticker } from '@/components/CreateEvent/CreateEventSticker'
import { Loading } from '@/components/Global/Loading/Loading'
import { CreateEventDeleteItemModal } from '@/components/CreateEvent/CreateEventDeleteItemModal'
import { CreateEventTitleImageInfo } from '@/components/CreateEvent/CreateEventTitleImageInfo'
import { CreateEventSelectType } from '@/components/CreateEvent/CreateEventSelectType'
import { CreateEventConfirmResetModal } from '@/components/CreateEvent/CreateEventConfirmResetModal'

import {
  type LineUp,
  type ICreateEventStoreState,
  type IUpdateEventStoreState,
  type FAQs,
  type Partners,
} from '@/types/CreateUpdateEvent'

import { useGetEventDataByEventAlias } from '@/hooks/CreateEvent/api'

import { isTruthy } from '@/utils/validation'

interface LocationState {
  resetStore: boolean
  fetchData: boolean
}

export function CreateEvent(): ReactElement {
  const [selectDateModalIsOpen, setSelectDateModalIsOpen] = useState(false)
  const [selectLocationModalIsOpen, setSelectLocationModalIsOpen] =
    useState(false)
  const [createArtistModalIsOpen, setCreateArtistModalIsOpen] = useState(false)
  const [createFAQsModalIsOpen, setCreateFAQsModalIsOpen] = useState(false)
  const [cropImageModalIsOpen, setCropImageModalIsOpen] = useState(false)
  const [isParentClosing, setIsParentClosing] = useState(false)
  const [showDeleteItemModal, setShowDeleteItemModal] = useState(false)
  const [showTitleImageInfoModal, setShowTitleImageInfoModal] = useState(false)
  const [showConfirmResetModal, setShowConfirmResetModal] = useState(false)
  const [executeDeleteFunction, setExecuteDeleteFunction] = useState<
    () => void
  >(() => {})

  const location = useLocation()

  if (location.state === null) {
    location.state = {
      resetStore: false,
      fetchData: false,
    }
  }
  const state = location.state as LocationState

  const [isLoading, setIsLoading] = useState(state.fetchData)

  let formStates: ICreateEventStoreState | IUpdateEventStoreState
  let getCompletePercentual
  let updateAnyState:
    | ((update: Partial<ICreateEventStoreState>) => void)
    | ((update: Partial<IUpdateEventStoreState>) => void)
  let resetCurrentLineup: () => void
  let deleteArtistOfLineUp: (artist: LineUp) => void
  let deleteFaq: (faq: FAQs) => void
  let deletePartner: (partner: Partners) => void
  let reset: () => void
  let deleteLocation: () => void

  const {
    getCompletePercentual: getCompletePercentualCreateEvent,
    updateAnyState: updateAnyStateCreateEvent,
    resetCurrentLineup: resetCurrentLineupCreateEvent,
    deleteArtistOfLineUp: deleteArtistOfLineUpCreateEvent,
    deleteFaq: deleteFaqCreateEvent,
    deletePartner: deletePartnerCreateEvent,
    reset: resetCreateEvent,
    deleteLocation: deleteLocationCreate,
  } = createEventStore()
  const {
    getCompletePercentual: getCompletePercentualUpdateEvent,
    updateAnyState: updateAnyStateUpdateEvent,
    resetCurrentLineup: resetCurrentLineupUpdateEvent,
    deleteArtistOfLineUp: deleteArtistOfLineUpUpdateEvent,
    deleteFaq: deleteFaqUpdateEvent,
    deletePartner: deletePartnerUpdateEvent,
    typeOperation,
    reset: resetUpdateEvent,
    deleteLocation: deleteLocationUpdate,
  } = updateEventStore()

  const formStatesCreateEvent = useAllStatesOfCreateEvent()
  const formStatesUpdateEvent = useAllStatesOfUpdateEvent()

  const { alias } = useParams()

  const { getEventDataByEventAlias, isLoading: isLoadingFetch } =
    useGetEventDataByEventAlias()

  if (typeOperation === 'Create') {
    formStates = formStatesCreateEvent
    getCompletePercentual = getCompletePercentualCreateEvent
    updateAnyState = updateAnyStateCreateEvent
    resetCurrentLineup = resetCurrentLineupCreateEvent
    deleteArtistOfLineUp = deleteArtistOfLineUpCreateEvent
    deleteFaq = deleteFaqCreateEvent
    deletePartner = deletePartnerCreateEvent
    reset = resetCreateEvent
    deleteLocation = deleteLocationCreate
    if (formStates.canReset) {
      reset()
    }
  } else {
    formStates = formStatesUpdateEvent
    getCompletePercentual = getCompletePercentualUpdateEvent
    updateAnyState = updateAnyStateUpdateEvent
    resetCurrentLineup = resetCurrentLineupUpdateEvent
    deleteArtistOfLineUp = deleteArtistOfLineUpUpdateEvent
    deleteFaq = deleteFaqUpdateEvent
    deletePartner = deletePartnerUpdateEvent
    deleteLocation = deleteLocationUpdate
    reset = resetUpdateEvent
  }

  async function handleGetEventDataByEventAlias(): Promise<void> {
    const response = await getEventDataByEventAlias(alias!)

    if (response.status && response.data !== undefined) {
      const eventData = response.data

      updateAnyState({
        ...eventData,
        alias,
        canFetchData: false,
      })
    }
  }

  const {
    setCurrentImage,
    setParamsOfCrop,
    setCurrentModalOfImage,
    currentOwnerOfImage,
  } = cropImageStore()

  const navigate = useNavigate()

  const [URLIsValid, setURLIsValid] = useState(false)

  function handleCreateArtistModalOnClose(): void {
    setIsParentClosing(false)
    setCropImageModalIsOpen(true)
  }

  function handleCropImageModalOnClose(): void {
    setIsParentClosing(false)
    if (currentOwnerOfImage === 'artistImage') {
      setCreateArtistModalIsOpen(true)
    }
  }

  function handlePartnerPhotoUpdate(
    e: React.ChangeEvent<HTMLInputElement>,
  ): void {
    e.preventDefault()

    if (e.target.files !== null) {
      const image = e.target.files[0]

      setCurrentImage(URL.createObjectURL(image))
      setCurrentModalOfImage('partners')
      setParamsOfCrop(false, 164, 100, 16 / 9)
      setCropImageModalIsOpen(true)
    }
  }

  function handleOnStickerSubmit(e: React.ChangeEvent<HTMLInputElement>): void {
    e.preventDefault()

    if (e.target.files !== null) {
      const image = e.target.files[0]

      const reader = new FileReader()

      reader.onloadend = () => {
        const imageUrl = reader.result?.toString()
        if (imageUrl !== undefined) {
          updateAnyState({
            sticker: {
              isLocalImage: true,
              key: image,
              url: imageUrl,
            },
          })
        } else {
          updateAnyState({
            sticker: null,
          })
        }
      }

      reader.readAsDataURL(image)
    }
  }

  function handlePreviewPageOnClick(): void {
    if (isTruthy(alias)) navigate(`/events/${alias}/preview`)
    else navigate(`/event-preview`)
  }

  useEffect(() => {
    if (state.fetchData) setIsLoading(isLoadingFetch)
  }, [isLoadingFetch])

  useEffect(() => {
    const state = location.state as LocationState
    if (typeOperation === 'Update' && state.fetchData) {
      void handleGetEventDataByEventAlias()
    }
  }, [])

  useEffect(() => {
    if (typeOperation === 'Create') {
      const state = location.state as LocationState
      if (state !== null && state.resetStore) {
        reset()
      }
    }
  }, [])

  if (isLoading) return <Loading hidden={!isLoading} />
  if (!isTruthy(alias) && formStates.type === null)
    return <CreateEventSelectType />

  return (
    <div className="size-full max-w-[500px]">
      <div className="relative flex w-full flex-col gap-6 bg-transparent pb-24">
        <div className="flex flex-col">
          <CreateEventUploadBackgroundArtImage
            formStates={formStates}
            updateAnyState={updateAnyState}
          />
          <div className="w-full  px-4">
            <CreateEventBrief
              setSelectDateModalIsOpen={setSelectDateModalIsOpen}
              setSelectLocationModalIsOpen={(state: boolean) => {
                setSelectLocationModalIsOpen(state)
              }}
              formStates={formStates}
              updateAnyState={updateAnyState}
              openDeleteItemModal={() => {
                setShowDeleteItemModal(true)
              }}
              setExecuteDeleteFunction={setExecuteDeleteFunction}
              openTitleImageInfoModal={() => {
                setShowTitleImageInfoModal(true)
              }}
              setStartCrop={() => {
                setCropImageModalIsOpen(true)
              }}
            />
          </div>
        </div>

        <CreateEventDescription
          formStates={formStates}
          updateAnyState={updateAnyState}
        />

        <div className="flex flex-col gap-2">
          <CreateEventLineupInput
            setCreateArtistModalIsOpen={() => {
              updateAnyState({
                currentArtistImage: null,
                currentArtistImageUrl: null,
                currentArtistName: null,
                canAddArtist: false,
              })
              setCreateArtistModalIsOpen(true)
            }}
            formStates={formStates}
            deleteArtistOfLineUp={deleteArtistOfLineUp}
            openDeleteItemModal={() => {
              setShowDeleteItemModal(true)
            }}
            setExecuteDeleteFunction={setExecuteDeleteFunction}
          />

          <CreateEventSpotifyURLInput
            formStates={formStates}
            updateAnyState={updateAnyState}
            URLIsValid={URLIsValid}
            setURLIsValid={setURLIsValid}
          />
        </div>

        <div className="flex  w-full flex-col gap-2 px-4">
          <span className="text-2xl font-bold">Info</span>

          <CreateEventDateInfoCard
            setSelectDateModalIsOpen={() => {
              setSelectDateModalIsOpen(true)
            }}
            formStates={formStates}
          />

          <CreateEventLocationInfoCard
            setSelectLocationModalIsOpen={() => {
              setSelectLocationModalIsOpen(true)
            }}
            formStates={formStates}
            resetLocation={deleteLocation}
          />

          <CreateEventTermsCard
            updateAnyState={updateAnyState}
            formStates={formStates}
          />

          <CreateEventFAQCard
            setCreateFAQsModalIsOpen={() => {
              setCreateFAQsModalIsOpen(true)
            }}
            formStates={formStates}
            deleteFaq={deleteFaq}
            openDeleteItemModal={() => {
              setShowDeleteItemModal(true)
            }}
            setExecuteDeleteFunction={setExecuteDeleteFunction}
          />
        </div>

        <CreateEventPartners
          handlePartnerPhotoUpdate={handlePartnerPhotoUpdate}
          formStates={formStates}
          deletePartner={deletePartner}
          openDeleteItemModal={() => {
            setShowDeleteItemModal(true)
          }}
          setExecuteDeleteFunction={setExecuteDeleteFunction}
        />

        <CreateEventSticker
          handleOnStickerSubmit={handleOnStickerSubmit}
          formStates={formStates}
          updateAnyState={updateAnyState}
          openDeleteItemModal={() => {
            setShowDeleteItemModal(true)
          }}
          setExecuteDeleteFunction={setExecuteDeleteFunction}
        />

        {typeOperation === 'Create' && (
          <div className="flex w-full items-center justify-center">
            <Button
              enabled={true}
              text="Limpar tudo"
              className="font-ligh w-fit bg-white px-4 py-2 text-xs"
              onClick={() => {
                setShowConfirmResetModal(true)
              }}
            />
          </div>
        )}

        {selectDateModalIsOpen && (
          <CreateEventSelectDateModal
            closeModal={() => {
              setSelectDateModalIsOpen(false)
            }}
            isParentClosing={isParentClosing}
            handleModalClose={() => {
              setIsParentClosing(true)
              setTimeout(setIsParentClosing, 400, false)
            }}
            formStates={formStates}
            updateAnyState={updateAnyState}
          />
        )}

        {selectLocationModalIsOpen && (
          <CreateEventSelectLocationModal
            closeModal={() => {
              setSelectLocationModalIsOpen(false)
            }}
            isParentClosing={isParentClosing}
            handleModalClose={() => {
              setIsParentClosing(true)
              setTimeout(setIsParentClosing, 400, false)
            }}
            formStates={formStates}
            updateAnyState={updateAnyState}
          />
        )}

        {createArtistModalIsOpen && (
          <CreateEventSelectCreateArtistModal
            closeModal={() => {
              setCreateArtistModalIsOpen(false)
            }}
            isParentClosing={isParentClosing}
            handleModalClose={() => {
              setIsParentClosing(true)
              setTimeout(handleCreateArtistModalOnClose, 400)
            }}
            handleSubmitData={() => {
              setIsParentClosing(true)
              setTimeout(setIsParentClosing, 400, false)
            }}
            formStates={formStates}
            updateAnyState={updateAnyState}
            resetCurrentLineup={resetCurrentLineup}
          />
        )}

        {createFAQsModalIsOpen && (
          <CreateEventCreateFAQsModal
            closeModal={() => {
              setCreateFAQsModalIsOpen(false)
            }}
            isParentClosing={isParentClosing}
            handleModalClose={() => {
              setIsParentClosing(true)
              setTimeout(setIsParentClosing, 400, false)
            }}
            formStates={formStates}
            updateAnyState={updateAnyState}
          />
        )}

        {cropImageModalIsOpen && (
          <CreateEventCropImageModal
            closeModal={() => {
              setCropImageModalIsOpen(false)
            }}
            isParentClosing={isParentClosing}
            handleModalClose={() => {
              setIsParentClosing(true)
              setTimeout(handleCropImageModalOnClose, 400)
            }}
            formStates={formStates}
            updateAnyState={updateAnyState}
          />
        )}
        {showDeleteItemModal && (
          <CreateEventDeleteItemModal
            executeDeleteFunction={executeDeleteFunction}
            isParentClosing={isParentClosing}
            handleModalClose={() => {
              setIsParentClosing(true)
              setTimeout(setIsParentClosing, 400, false)
            }}
            closeModal={() => {
              setShowDeleteItemModal(false)
            }}
          />
        )}
        {showTitleImageInfoModal && (
          <CreateEventTitleImageInfo
            isParentClosing={isParentClosing}
            handleModalClose={() => {
              setIsParentClosing(true)
              setTimeout(setIsParentClosing, 400, false)
              setTimeout(setShowTitleImageInfoModal, 400, false)
            }}
            closeModal={() => {
              setShowTitleImageInfoModal(false)
            }}
            setStartCrop={() => {
              setCropImageModalIsOpen(true)
            }}
            updateAnyState={updateAnyState}
          />
        )}
        {showConfirmResetModal && (
          <CreateEventConfirmResetModal
            closeModal={() => {
              setIsParentClosing(true)
              setTimeout(setIsParentClosing, 400, false)
              setTimeout(setShowConfirmResetModal, 400, false)
            }}
            isParentClosing={isParentClosing}
            resetForm={() => {
              reset()
            }}
          />
        )}
      </div>
      <div className="fixed -bottom-1 left-0 z-20 flex h-20 w-full  items-center justify-between bg-dark-black p-4 desktop:absolute">
        <div className="flex items-center">
          <div className="size-16">
            <PieChart
              progress={Math.round(getCompletePercentual() * 100)}
              progressColor={'#D5FF5C'}
              barColor={'#181818'}
              customStartAngle={90}
              isPercent={true}
            />
          </div>
          <span className="w-16 text-[0.625rem]">Preenchimento do evento</span>
        </div>
        <div className="h-12 w-44">
          <Button
            enabled={
              isTruthy(formStates.backgroundArtImage) &&
              isTruthy(formStates.eventName) &&
              isTruthy(formStates.startDate) &&
              isTruthy(formStates.endDate) &&
              URLIsValid
            }
            text="Ver preview"
            onClick={handlePreviewPageOnClick}
          />
        </div>
      </div>
    </div>
  )
}
