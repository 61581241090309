import { type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

import { numberToReais } from '@/utils/formatNumber'
import { isTruthy } from '@/utils/validation'

import { type ITicketSpec } from '@/types/Tickets'

interface AnalyticsTicketsCardSimpleViewProps {
  ticketsSpecs: ITicketSpec[]
}

export function AnalyticsTicketsCardSimpleView({
  ticketsSpecs,
}: AnalyticsTicketsCardSimpleViewProps): ReactElement {
  return (
    <main className="flex w-full flex-wrap gap-4">
      {ticketsSpecs.map((ticketSpec) => {
        return (
          <div
            className={twMerge(
              'flex w-[calc(50%-0.5rem)] flex-col gap-1 rounded-lg border bg-background-main p-4',
              ticketSpec.active
                ? 'border-primary-main'
                : 'border-background-main',
            )}
            key={ticketSpec.id}
          >
            <div className="flex flex-col gap-1">
              <span className="text-sm font-bold leading-4">
                {ticketSpec.description}
              </span>
              <span className="text-sm leading-3">
                {numberToReais(ticketSpec.price, 2)}
              </span>
            </div>
            {isTruthy(ticketSpec.numberOfSoldTickets) ? (
              <span className="text-sm font-bold leading-3 text-primary-main">
                {ticketSpec.numberOfSoldTickets} venda
                {ticketSpec.numberOfSoldTickets! === 1 ? '' : 's'}
              </span>
            ) : (
              <span className="text-sm leading-3">0 vendas</span>
            )}
          </div>
        )
      })}
    </main>
  )
}
