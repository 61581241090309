import { type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import { PermissionSelector } from '@/components/Team/PermissionSelector'
import { PermissionSwitch } from '@/components/Team/PermissionSwitch'
import { PermissionsPreset } from '@/components/Team/PermissionsPreset'
import { Button } from '@/components/Global/Button'

import { teamStore } from '@/store/team'
import { organizationStore } from '@/store/organization'
import { errorStore } from '@/store/error'

import {
  eventPermissionsDescriptions,
  organizationPermissionsDescriptions,
} from '@/utils/teamPermissionsDescriptions'

import { usePostCreateOrganizationRole } from '@/hooks/Team'

import { CANT_CREATE_ORGANIZATION_ROLE } from '@/errors'

export function CreateOrganizationRole(): ReactElement {
  const { user, permissions, setPermissions } = teamStore()

  const navigate = useNavigate()

  const { currentOrganization, refreshOrganizations } = organizationStore()
  const { showError } = errorStore()

  const { createOrganizationRole, isLoading } = usePostCreateOrganizationRole(
    currentOrganization?.organizer.organizationId ?? 0,
  )

  const canCreateOrganizationRole =
    permissions.event.finance !== '' &&
    permissions.event.analytics !== '' &&
    permissions.event.list !== '' &&
    permissions.event.tickets !== '' &&
    permissions.event.grant !== '' &&
    permissions.event.link !== '' &&
    permissions.organization.finance !== '' &&
    permissions.organization.analytics !== '' &&
    permissions.organization.access !== '' &&
    permissions.organization.communicationCampaign !== ''

  async function handleCreateOrganizationRole(): Promise<void> {
    const response = await createOrganizationRole({
      userId: user?.id ?? 0,
      roles: permissions,
    })

    if (response.status !== 200) {
      showError(CANT_CREATE_ORGANIZATION_ROLE)
      return
    }

    if (refreshOrganizations !== undefined) void refreshOrganizations()
    navigate('/team')
  }

  return (
    <div className="relative flex size-full flex-col items-start gap-6 p-4">
      <div className="flex w-full flex-col items-center justify-between gap-4 desktop:flex-row">
        {user !== undefined && (
          <div className="flex h-fit w-full items-center gap-4">
            <img
              src={`${import.meta.env.VITE_S3 as string}/${user.imageKey}`}
              alt="User profile image"
              className="aspect-square size-20 rounded-full"
            />
            <div className="flex w-full flex-col gap-1">
              <span className="line-clamp-1 text-2xl font-bold leading-7">
                {user.firstName} {user.lastName}
              </span>
              <span className="text-sm leading-3">@{user.username}</span>
            </div>
          </div>
        )}
        <div className="flex w-full justify-end">
          <PermissionsPreset
            permissions={permissions}
            setPermissions={setPermissions}
            isGlobalPermission
          />
        </div>
      </div>
      <div className="w-full columns-1 desktop:columns-2">
        <div className="mb-6 flex w-full break-inside-avoid-column flex-col gap-2">
          <div>
            <span className="text-lg font-black">Permissões nos eventos</span>
          </div>
          <div className="flex w-full flex-col gap-2">
            <PermissionSelector
              permissions={eventPermissionsDescriptions.FINANCE}
              title="Financeiro"
              currentKey={permissions.event.finance}
              setCurrentKey={(p) => {
                setPermissions({
                  ...permissions,
                  event: { ...permissions.event, finance: p },
                })
              }}
              canEdit
            />
            <PermissionSelector
              permissions={eventPermissionsDescriptions.ANALYTICS}
              title="Analíticos"
              currentKey={permissions.event.analytics}
              setCurrentKey={(p) => {
                setPermissions({
                  ...permissions,
                  event: { ...permissions.event, analytics: p },
                })
              }}
              canEdit
            />
            <PermissionSelector
              permissions={eventPermissionsDescriptions.LIST}
              title="Lista"
              currentKey={permissions.event.list}
              setCurrentKey={(p) => {
                setPermissions({
                  ...permissions,
                  event: { ...permissions.event, list: p },
                })
              }}
              canEdit
            />
            <PermissionSelector
              permissions={eventPermissionsDescriptions.TICKETS}
              title="Lotes"
              currentKey={permissions.event.tickets}
              setCurrentKey={(p) => {
                setPermissions({
                  ...permissions,
                  event: { ...permissions.event, tickets: p },
                })
              }}
              canEdit
            />
            <PermissionSelector
              permissions={eventPermissionsDescriptions.GRANT}
              title="Cortesias"
              currentKey={permissions.event.grant}
              setCurrentKey={(p) => {
                setPermissions({
                  ...permissions,
                  event: { ...permissions.event, grant: p },
                })
              }}
              canEdit
            />
            <PermissionSelector
              permissions={eventPermissionsDescriptions.LINK}
              title="Links"
              currentKey={permissions.event.link}
              setCurrentKey={(p) => {
                setPermissions({
                  ...permissions,
                  event: { ...permissions.event, link: p },
                })
              }}
              canEdit
            />
            <PermissionSwitch
              title="Validação de ingressos"
              active={permissions.event.validation}
              setActive={(state) => {
                setPermissions({
                  ...permissions,
                  event: { ...permissions.event, validation: state },
                })
              }}
              canEdit
            />
            <PermissionSwitch
              title="Editar evento"
              active={permissions.event.edit}
              setActive={(state) => {
                setPermissions({
                  ...permissions,
                  event: { ...permissions.event, edit: state },
                })
              }}
              canEdit
            />
          </div>
        </div>

        <div className="flex w-full break-inside-avoid-column flex-col gap-2">
          <div>
            <span className="text-lg font-black">
              Permissões na organização
            </span>
          </div>
          <div className="flex w-full flex-col gap-2">
            <PermissionSelector
              permissions={organizationPermissionsDescriptions.FINANCE}
              title="Financeiro"
              currentKey={permissions.organization.finance}
              setCurrentKey={(p) => {
                setPermissions({
                  ...permissions,
                  organization: { ...permissions.organization, finance: p },
                })
              }}
              canEdit
            />
            <PermissionSelector
              permissions={organizationPermissionsDescriptions.ANALYTICS}
              title="Analíticos"
              currentKey={permissions.organization.analytics}
              setCurrentKey={(p) => {
                setPermissions({
                  ...permissions,
                  organization: { ...permissions.organization, analytics: p },
                })
              }}
              canEdit
            />
            <PermissionSelector
              permissions={organizationPermissionsDescriptions.MANAGEMENT}
              title="Gestão de membros"
              currentKey={permissions.organization.access}
              setCurrentKey={(p) => {
                setPermissions({
                  ...permissions,
                  organization: { ...permissions.organization, access: p },
                })
              }}
              canEdit
            />
            <PermissionSelector
              permissions={
                organizationPermissionsDescriptions.COMMUNICATION_CAMPAIGN
              }
              title="Envio de comunicações"
              currentKey={permissions.organization.communicationCampaign}
              setCurrentKey={(p) => {
                setPermissions({
                  ...permissions,
                  organization: {
                    ...permissions.organization,
                    communicationCampaign: p,
                  },
                })
              }}
              canEdit
            />
            <PermissionSwitch
              title="Criação de eventos"
              active={permissions.organization.eventCreation}
              setActive={(state) => {
                setPermissions({
                  ...permissions,
                  organization: {
                    ...permissions.organization,
                    eventCreation: state,
                  },
                })
              }}
              canEdit
            />
            <PermissionSwitch
              title="Edição de dados da organização"
              active={permissions.organization.settings}
              setActive={(state) => {
                setPermissions({
                  ...permissions,
                  organization: {
                    ...permissions.organization,
                    settings: state,
                  },
                })
              }}
              canEdit
            />
          </div>
        </div>
      </div>
      <div className="w-full max-w-[300px] self-center py-6">
        <div className="h-12">
          <Button
            text="Confirmar"
            enabled={canCreateOrganizationRole && !isLoading}
            onClick={() => {
              void handleCreateOrganizationRole()
            }}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  )
}
