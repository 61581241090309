import { type ReactElement } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import { BsPlusCircle } from 'react-icons/bs'

import { type IPIX } from '@/types/CreateEditOrganization'

import { AiOutlineDelete } from 'react-icons/ai'
import { FiEdit2 } from 'react-icons/fi'

interface CreateEditOrganizationPixProps {
  openCreatePixModal: () => void
  pixKeys: IPIX[]
  deletePixKey: (key: string) => void
  editPixKey: (pixKey: IPIX) => void
}

export function CreateEditOrganizationPixList({
  openCreatePixModal,
  pixKeys,
  deletePixKey,
  editPixKey,
}: CreateEditOrganizationPixProps): ReactElement {
  return (
    <div className="flex w-full flex-col gap-2">
      <span className="font-black">Chaves PIX</span>
      {pixKeys.length === 0 ? (
        <motion.div
          className="flex w-full items-center justify-between rounded-md bg-[#181818] p-6 hover:cursor-pointer desktop:w-3/5"
          onClick={openCreatePixModal}
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
        >
          <span className="w-4/5 text-xs">
            Cadastre suas chaves PIX para receber os repasses do seu evento
          </span>
          <BsPlusCircle size={24} color="white" />
        </motion.div>
      ) : (
        <div className="flex flex-col items-center gap-2">
          <AnimatePresence>
            {pixKeys.map((pixKey) => (
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.5 }}
                layout
                key={pixKey.id}
                className="flex w-full items-center justify-between gap-6 rounded-md bg-[#181818] p-4"
                onClick={() => {}}
              >
                <div className="flex flex-col">
                  <span className="font-bold">{pixKey.name}</span>
                  <span className="text-sm text-text-secondary">
                    {pixKey.key}
                  </span>
                </div>
                <div className="flex gap-2">
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      deletePixKey(pixKey.key)
                    }}
                    className="flex size-8 items-center justify-center rounded-md border border-dark-dark-gray transition-colors duration-100 ease-in-out hover:cursor-pointer hover:bg-dark-dark-gray"
                  >
                    <AiOutlineDelete size={16} />
                  </button>
                  <button
                    className="flex size-8 items-center justify-center rounded-md border border-dark-dark-gray transition-colors duration-100 ease-in-out hover:cursor-pointer hover:bg-dark-dark-gray"
                    onClick={(e) => {
                      e.preventDefault()
                      editPixKey(pixKey)
                    }}
                  >
                    <FiEdit2 size={14} />
                  </button>
                </div>
              </motion.div>
            ))}
          </AnimatePresence>
          <div
            className="flex w-fit items-center justify-between gap-4 rounded-full bg-[#181818] px-4 py-2 hover:cursor-pointer"
            onClick={openCreatePixModal}
          >
            <span className="text-sm font-bold">Adicionar</span>
            <BsPlusCircle size={24} color="white" />
          </div>
        </div>
      )}
    </div>
  )
}
