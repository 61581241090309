import { useState, type ReactElement, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { errorStore } from '@/store/error'
import { grantStore, useAllUsersWithGrant } from '@/store/grant'
import { useUserPermissions } from '@/store/user'

import { useGetGrants, useGetSearchGrant } from '@/hooks/Grant'

import { Button } from '@/components/Global/Button'
import { SearchBar } from '@/components/Global/SearchBar'
import { GrantCreateModal } from '@/components/Grant/GrantCreateModal'
import { GrantInfoModal } from '@/components/Grant/GrantInfoModal'
import { GrantSelectTicketModal } from '@/components/Grant/GrantSelectTicketModal'
import { GrantUserCard } from '@/components/Grant/GrantUserCard'
import { Spinner } from '@/components/Global/Spinner'

import { type IGrant } from '@/types/Grant'

import { hasClearence } from '@/utils/rbac'

export function Grant(): ReactElement {
  const [searchTerm, setSearchTerm] = useState('')
  const [isSearching, setIsSearching] = useState(false)
  const [userGrantInfoModalIsOpen, setUserGrantInfoModalIsOpen] =
    useState(false)
  const [isParentClosing, setIsParentClosing] = useState(false)
  const [createGrantModalIsOpen, setCreateGrantModalIsOpen] = useState(false)
  const [selectTicketModalIsOpen, setSelectTicketModalIsOpen] = useState(false)

  const [currentPageGlobal, setCurrentPageGlobal] = useState(0)
  const [currentPageSearch, setCurrentPageSearch] = useState(0)
  const [noNewGrantsGlobal, setNoNewGrantsGlobal] = useState(false)
  const [noNewGrantsSearch, setNoNewGrantsSearch] = useState(false)

  const { alias } = useParams()

  const { searchUsersWithGrant, isLoading: isLoadingSearch } =
    useGetSearchGrant()
  const { getGrants } = useGetGrants()
  const { showError } = errorStore()
  const { eventPermissions } = useUserPermissions()

  const { setAllUsers, setCurrentUser } = grantStore()
  const { allUsers } = useAllUsersWithGrant()

  const canEditGrant = hasClearence(eventPermissions.grant, 'EDITOR')

  async function handleGetGrants(page: number = 0): Promise<void> {
    const response = await getGrants(alias!, page)
    if (response.status === 200) {
      if (response.grants !== undefined && response.grants.length < 50) {
        setNoNewGrantsGlobal(true)
      }
      if (page === 0) setAllUsers(response?.grants ?? [])
      else setAllUsers([...(allUsers ?? []), ...(response.grants ?? [])])
    } else if (response.status !== 401) {
      showError('Erro ao buscar cortesias!')
    }
  }

  async function handleSearch(page: number = 0): Promise<void> {
    setNoNewGrantsGlobal(false)
    const response = await searchUsersWithGrant(searchTerm, alias!, page)
    if (!response.status) {
      showError('Erro ao pesquisar usuários!')
    } else if (response.users?.length === 0 && page === 0) {
      showError('Nenhum usuário encontrado!')
      if (allUsers!.length < 50) setNoNewGrantsSearch(true)
    } else if (response.users !== undefined) {
      if (response.users.length < 50) setNoNewGrantsSearch(true)
      if (page === 0) setAllUsers(response?.users)
      else setAllUsers([...(allUsers ?? []), ...(response.users ?? [])])
    }
  }

  function handleOnClick(user: IGrant): void {
    setIsParentClosing(false)
    setUserGrantInfoModalIsOpen(true)
    setCurrentUser(user)
  }

  function handleModalClose(): void {
    setIsParentClosing(false)
  }

  function handleCreateGrantModalClose(): void {
    setCreateGrantModalIsOpen(false)
    setIsParentClosing(false)
    setSelectTicketModalIsOpen(true)
  }

  function triggerNewPage(): void {
    if (isSearching) {
      void handleSearch(currentPageSearch + 1)
      setCurrentPageSearch((current) => current + 1)
    } else {
      void handleGetGrants(currentPageGlobal + 1)
      setCurrentPageGlobal((current) => current + 1)
    }
  }

  useEffect(() => {
    setAllUsers([])
    void handleGetGrants()
  }, [])

  useEffect(() => {
    if (isSearching && searchTerm.length === 0) {
      setAllUsers([])
      setIsSearching(false)
      setNoNewGrantsSearch(false)
      setNoNewGrantsGlobal(false)
      setCurrentPageSearch(0)
      setCurrentPageGlobal(0)
      void handleGetGrants()
    }
  }, [isSearching, searchTerm])

  useEffect(() => {
    if (allUsers !== null && allUsers.length > 0) {
      // Instanciate the observer
      const intersectionObserver = new IntersectionObserver((entries) => {
        if (entries.some((entry) => entry.isIntersecting)) {
          triggerNewPage()
        }
      })

      const loadSentry = document.querySelector('#loadSentry')
      if (loadSentry !== null) intersectionObserver.observe(loadSentry)

      return () => {
        intersectionObserver.disconnect()
      }
    }
  }, [allUsers])

  return (
    <>
      <div className="flex size-full max-w-[600px]">
        <div className="flex size-full flex-col gap-4 p-4">
          <main className="flex size-full flex-col gap-4">
            <SearchBar
              placeholder={'Username, email ou celular'}
              searchParam={searchTerm}
              setSearchParam={function (searchParam: string): void {
                setSearchTerm(searchParam)
              }}
              handleSearch={() => {
                setNoNewGrantsGlobal(false)
                setNoNewGrantsSearch(false)
                setCurrentPageGlobal(0)
                setCurrentPageSearch(0)
                setIsSearching(true)
                void handleSearch()
              }}
              isSearching={isLoadingSearch}
            />
            {allUsers !== null && (
              <div className="flex w-full flex-col pb-16">
                {allUsers.map((user, index) => {
                  return (
                    <GrantUserCard
                      grantData={user}
                      handleOnClick={(user: IGrant): void => {
                        handleOnClick(user)
                      }}
                      key={index}
                    />
                  )
                })}
              </div>
            )}
            {!noNewGrantsGlobal && !noNewGrantsSearch && (
              <div
                id="loadSentry"
                className="mb-20 flex w-full items-center justify-center gap-4 pb-2"
              >
                <Spinner
                  borderWidth="border-4"
                  borderColor="border-primary-main/50"
                  bottomBorderColor="border-b-primary-main"
                />
              </div>
            )}
          </main>

          {userGrantInfoModalIsOpen && (
            <GrantInfoModal
              closeModal={(): void => {
                setIsParentClosing(true)
                setTimeout(setUserGrantInfoModalIsOpen, 400, false)
              }}
              isParentClosing={isParentClosing}
              handleModalClose={() => {
                setIsParentClosing(true)
                setTimeout(handleModalClose, 400)
              }}
            />
          )}
          {createGrantModalIsOpen && (
            <GrantCreateModal
              closeModal={() => {
                setIsParentClosing(true)
                setTimeout(setCreateGrantModalIsOpen, 400, false)
              }}
              isParentClosing={isParentClosing}
              handleModalClose={() => {
                setIsParentClosing(true)
                setTimeout(handleCreateGrantModalClose, 400)
              }}
            />
          )}
          {selectTicketModalIsOpen && (
            <GrantSelectTicketModal
              closeModal={() => {
                setIsParentClosing(true)
                setTimeout(setSelectTicketModalIsOpen, 400, false)
              }}
              isParentClosing={isParentClosing}
              handleModalClose={() => {
                setIsParentClosing(true)
                setTimeout(setSelectTicketModalIsOpen, 400, true)
              }}
            />
          )}
        </div>
      </div>
      {canEditGrant && (
        <footer className="fixed bottom-4 left-1/2 z-10 flex h-12 w-fit min-w-[300px] -translate-x-1/2 items-center justify-center px-4 desktop:absolute">
          <Button
            enabled={true}
            text="Enviar cortesia"
            onClick={() => {
              setIsParentClosing(false)
              setCreateGrantModalIsOpen(true)
            }}
          />
        </footer>
      )}
    </>
  )
}
