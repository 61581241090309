import { type ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { SlArrowRight } from 'react-icons/sl'

import { EventBrief } from '@/components/Event/EventBrief'

interface EventCardProps {
  alias: string | undefined
  imageUrl: string
  date: string
  location?: string
  title?: string
  variation: 'small' | 'medium' | 'big'
  isClickable?: boolean
}

export function EventCard({
  alias,
  imageUrl,
  date,
  location,
  title,
  variation,
  isClickable = true,
}: EventCardProps): ReactElement {
  const variations = {
    parentDiv: {
      small: 'p-1 border flex-row rounded-r-lg bg-transparent',
      medium: 'p-1 border flex-col rounded-b-lg',
      big: 'p-4 border-2 flex-row rounded-b-lg items-center',
    },
    imageDiv: {
      small: 'w-16 aspect-square rounded mr-1 h-full object-cover',
      medium: 'w-40 aspect-square rounded-md mb-1 h-full object-cover',
      big: 'w-32 aspect-square rounded-md mr-2 h-full object-cover',
    },
    briefDiv: {
      small:
        'bg-trasnparent w-full max-w-full flex items-center justify-between',
      medium: 'bg-background-main w-40',
      big: 'h-min',
    },
    title: {
      small: 'max-w-full text-elipsis line-clamp-1 text-xs text-white',
      medium: 'leading-4 text-white text-sm text-elipsis line-clamp-1',
      big: 'text-2xl text-white font-bold leading-6',
    },
  }

  return (
    <Link
      to={isClickable ? `/events/${alias ?? ''}` : ''}
      className={
        'relative flex w-full rounded-lg border-secondary-gray bg-dark-black  ' +
        variations.parentDiv[variation]
      }
    >
      <img
        src={`${import.meta.env.VITE_S3 as string}/${imageUrl}`}
        alt="Event image"
        className={variations.imageDiv[variation]}
      />
      <div className={'rounded p-2 ' + variations.briefDiv[variation]}>
        <div
          className={twMerge(
            'flex flex-col',
            variation === 'small' && 'max-w-[93%]',
          )}
        >
          <h1 className={variations.title[variation]}>{title}</h1>
          <EventBrief
            date={date}
            location={location}
            padding="0"
            variant="white"
            highlighted={variation !== 'big'}
          />
        </div>
        {variation === 'small' && isClickable && (
          <SlArrowRight
            color="#7c7c7c"
            className="absolute right-2 top-1/2 -translate-x-1/2 -translate-y-1/2"
            size={12}
          />
        )}
      </div>
    </Link>
  )
}
