import { parseISO } from 'date-fns'
import { datesToFormat } from './formatData'

import { type Event } from '@/types/Organization'

interface CategorizeEventsReturn {
  pastEvents: Event[]
  futureEvents: Event[]
  pendingApprovalEvents: Event[]
  rejectedEvents: Event[]
}

function sortByMostRecent(a: Event, b: Event): number {
  const dateA = parseISO(a.date)
  const dateB = parseISO(b.date)

  if (dateA < dateB) {
    return 1
  }
  if (dateA > dateB) {
    return -1
  }
  return 0
}

export function categorizeEvents(events: Event[]): CategorizeEventsReturn {
  const currentDate = new Date()
  const pastEvents: Event[] = []
  const futureEvents: Event[] = []

  const approvedEvents: Event[] = events.filter(
    (event) => event.status === 'APPROVED',
  )
  const pendingApprovalEvents: Event[] = events
    .filter((event) => event.status === 'PENDING')
    .map((event) => {
      return {
        ...event,
        date: datesToFormat(event.date, event.endDate),
      }
    })
  const rejectedEvents: Event[] = events
    .filter((event) => event.status === 'REJECTED')
    .map((event) => {
      return {
        ...event,
        date: datesToFormat(event.date, event.endDate),
      }
    })

  const orderedEvents = approvedEvents.sort((a, b) => {
    const dateA = parseISO(a.date)
    const dateB = parseISO(b.date)

    if (dateA < dateB) {
      return -1
    }
    if (dateA > dateB) {
      return 1
    }
    return 0
  })

  for (const event of orderedEvents) {
    const eventDate = new Date(event.date)
    const eventWithFormattedDate = {
      ...event,
      date: datesToFormat(event.date, event.endDate),
    }

    if (eventDate < currentDate) {
      pastEvents.push(eventWithFormattedDate)
    } else {
      futureEvents.push(eventWithFormattedDate)
    }
  }

  // Sorting by date
  pastEvents.sort(sortByMostRecent)
  futureEvents.sort(sortByMostRecent)
  pendingApprovalEvents.sort(sortByMostRecent)
  rejectedEvents.sort(sortByMostRecent)

  return {
    pastEvents,
    futureEvents,
    pendingApprovalEvents,
    rejectedEvents,
  }
}
