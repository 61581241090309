import { type ReactElement, useState, useEffect } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useParams } from 'react-router-dom'

import { DownloadApp } from '@/components/Global/DownloadApp'
import { GenerateCheckInCodeModal } from '@/components/CheckIn/GenerateCheckInCodeModal'
import { SwitchButton } from '@/components/Global/SwitchButton'
import { Loading } from '@/components/Global/Loading/Loading'
import { Button } from '@/components/Global/Button'

import GandayaStaffIcon from '@/assets/gandayaStaff.png'

import { BsCheckLg } from 'react-icons/bs'
import { FaArrowRotateLeft } from 'react-icons/fa6'
import { TbCopy } from 'react-icons/tb'

import { useGetCheckInCode } from '@/hooks/CheckInCode/useGetCheckInCode'
import { usePutSwitchCheckInCodeState } from '@/hooks/CheckInCode/usePutSwitchCheckInCodeState'
import { usePostGenerateCheckInCode } from '@/hooks/CheckInCode/usePostGenerateCheckInCode'

export function CheckIn(): ReactElement {
  const [canCopyCode, setCanCopyCode] = useState(true)
  const [canGenerateCode, setCanGenerateCode] = useState(true)
  const [codeActive, setCodeActive] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [isParentClosing, setIsParentClosing] = useState(false)
  const [code, setCode] = useState('')
  const [noCode, setNoCode] = useState(false)

  const { alias } = useParams()

  const {
    data: codeData,
    isLoading: isLoadingGetCode,
    error,
  } = useGetCheckInCode(alias!)
  const { switchCheckInCodeState } = usePutSwitchCheckInCodeState(alias!)
  const { generateCode, isLoading: isLoadingPostGenerateCode } =
    usePostGenerateCheckInCode(alias!)

  async function copyLinkToClipboard(): Promise<void> {
    setCanCopyCode(false)
    setTimeout(setCanCopyCode, 3000, true)
    await navigator.clipboard.writeText(code ?? '')
  }

  async function handleCodeGeneration(): Promise<void> {
    handleModalClose()
    setTimeout(setCanGenerateCode, 200, false)
    setTimeout(setCanGenerateCode, 3000, true)
    const response = await generateCode()
    setCode(response.code ?? '')
    setCodeActive(true)
  }

  async function handleCodeStatus(): Promise<void> {
    setCodeActive((prev) => !prev)
    await switchCheckInCodeState({ code })
  }

  function handleModalClose(): void {
    setIsParentClosing(true)
    setTimeout(setShowConfirmationModal, 400, false)
    setTimeout(setIsParentClosing, 400, false)
  }

  async function handleFirstCodeGeneration(): Promise<void> {
    const response = await generateCode()
    setCode(response.code ?? '')
    setCodeActive(true)
    setNoCode(false)
  }

  useEffect(() => {
    setCode(codeData?.code ?? '')
    setCodeActive(codeData?.active ?? false)
  }, [codeData])

  useEffect(() => {
    if (error !== undefined) {
      setNoCode(true)
    }
  }, [error])

  if (isLoadingGetCode) return <Loading />
  return (
    <div className="flex size-full max-w-[500px] flex-col justify-center">
      <div className="top-0 flex h-max flex-col items-center justify-center gap-10 p-4 desktop:gap-16">
        <span className="w-[90%] text-center">
          Para validar a entrada de clientes no seu evento, baixe o aplicativo{' '}
          <span className="font-black">Gandaya Staff</span> e insira o código
          abaixo.
        </span>
        <div className="flex w-full flex-col items-center gap-4 rounded-lg bg-dark-black p-4">
          <div className="flex w-full justify-between">
            <span className="text-xl font-bold">Código do evento</span>
            {noCode ? (
              <div></div>
            ) : (
              <div className="flex gap-2">
                <button className="flex size-7 items-center justify-center rounded-md bg-primary-main p-1">
                  <AnimatePresence mode="wait">
                    {canGenerateCode ? (
                      <motion.div
                        key="toGenerate"
                        animate={{ rotate: 0 }}
                        exit={{ rotate: -720 }}
                        transition={{ duration: 1, ease: 'easeInOut' }}
                        onClick={() => {
                          setShowConfirmationModal(true)
                        }}
                      >
                        <FaArrowRotateLeft color="#181818" />
                      </motion.div>
                    ) : (
                      <motion.button
                        key="generated"
                        className="flex items-center justify-center rounded-md bg-primary-dark p-1"
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2, ease: 'easeInOut' }}
                      >
                        <BsCheckLg size={20} color="white" />
                      </motion.button>
                    )}
                  </AnimatePresence>
                </button>
                <button className="flex size-7 items-center  justify-center rounded-md bg-primary-main p-1">
                  <AnimatePresence mode="wait">
                    {canCopyCode ? (
                      <motion.button
                        key="toCopy"
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2, ease: 'easeInOut' }}
                        onClick={copyLinkToClipboard}
                      >
                        <TbCopy color="#181818" size={20} />
                      </motion.button>
                    ) : (
                      <motion.button
                        key="copied"
                        className="flex items-center justify-center rounded-md bg-primary-dark p-1"
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2, ease: 'easeInOut' }}
                      >
                        <BsCheckLg size={20} color="white" />
                      </motion.button>
                    )}
                  </AnimatePresence>
                </button>
              </div>
            )}
          </div>
          {noCode ? (
            <div className="h-10 w-2/5">
              <Button
                onClick={handleFirstCodeGeneration}
                enabled={!isLoadingPostGenerateCode}
                isLoading={isLoadingPostGenerateCode}
                text="Gerar código"
              />
            </div>
          ) : (
            <>
              <div className="rounded-full bg-background-main px-6 py-2">
                <span className="text-2xl font-black">{code}</span>
              </div>
              <div className="flex flex-col">
                <div className="flex items-center justify-between">
                  <span className="text-lg font-bold">
                    Código {codeActive ? 'ativado' : 'desativado'}
                  </span>
                  <SwitchButton
                    active={codeActive}
                    handleOnClick={handleCodeStatus}
                  />
                </div>
                <span className="text-xs leading-3">
                  Esta opção permite que você desative ou ative o código do seu
                  evento quando quiser.
                </span>
              </div>
            </>
          )}
        </div>
        <div className="flex w-full flex-col items-center gap-4 rounded-lg bg-dark-black p-4">
          <div className="flex items-center justify-center gap-4">
            <img
              src={GandayaStaffIcon}
              alt=""
              className="aspect-square w-16 rounded-md"
            />
            <span className="text-2xl font-black">Gandaya Staff</span>
          </div>
          <div className="z-10 flex w-full justify-evenly">
            <DownloadApp
              size="small"
              variant="dark"
              store="AppStore"
              storeUrl={import.meta.env.VITE_APP_STORE_STAFF as string}
            />
            <DownloadApp
              size="small"
              variant="dark"
              store="PlayStore"
              storeUrl={import.meta.env.VITE_PLAY_STORE_STAFF as string}
            />
          </div>
        </div>
      </div>
      {showConfirmationModal && (
        <GenerateCheckInCodeModal
          closeModal={handleModalClose}
          isParentClosing={isParentClosing}
          isError={false}
          errorMessage=""
          onClick={handleCodeGeneration}
        />
      )}
    </div>
  )
}
