import { api } from '@/services/axios'
import { type IGrant, type IGetGrantsReturn } from '@/types/Grant'
import { type AxiosError } from 'axios'
import { useState } from 'react'

export const useGetGrants = (): {
  getGrants: (alias: string, page: number) => Promise<IGetGrantsReturn>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function getGrants(
    alias: string,
    page: number = 0,
  ): Promise<IGetGrantsReturn> {
    const take = 50
    const skip = 50 * page

    setIsLoading(true)
    const shouldFetch = alias !== undefined
    if (!shouldFetch) {
      setIsLoading(false)
      return {
        status: 400,
      }
    }
    try {
      const response = await api.get<IGrant[]>(
        `/admin/events/${alias}/grants?skip=${skip}&take=${take}`,
      )
      setIsLoading(false)
      return {
        status: 200,
        grants: response.data,
      }
    } catch (err) {
      setIsLoading(false)
      const error = err as AxiosError
      return {
        status: error.response?.status ?? 0,
      }
    }
  }

  return { getGrants, isLoading }
}
