import { type ReactElement, useState, useEffect } from 'react'

import { Button } from '@/components/Global/Button'

import { formatPhoneNumber } from '@/utils/formatString'
import { AnimatePresence } from 'framer-motion'

import { INVALID_BRAZILLIAN_PHONE } from '@/errors'

export interface LoginProps {
  phoneNumber: string
  setPhoneNumber: (phoneNumber: string) => void
  setState: (
    state:
      | 'login'
      | 'twoFactorPhone'
      | 'signup'
      | 'profilePicture'
      | 'communicationMethod',
  ) => void
  showError: (errorMessage: string, screenTime?: number) => void
}

export function Login({
  phoneNumber,
  setPhoneNumber,
  setState,
  showError,
}: LoginProps): ReactElement {
  const [inputSelected, setInputSelected] = useState(false)
  const [isGringo, setIsGringo] = useState(false)

  const countryCode = phoneNumber.slice(0, 3)
  const number = phoneNumber.slice(3)

  const inputDivStyle =
    `rounded w-full bg-white p-2 text-2xl font-black border-2 transition-color duration-200 ease-in-out flex` +
    (inputSelected ? ` border-primary-main` : ``)

  function handleSelectCommunicationMethod(): void {
    let phoneNumberWithCountryCode
    if (isGringo) {
      phoneNumberWithCountryCode = '+' + phoneNumber
    } else {
      const cleanPhoneNumber = phoneNumber.replace(/\D/g, '')

      if (cleanPhoneNumber.length !== 11 || cleanPhoneNumber[2] !== '9') {
        showError(INVALID_BRAZILLIAN_PHONE, 5000)
        return
      }

      phoneNumberWithCountryCode = '+55' + cleanPhoneNumber
    }

    setPhoneNumber(phoneNumberWithCountryCode)
    setState('communicationMethod')
  }

  useEffect(() => {
    if (phoneNumber !== '') {
      if (countryCode === '+55') {
        setPhoneNumber(number)
      } else {
        setIsGringo(true)
        setPhoneNumber(phoneNumber.replace('+', ''))
      }
    }
  }, [])

  return (
    <div className="mt-5 flex w-full flex-col justify-between overflow-hidden p-8">
      <h1 className="w-full text-4xl text-text-dark">
        login/
        <br />
        cadastro
      </h1>

      <div className="mb-10 mt-4 flex w-full max-w-[300px] flex-col items-end gap-2 self-center">
        <button
          className="font-regular flex gap-1.5 rounded-full bg-primary-main px-4 pb-1 pt-1.5 text-center text-sm font-medium leading-[16px] text-dark-black"
          onClick={(e) => {
            e.preventDefault()
            setPhoneNumber('')
            setIsGringo(!isGringo)
          }}
        >
          <span>{isGringo ? 'Brasileiros' : 'Internationals'}</span>
          <span>{isGringo ? '🇧🇷' : '🌎'}</span>
        </button>
        <AnimatePresence mode="wait">
          {isGringo ? (
            <div key="gringo" className={inputDivStyle}>
              <span className="text-xl">🌎</span>
              <input
                className="placeholder:text-secondary ml-2 w-full text-xl text-text-dark shadow-none outline-none placeholder:opacity-50"
                type="text"
                value={phoneNumber.length > 0 ? `+${phoneNumber}` : ''}
                onChange={(e) => {
                  setPhoneNumber(e.target.value.replace('+', ''))
                }}
                placeholder="+123 00 0000-0000"
                onFocus={() => {
                  setInputSelected(true)
                }}
                onBlur={() => {
                  setInputSelected(false)
                }}
                inputMode="tel"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSelectCommunicationMethod()
                  }
                }}
              />
            </div>
          ) : (
            <div key="br" className={inputDivStyle}>
              <span className="text-xl">🇧🇷 </span>
              <span className="ml-2 text-lg text-text-secondary">+55</span>
              <input
                className="placeholder:text-secondary ml-2 w-full text-xl text-text-dark shadow-none outline-none placeholder:opacity-50"
                type="text"
                value={formatPhoneNumber(phoneNumber)}
                onChange={(e) => {
                  setPhoneNumber(e.target.value)
                }}
                placeholder="(00) 00000-0000"
                onFocus={() => {
                  setInputSelected(true)
                }}
                onBlur={() => {
                  setInputSelected(false)
                }}
                inputMode="tel"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSelectCommunicationMethod()
                  }
                }}
              />
            </div>
          )}
        </AnimatePresence>
      </div>
      <div className="h-12 w-40">
        <Button
          onClick={() => {
            handleSelectCommunicationMethod()
          }}
          enabled={phoneNumber.length > 6}
          text="Próximo"
          className="text-2xl font-black disabled:bg-dark-light-gray"
        />
      </div>
    </div>
  )
}

export default Login
