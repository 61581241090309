import { type SVGAttributes, type ReactElement } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { tv } from 'tailwind-variants'

import { Spinner } from '../Spinner'

interface IconButtonProps {
  onClick: () => void
  text: string
  enabled: boolean
  isLoading?: boolean
  Icon?: (props: SVGAttributes<SVGElement>) => JSX.Element // Ex.: Icon={(props) => <AiOutlineDelete size={20} {...props} />}
  variant: 'green' | 'red'
}

export function IconButton({
  onClick,
  text,
  Icon,
  variant,
  isLoading = false,
  enabled,
}: IconButtonProps): ReactElement {
  const buttonVariant = tv({
    base: 'flex size-full items-center justify-center gap-1 rounded-md transition-colors duration-100 ease-in-out',
    variants: {
      background: {
        green:
          enabled && !isLoading
            ? 'bg-primary-main text-dark-black'
            : 'bg-[#A2A2A2] text-dark-dark-gray',
        red:
          enabled && !isLoading
            ? 'bg-tonal-red text-white'
            : 'bg-[#A2A2A2] text-dark-dark-gray',
      },
    },
  })

  return (
    <button
      className={buttonVariant({ background: variant })}
      onClick={() => {
        if (enabled) onClick()
      }}
    >
      <AnimatePresence mode="wait">
        {isLoading ? (
          <motion.div
            key="loading"
            className="flex size-full items-center justify-center rounded-md"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1, ease: 'easeInOut' }}
          >
            <Spinner
              borderWidth="border-4"
              borderColor="border-background-main/50"
              bottomBorderColor="border-b-background-main"
              width="w-6"
              height="h-6"
            />
          </motion.div>
        ) : (
          <motion.div
            className="flex size-full items-center justify-center gap-1"
            key="normal"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1, ease: 'easeInOut' }}
          >
            {Icon !== undefined && <Icon className="" />}
            <span className="text-xs font-bold">{text}</span>
          </motion.div>
        )}
      </AnimatePresence>
    </button>
  )
}
