import { isTruthy } from '@/utils/validation'
import { type ReactElement } from 'react'
import { type TooltipProps } from 'recharts'

interface CustomTooltipProps extends TooltipProps<number, string> {}

export function CustomTooltip({
  active,
  payload,
  label,
}: CustomTooltipProps): ReactElement | null {
  if (active === true && isTruthy(payload) && payload!.length > 0) {
    const pageViewtext = `${payload![0].value} ${payload![0].value === 1 ? 'acesso' : 'acessos'}`
    return (
      <div className="flex flex-col gap-0.5 rounded-md bg-dark-black/50 p-2 backdrop-blur-sm">
        <p className="font-black">{pageViewtext}</p>
        <p className="text-xs">{`${label}`}</p>
      </div>
    )
  }

  return null
}
