import { type ReactElement } from 'react'
import * as Select from '@radix-ui/react-select'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'

interface DropDownProps {
  label?: string
  dropDownData: Array<{
    value: string
    id: number | undefined
  }>
  handleOnChange: (id: number) => void
  value: string
}

export function DropDown({
  label,
  dropDownData,
  handleOnChange,
  value,
}: DropDownProps): ReactElement {
  return (
    <Select.Root
      onValueChange={(id: string) => {
        handleOnChange(Number(id))
      }}
      value={value}
    >
      <Select.Trigger className="flex items-center gap-2 border-b-primary-main  text-xs font-bold outline-none data-[highlighted]:border-none data-[highlighted]:outline-none">
        <Select.Value asChild={true}>
          <span className="line-clamp-none max-w-[200px] truncate text-xs max-[400px]:max-w-32">
            {value}
          </span>
        </Select.Value>
        <Select.Icon>
          <BsChevronDown style={{ strokeWidth: '1px' }} />
        </Select.Icon>
      </Select.Trigger>
      <Select.Portal className="relative">
        <Select.Content className="absolute left-4 top-0 z-10 w-full overflow-hidden rounded-lg border border-primary-main bg-dark-black">
          <Select.ScrollUpButton className="flex items-center justify-center">
            <BsChevronUp />
          </Select.ScrollUpButton>
          <Select.Viewport className="p-2">
            <Select.Group className="flex max-h-[215px] flex-col gap-2 overflow-scroll">
              {label !== undefined && <Select.Label>{label}</Select.Label>}
              {dropDownData.map((data) => {
                return (
                  <Select.Item
                    value={`${data.id ?? '-1'}`}
                    className=" flex w-full cursor-pointer items-center gap-2  border-none p-2 text-xs font-bold text-white outline-none hover:rounded-md hover:bg-background-main"
                    key={data.id ?? 'Nenhum'}
                  >
                    <Select.ItemText>{data.value}</Select.ItemText>
                  </Select.Item>
                )
              })}
            </Select.Group>
          </Select.Viewport>
          <Select.ScrollDownButton>
            <BsChevronDown />
          </Select.ScrollDownButton>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  )
}
