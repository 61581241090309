import { useState, type ReactElement } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useParams } from 'react-router-dom'

import { Modal } from '@/components/Global/Modal'

import { deviceStore } from '@/store/device'
import { linkStore } from '@/store/link'

import { FaCheck } from 'react-icons/fa6'

interface LinkUrlModalProps {
  closeModal: () => void
  isParentClosing: boolean
}

export function LinkUrlModal({
  closeModal,
  isParentClosing,
}: LinkUrlModalProps): ReactElement {
  const [sellLinkCopied, setSellLinkCopied] = useState(false)
  const [trackingLinkCopied, setTrackingLinkCopied] = useState(false)

  const { isMobile } = deviceStore()
  const { currentLink, closeLinkModal } = linkStore()
  const { alias } = useParams()

  async function handleSellLinkCopy(): Promise<void> {
    await navigator.clipboard.writeText(
      `${import.meta.env.VITE_KAYA_URL as string}/${alias}?link=${currentLink?.code}`,
    )
    setSellLinkCopied(true)
    setTimeout(closeLinkModal, 1000, false)
  }

  async function handleTrackingLinkCopy(): Promise<void> {
    await navigator.clipboard.writeText(
      `${import.meta.env.VITE_KAYA_URL as string}/links/${alias}/${currentLink?.hash}`,
    )
    setTrackingLinkCopied(true)
    setTimeout(closeLinkModal, 1000, false)
  }

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      isMobile={isMobile}
    >
      <div className="flex size-full flex-col items-center gap-6 p-4 pt-8 text-black">
        <span className="text-2xl font-black">
          Selecione qual link deseja copiar
        </span>

        <div className="flex size-full flex-col items-center justify-center gap-2 desktop:flex-row desktop:gap-6">
          <AnimatePresence mode="wait">
            {sellLinkCopied ? (
              <motion.div
                className="flex h-[100px] w-full flex-row items-center justify-center gap-4 rounded-lg bg-dark-light-gray p-4 desktop:h-[132px] desktop:flex-col desktop:gap-2"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                key="check-sell-link"
                transition={{ duration: 0.5, ease: 'easeInOut' }}
              >
                <div className="flex size-16 items-center justify-center rounded-full bg-primary-main">
                  <FaCheck size={32} />
                </div>
                <span className="text-xl font-bold">Link copiado!</span>
              </motion.div>
            ) : (
              <motion.button
                className="flex h-[100px] w-full flex-col gap-2 rounded-lg bg-dark-light-gray p-4 desktop:h-[132px]"
                onClick={handleSellLinkCopy}
              >
                <span className="text-xl font-bold">Link de venda</span>
                <span className="w-full text-start text-sm leading-4 ">
                  Esse link é utilizado pelos usuários para realizarem a compra
                  de ingressos.
                </span>
              </motion.button>
            )}
          </AnimatePresence>

          <AnimatePresence mode="wait">
            {trackingLinkCopied ? (
              <motion.div
                className="flex h-[100px] w-full flex-row items-center justify-center gap-4 rounded-lg bg-dark-light-gray p-4 desktop:h-[132px] desktop:flex-col desktop:gap-2"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                key="check-sell-link"
                transition={{ duration: 0.5, ease: 'easeInOut' }}
              >
                <div className="flex size-16 items-center justify-center rounded-full bg-primary-main">
                  <FaCheck size={32} />
                </div>
                <span className="text-xl font-bold">Link copiado!</span>
              </motion.div>
            ) : (
              <button
                className="flex h-[100px] w-full flex-col gap-2 rounded-lg bg-dark-light-gray p-4 desktop:h-[132px]"
                onClick={handleTrackingLinkCopy}
              >
                <div className="flex items-center gap-2 desktop:flex-col desktop:items-start desktop:gap-0">
                  <span className="line-clamp-1 text-xl font-bold">
                    Link de rastreio
                  </span>
                </div>
                <span className="w-full text-start text-sm leading-4 ">
                  Esse link é específico para promoters acompanharem as vendas
                  do seu link.
                </span>
              </button>
            )}
          </AnimatePresence>
        </div>
      </div>
    </Modal>
  )
}
