import { useState, type ReactElement } from 'react'
import { motion } from 'framer-motion'
import { twMerge } from 'tailwind-merge'

import { Button } from '@/components/Global/Button'

import logoTransparent from '@/assets/logo-gandaya-clean.png'

import { deviceStore } from '@/store/device'

interface CreateEditOrganizationSelectTypeProps {
  setOrganizationType: (type: 'SOLO' | 'TEAM') => void
}

export function CreateEditOrganizationSelectType({
  setOrganizationType,
}: CreateEditOrganizationSelectTypeProps): ReactElement {
  const [localOrganizationType, setLocalOrganizationType] = useState<
    'SOLO' | 'TEAM' | null
  >(null)

  const { isMobile } = deviceStore()

  return (
    <div className="fixed left-0 top-0 z-0 h-dvh w-dvw">
      <img
        src={logoTransparent}
        alt="Logo Gandaya"
        className={twMerge(
          'absolute left-5 top-5 z-20 w-52',
          isMobile && 'left-1/2 -translate-x-1/2',
        )}
      />
      <div
        className={twMerge(
          'absolute left-0 top-0 z-10 flex size-full items-center justify-center',
          isMobile && 'pt-16',
        )}
      >
        <div className="flex w-full flex-col items-center justify-center gap-4 px-4 desktop:flex-row">
          <div
            className={twMerge(
              'flex h-[40dvh] max-h-[400px] min-h-[250px] w-full max-w-[400px] cursor-pointer flex-col justify-end overflow-hidden rounded-md bg-white/20 p-4 transition-all duration-100 ease-in-out',
              localOrganizationType === 'SOLO' && 'bg-white/50',
            )}
            onClick={() => {
              setLocalOrganizationType('SOLO')
            }}
          >
            <span className="text-xl font-bold">Criador individual</span>
            <span className="mb-2.5 mt-0.5 text-xs leading-4 text-white">
              Quer organizar eventos fechados para poucos convidados? Selecione
              esta opção para iniciar a sua jornada na Gandaya como criador
              individual.
            </span>
            <motion.div
              className="flex w-full"
              initial={{ opacity: 0, height: '0px' }}
              animate={
                localOrganizationType === 'SOLO'
                  ? { opacity: 1, height: '32px' }
                  : { opacity: 0, height: '0px' }
              }
              transition={{ duration: 0.15 }}
              key="simple-confirm"
            >
              <Button
                text="Confirmar"
                enabled
                className="h-8 w-[100px] bg-dark-black px-4 text-sm text-white"
                onClick={() => {
                  setOrganizationType('SOLO')
                }}
                spinnerHeight="h-6"
                spinnerWidth="w-6"
              />
            </motion.div>
          </div>
          <div
            className={twMerge(
              'flex h-[40dvh] max-h-[400px] min-h-[250px] w-full max-w-[400px] cursor-pointer flex-col justify-end overflow-hidden rounded-md bg-white/20 p-4 transition-all duration-100 ease-in-out',
              localOrganizationType === 'TEAM' && 'bg-white/50',
            )}
            onClick={() => {
              setLocalOrganizationType('TEAM')
            }}
          >
            <span className="text-xl font-bold">
              Coletivo, equipe ou agência
            </span>
            <span className="mb-2.5 mt-0.5 text-xs leading-4 text-white">
              Faz parte de um grupo de pessoas que organiza eventos abertos com
              muitos convidados? Selecione esta opção para iniciar a sua jornada
              na Gandaya com acesso a ferramentas profissionais para grandes
              organizadores.
            </span>
            <motion.div
              className="flex w-full"
              initial={{ opacity: 0, height: '0px' }}
              animate={
                localOrganizationType === 'TEAM'
                  ? { opacity: 1, height: '32px' }
                  : { opacity: 0, height: '0px' }
              }
              transition={{ duration: 0.15 }}
              key="full-confirm"
            >
              <Button
                text="Confirmar"
                enabled
                className="h-8 w-[100px] bg-dark-black px-4 text-sm text-white"
                onClick={() => {
                  setOrganizationType('TEAM')
                }}
                spinnerHeight="h-6"
                spinnerWidth="w-6"
              />
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  )
}
