import { type ReactElement } from 'react'

import { Modal } from '@/components/Global/Modal'
import { deviceStore } from '@/store/device'
import { Button } from '@/components/Global/Button'

import { useDeleteLink } from '@/hooks/Link'

import { linkStore } from '@/store/link'
import { errorStore } from '@/store/error'

interface LinkConfirmDeleteModalProps {
  closeModal: () => void
  isParentClosing?: boolean
}

export function LinkConfirmDeleteModal({
  closeModal,
  isParentClosing,
}: LinkConfirmDeleteModalProps): ReactElement {
  const { isMobile } = deviceStore()
  const { removeLink, currentLink } = linkStore()
  const { showError } = errorStore()

  const { deleteLink, isLoading: isLoadingDelete } = useDeleteLink()

  async function handleDeleteLink(): Promise<void> {
    if (currentLink === null) return
    const response = await deleteLink(currentLink.id)
    if (!response.status) {
      showError('Erro ao deletar link. Tente novamente.')
      return
    }
    removeLink(currentLink)
    closeModal()
  }

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      isMobile={isMobile}
    >
      <div className="flex flex-col items-center gap-8 p-4">
        <div className="flex flex-col gap-1">
          <span className="text-3xl font-black text-black">
            Você deseja deletar este Link?
          </span>
          <span className="text-black">Esta ação não pode ser revertida.</span>
        </div>
        <div className="h-12 w-3/5">
          <Button
            text="Confirmar"
            enabled={!isLoadingDelete}
            className="bg-tonal-red text-white disabled:cursor-not-allowed disabled:bg-[#A2A2A2] disabled:text-[#656565]"
            isLoading={isLoadingDelete}
            onClick={() => {
              void handleDeleteLink()
            }}
          />
        </div>
      </div>
    </Modal>
  )
}
