import { api } from '@/services/axios'
import { type AxiosError } from 'axios'
import { useState } from 'react'

import { type ICreateWithdrawal } from '@/types/Finance'
import { isTruthy } from '@/utils/validation'

interface PostReturnProps {
  status: number
}

export const usePostCreateWithdrawal = (
  alias: string,
): {
  createWithdrawal: (body: ICreateWithdrawal) => Promise<PostReturnProps>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function createWithdrawal(
    body: ICreateWithdrawal,
  ): Promise<PostReturnProps> {
    setIsLoading(true)
    try {
      const form = new FormData()

      form.append('type', body.type)
      form.append('amount', body.amount.toString())
      form.append('description', body.description)
      form.append('accountKey', body.accountKey)
      if (isTruthy(body.receiver)) form.append('receiver', body.receiver!)
      if (isTruthy(body.invoice)) form.append('invoice', body.invoice!)

      await api.post(`/admin/withdrawals/${alias}`, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      return {
        status: 200,
      }
    } catch (error) {
      setIsLoading(false)
      const err = error as AxiosError
      return {
        status: err.response?.status ?? 500,
      }
    }
  }

  return {
    createWithdrawal,
    isLoading,
  }
}
