import { type ReactElement } from 'react'

export function LinkIcon(): ReactElement {
  return (
    <svg
      className="size-2.5 whitespace-nowrap"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.845 8.155a1.666 1.666 0 10-2.357 2.357 1.666 1.666 0 002.357-2.357zm0 0l4.31-4.31m0 0a1.666 1.666 0 102.357-2.356 1.666 1.666 0 00-2.357 2.356zm0 0l.002-.002"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
