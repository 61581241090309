import { useState, type ReactElement } from 'react'

import { CopyIcon } from '@/components/Icons/CopyIcon'
import { ExpandableItems } from '@/components/Global/ExpandableItems'
import { SwitchButton } from '@/components/Global/SwitchButton'
import { Spinner } from '@/components/Global/Spinner'

import { useUpdateLink } from '@/hooks/Link'

import { errorStore } from '@/store/error'
import { linkStore } from '@/store/link'
import { useUserPermissions } from '@/store/user'

import { type ILink } from '@/types/Links'

import { PiChartDonutLight } from 'react-icons/pi'

import { numberToPercent, numberToReais } from '@/utils/formatNumber'
import { hasClearence } from '@/utils/rbac'
import { isTruthy } from '@/utils/validation'

interface LinkCardProps {
  link: ILink
}

export function LinkCard({ link }: LinkCardProps): ReactElement {
  const [linkIsActive, setLinkIsActive] = useState(link.active)

  const { showError } = errorStore()
  const { editLink, setModalToOpen, setCurrentLink } = linkStore()
  const { eventPermissions } = useUserPermissions()

  const canEditLinks = hasClearence(eventPermissions.link, 'EDITOR')

  const linkType =
    link.discount === undefined && link.ticketSpecs === undefined
      ? 'Track'
      : link.discount !== undefined
        ? link.discount.amount !== undefined
          ? 'Amount'
          : 'Percentage'
        : 'TicketSpecPromo'

  const { updateLink, isLoading: isUpdatingLink } = useUpdateLink()

  const hasTicketSpecPromo =
    link.ticketSpecs !== undefined && link.ticketSpecs.length > 0

  const ticketSpecPromoSum = link.ticketSpecs?.reduce(
    (acc, ticketSpecPromo) => {
      return acc + ticketSpecPromo.limit
    },
    0,
  )

  function returnLinkLabel(): string {
    switch (linkType) {
      case 'Track':
        return 'UTM'
      case 'Amount':
        return `Desconto de ${numberToReais(link.discount!.amount, 2)}`
      case 'Percentage':
        return `Desconto de ${numberToPercent(
          link.discount!.percentage * 100,
          2,
        )}`
      case 'TicketSpecPromo':
        return `Liberação de ingressos`
    }
  }

  async function handleOnSwitch(): Promise<void> {
    setLinkIsActive((prev) => !prev)
    const response = await updateLink({
      linkId: link.id,
      linkType:
        linkType === 'Amount' || linkType === 'Percentage'
          ? 'Discount'
          : linkType,
      code: link.code,
      discount: link.discount,
      ticketSpecsPromo: link.ticketSpecs,
      active: !linkIsActive,
    })
    if (!response.status) {
      setLinkIsActive(response.link!.active)
      showError('Erro ao atualizar o ingresso. Tente novamente!')
    }
  }

  function handleEditLink(): void {
    const editLinkType =
      link.discount === undefined && link.ticketSpecs === undefined
        ? 'UTM'
        : link.discount !== undefined
          ? 'Discount'
          : 'TicketSpecPromo'
    editLink(link, editLinkType)
  }

  function handleOpenAnalyticsAndUsers(): void {
    setCurrentLink(link)
    setModalToOpen('AnalyticsUsers')
  }

  return (
    <div className="flex w-full justify-between rounded-lg bg-dark-black p-4">
      <div className="flex w-full flex-col justify-between gap-4">
        <div className="flex flex-col">
          <div className="flex w-full justify-between">
            <div className="flex flex-col gap-2">
              <span className="text-base font-black">{link.code}</span>
              <div className="flex flex-col gap-1">
                <span className="text-xs leading-3 text-[#e3e3e3]">
                  {returnLinkLabel()}
                </span>
                {hasTicketSpecPromo && (
                  <span className="text-xs leading-none text-white">
                    {ticketSpecPromoSum} ingresso
                    {ticketSpecPromoSum !== 1 && 's'} liberado
                    {ticketSpecPromoSum !== 1 && 's'}
                  </span>
                )}
                <span className="mb-1 text-xs font-bold leading-none text-primary-main">
                  {link.usage} ingresso
                  {link.usage !== 1 && 's'} vendido
                  {link.usage !== 1 && 's'}
                </span>
                <div className="flex items-center gap-2">
                  <SwitchButton
                    active={linkIsActive}
                    handleOnClick={() => {
                      void handleOnSwitch()
                    }}
                    disable={isUpdatingLink || !canEditLinks}
                  />
                  {isUpdatingLink && (
                    <Spinner
                      borderWidth="border-[3px]"
                      borderColor="border-primary-main/50"
                      bottomBorderColor="border-b-primary-main"
                      width="w-4"
                      height="h-4"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col items-end justify-between">
              {canEditLinks && (
                <ExpandableItems
                  canDelete={link.usage === 0}
                  onEdit={handleEditLink}
                  onDelete={() => {
                    setCurrentLink(link)
                    setModalToOpen('Delete')
                  }}
                />
              )}
              <div className="flex items-center gap-2">
                <button onClick={handleOpenAnalyticsAndUsers}>
                  <PiChartDonutLight size={22} style={{ strokeWidth: '4px' }} />
                </button>

                <button
                  onClick={() => {
                    setCurrentLink(link)
                    setModalToOpen('Url')
                  }}
                >
                  <CopyIcon strokeColor="#fff" />
                </button>
              </div>
            </div>
          </div>
        </div>
        {linkType === 'TicketSpecPromo' && (
          <div className="flex flex-col gap-4">
            {link.ticketSpecs
              ?.filter(
                (ticketSpecPromo) =>
                  ticketSpecPromo.limit > 0 || ticketSpecPromo.usage > 0,
              )
              .map((ticketSpecPromo, index) => {
                const ticketTitle = isTruthy(ticketSpecPromo.batchDescription)
                  ? `${ticketSpecPromo.batchDescription} - ${ticketSpecPromo.ticketSpecDescription}`
                  : ticketSpecPromo.ticketSpecDescription
                return (
                  <div
                    key={index}
                    className="flex flex-col rounded-lg bg-background-main px-2.5 py-4"
                  >
                    <span className="text-sm">{ticketTitle}</span>
                    <span className="text-xs text-white">
                      {ticketSpecPromo.limit} ingresso
                      {ticketSpecPromo.limit !== 1 && 's'} liberado
                      {ticketSpecPromo.limit !== 1 && 's'}
                    </span>
                    <span className="text-xs font-bold text-primary-main">
                      {ticketSpecPromo.usage} ingresso
                      {ticketSpecPromo.usage !== 1 && 's'} vendido
                      {ticketSpecPromo.usage !== 1 && 's'}
                    </span>
                  </div>
                )
              })}
          </div>
        )}
      </div>
    </div>
  )
}
