import { useState, useEffect } from 'react'
import useSWR from 'swr'

import { fetcher } from '@/services/axios'

import { type ILink } from '@/types/Links'

export const useGetLinksByEventAlias = (
  alias: string,
): {
  getLinksByEventAliasResponse: ILink[] | undefined
  isLoading: boolean
  refresh: () => void
} => {
  const [isLoading, setIsLoading] = useState(true)
  const shouldFetch = alias !== undefined
  const { data, error, mutate } = useSWR<ILink[], string>(
    shouldFetch && `/admin/events/${alias}/links`,
    fetcher,
  )

  function refresh(): void {
    void mutate()
  }

  useEffect(() => {
    setIsLoading(!(data !== undefined || error !== undefined))
  }, [data, error])

  return {
    getLinksByEventAliasResponse: data,
    isLoading,
    refresh,
  }
}
