import { create } from 'zustand'
import {
  devtools,
  persist,
  createJSONStorage,
  type StateStorage,
} from 'zustand/middleware'
import localForage from 'localforage'

import { type ICreateCommunicationCampaign } from '@/types/CommunicationCampaign'

interface CommunicationCampaignStoreProps extends ICreateCommunicationCampaign {
  campaignNamesInUse: string[]
  updateProperty: (
    prop: keyof CommunicationCampaignStoreProps,
    value: string | number | File | string[] | null,
  ) => void
  reset: () => void
}

export const communicationCampaignStore =
  create<CommunicationCampaignStoreProps>()(
    devtools(
      persist(
        (set) => ({
          name: '',
          title: '',
          content: '',
          type: 'PUSH',
          scheduledFor: new Date().toISOString(),
          estimatedSubscribers: 0,
          estimatedPrice: 0,
          xlsxFile: null,
          campaignNamesInUse: [],
          updateProperty: (
            prop: keyof CommunicationCampaignStoreProps,
            value: string | number | File | string[] | null,
          ) => {
            set((state) => ({
              ...state,
              [prop]: value,
            }))
          },
          reset: () => {
            set({
              name: '',
              title: '',
              content: '',
              type: 'PUSH',
              scheduledFor: new Date().toISOString(),
              estimatedSubscribers: 0,
              estimatedPrice: 0,
              xlsxFile: null,
              campaignNamesInUse: [],
            })
          },
        }),
        {
          name: 'communication-campaign-storage',
          storage: createJSONStorage(() => {
            return localForage as StateStorage
          }),
        },
      ),
    ),
  )

export const useCommunicationCampaignStore =
  (): CommunicationCampaignStoreProps => {
    return communicationCampaignStore((state) => state)
  }
