import { useState } from 'react'
import { type AxiosResponse, type AxiosError } from 'axios'

import { api } from '@/services/axios'
import { type IPingResponse, type IPingReturn } from '@/types/Auth/usePing'

export const usePing = (): {
  ping: () => Promise<IPingReturn>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function ping(): Promise<IPingReturn> {
    setIsLoading(true)

    try {
      const response: AxiosResponse<IPingResponse> = await api.get(`/auth/ping`)
      setIsLoading(false)
      return { status: 200, user: response.data.user }
    } catch (err) {
      const error = err as AxiosError
      setIsLoading(false)
      return { status: error.status as number }
    }
  }
  return { ping, isLoading }
}
