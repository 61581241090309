import { type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import { SlArrowLeft } from 'react-icons/sl'

export function BackButton(): ReactElement {
  const navigate = useNavigate()
  return (
    <button
      className="flex size-fit items-center gap-2 rounded-md bg-dark-black px-2 py-1"
      onClick={() => {
        navigate(-1)
      }}
    >
      <SlArrowLeft size={14} />
      <span className="text-sm font-bold">Voltar</span>
    </button>
  )
}
