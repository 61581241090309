import { type ReactNode, type ReactElement } from 'react'

interface EventRootProps {
  children: ReactNode
}

export function EventRoot({ children }: EventRootProps): ReactElement {
  return (
    <div className="relative flex size-full max-w-lg flex-col items-start justify-start overflow-x-hidden bg-background-main pb-24">
      {children}
    </div>
  )
}
