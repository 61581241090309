import { type ReactElement } from 'react'
import * as z from 'zod'

import { errorStore } from '@/store/error'
import {
  linkStore,
  useAllLinks,
  useCurrentLink,
  useRefreshLinks,
} from '@/store/link'

import { useUpdateLink } from '@/hooks/Link'

import { Button } from '@/components/Global/Button'
import { Form } from '@/components/Global/Form/Form'
import { FormInput } from '@/components/Global/Form/FormInput'

export function LinkUTMFormUpdate(): ReactElement {
  const { updateLink, isLoading: isLoadingUpdateLink } = useUpdateLink()

  const { currentLink } = useCurrentLink()
  const { allLinks } = useAllLinks()
  const { showError } = errorStore()
  const { refreshLinks } = useRefreshLinks()

  const { updateLink: updateLinkStore, closeLinkModal } = linkStore()

  let defaultValues: Record<string, string | number> | null = null
  if (currentLink !== null) {
    defaultValues = {
      code: currentLink.code,
    }
  }

  const updateUTMLink = z.object({
    code: z
      .string()
      .min(2, { message: 'O nome do código precisa ter pelo menos 2 letras.' })
      .refine(
        (value) => {
          const nameExists = allLinks.find((link) => link.code === value)
          return nameExists === undefined
        },
        {
          message: 'Já existe um código com esse nome',
        },
      )
      .refine(
        (value) => {
          const regex = /^[a-zA-Z0-9-_]+$/
          if (regex.test(value)) {
            return true
          } else {
            return false
          }
        },
        {
          message: 'O nome do link não pode ter caracteres especiais ou espaço',
        },
      ),
  })

  type FormData = z.infer<typeof updateUTMLink>

  async function handleEditLink(data: FormData): Promise<void> {
    const response = await updateLink({
      linkId: currentLink!.id,
      code: data.code,
      linkType: 'Track',
    })
    if (!response.status) {
      showError('Não foi possível atualizar o link. Tente novamente')

      return
    }
    if (response.link !== undefined) {
      updateLinkStore(response.link)
    }
    refreshLinks()
    closeLinkModal()
  }

  return (
    <Form
      formSchema={updateUTMLink}
      className="flex w-full flex-col gap-4 px-4"
      handleModalClose={(data: FormData) => {
        void handleEditLink(data)
      }}
      defaultValues={defaultValues ?? undefined}
    >
      <FormInput
        type="text"
        id="code"
        placeholder="Código do link*"
        name="code"
        autoCorrect="off"
        autoCapitalize="none"
        isInputTitle={true}
      />
      <div className="h-12 w-full max-w-[474px] self-center">
        <Button
          type="submit"
          enabled={!isLoadingUpdateLink}
          text="Salvar"
          isLoading={isLoadingUpdateLink}
        />
      </div>
    </Form>
  )
}
