import { type ReactElement } from 'react'
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  type Crop,
} from 'react-image-crop'

interface CropImageProps {
  uploadedImageUrl: string
  setCrop: (crop: Crop) => void
  crop: Crop | undefined
  isCircularCrop: boolean
  minHeight: number
  minWidth: number
  aspectRatio?: number
}

export function CropImage({
  uploadedImageUrl,
  crop,
  setCrop,
  isCircularCrop = false,
  minHeight,
  minWidth,
  aspectRatio,
}: CropImageProps): ReactElement {
  function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
  ): Crop {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 80,
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    )
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>): void {
    const { width, height } = e.currentTarget
    setCrop(centerAspectCrop(width, height, aspectRatio ?? 1))
  }

  return (
    <ReactCrop
      crop={crop}
      onChange={(_, percentCrop) => {
        setCrop(percentCrop)
      }}
      aspect={aspectRatio}
      className="max-h-[96rem] max-w-[96rem] rounded-lg"
      minHeight={minHeight}
      minWidth={minWidth}
      circularCrop={isCircularCrop}
    >
      <img
        alt="Image to crop"
        src={uploadedImageUrl}
        className="w-80 rounded-lg"
        onLoad={onImageLoad}
      />
    </ReactCrop>
  )
}
