import { useParams } from 'react-router-dom'
import { type ReactElement } from 'react'

import { useUserPermissions } from '@/store/user'

import { AnalyticsRevenueCard } from '@/components/Analytics/AnalyticsRevenueCard'
import { AnalyticsSalesCard } from '@/components/Analytics/AnalyticsSalesCard'
import { AnalyticsAgeGenderCard } from '@/components/Analytics/AnalyticsAgeGenderCard'
import { AnalyticsLinksCard } from '@/components/Analytics/AnalyticsLinksCard'
import { AnalyticsTicketsCard } from '@/components/Analytics/AnalyticsTicketsCard'
import { AnalyticsAverageTicketCard } from '@/components/Analytics/AnalyticsAverageTicketCard'
import { AnalyticsValidationCard } from '@/components/Analytics/AnalyticsValidationCard'
import { AnalyticsPageviewCard } from '@/components/Analytics/AnalyticsPageviewCard'

import { hasClearence } from '@/utils/rbac'

export function Analytics(): ReactElement {
  const { alias } = useParams()

  const { eventPermissions } = useUserPermissions()

  const canViewLinks = hasClearence(eventPermissions.link, 'VIEWER')
  const canViewTickets = hasClearence(eventPermissions.tickets, 'VIEWER')

  return (
    <div className="flex min-h-full w-full flex-col gap-4">
      {
        <main className="mt-4 grid w-full max-w-full grid-cols-1 gap-4 px-4 pb-4">
          <AnalyticsRevenueCard alias={alias!} />

          <AnalyticsSalesCard alias={alias!} />

          <AnalyticsPageviewCard alias={alias!} />

          <AnalyticsAgeGenderCard alias={alias!} />

          <AnalyticsAverageTicketCard />

          <AnalyticsValidationCard />

          {/* <AnalyticsSimilarEventCard similarEventData={similarEventData} /> */}

          {canViewLinks && <AnalyticsLinksCard alias={alias!} />}

          {canViewTickets && <AnalyticsTicketsCard alias={alias!} />}
        </main>
      }
    </div>
  )
}
