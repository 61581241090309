import { AnimatePresence, motion } from 'framer-motion'
import {
  type ReactNode,
  type ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react'

interface SnackBarMobileWrapperProps {
  children: ReactNode
  offSet?: number
  isError: boolean
}

export function SnackBarMobileWrapper({
  children,
  offSet = 10,
  isError,
}: SnackBarMobileWrapperProps): ReactElement {
  const [divOffset, setDivOffset] = useState(0)
  const divHeightRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (divHeightRef.current != null) {
      setDivOffset(-divHeightRef.current.offsetHeight - offSet)
    }
  }, [children, offSet])

  return (
    <AnimatePresence>
      {isError && (
        <motion.div
          ref={divHeightRef}
          initial={{ y: 0 }}
          animate={{ y: divOffset }}
          exit={{ y: 0 }}
          transition={{ duration: 0.5, ease: 'backInOut' }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}
