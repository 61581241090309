import { type ReactElement } from 'react'
import { Link, useParams } from 'react-router-dom'

import { type IAnalyticsData } from '@/types/Event'

import { numberToReais } from '@/utils/formatNumber'

interface AnalyticsCardProps {
  data: IAnalyticsData
}

export function AnalyticsCard({ data }: AnalyticsCardProps): ReactElement {
  const { alias } = useParams()
  return (
    <Link
      to={`/events/${alias}/analytics`}
      className="flex w-full items-center justify-between pl-4"
    >
      <div className="flex flex-col gap-4">
        <header className="text-base font-bold">Analíticos</header>
        <div className="flex justify-start gap-4">
          <div className="flex flex-col gap-1">
            <span className="text-[0.625rem] text-[#959595]">
              Faturamento nas últimas 24h
            </span>
            <span className="text-xl font-bold">
              {numberToReais(data?.revenue24h, 2)}
            </span>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[0.625rem] text-[#959595]">
              Vendas nas últimas 24h
            </span>
            <span className="text-xl font-bold">{data.sales24h}</span>
          </div>
        </div>
      </div>
    </Link>
  )
}
