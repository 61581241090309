import { useState, useEffect } from 'react'
import useSWR from 'swr'

import { fetcher } from '@/services/axios'

import { type GetRevenuesByEventAlias } from '@/types/Analytics'

import { isTruthy } from '@/utils/validation'
import { getTimeZone } from '@/utils/timeZone'

export const useGetRevenuesByEventAlias = (
  alias: string,
  time?: string | undefined,
  spec?: number | undefined,
): {
  getRevenuesByEventAlias: GetRevenuesByEventAlias | undefined
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(true)

  const url =
    `/admin/analytics/${alias}/revenue` +
    `?timezone=${getTimeZone()}` +
    (isTruthy(time) ? '&time=' + time : '') +
    (isTruthy(spec) ? '&spec=' + spec : '')

  const shouldFetch = alias !== undefined

  const { data, error } = useSWR<GetRevenuesByEventAlias, string>(
    shouldFetch && url,
    fetcher,
  )

  if (data !== undefined && data.revenueByDate.length > 0) {
    while (data.revenueByDate[0].revenue === 0) {
      data.revenueByDate.shift()
    }
    while (data.revenueByDate[data.revenueByDate.length - 1].revenue === 0) {
      data.revenueByDate.pop()
    }
  }

  useEffect(() => {
    setIsLoading(!(data !== undefined || error !== undefined))
  }, [data, error])

  return { getRevenuesByEventAlias: data, isLoading }
}
