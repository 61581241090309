import { useState } from 'react'
import { type AxiosResponse } from 'axios'

import { api } from '@/services/axios'

import { type UpdateEvent } from '@/types/CreateUpdateEvent'

interface PostReturnProps {
  status: boolean
  data?: UpdateEvent
}

export const usePutUpdateEventManagementType = (
  eventAlias: string,
): {
  updateEventManagementType: (
    managementType: 'SIMPLE' | 'FULL',
  ) => Promise<PostReturnProps>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function updateEventManagementType(
    managementType: 'SIMPLE' | 'FULL',
  ): Promise<PostReturnProps> {
    setIsLoading(true)

    try {
      const response: AxiosResponse<UpdateEvent> = await api.put(
        `/admin/events/${eventAlias}/management`,
        { managementType },
      )

      setIsLoading(false)

      return {
        status: true,
        data: response.data,
      }
    } catch (error) {
      setIsLoading(false)
      return {
        status: false,
      }
    }
  }

  return {
    updateEventManagementType,
    isLoading,
  }
}
