import { type ReactElement } from 'react'

import { Modal } from '@/components/Global/Modal'
import { Button } from '@/components/Global/Button'

import { errorStore, useCurrentError } from '@/store/error'
import { deviceStore } from '@/store/device'
import { ticketStore } from '@/store/tickets'

import { useDeleteTicketSpec } from '@/hooks/Tickets/TicketSpec'
import { useDeleteBatch } from '@/hooks/Tickets/Batch'

interface TicketsSpecConfirmDeletionModalProps {
  closeModal: () => void
  isParentClosing?: boolean
}

export function ConfirmDeletionModal({
  closeModal,
  isParentClosing,
}: TicketsSpecConfirmDeletionModalProps): ReactElement {
  // Store
  const { isError, errorMessage } = useCurrentError()
  const { isMobile } = deviceStore()
  const {
    currentTicketSpec,
    deleteTicketSpec: deleteTicketSpecStore,
    currentBatch,
    deleteBatch: deleteBatchStore,
  } = ticketStore()
  const { showError } = errorStore()

  const mustDeleteBatch = currentBatch !== undefined
  const mustDeleteTicketSpec = currentTicketSpec !== undefined

  // Nothing to delete or both must be deleted (wrong cenario)
  if (
    (!mustDeleteBatch && !mustDeleteTicketSpec) ||
    (mustDeleteBatch && mustDeleteTicketSpec)
  ) {
    closeModal()
  }

  const modalTitle = mustDeleteBatch
    ? 'Você deseja deletar este lote?'
    : 'Você deseja deletar este ingresso?'

  const modalContent = mustDeleteBatch ? (
    <span className="leading-5 text-black">
      Ao deletar o lote <strong>{currentBatch.description}</strong>, todos os
      ingressos associados a ele serão deletados. Esta ação não poderá ser
      revertida.
    </span>
  ) : (
    <span className="text-black">
      Ingresso <strong>{currentTicketSpec?.description}</strong>. Esta ação não
      poderá ser revertida.
    </span>
  )

  // Hooks
  const { deleteTicketSpec, isLoading: isLoadingDeleteTicketSpec } =
    useDeleteTicketSpec()
  const { deleteBatch, isLoading: isLoadingDeleteBatch } = useDeleteBatch()

  // Functions
  async function handleDeleteTicketSpec(): Promise<void> {
    const response = await deleteTicketSpec(currentTicketSpec!.id!)
    if (!response.status) {
      showError('Erro ao deletar o ingresso. Tente novamente!')
      return
    }
    deleteTicketSpecStore(currentTicketSpec!.id!)
    closeModal()
  }

  async function handleDeleteBatch(): Promise<void> {
    const response = await deleteBatch(currentBatch!.id!)
    if (!response.status) {
      showError('Erro ao deletar o lote. Tente novamente!')
      return
    }
    deleteBatchStore(currentBatch!.id!)
    closeModal()
  }

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      isError={isError}
      errorMessage={errorMessage}
      isMobile={isMobile}
    >
      <div className="flex flex-col items-center gap-8 p-4">
        <div className="flex flex-col gap-1">
          <span className="text-3xl font-black text-black">{modalTitle}</span>
          {modalContent}
        </div>
        <div className="h-12 w-3/5">
          <Button
            text="Confirmar"
            enabled={!isLoadingDeleteTicketSpec || !isLoadingDeleteBatch}
            className="bg-tonal-red text-white disabled:bg-[#A2A2A2] disabled:text-[#656565]"
            isLoading={isLoadingDeleteTicketSpec || isLoadingDeleteBatch}
            onClick={() => {
              if (mustDeleteTicketSpec) void handleDeleteTicketSpec()
              if (mustDeleteBatch) void handleDeleteBatch()
            }}
          />
        </div>
      </div>
    </Modal>
  )
}
