import { type Dispatch, type SetStateAction, type ReactElement } from 'react'
import { BsPlusCircle } from 'react-icons/bs'

import { DeleteIcon } from '@/components/Icons/DeleteIcon'

import {
  type LineUp,
  type ICreateEventStoreState,
  type IUpdateEventStoreState,
} from '@/types/CreateUpdateEvent'

interface CreateEventLineupInputProps {
  setCreateArtistModalIsOpen: () => void
  formStates: ICreateEventStoreState | IUpdateEventStoreState
  deleteArtistOfLineUp: (artist: LineUp) => void
  openDeleteItemModal: () => void
  setExecuteDeleteFunction: Dispatch<SetStateAction<() => void>>
}

export function CreateEventLineupInput({
  setCreateArtistModalIsOpen,
  formStates,
  deleteArtistOfLineUp,
  openDeleteItemModal,
  setExecuteDeleteFunction,
}: CreateEventLineupInputProps): ReactElement {
  return (
    <div className="flex w-full flex-col gap-2 px-4">
      <span className="text-2xl font-bold">Line Up</span>
      <div className="relative overflow-hidden rounded-lg bg-dark-black">
        <div className="absolute left-0 top-0 z-10 h-full w-4 bg-gradient-to-r from-dark-black to-transparent" />
        <div className="absolute right-0 top-0 z-10 h-full w-4 bg-gradient-to-l from-dark-black to-transparent" />
        <div className="flex w-full max-w-full items-center justify-start gap-6 overflow-x-scroll p-4">
          {formStates.lineUp !== null && formStates.lineUp.length > 0 && (
            <div className="relative flex w-fit gap-2">
              <div className={`flex w-full gap-4`}>
                {formStates.lineUp?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="relative flex w-fit items-center justify-between"
                    >
                      <div
                        className="flex h-fit w-[100px] flex-col gap-2"
                        key={index}
                      >
                        <img
                          src={
                            item.isLocalImage
                              ? item.artistImageUrl
                              : `${import.meta.env.VITE_S3 as string}/${
                                  item.artistImageUrl
                                }`
                          }
                          alt="Artist image"
                          className="size-[100px] rounded-md object-cover"
                        />
                        <span className="text-sm font-semibold text-white">
                          {item.artistName}
                        </span>
                      </div>

                      <DeleteIcon
                        onClick={() => {
                          openDeleteItemModal()
                          setExecuteDeleteFunction(() => () => {
                            deleteArtistOfLineUp(item)
                          })
                        }}
                        className="absolute -right-2 -top-2 cursor-pointer"
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          )}

          <div className="flex flex-col gap-2">
            <button
              className="flex size-[100px] items-center justify-center rounded-md bg-background-main"
              onClick={() => {
                setCreateArtistModalIsOpen()
              }}
            >
              <BsPlusCircle className="size-7 cursor-pointer" color="#656565" />
            </button>
            <div className="h-[20px] w-[100px] rounded-md bg-background-main" />
          </div>
        </div>
      </div>
    </div>
  )
}
