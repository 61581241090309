import { type ReactElement } from 'react'

import { AiFillExclamationCircle } from 'react-icons/ai'

interface SnackBarContentProps {
  message?: string
}

export function SnackBarContent({
  message,
}: SnackBarContentProps): ReactElement {
  return (
    <div className="flex w-full items-center rounded bg-tonal-red p-4 text-white drop-shadow-md">
      <AiFillExclamationCircle color="white" size={32} />
      <p className="ml-2 w-[90%] text-sm ">{message}</p>
    </div>
  )
}
