export function numberToReais(number: number, decimals = 0): string {
  const formattedNumber = number.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: decimals,
  })

  return formattedNumber
}

export function numberToPercent(number: number, decimals = 0): string {
  return new Intl.NumberFormat('default', {
    style: 'percent',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  })
    .format(number / 100)
    .replace('.', ',')
}

export function formatNumber(number: number): string {
  return new Intl.NumberFormat('pt-BR').format(number)
}
