import { api } from '@/services/axios'
import { useState } from 'react'

interface DeleteReturnProps {
  status: boolean
  message?: string
}

export const useDeleteGrant = (
  id: number,
): {
  deleteGrant: () => Promise<DeleteReturnProps>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function deleteGrant(): Promise<DeleteReturnProps> {
    setIsLoading(true)
    try {
      await api.delete(`/admin/grants/${id}`)

      setIsLoading(false)

      return {
        status: true,
      }
    } catch (error) {
      setIsLoading(false)
      return {
        status: false,
        message: 'Cortesia não pôde ser deletada',
      }
    }
  }

  return {
    deleteGrant,
    isLoading,
  }
}
