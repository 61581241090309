import { create } from 'zustand'

interface HomeProps {
  showInviteProducerModal: boolean
  setShowInviteProducerModal: (s: boolean) => void
}

export const homeStore = create<HomeProps>()((set) => ({
  showInviteProducerModal: false,
  setShowInviteProducerModal: (s: boolean) => {
    set(() => ({ showInviteProducerModal: s }))
  },
}))
