import { useEffect, type ReactElement } from 'react'

import { LoginSignup } from '@/components/Auth/LoginSignup'

import { errorStore } from '@/store/error'
import { SnackBar } from '@/components/Global/SnackBar'

export function LoginSignupPage(): ReactElement {
  const bgImageId = Math.floor(Math.random() * 10) + 1

  const { showError, showErrorWrapper, isError, errorMessage } = errorStore()

  useEffect(() => {}, [])
  return (
    <div className="relative flex min-h-full w-full flex-col items-center justify-center">
      <video
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          display: 'block',
          width: '100vw',
          height: '100vh',
          objectFit: 'cover',
        }}
        src={`https://gandaya.b-cdn.net/bg-videos/v${bgImageId}.mp4`}
        autoPlay
        loop
        muted
        playsInline
        className="bg-black blur-md brightness-50"
      />
      <div className="z-10 flex h-full w-[90%] max-w-[500px] flex-col items-start justify-center gap-4 py-8">
        <div className="flex w-full flex-col justify-center rounded-xl bg-dark-white">
          <LoginSignup showError={showError} />
        </div>
      </div>

      {showErrorWrapper && (
        <div className="fixed right-4 top-4 z-50 h-20 w-full max-w-[300px]">
          <SnackBar isError={isError} message={errorMessage} isMobile={false} />
        </div>
      )}
    </div>
  )
}
