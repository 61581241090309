import { type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'
import { useNavigate } from 'react-router-dom'

import { type OrganizationData } from '@/types/Organization'

import { organizationStore } from '@/store/organization'

interface SideBarOrganizationButtonProps {
  organization: OrganizationData
}

export function SideBarOrganizationButton({
  organization,
}: SideBarOrganizationButtonProps): ReactElement {
  const { setCurrentOrganization, currentOrganization } = organizationStore()

  const navigate = useNavigate()

  return (
    <button
      key={organization.organizer.organizationId}
      onClick={() => {
        setCurrentOrganization(organization)
        navigate('')
      }}
    >
      <img
        src={`${import.meta.env.VITE_S3 as string}/${
          organization.organizer.backgroundArtImageKey
        }`}
        alt="Organization image"
        className={twMerge(
          'aspect-square h-16 w-16 rounded-md border-2 border-transparent',
          organization.organizer.organizationId ===
            currentOrganization?.organizer.organizationId &&
            'border-primary-main',
        )}
      />
    </button>
  )
}
