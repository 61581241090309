import { useEffect, useState, type ReactElement } from 'react'

import './styles.css'

interface LoadingProps {
  hidden?: boolean
}

export function Loading({ hidden = false }: LoadingProps): ReactElement {
  const [z, setZ] = useState<string>('')

  const opacityClassName = hidden ? `opacity-0` : `opacity-100`

  useEffect(() => {
    if (hidden) {
      setTimeout(() => {
        setZ(' -z-10')
      }, 500)
    } else {
      setZ(' z-50')
    }
  }, [hidden])

  return (
    <div
      className={
        'absolute flex h-full w-full items-center justify-center bg-background-main transition-opacity duration-500 ease-out ' +
        opacityClassName +
        z
      }
    >
      <div className="m-0 block leading-3">
        <div className={`loader size-16`}></div>
      </div>
    </div>
  )
}
