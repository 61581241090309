import { useState } from 'react'
import { api } from '@/services/axios'
import { type AxiosError } from 'axios'

interface DeleteReturnProps {
  status: number
}

export const useDeleteCommunicationCampaignById = (
  organizationId: number,
): {
  deleteCommunicationCampaign: (id: number) => Promise<DeleteReturnProps>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function deleteCommunicationCampaign(
    campaignId: number,
  ): Promise<DeleteReturnProps> {
    setIsLoading(true)

    const body = {
      status: 'CANCELLED',
    }

    try {
      await api.put(
        `/organizations/${organizationId}/campaigns/${campaignId}/`,
        body,
      )

      setIsLoading(false)
      return {
        status: 200,
      }
    } catch (error) {
      setIsLoading(false)
      const err = error as AxiosError
      return {
        status: err.response?.status ?? 500,
      }
    }
  }

  return {
    deleteCommunicationCampaign,
    isLoading,
  }
}
