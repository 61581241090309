import { useState, useEffect } from 'react'
import useSWR from 'swr'

import { fetcher } from '@/services/axios'

import { type IGlobalPermissions } from '@/types/Team'

export const useGetMemberGlobalPermissions = (
  organizationId: number,
  username: string,
): {
  permissions: IGlobalPermissions | undefined
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(true)
  const shouldFetch = organizationId !== undefined && username !== undefined
  const { data, error } = useSWR<IGlobalPermissions, string>(
    shouldFetch && `/organizations/${organizationId}/user/${username}`,
    fetcher,
  )

  useEffect(() => {
    setIsLoading(!(data !== undefined || error !== undefined))
  }, [data, error])

  return {
    permissions: data,
    isLoading,
  }
}
