import { useState } from 'react'
import { api } from '@/services/axios'
import { type AxiosError } from 'axios'

import { type IEventPermissions } from '@/types/Team'

interface IEventPermissionsResponse {
  roles: {
    event: IEventPermissions
  }
}

interface IEventPermissionsReturn {
  roles?: {
    event: IEventPermissions
  }
  status: number
}

export const useGetUserEventPermissions = (): {
  getUserEventPermissions: (
    eventAlias: string,
    username: string,
  ) => Promise<IEventPermissionsReturn>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(true)

  async function getUserEventPermissions(
    eventAlias: string,
    username: string,
  ): Promise<IEventPermissionsReturn> {
    setIsLoading(true)
    const shouldFetch = eventAlias !== undefined && username !== undefined
    if (!shouldFetch) return { status: 400 }

    try {
      const response = await api.get<IEventPermissionsResponse>(
        `/admin/events/${eventAlias}/user/${username}`,
      )

      setIsLoading(false)

      return {
        status: 200,
        roles: response.data.roles,
      }
    } catch (error) {
      setIsLoading(false)
      const err = error as AxiosError
      return {
        status: err.response?.status ?? 500,
      }
    }
  }

  return {
    getUserEventPermissions,
    isLoading,
  }
}
