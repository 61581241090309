import { type ReactElement, useState, useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { type AxiosError } from 'axios'

import { AccessDenied } from '@/pages/AccessDenied'

import { DesktopWrapper } from '@/components/DesktopWrapper'
import { MobileWrapper } from '@/components/MobileWrapper'
import { Loading } from '@/components/Global/Loading/Loading'

import { wrapperStore } from '@/store/wrapper'
import { deviceStore } from '@/store/device'

import { api } from '@/services/axios'

import { getCurrentPage } from '@/utils/getCurrentPage'

import { useGetOrganizationInfosByUser } from '@/hooks/Organization'
import { organizationStore } from '@/store/organization'
import { useUserPermissions } from '@/store/user'

export function DefaultLayout(): ReactElement {
  const [accessDenied, setAccessDenied] = useState(false)

  const { isMobile } = deviceStore()
  const { setCurrentPage, hideAll } = wrapperStore()
  const {
    setCurrentOrganization,
    currentOrganization,
    setAllOrganizations,
    resetOrganizationStore,
    setRefreshOrganizations,
  } = organizationStore()
  const { setOrganizationPermissions } = useUserPermissions()

  const navigate = useNavigate()

  const {
    getOrganizationInfosByUser,
    isLoading,
    refreshOrganizationInfosByUser,
  } = useGetOrganizationInfosByUser()

  // Response interceptor
  api.interceptors.response.use(
    function (response) {
      return response
    },
    async function (error) {
      const err = error as AxiosError
      if (err.response?.status === 401) {
        setAccessDenied(true)
        hideAll()
      }
      return await Promise.reject(error)
    },
  )

  const location = useLocation()

  useEffect(() => {
    const currentPage = getCurrentPage(location.pathname)
    setCurrentPage(currentPage)
    if (accessDenied) {
      setAccessDenied(false)
    }
  }, [location])

  useEffect(() => {
    if (getOrganizationInfosByUser === undefined) return
    if (getOrganizationInfosByUser.length === 0) {
      resetOrganizationStore()
      navigate('/create-organization')
    }

    if (currentOrganization === undefined)
      setCurrentOrganization(getOrganizationInfosByUser[0])

    const currentOrgData = getOrganizationInfosByUser.find(
      (org) =>
        org.organizer.organizationId ===
        currentOrganization?.organizer.organizationId,
    )

    if (currentOrgData !== undefined) {
      setCurrentOrganization(currentOrgData)
    } else {
      setCurrentOrganization(getOrganizationInfosByUser[0])
    }

    setAllOrganizations(getOrganizationInfosByUser)
  }, [getOrganizationInfosByUser])

  useEffect(() => {
    if (
      currentOrganization?.organizer.roles !== undefined &&
      currentOrganization?.organizer.roles !== null
    ) {
      setOrganizationPermissions(currentOrganization.organizer.roles)
    } else {
      setOrganizationPermissions({
        finance: 'FORBIDDEN',
        analytics: 'FORBIDDEN',
        access: 'FORBIDDEN',
        communicationCampaign: 'FORBIDDEN',
        eventCreation: false,
        settings: false,
      })
    }
  }, [currentOrganization])

  useEffect(() => {
    setRefreshOrganizations(refreshOrganizationInfosByUser)
  }, [])

  if (isLoading) return <Loading />
  if (accessDenied) return <AccessDenied />
  return (
    <>
      {isMobile ? (
        <MobileWrapper>
          <Outlet />
        </MobileWrapper>
      ) : (
        <DesktopWrapper>
          <Outlet />
        </DesktopWrapper>
      )}
    </>
  )
}
