import { type ReactElement } from 'react'
import * as z from 'zod'

import { errorStore } from '@/store/error'
import { ticketStore } from '@/store/tickets'

import { usePostCreateBatch, usePutUpdateBatch } from '@/hooks/Tickets/Batch'

import { Form } from '../../../Global/Form/Form'
import { FormInput } from '@/components/Global/Form/FormInput'
import { Button } from '@/components/Global/Button'

import { useCurrentEvent } from '@/store/eventMenu'

const createBatchSchema = z.object({
  name: z
    .string()
    .min(3, { message: 'O nome do lote precisa ter pelo menos 3 letras.' }),
  limitPerSale: z.string().transform((data) => Number(data)),
  limitByUser: z.string().transform((data) => Number(data)),
})

type FormData = z.infer<typeof createBatchSchema>

interface BatchFormProps {
  closeModal: () => void
}

export function BatchForm({ closeModal }: BatchFormProps): ReactElement {
  const {
    currentBatch,
    addBatch: addBatchStore,
    updateBatch: updateBatchStore,
  } = ticketStore()
  const { currentEvent } = useCurrentEvent()

  let defaultValues: Record<string, string | number> | null = null
  if (currentBatch !== undefined) {
    defaultValues = {
      name: currentBatch.description,
      limitPerSale: currentBatch.numberOfAvailableTickets?.toString() ?? '',
      limitByUser: currentBatch.userLimit?.toString() ?? '',
      nextBatch: currentBatch.follower.batchDescription!,
    }
  }
  const { createBatch, isLoading: isLoadingCreateBatch } = usePostCreateBatch()
  const { updateBatch, isLoading: isLoadingUpdateBatch } = usePutUpdateBatch()
  const { showError } = errorStore()

  async function handleCreateOrUpdateBatch(data: FormData): Promise<void> {
    if (currentBatch === undefined) {
      await handleCreateBatch(data)
    } else {
      await handleUpdateBatch(data)
    }
  }

  async function handleUpdateBatch(data: FormData): Promise<void> {
    if (currentBatch !== undefined) {
      const response = await updateBatch({
        batchId: currentBatch.id!,
        description: data.name,
        numberOfAvailableTickets: data.limitPerSale,
        userLimit: data.limitByUser,
      })
      if (!response.status) {
        showError('Não foi possivel atualizar o lote. Tente novamente')
        return
      }
      if (response.batch !== undefined) {
        updateBatchStore(response.batch)
        closeModal()
      }
    }
  }

  async function handleCreateBatch(data: FormData): Promise<void> {
    const response = await createBatch({
      eventId: currentEvent!.id,
      description: data.name,
      numberOfAvailableTickets: data.limitPerSale,
      userLimit: data.limitByUser,
    })

    if (!response.status) {
      showError('Não foi possivel criar o lote. Tente novamente')
      return
    }

    if (response.batch !== undefined) {
      addBatchStore(response.batch)
      closeModal()
    }
  }

  return (
    <Form
      formSchema={createBatchSchema}
      className="flex w-4/5 flex-col gap-4"
      handleModalClose={(data: FormData): void => {
        void handleCreateOrUpdateBatch(data)
      }}
      defaultValues={defaultValues ?? undefined}
    >
      <FormInput
        type="text"
        id="name"
        placeholder="Nome do Lote *"
        name="name"
        autoCorrect="off"
        autoCapitalize="none"
        isInputTitle
      />
      <FormInput
        label={'Limite de vendas'}
        type="number"
        id="limitPerSale"
        placeholder="Ex: 1000"
        name="limitPerSale"
        autoCorrect="off"
        autoCapitalize="none"
      />
      <FormInput
        label={'Limite por usuário'}
        type="number"
        id="limitByUser"
        placeholder="Ex: 3"
        name="limitByUser"
        autoCorrect="off"
        autoCapitalize="none"
      />
      <div className="h-12 w-full max-w-[474px] self-center">
        <Button
          type="submit"
          enabled={!isLoadingCreateBatch && !isLoadingUpdateBatch}
          text="Salvar"
          isLoading={isLoadingCreateBatch || isLoadingUpdateBatch}
        />
      </div>
    </Form>
  )
}
