import { create } from 'zustand'

interface ErrorProps {
  isError: boolean
  errorMessage: string
  showErrorWrapper: boolean
  showError: (errorMessage: string, screenTime?: number) => void
}

export const errorStore = create<ErrorProps>((set) => {
  return {
    isError: false,
    errorMessage: '',
    showErrorWrapper: false,
    showError: (errorMessage: string, screenTime: number = 3000) => {
      set({
        errorMessage,
        isError: true,
        showErrorWrapper: true,
      })

      setTimeout(() => {
        set({
          isError: false,
        })
      }, screenTime)

      setTimeout(() => {
        set({
          errorMessage: '',
          showErrorWrapper: false,
        })
      }, screenTime + 400)
    },
  }
})

export const useCurrentError = (): {
  isError: boolean
  errorMessage: string
  showErrorWrapper: boolean
} => {
  return errorStore((state) => {
    const { isError, errorMessage, showErrorWrapper } = state

    return { isError, errorMessage, showErrorWrapper }
  })
}
