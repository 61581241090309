import { useState, type ReactElement } from 'react'

import { Button } from '@/components/Global/Button'
import { Modal } from '@/components/Global/Modal'
import { Selector } from '@/components/Global/Selector'

import { deviceStore } from '@/store/device'
import { linkStore } from '@/store/link'

interface LinkSelectTypeModalProps {
  closeModal: () => void
  isParentClosing?: boolean
}

export function LinkSelectTypeModal({
  closeModal,
  isParentClosing,
}: LinkSelectTypeModalProps): ReactElement {
  const [selected, setSelected] = useState<
    'UTM' | 'Discount' | 'TicketSpecPromo'
  >()

  const { isMobile } = deviceStore()
  const { createLink } = linkStore()

  function handleOnClick(): void {
    if (selected !== undefined) createLink(selected)
  }

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      isMobile={isMobile}
    >
      <div className="flex h-fit w-full flex-col items-center gap-8 p-4 text-black">
        <div className="flex w-full flex-col gap-2">
          <Selector
            title={'UTM'}
            subtitle={
              'Esse link não libera desconto. Você pode usá-lo para saber quantas pessoas compraram usando esse link.'
            }
            isSelected={selected === 'UTM'}
            onClick={(): void => {
              setSelected('UTM')
            }}
            variant={'light'}
          />
          <Selector
            title={'Desconto'}
            subtitle={
              'Ofereça um desconto em R$ ou em % aos compradores que usarem esse link.'
            }
            isSelected={selected === 'Discount'}
            onClick={(): void => {
              setSelected('Discount')
            }}
            variant={'light'}
          />
          <Selector
            title={'Liberação de ingressos'}
            subtitle={
              'Os compradores com esse link poderão acessar ingressos e lotes anteriores.'
            }
            isSelected={selected === 'TicketSpecPromo'}
            onClick={(): void => {
              setSelected('TicketSpecPromo')
            }}
            variant={'light'}
          />
        </div>
        <div className="h-12 w-full max-w-[474px] px-4">
          <Button
            enabled={selected !== undefined}
            onClick={handleOnClick}
            text="Próximo"
          />
        </div>
      </div>
    </Modal>
  )
}
