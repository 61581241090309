import { api } from '@/services/axios'
import { useState } from 'react'

interface PostReturnProps {
  status: boolean
}

export const usePostValidateTickets = (): {
  validateTickets: (eventId: number, ids: number[]) => Promise<PostReturnProps>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function validateTickets(
    eventId: number,
    ids: number[],
  ): Promise<PostReturnProps> {
    setIsLoading(true)
    try {
      const body = {
        eventId,
        ids,
      }

      await api.post(`/admin/tickets/validate`, body)

      setIsLoading(false)

      return {
        status: true,
      }
    } catch (error) {
      setIsLoading(false)
      return {
        status: false,
      }
    }
  }

  return {
    validateTickets,
    isLoading,
  }
}
