import { type ReactElement, useState } from 'react'
import { z } from 'zod'
import { parse, startOfToday, startOfDay } from 'date-fns'
import { useSearchParams } from 'react-router-dom'

import {
  usePostSignup,
  usePostVerifyUsername,
  usePostVerifyCPF,
  usePostVerifyEmail,
} from '@/hooks/Auth'

import { userStore } from '@/store/user'

import {
  formatCPF,
  formatDate,
  formatPhoneNumber,
  validateCpf,
} from '@/utils/formatString'
import { validTLDs } from '@/utils/tlds'
import { isTruthy } from '@/utils/validation'

import { FormInput } from '@/components/Global/Form/FormInput'
import { Form } from '@/components/Global/Form/Form'
import { Button } from '@/components/Global/Button'

import { SIGNUP_ERROR } from '@/errors'

interface SignupProps {
  phoneNumber: string
  phoneNumberValidationKey: string
  setState: (
    state:
      | 'login'
      | 'twoFactorPhone'
      | 'signup'
      | 'profilePicture'
      | 'communicationMethod',
  ) => void
  showError: (errorMessage: string, screenTime?: number) => void
}

export function Signup({
  phoneNumber,
  phoneNumberValidationKey,
  setState,
  showError,
}: SignupProps): ReactElement {
  const [verifyUsernameState, setVerifyUsernameState] = useState<
    null | 'loading' | 'success' | 'error'
  >(null)
  const [prevUsername, setPrevUsername] = useState('')
  const [usernameIsValidated, setUsernameIsValidated] = useState(false)

  const [verifyCPFState, setVerifyCPFState] = useState<
    null | 'loading' | 'success' | 'error'
  >(null)
  const [prevCPF, setPrevCPF] = useState('')
  const [CPFIsValidated, setCPFIsValidated] = useState(false)

  const [verifyEmailState, setVerifyEmailState] = useState<
    null | 'loading' | 'success' | 'error'
  >(null)
  const [prevEmail, setPrevEmail] = useState('')
  const [verifiedEmail, setVerifiedEmail] = useState(false)

  const countryCode = phoneNumber.slice(0, 3)
  const number = phoneNumber.slice(3)
  const isBrazillian = countryCode === '+55'

  const [searchParams] = useSearchParams()

  let defaultValues: Record<string, string | number> | null = null
  if (phoneNumber !== null) {
    defaultValues = {
      phoneNumber,
      email: searchParams.get('email') ?? '',
    }
  }

  const { postSignup, isLoading } = usePostSignup()
  const { verifyUsername } = usePostVerifyUsername()
  const { verifyCPF } = usePostVerifyCPF()
  const { verifyEmail } = usePostVerifyEmail()

  const { loginUser } = userStore()

  const createSignupSchema = z.object({
    username: z
      .string()
      .min(1, { message: 'O username é obrigatório.' })
      .min(4, { message: 'O username precisa ter pelo menos 4 caracateres.' })
      .refine(
        (value) => {
          const emojiRegex = /\p{Extended_Pictographic}/u
          return !emojiRegex.test(value)
        },
        {
          message: 'Não use emojis.',
        },
      )
      .refine(
        (value) => {
          const regex = /^[a-z0-9-_]+$/
          if (!regex.test(value)) {
            return false
          }
          return true
        },
        {
          message: 'Use apenas letras minúsculas, - ou _ no username',
        },
      )
      .refine(
        async (value) => {
          if (value !== prevUsername && value !== '') {
            setPrevUsername(value)
            return await handleUsernameVerify(value)
          }
          return usernameIsValidated
        },
        {
          message: 'Username indisponível.',
        },
      ),
    firstName: z
      .string()
      .min(1, { message: 'O nome é obrigatório.' })
      .refine(
        (value) => {
          const emojiRegex = /\p{Extended_Pictographic}/u
          return !emojiRegex.test(value)
        },
        {
          message: 'Não use emojis.',
        },
      )
      .refine(
        (value) => {
          const letterRegex = /^[a-zA-Z\u00C0-\u00FF\s]+$/
          return letterRegex.test(value)
        },
        {
          message: 'Use apenas letras.',
        },
      ),
    lastName: z
      .string()
      .min(1, { message: 'O sobrenome é obrigatório.' })
      .refine(
        (value) => {
          const emojiRegex = /\p{Extended_Pictographic}/u
          return !emojiRegex.test(value)
        },
        {
          message: 'Não use emojis.',
        },
      )
      .refine(
        (value) => {
          const letterRegex = /^[a-zA-Z\u00C0-\u00FF\s]+$/
          return letterRegex.test(value)
        },
        {
          message: 'Use apenas letras.',
        },
      ),
    email: z
      .string()
      .min(1, { message: 'O email é obrigatório.' })
      .email({ message: 'Email inválido.' })
      .refine(
        (email) => {
          const tld = email.split('.').pop()
          return isValidTLD(tld as string)
        },
        {
          message: 'Email inválido.',
        },
      )
      .refine(
        async (value) => {
          if (prevEmail !== value) {
            setPrevEmail(value)
            return await handleEmailVerify(value)
          }
          return verifiedEmail
        },
        {
          message: 'Email indisponível.',
        },
      ),
    password: z.string().min(6, {
      message: 'Sua senha precisa ter pelo menos 6 caracteres.',
    }),
    cpf: z.optional(
      z
        .string()
        .min(1, { message: 'O CPF é obrigatório.' })
        .refine((value) => validateCpf(value), {
          message: 'CPF inválido.',
        })
        .transform((value) => {
          const cleanCpf = value.replace(/\D+/g, '')
          return cleanCpf
        })
        .refine(
          async (value) => {
            if (prevCPF !== value) {
              setPrevCPF(value)
              return await handleCPFVerify(value)
            }
            return CPFIsValidated
          },
          {
            message: 'CPF indisponível.',
          },
        ),
    ),
    birthdate: z
      .string({ required_error: 'Aniver é obrigatório.' })
      .min(1, { message: 'Aniver é obrigatório.' })
      .refine(
        (date) => {
          const dateObj = parse(date, 'dd/MM/yyyy', new Date())
          const today = startOfToday()
          const olderDate = startOfDay(new Date(1900, 0, 1))

          return dateObj < today && dateObj > olderDate
        },
        {
          message: 'Aniver inválido.',
        },
      ),
  })

  type FormData = z.infer<typeof createSignupSchema>

  function isValidTLD(tld: string | undefined | null): boolean {
    if (tld === undefined || tld === null) return false

    return validTLDs.includes(tld)
  }

  async function handleUsernameVerify(username: string): Promise<boolean> {
    setVerifyUsernameState('loading')
    const response = await verifyUsername(username)
    if (response.isAvailable) {
      setVerifyUsernameState('success')
      setUsernameIsValidated(true)
      return true
    }
    setVerifyUsernameState('error')
    setUsernameIsValidated(false)
    return false
  }

  async function handleCPFVerify(cpf: string): Promise<boolean> {
    setVerifyCPFState('loading')
    const response = await verifyCPF(cpf)
    if (response.isAvailable) {
      setVerifyCPFState('success')
      setCPFIsValidated(true)
      return true
    }
    setVerifyCPFState('error')
    setCPFIsValidated(false)
    return false
  }

  async function handleEmailVerify(email: string): Promise<boolean> {
    setVerifyEmailState('loading')
    const response = await verifyEmail(email)
    if (response.isAvailable) {
      setVerifyEmailState('success')
      setVerifiedEmail(true)
      return true
    }
    setVerifyEmailState('error')
    setVerifiedEmail(false)
    return false
  }

  async function handleSignup(data: FormData): Promise<void> {
    // Check if data is filled
    const isValid =
      data.username.trim() !== '' &&
      data.firstName.trim() !== '' &&
      data.lastName.trim() !== '' &&
      data.email.trim() !== '' &&
      data.password.trim() !== '' &&
      data.birthdate.trim() !== '' &&
      (!isBrazillian || data.cpf?.trim() !== '')
    if (!isValid) {
      showError('Preencha todos os campos.')
      return
    }
    // Formatting date
    const d = parse(data.birthdate, 'dd/MM/yyyy', new Date())
    data.birthdate = d.toISOString()

    const signupData = {
      ...data,
      phoneNumber: phoneNumber.replace(/[^0-9+]/g, ''),
      phoneNumberValidationKey,
    }

    const response = await postSignup(signupData)
    if (response.status === 200 && response.user !== undefined) {
      const { id, username, firstName, lastName, email, phoneNumber, cpf } =
        response.user
      loginUser({
        id,
        username,
        firstName,
        lastName,
        email,
        phoneNumber,
        imageKey: '',
        balance: 0,
        verifiedEmail: false,
        cpf,
      })
      setState('profilePicture')
    } else {
      showError(SIGNUP_ERROR)
    }
  }

  return (
    <div className="flex max-h-screen w-full flex-col justify-between p-4 px-6">
      <h1 className="my-4 text-4xl text-text-dark">cadastro</h1>
      <h1 className="my-4 text-xl text-text-dark">
        {isBrazillian
          ? `🇧🇷 ${countryCode} ${formatPhoneNumber(number)}`
          : `🌎 ${phoneNumber}`}
      </h1>
      <div className="w-full">
        <Form
          formSchema={createSignupSchema}
          className="grid grid-cols-2 gap-2"
          handleModalClose={(data: FormData): void => {
            void handleSignup(data)
          }}
          defaultValues={defaultValues ?? undefined}
        >
          <FormInput
            label="Username"
            type="text"
            id="username"
            placeholder="ex: pedrof"
            name="username"
            autoCorrect="off"
            autoCapitalize="none"
            wrapperDivClassname="col-span-2"
            hasVerifier
            verifierState={verifyUsernameState}
          />
          <FormInput
            label="Email"
            type="text"
            id="email"
            placeholder="ex: pedrof@gandaya.io"
            name="email"
            autoCorrect="off"
            autoCapitalize="none"
            wrapperDivClassname="col-span-2"
            hasVerifier
            verifierState={verifyEmailState}
            disabled={isTruthy(searchParams.get('email'))}
          />
          <FormInput
            label="Nome"
            type="text"
            id="firstName"
            placeholder="ex: Pedro"
            name="firstName"
            autoCorrect="off"
            autoCapitalize="words"
            className="col-span-2"
          />
          <FormInput
            label="Sobrenome"
            type="text"
            id="lastName"
            placeholder="ex: Fernandes"
            name="lastName"
            autoCorrect="off"
            autoCapitalize="words"
          />
          {isBrazillian ? (
            <FormInput
              label="CPF"
              type="text"
              id="cpf"
              placeholder="123.456.789.01"
              name="cpf"
              autoCorrect="off"
              autoCapitalize="none"
              inputMode="numeric"
              onChangeFunction={formatCPF}
              hasVerifier
              verifierState={verifyCPFState}
            />
          ) : (
            <></>
          )}
          <FormInput
            label="Aniver"
            type="text"
            id="birthdate"
            placeholder="28/08/2000"
            name="birthdate"
            autoCorrect="off"
            autoCapitalize="none"
            inputMode="numeric"
            onChangeFunction={formatDate}
          />
          <FormInput
            label="Senha"
            type="password"
            id="password"
            placeholder="*********"
            name="password"
            autoCorrect="off"
            autoCapitalize="none"
            wrapperDivClassname={isBrazillian ? 'col-span-2' : ''}
            isPassword
          />
          <div className="mb-8 h-12 w-fit min-w-[140px]">
            <Button
              type="submit"
              enabled={!isLoading}
              text="Cadastrar"
              isLoading={isLoading}
              className="mt-4 px-4 py-2 text-xl font-black disabled:bg-dark-light-gray"
            />
          </div>
        </Form>
      </div>
    </div>
  )
}
