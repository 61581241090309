import { type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import { useUserPermissions } from '@/store/user'

import { hasClearence } from '@/utils/rbac'

interface TeamCardProps {
  teamImageKeys: string[]
}

export function TeamCard({ teamImageKeys }: TeamCardProps): ReactElement {
  const navigate = useNavigate()

  const { organizationPermissions } = useUserPermissions()

  const canViewTeam = hasClearence(organizationPermissions.access, 'VIEWER')

  return (
    <div className="flex w-full flex-col gap-2 rounded-lg bg-dark-black p-4">
      <span className="font-bold">Equipe</span>
      <div className="flex items-center justify-between">
        <div className="ml-4 flex">
          {teamImageKeys.map((imageKey, index) => (
            <div
              className="-ml-4 aspect-square size-8 overflow-hidden rounded-full border-2 border-dark-black"
              key={index}
            >
              <img
                src={`${import.meta.env.VITE_S3 as string}/${imageKey}`}
                alt="Team member"
                className="aspect-square size-full"
              />
            </div>
          ))}
        </div>
        {canViewTeam && (
          <button
            className="rounded-full bg-primary-main px-4 py-1 text-sm font-medium text-dark-black"
            onClick={() => {
              navigate('/team')
            }}
          >
            Gerir equipe
          </button>
        )}
      </div>
    </div>
  )
}
