import { api } from '@/services/axios'
import { useState } from 'react'

interface DeleteReturnProps {
  status: boolean
}

export const useDeleteTicketSpec = (): {
  deleteTicketSpec: (ticketSpecId: number) => Promise<DeleteReturnProps>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function deleteTicketSpec(
    ticketSpecId: number,
  ): Promise<DeleteReturnProps> {
    setIsLoading(true)
    try {
      await api.delete(`/admin/ticketSpecs/${ticketSpecId}`)

      setIsLoading(false)
      return {
        status: true,
      }
    } catch (error) {
      setIsLoading(false)
      return {
        status: false,
      }
    }
  }

  return {
    deleteTicketSpec,
    isLoading,
  }
}
