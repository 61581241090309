import { EventImage } from '@/components/CreateEventPreview/EventImage/EventImage'
import { EventLineUp } from '@/components/CreateEventPreview/EventLineUp/EventLineUp'
import { EventPlaylist } from '@/components/CreateEventPreview/EventPlaylist/EventPlaylist'
import { EventPartners } from '@/components/CreateEventPreview/EventPartners/EventPartners'
import { EventBit } from '@/components/CreateEventPreview/EventBit/EventBit'
import { EventInfo } from '@/components/CreateEventPreview/EventInfo/EventInfo'
import { EventRoot } from './EventRoot/EventRoot'
import { EventBrief } from '@/components/CreateEventPreview/EventBrief'
import { EventDescription } from '@/components/CreateEventPreview/EventDescription/EventDescription'
import { Footer } from '@/components/CreateEventPreview/Footer/Footer'

export const EventBase = {
  Root: EventRoot,
  Image: EventImage,
  Brief: EventBrief,
  Description: EventDescription,
  LineUp: EventLineUp,
  Playlist: EventPlaylist,
  Info: EventInfo,
  Partners: EventPartners,
  Bit: EventBit,
  Footer,
}
