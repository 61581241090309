import { type ReactElement, useState, useRef, useEffect } from 'react'
import { twMerge } from 'tailwind-merge'

import { Modal } from '@/components/Global/Modal'
import { Button } from '@/components/Global/Button'
import { Selector } from '@/components/Global/Selector'

import { useCurrentError, errorStore } from '@/store/error'
import { deviceStore } from '@/store/device'
import { useCurrentOrganization } from '@/store/organization'

import { isTruthy } from '@/utils/validation'
import { numberToReais } from '@/utils/formatNumber'

import { type IAccountKey } from '@/types/Finance'

import {
  usePostCreateWithdrawal,
  useGetOrganizationAccountKeys,
} from '@/hooks/Finance'

import { CREATE_WITHDRAWAL, MINIMUM_WITHDRAWAL_VALUE } from '@/errors'

interface CreateInternalWithdrawalModalProps {
  closeModal: () => void
  handleModalClose: () => void
  isParentClosing?: boolean
  alias: string
  balance: number
}

export function CreateInternalWithdrawalModal({
  closeModal,
  handleModalClose,
  isParentClosing,
  alias,
  balance,
}: CreateInternalWithdrawalModalProps): ReactElement {
  const [amount, setAmount] = useState(0)
  const [description, setDescription] = useState('')
  const [accountKeySelected, setAccountKeySelected] = useState<IAccountKey>()
  const [amountBiggerThanBalance, setAmountBiggerThanBalance] = useState(false)

  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  const { currentOrganization } = useCurrentOrganization()

  const { createWithdrawal, isLoading } = usePostCreateWithdrawal(alias)
  const { organizationAccountKeys } = useGetOrganizationAccountKeys(
    currentOrganization?.organizer.organizationId ?? 0,
  )

  const { isError, errorMessage } = useCurrentError()
  const { showError } = errorStore()
  const { isMobile } = deviceStore()

  async function handleCreateWithdrawal(): Promise<void> {
    const response = await createWithdrawal({
      type: 'INTERNAL',
      amount,
      description,
      accountKey: accountKeySelected?.accountKey ?? '',
    })

    if (response.status === 200) {
      handleModalClose()
      return
    }
    showError(CREATE_WITHDRAWAL)
  }

  useEffect(() => {
    setAmountBiggerThanBalance(amount > balance)
  }, [amount, balance])

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      isError={isError}
      errorMessage={errorMessage}
      isMobile={isMobile}
    >
      <div className="flex h-fit w-full flex-col items-center gap-8 p-4 text-black">
        <div className="flex w-full flex-col gap-0">
          <div className="flex flex-col">
            <span className="font-bold">Valor *</span>
            <input
              type="string"
              value={numberToReais(amount, 2)}
              onChange={(e) => {
                const value = e.target.value
                const cleanedValue = value
                  .replace(/[^0-9]/g, '')
                  .replace(/^0+/, '')
                const number = Number(cleanedValue)
                const finalNumber = number / 100
                setAmount(finalNumber)
              }}
              placeholder="R$ 1.928,32"
              className={twMerge(
                'border-2 border-x-white border-b-primary-main border-t-white py-2 outline-none transition-all duration-200 ease-in-out focus:rounded-lg focus:border-2 focus:border-primary-main focus:px-2',
                amountBiggerThanBalance &&
                  'border-b-tonal-red focus:border-tonal-red',
              )}
              inputMode="numeric"
            />
            <span
              className={twMerge(
                'mt-1 text-xs font-medium transition-colors duration-100 ease-in-out',
                amountBiggerThanBalance ? 'text-tonal-red' : 'text-white',
              )}
            >
              O valor não pode ser maior que o seu saldo.
            </span>
          </div>
          <div className="mb-4 flex flex-col">
            <span className="font-bold">Descrição</span>
            <textarea
              value={description}
              onChange={(e) => {
                setDescription(e.target.value)
              }}
              ref={textAreaRef}
              className="resize-none appearance-none rounded-lg border-2 border-primary-main p-2 text-sm outline-none placeholder:text-text-terciary"
              placeholder="...."
            />
          </div>
          <div className="flex flex-col">
            <span className="font-bold">Destino (PIX) *</span>
            <div className="relative">
              <div className="flex max-h-52 flex-col gap-2 overflow-scroll py-2">
                {organizationAccountKeys?.map((option) => {
                  return (
                    <Selector
                      key={option.accountKey}
                      title={option.title}
                      subtitle={option.accountKey}
                      isSelected={accountKeySelected?.title === option.title}
                      onClick={(): void => {
                        setAccountKeySelected(option)
                      }}
                      variant={'light'}
                    />
                  )
                })}
                <div className="absolute left-0 top-0 z-50 h-2 w-full bg-gradient-to-b from-white to-transparent" />
                <div className="absolute bottom-0 left-0 z-50 h-2 w-full bg-gradient-to-b from-transparent to-white" />
              </div>
            </div>
          </div>
          <div className="mt-4 h-12 w-full max-w-[300px] self-center">
            <Button
              enabled={
                isTruthy(amount) &&
                isTruthy(accountKeySelected) &&
                !isLoading &&
                !amountBiggerThanBalance
              }
              text="Confirmar"
              onClick={() => {
                if (amount < 1) {
                  showError(MINIMUM_WITHDRAWAL_VALUE)
                  return
                }
                void handleCreateWithdrawal()
              }}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}
