import { useState } from 'react'

import { api } from '@/services/axios'

import { type IOrganization } from '@/types/CreateEditOrganization'
import { isTruthy } from '@/utils/validation'
import { handleImageSize } from '@/utils/formatImage'

export const usePutEditOrganization = (
  organizationId: number,
): {
  editOrganization: (body: IOrganization) => Promise<boolean>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function editOrganization(body: IOrganization): Promise<boolean> {
    setIsLoading(true)

    const formData = new FormData()

    formData.append('name', body.name)
    formData.append('description', body.description)
    formData.append('email', body.email)
    if (isTruthy(body.profileImage)) {
      if (body.profileImage instanceof File) {
        const profileImageCompressed = await handleImageSize(
          1024,
          1024,
          1,
          body.profileImage,
        )
        formData.append('profileImage', profileImageCompressed)
      } else {
        formData.append('profileImage', body.profileImage)
      }
    }
    formData.append('pixKeys', JSON.stringify(body.pixKeys))

    try {
      await api.put(`/organizations/${organizationId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      setIsLoading(false)

      return true
    } catch (error) {
      setIsLoading(false)
      return false
    }
  }

  return {
    editOrganization,
    isLoading,
  }
}
