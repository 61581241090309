import { type ReactElement } from 'react'

import { useCurrentError } from '@/store/error'
import { deviceStore } from '@/store/device'

import { Modal } from '@/components/Global/Modal'
import { BatchForm } from '../BatchForm'

interface BatchModalProps {
  closeModal: () => void
  isParentClosing?: boolean
}

export function BatchModal({
  closeModal,
  isParentClosing,
}: BatchModalProps): ReactElement {
  const { isError, errorMessage } = useCurrentError()
  const { isMobile } = deviceStore()

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      isError={isError}
      errorMessage={errorMessage}
      isMobile={isMobile}
    >
      <div className="flex h-fit w-full flex-col items-center px-4 py-5 text-black">
        <BatchForm closeModal={closeModal} />
      </div>
    </Modal>
  )
}
