import { type ReactElement } from 'react'
import { LuCalendar } from 'react-icons/lu'

import { datesToFormat, generateEventTime } from '@/utils/formatData'

import {
  type ICreateEventStoreState,
  type IUpdateEventStoreState,
} from '@/types/CreateUpdateEvent'
import { isTruthy } from '@/utils/validation'

interface CreateEventLocationInfoCardProps {
  setSelectDateModalIsOpen: () => void
  formStates: ICreateEventStoreState | IUpdateEventStoreState
}

export function CreateEventDateInfoCard({
  setSelectDateModalIsOpen,
  formStates,
}: CreateEventLocationInfoCardProps): ReactElement {
  const { formattedStartDate, formattedEndDate, formattedMaxEntranceDate } =
    generateEventTime(
      formStates.startDate ?? '',
      formStates.endDate ?? '',
      formStates.maxEntranceDate,
    )

  return (
    <div
      className="flex w-full cursor-pointer items-center justify-between gap-4 rounded-lg bg-dark-black p-4"
      onClick={() => {
        setSelectDateModalIsOpen()
      }}
    >
      <div className="flex grow flex-col items-start justify-start">
        <span className={`text-base font-bold leading-7 text-primary-main`}>
          {datesToFormat(formStates.startDate!, formStates.endDate!)}
        </span>
        <span className="text-sm font-normal leading-5 text-text-secondary">
          Início {formattedStartDate}
        </span>
        {isTruthy(formattedMaxEntranceDate) && (
          <span className="text-sm font-normal leading-5 text-text-secondary">
            {`Entrada máxima ${formattedMaxEntranceDate}`}
          </span>
        )}
        <span className="text-sm font-normal leading-5 text-text-secondary">
          Término {formattedEndDate}
        </span>
      </div>
      <div>
        <div className="flex size-8 items-center justify-center rounded-full bg-white">
          <LuCalendar className="text-[#2e2e2e]" />
        </div>
      </div>
    </div>
  )
}
