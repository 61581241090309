import { type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

interface SwitchProps {
  label?: string
  active: boolean
  disable?: boolean
  handleOnClick: () => void
}

export function SwitchButton({
  label,
  active = false,
  handleOnClick,
  disable = false,
}: SwitchProps): ReactElement {
  function handleToggle(): void {
    handleOnClick()
  }

  return (
    <div className={twMerge('relative h-[18px] w-[34px]')}>
      <label
        className={twMerge(
          'relative',
          disable ? 'hover:cursor-not-allowed' : 'hover:cursor-pointer',
        )}
      >
        <input
          type="checkbox"
          className="invisible absolute"
          checked={active}
          readOnly
          onClick={handleToggle}
          disabled={disable}
        />
        <div
          className={twMerge(
            'transition-color absolute h-[18px] w-[30px] rounded-full border bg-background-main duration-200 ease-out ',
            active ? 'border-primary-main' : 'border-[#A3A3A3]',
            disable && 'border-[#A3A3A3]',
          )}
        ></div>
        <div
          className={twMerge(
            'transition-color absolute left-[3px] top-[3px] aspect-square h-3 w-3 rounded-full duration-200 ease-out',
            active ? 'left-[15px] bg-primary-main' : ' bg-[#A3A3A3]',
            disable && 'bg-[#A3A3A3]',
          )}
        ></div>
        {label !== null && (
          <div className="ml-3 font-medium text-gray-700">{label}</div>
        )}
      </label>
    </div>
  )
}
