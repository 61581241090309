import { type ReactElement } from 'react'

import { Modal } from '@/components/Global/Modal'
import { Button } from '@/components/Global/Button'

import { deviceStore } from '@/store/device'

interface GenerateCheckInCodeModalProps {
  closeModal: () => void
  isParentClosing: boolean
  isError: boolean
  errorMessage: string
  onClick: () => Promise<void>
}

export function GenerateCheckInCodeModal({
  closeModal,
  isParentClosing,
  isError,
  errorMessage,
  onClick,
}: GenerateCheckInCodeModalProps): ReactElement {
  const { isMobile } = deviceStore()
  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      isError={isError}
      errorMessage={errorMessage}
      isMobile={isMobile}
    >
      <div className="flex h-fit w-full flex-col items-center gap-8 p-4 text-black">
        <div className="flex w-full flex-col justify-between gap-4">
          <div className="flex flex-col gap-2">
            <span className="text-3xl font-black">Gerar um novo código?</span>
            <span className="leading-5">
              Ao gerar um novo código, não será mais possível usar o anterior.
              Deseja continuar?
            </span>
          </div>
          <div className="h-12 w-full max-w-[200px] self-center">
            <Button
              text="Confirmar"
              enabled
              className="bg-tonal-red text-white"
              onClick={onClick}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}
