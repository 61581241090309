import { type ReactElement, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { ticketStore } from '@/store/tickets'

import { useGetTickets } from '@/hooks/Tickets'

import { Loading } from '@/components/Global/Loading/Loading'
import { TicketsSimpleView } from '@/components/Tickets/TicketsSimpleView'
import { TicketsFullView } from '@/components/Tickets/TicketsFullView'
import { TicketsSelectManagementType } from '@/components/Tickets/TicketsSelectManagementType'

export function Tickets(): ReactElement {
  const { alias } = useParams()

  const { tickets, isLoading } = useGetTickets(alias!)

  const { managementType, setManagementType, setBatches, setTicketsSpecs } =
    ticketStore()

  useEffect(() => {
    if (tickets !== undefined) {
      if (tickets.managementType === 'SIMPLE') {
        setManagementType('SIMPLE')
        setTicketsSpecs(tickets.ticketsSpecs!)
      } else if (tickets.managementType === 'FULL') {
        const tempTicketSpect = tickets.batches!.flatMap((batch) => {
          return batch.ticketSpecs!.map((ticketSpec) => {
            return ticketSpec
          })
        })
        const tempBatches = tickets.batches!.map((batch) => {
          return {
            ...batch,
            ticketSpecs: undefined,
          }
        })
        setManagementType('FULL')
        setTicketsSpecs(tempTicketSpect)
        setBatches(tempBatches)
      } else {
        setManagementType(undefined)
        setTicketsSpecs([])
        setBatches([])
      }
    }
  }, [tickets])

  if (isLoading) return <Loading />
  return (
    <div className="relative flex min-h-full w-full flex-col gap-4 p-4">
      {managementType === 'SIMPLE' ? (
        <TicketsSimpleView />
      ) : managementType === 'FULL' ? (
        <TicketsFullView />
      ) : (
        <TicketsSelectManagementType />
      )}
    </div>
  )
}
