import {
  type IPermissions,
  type IEventPermissions,
  type IOrganizationPermissions,
} from '@/types/Team'

export function permissionsDeepEqual(
  obj1?: IPermissions,
  obj2?: IPermissions,
): boolean {
  if (obj1 === undefined || obj2 === undefined) return false
  const keys1 = Object.keys(obj1) as Array<keyof IPermissions>
  const keys2 = Object.keys(obj2) as Array<keyof IPermissions>

  if (keys1.length !== keys2.length) {
    return false
  }

  // Iterate over each key in obj1
  for (const key of keys1) {
    const val1 = obj1[key]
    const val2 = obj2[key]

    // Check if both values are objects
    if (typeof val1 === 'object' && typeof val2 === 'object') {
      // If they are objects, ensure they are both IEventPermissions or IOrganizationPermissions
      if (
        !isEqualEventPermissions(
          val1 as IEventPermissions,
          val2 as IEventPermissions,
        ) &&
        !isEqualOrganizationPermissions(
          val1 as IOrganizationPermissions,
          val2 as IOrganizationPermissions,
        )
      ) {
        return false
      }
    } else {
      // Compare primitive values directly
      if (val1 !== val2) {
        return false
      }
    }
  }

  return true
}

// Helper function to compare IEventPermissions objects
function isEqualEventPermissions(
  obj1: IEventPermissions,
  obj2: IEventPermissions,
): boolean {
  const eventKeys = Object.keys(obj1) as Array<keyof IEventPermissions>

  for (const key of eventKeys) {
    if (obj1[key] !== obj2[key]) {
      return false
    }
  }

  return true
}

// Helper function to compare IOrganizationPermissions objects
function isEqualOrganizationPermissions(
  obj1: IOrganizationPermissions,
  obj2: IOrganizationPermissions,
): boolean {
  const orgKeys = Object.keys(obj1) as Array<keyof IOrganizationPermissions>

  for (const key of orgKeys) {
    if (obj1[key] !== obj2[key]) {
      return false
    }
  }

  return true
}
