import { api } from '@/services/axios'
import { useState } from 'react'

import { type IOrganizatioDTO } from '@/types/CreateEditOrganization'

interface PostReturnProps {
  status: boolean
  organizationData?: IOrganizatioDTO
}

export const useGetOrganizationById = (
  id: number,
): {
  getOrganizationData: () => Promise<PostReturnProps>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function getOrganizationData(): Promise<PostReturnProps> {
    setIsLoading(true)
    try {
      const response = await api.get<IOrganizatioDTO>(`/organizations/${id}`)

      setIsLoading(false)

      return {
        status: true,
        organizationData: response.data,
      }
    } catch (error) {
      setIsLoading(false)
      return {
        status: false,
      }
    }
  }

  return {
    getOrganizationData,
    isLoading,
  }
}
