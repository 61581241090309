import { useState, type ReactElement, useEffect, useRef } from 'react'
import { twMerge } from 'tailwind-merge'
import { useParams } from 'react-router-dom'

import { SwitchButton } from '@/components/Global/SwitchButton'
import { AnimatedNumber } from '@/components/Analytics/AnimatedNumber'

import { numberToReais } from '@/utils/formatNumber'

import { BsChevronDown } from 'react-icons/bs'

import { useGetAverageTicketPrice } from '@/hooks/Analytics'

interface AverageTicket {
  batchDescription: string
  specs: Array<{
    id: number
    description: string
    revenue: number
    quantity: number
    active: boolean
  }>
}

export function AnalyticsAverageTicketCard(): ReactElement {
  const [showTickets, setShowTickets] = useState(false)
  const [averageTicket, setAverageTicket] = useState(0)
  const [averageTicketPrices, setAverageTicketPrices] =
    useState<AverageTicket[]>()

  const ticketsDivRef = useRef<HTMLDivElement>(null)

  const { alias } = useParams()

  const { averageTicketPricesData } = useGetAverageTicketPrice(alias!)

  function toggleTicketActive(id: number): void {
    setAverageTicketPrices(
      averageTicketPrices?.map((ticketBatch) => {
        ticketBatch.specs.map((spec) => {
          if (spec.id === id) {
            spec.active = !spec.active
          }
          return spec
        })
        return ticketBatch
      }),
    )
  }

  function calculateAverageTicket(): void {
    let totalRevenue = 0
    let totalQuantity = 0
    averageTicketPrices?.forEach((batch) => {
      batch.specs.forEach((spec) => {
        if (spec.active) {
          totalRevenue += spec.revenue
          totalQuantity += spec.quantity
        }
      })
    })
    const tempAverageTicket = totalRevenue / totalQuantity
    setAverageTicket(tempAverageTicket > 0 ? tempAverageTicket : 0)
  }

  const setMaxHeight = (showTickets: boolean): void => {
    if (ticketsDivRef.current != null) {
      const contentElement = ticketsDivRef.current
      const contentHeight = contentElement.scrollHeight
      contentElement.style.maxHeight = showTickets
        ? `${contentHeight}px`
        : '0px'
    }
  }

  useEffect(() => {
    calculateAverageTicket()
  }, [averageTicketPrices])

  useEffect(() => {
    if (averageTicketPricesData !== undefined) {
      const newAverageTicketPrices = averageTicketPricesData?.map(
        (ticketBatch) => {
          return {
            ...ticketBatch,
            specs: ticketBatch.specs.map((spec) => {
              return { ...spec, active: true }
            }),
          }
        },
      )
      setAverageTicketPrices(newAverageTicketPrices)
    }
  }, [averageTicketPricesData])

  return (
    <div className="flex w-full flex-col justify-between gap-4 rounded-lg bg-dark-black p-4">
      <header className="flex">
        <span className="text-xl font-black leading-4">Ticket médio</span>
      </header>
      <main className="flex flex-col ">
        <AnimatedNumber val={averageTicket} className="text-4xl font-black" />
        {averageTicketPrices !== undefined &&
          averageTicketPrices.length > 0 && (
            <>
              <div
                className="my-2 grid max-h-0 w-full grid-cols-1 gap-4 overflow-hidden transition-all duration-500 ease-in-out desktop:grid-cols-2"
                ref={ticketsDivRef}
              >
                {averageTicketPrices?.map((batch, index) => (
                  <div
                    key={index}
                    className="flex h-fit w-full flex-col gap-2 rounded-lg bg-background-main p-4"
                  >
                    <span className="font-bold">{batch.batchDescription}</span>
                    <div className="flex flex-col gap-1">
                      {batch.specs.map((spec) => (
                        <div
                          key={spec.description}
                          className="flex items-center justify-between rounded-lg bg-dark-black p-2 text-sm"
                        >
                          <div className="flex flex-col">
                            <span className="text-sm font-medium">
                              {spec.description}
                            </span>
                            <span className="text-xs text-text-secondary">
                              {`${numberToReais(spec.revenue)} em ${
                                spec.quantity
                              }
                          venda${spec.quantity !== 1 ? 's' : ''}`}
                            </span>
                          </div>
                          <SwitchButton
                            active={spec.active}
                            handleOnClick={() => {
                              toggleTicketActive(spec.id)
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              <div
                className={twMerge(
                  'flex w-fit items-center justify-between gap-2 hover:cursor-pointer',
                )}
                onClick={() => {
                  setMaxHeight(!showTickets)
                  setShowTickets(!showTickets)
                }}
              >
                <span className="text-xs font-medium">Ingressos</span>
                <BsChevronDown
                  color="#fff"
                  size={12}
                  className={twMerge(
                    'transition-transform duration-500 ease-in-out',
                    showTickets && '-rotate-180',
                  )}
                  style={{ strokeWidth: '1px' }}
                />
              </div>
            </>
          )}
      </main>
    </div>
  )
}
