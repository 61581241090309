import { type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

import { numberToReais } from '@/utils/formatNumber'
import { isTruthy } from '@/utils/validation'

import { type IBatch } from '@/types/Tickets'

interface AnalyticsTicketsCardFullViewProps {
  batches: IBatch[]
}

export function AnalyticsTicketsCardFullView({
  batches,
}: AnalyticsTicketsCardFullViewProps): ReactElement {
  return (
    <main className="flex w-full flex-col gap-6">
      {batches.map((batch) => {
        const totalTicketsSold = batch.ticketSpecs?.reduce(
          (accumulator, ticketSpec) =>
            accumulator + ticketSpec.numberOfSoldTickets!,
          0,
        )

        return (
          <div className="flex w-full flex-col gap-2" key={batch.id}>
            <div className="flex flex-col">
              <span className="text-base font-black leading-5">
                {batch.description}
              </span>
              {totalTicketsSold !== 0 ? (
                <span className="text-sm font-bold leading-4 text-primary-main">
                  {totalTicketsSold} venda
                  {totalTicketsSold === 1 ? '' : 's'}
                </span>
              ) : (
                <span className="text-sm leading-3">
                  Sem ingressos vendidos
                </span>
              )}
            </div>

            <div className="flex w-full flex-wrap gap-4">
              {batch.ticketSpecs?.map((ticketSpec) => {
                return (
                  <div
                    className={twMerge(
                      'flex w-[calc(50%-0.5rem)] flex-col gap-1 rounded-lg border bg-background-main p-4',
                      ticketSpec.active
                        ? 'border-primary-main'
                        : 'border-background-main',
                    )}
                    key={ticketSpec.id}
                  >
                    <div className="flex flex-col gap-1">
                      <span className="text-sm font-bold leading-4">
                        {ticketSpec.description}
                      </span>
                      <span className="text-sm leading-3">
                        {numberToReais(ticketSpec.price, 2)}
                      </span>
                    </div>
                    {isTruthy(ticketSpec.numberOfSoldTickets) ? (
                      <span className="text-sm font-bold leading-3 text-primary-main">
                        {ticketSpec.numberOfSoldTickets} venda
                        {ticketSpec.numberOfSoldTickets! === 1 ? '' : 's'}
                      </span>
                    ) : (
                      <span className="text-sm leading-3">0 vendas</span>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}
    </main>
  )
}
