import { create } from 'zustand'

interface RoutingProps {
  showLogin: boolean
  toPath: string
  isPinging: boolean
  setToPath: (s: string) => void
  setShowLogin: (n: boolean) => void
  setIsPinging: (p: boolean) => void
}

export const routingStore = create<RoutingProps>()((set) => ({
  showLogin: false as boolean,
  toPath: '',
  isPinging: true as boolean,
  setShowLogin: (n: boolean) => {
    set(() => ({ showLogin: n }))
  },
  setToPath: (s: string) => {
    set(() => ({ toPath: s }))
  },
  setIsPinging: (p: boolean) => {
    set(() => ({ isPinging: p }))
  },
}))
