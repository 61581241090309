import { type ElementType, type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

import { Button } from '@/components/Global/Button'

import { GoSearch } from 'react-icons/go'

interface SearchBarProps {
  searchParam: string
  setSearchParam: (searchParam: string) => void
  handleSearch: () => void
  isSearching: boolean
  placeholder: string
  className?: string
  variant?: 'primary' | 'dark'
}

export function SearchBar({
  searchParam,
  setSearchParam,
  handleSearch,
  isSearching,
  placeholder,
  className,
  variant = 'primary',
}: SearchBarProps): ReactElement {
  return (
    <>
      <div
        className={twMerge(
          'flex items-center justify-between rounded-lg border-2',
          className,
          variant === 'primary' && 'border-primary-main',
          variant === 'dark' && 'border-dark-black',
        )}
      >
        <div className=" flex w-4/5 flex-col p-2">
          <span
            className={twMerge(
              'text-[0.625rem] font-bold',
              variant === 'primary' && 'text-white',
              variant === 'dark' && 'text-black',
            )}
          >
            {placeholder}
          </span>
          <input
            type="text"
            className={twMerge(
              'w-full bg-transparent font-bold text-white focus:border-0 focus:outline-none',
              variant === 'primary' && 'text-white',
              variant === 'dark' && 'text-black',
            )}
            onChange={(e) => {
              setSearchParam(e.target.value)
            }}
            value={searchParam}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleSearch()
            }}
          />
        </div>
        <div className="mr-1 size-12">
          <Button
            icon={GoSearch as ElementType}
            enabled={!isSearching && searchParam !== ''}
            onClick={() => {
              handleSearch()
            }}
            className={twMerge(
              'rounded-[4px]',
              variant === 'primary' && 'bg-primary-main',
              variant === 'dark' && 'bg-dark-black disabled:bg-[#A2A2A2]',
            )}
            isLoading={isSearching}
            iconClassName={twMerge(
              variant === 'primary' && 'text-background-main',
              variant === 'dark' && 'text-white',
            )}
          />
        </div>
      </div>
    </>
  )
}
