import { useEffect, type ReactElement } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { LoginSignupPage } from '../pages/LoginSignup'
import { routingStore } from '../store/routing'

export function AuthRouter(): ReactElement {
  const navigate = useNavigate()
  const { setToPath } = routingStore()

  useEffect(() => {
    if (location.pathname !== '/login') {
      setToPath(location.pathname)
      navigate('/login')
    }
  }, [])
  return (
    <Routes>
      <Route path="/*" element={<LoginSignupPage />} />
    </Routes>
  )
}
