import { create } from 'zustand'

interface DeviceProps {
  isMobile: boolean
  setIsMobile: (s: boolean) => void
}

export const deviceStore = create<DeviceProps>()((set) => ({
  isMobile: true,
  setIsMobile: (s: boolean) => {
    set(() => ({ isMobile: s }))
  },
}))
