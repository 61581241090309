import { api } from '@/services/axios'
import { type IUserData, type IUserOfEventReturn } from '@/types/List'
import { type AxiosError } from 'axios'
import { useState } from 'react'

export const useGetList = (): {
  getUsers: (alias: string, page: number) => Promise<IUserOfEventReturn>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function getUsers(
    alias: string,
    page: number = 0,
  ): Promise<IUserOfEventReturn> {
    const take = 50
    const skip = 50 * page
    setIsLoading(true)
    const shouldFetch = alias !== undefined
    if (!shouldFetch) {
      setIsLoading(false)
      return {
        status: 400,
      }
    }

    try {
      const response = await api.get<IUserData[]>(
        `/admin/events/${alias}/users?skip=${skip}&take=${take}`,
      )
      setIsLoading(false)
      return {
        status: 200,
        users: response.data,
      }
    } catch (err) {
      const error = err as AxiosError
      setIsLoading(false)
      return {
        status: error.response?.status ?? 0,
      }
    }
  }

  return { getUsers, isLoading }
}
