import { useRef, type ReactElement, useState, useEffect } from 'react'

import {
  type ICreateEventStoreState,
  type IUpdateEventStoreState,
} from '@/types/CreateUpdateEvent'

interface CreateEventDescriptionProps {
  formStates: ICreateEventStoreState | IUpdateEventStoreState
  updateAnyState:
    | ((update: Partial<ICreateEventStoreState>) => void)
    | ((update: Partial<IUpdateEventStoreState>) => void)
}

export function CreateEventDescription({
  formStates,
  updateAnyState,
}: CreateEventDescriptionProps): ReactElement {
  function handleChange(evt: React.ChangeEvent<HTMLTextAreaElement>): void {
    const val = evt.target?.value

    if (val === '') {
      setEventDescription(val)
      updateAnyState({ eventDescription: '' })
      return
    }
    if (val !== '' && val !== null) {
      updateAnyState({ eventDescription: val })
    }

    setEventDescription(val)
  }

  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  const [eventDescription, setEventDescription] = useState(
    formStates.eventDescription,
  )

  // useAutosizeTextArea(textAreaRef.current, value ?? '')

  useEffect(() => {
    if (textAreaRef !== null) {
      textAreaRef.current!.style.height = '0px'
      const scrollHeight = textAreaRef.current!.scrollHeight

      textAreaRef.current!.style.height = scrollHeight + 'px'
    }
  }, [textAreaRef, eventDescription, formStates.eventDescription])

  useEffect(() => {
    setEventDescription(formStates.eventDescription)
  }, [formStates.eventDescription])

  return (
    <div className="flex w-full flex-col gap-2 px-4">
      <span className="text-2xl font-bold">Descrição</span>
      <textarea
        className="resize-none appearance-none rounded-lg bg-dark-black p-4 text-sm outline-none placeholder:text-[#656565] "
        placeholder="Adicione a descrição do evento"
        ref={textAreaRef}
        value={eventDescription ?? formStates.eventDescription ?? ''}
        onChange={handleChange}
      />
    </div>
  )
}
