import { type ReactElement, forwardRef, type ForwardedRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { Spinner } from '@/components/Global/Spinner'

import { isTruthy } from '@/utils/validation'

interface FooterProps {
  title?: string
  value?: string | undefined
  subtitle?: string
  buttonText: string
  buttonEnabled: boolean
  buttonOnClick: () => void
  isLoading?: boolean
  children?: ReactElement
  error?: ReactElement
  secondaryDisabled?: boolean
  ref?: ForwardedRef<HTMLDivElement>
  hideButton?: boolean
}

export const Footer = forwardRef(
  (
    {
      title,
      value,
      subtitle,
      buttonText,
      buttonEnabled,
      buttonOnClick,
      isLoading = false,
      children,
      error,
      secondaryDisabled = false,
      hideButton = false,
    }: FooterProps,
    ref: ForwardedRef<HTMLDivElement>, // Declare the type for ref
  ): ReactElement => {
    const variations = {
      enabled: 'bg-primary-main text-black',
      disabled: secondaryDisabled
        ? 'bg-primary-dark text-white'
        : 'bg-dark-light-gray text-dark-dark-gray',
    }

    return (
      <div className="fixed bottom-0 z-20 w-full" ref={ref}>
        {error}
        {children}
        <div className="relative z-20 bg-dark-black p-4 transition-height duration-200 ease-linear">
          <div className="flex items-center justify-between bg-dark-black">
            <div className="flex flex-col">
              {isTruthy(title) && (
                <span className="text-sm leading-5 text-text-secondary">
                  {title}
                </span>
              )}
              {isTruthy(value) && (
                <div className="flex items-end">
                  <span className="text-2xl font-extrabold leading-6 text-white">
                    {value !== undefined && value}
                  </span>
                  {isTruthy(subtitle) && (
                    <span className="ml-2 text-sm leading-3 text-text-secondary">
                      {subtitle}
                    </span>
                  )}
                </div>
              )}
            </div>
            {!hideButton && (
              <button
                className={twMerge(
                  'relative h-12 rounded-full text-lg font-bold transition-colors duration-200 ease-in-out ',
                  variations[buttonEnabled ? 'enabled' : 'disabled'],
                  title === undefined &&
                    value === undefined &&
                    subtitle === undefined
                    ? 'w-full'
                    : 'w-40',
                )}
                disabled={!buttonEnabled}
                onClick={buttonOnClick}
              >
                <div
                  className={`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transition-opacity duration-[400ms] ease-in-out ${
                    isLoading ? 'opacity-100' : 'opacity-0'
                  }`}
                >
                  <Spinner
                    borderWidth="border-4"
                    borderColor="border-[#232323]/50"
                    bottomBorderColor="border-b-[#232323]"
                  />
                </div>

                <div
                  className={`transition-opacity duration-200 ease-in-out ${
                    isLoading ? 'opacity-0' : 'opacity-100'
                  }`}
                >
                  {buttonText}
                </div>
              </button>
            )}
          </div>
        </div>
      </div>
    )
  },
)

Footer.displayName = 'Footer'
