import { useState, type ReactElement, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'

import { Loading } from '@/components/Global/Loading/Loading'
import { LinkTicketModal } from '@/components/Links/LinkTicketModal'
import { LinkCard } from '@/components/Links/LinkCard'
import { LinkDiscountModal } from '@/components/Links/LinkDiscountModal'
import { LinkSelectTypeModal } from '@/components/Links/LinkSelectTypeModal'
import { LinkUTMModal } from '@/components/Links/LinkUTMModal'
import { LinkSearchBar } from '@/components/Links/LinkSearchBar'
import { LinkTableRow } from '@/components/Links/LinkTableRow'
import { LinkTableHeader } from '@/components/Links/LinkTableHeader'
import { LinkDesktopHeader } from '@/components/Links/LinkDesktopHeader'
import { LinkAnalyticsUsersModal } from '@/components/Links/LinkAnalyticsUsersModal'
import { LinkUrlModal } from '@/components/Links/LinkUrlModal'
import { LinkConfirmDeleteModal } from '@/components/Links/LinkConfirmDeleteModal'

import {
  linkStore,
  useAllLinks,
  useLinkModal,
  useRefreshLinks,
} from '@/store/link'
import { deviceStore } from '@/store/device'

import { useGetLinksByEventAlias } from '@/hooks/Link'

import { type ILink } from '@/types/Links'

export interface IOptions {
  filterZeros: boolean
  orderAlphabetically: boolean
  orderByDate: boolean
  orderBySales: boolean
}

export function Links(): ReactElement {
  const [searchParam, setSearchParam] = useState('')
  const [renderedLinks, setRenderedLinks] = useState<ILink[]>([])
  const [options, setOptions] = useState<IOptions>({
    filterZeros: false,
    orderAlphabetically: false,
    orderByDate: false,
    orderBySales: true,
  })

  const mainDivRef = useRef<HTMLDivElement>(null)

  const { alias } = useParams()

  const {
    getLinksByEventAliasResponse,
    isLoading: isLoadingLink,
    refresh,
  } = useGetLinksByEventAlias(alias!)

  const { setAllLinks, setModalToOpen } = linkStore()
  const { isMobile } = deviceStore()

  const { allLinks } = useAllLinks()
  const { modalToOpen, isParentClosing, setIsParentClosing } = useLinkModal()
  const { setRefreshLinks } = useRefreshLinks()

  useEffect(() => {
    if (getLinksByEventAliasResponse !== undefined) {
      setAllLinks(getLinksByEventAliasResponse)
      setRenderedLinks(getLinksByEventAliasResponse)
    }
    setRefreshLinks(refresh)
  }, [getLinksByEventAliasResponse])

  function handleFilterSortSearch(): void {
    let tempRenderedLinks = allLinks

    if (options.filterZeros) {
      tempRenderedLinks = tempRenderedLinks.filter((link) => {
        return link.usage > 0
      })
    }

    if (options.orderAlphabetically) {
      tempRenderedLinks = tempRenderedLinks.sort((a, b) => {
        const codeA = a.code.toLowerCase()
        const codeB = b.code.toLowerCase()
        if (codeA < codeB) return -1
        if (codeA > codeB) return 1
        return 0
      })
    }

    if (options.orderByDate) {
      tempRenderedLinks = tempRenderedLinks.sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      })
    }

    if (options.orderBySales) {
      tempRenderedLinks = tempRenderedLinks.sort((a, b) => {
        return b.usage - a.usage
      })
    }

    if (searchParam !== '') {
      tempRenderedLinks = tempRenderedLinks.filter((link) => {
        return link.code.toLowerCase().includes(searchParam.toLowerCase())
      })
    }

    setRenderedLinks(tempRenderedLinks)
  }

  function handleCloseModal(): void {
    setIsParentClosing(true)
    setTimeout(setIsParentClosing, 200, false)
    setTimeout(setModalToOpen, 400, null)
  }

  useEffect(() => {
    if (searchParam === '') handleFilterSortSearch()
  }, [searchParam])

  useEffect(() => {
    handleFilterSortSearch()
  }, [options, allLinks])

  if (isLoadingLink) return <Loading hidden={!isLoadingLink} />
  return (
    <div ref={mainDivRef} className="relative flex size-full flex-col gap-4">
      <div className="px-4 pt-4">
        {isMobile ? (
          <LinkSearchBar
            searchParam={searchParam}
            setSearchParam={setSearchParam}
            handleSearch={handleFilterSortSearch}
            isSearching={false}
            placeholder="Pesquisar Link"
            options={options}
            setOptions={setOptions}
          />
        ) : (
          <LinkDesktopHeader
            searchParam={searchParam}
            setSearchParam={setSearchParam}
            handleSearch={handleFilterSortSearch}
            options={options}
            setOptions={setOptions}
          />
        )}
      </div>

      {isMobile ? (
        <div className="mx-4 mt-11 pb-4">
          <ul className="col w-full gap-4 desktop:columns-2 ">
            {renderedLinks.map((link) => {
              return (
                <li key={link.code} className="mb-4 break-inside-avoid-column">
                  <LinkCard key={link.code} link={link} />
                </li>
              )
            })}
          </ul>
          {allLinks.length === 0 && (
            <div className="flex w-full justify-center">
              <span>Adicione um link a esse evento.</span>
            </div>
          )}
        </div>
      ) : (
        <div className="mt-4 flex w-full flex-col gap-0 px-4">
          {allLinks.length === 0 ? (
            <div className="flex w-full justify-center">
              <span>Adicione um link a esse evento.</span>
            </div>
          ) : (
            <>
              <LinkTableHeader />
              <div className="flex w-full flex-col gap-2">
                {renderedLinks.map((link) => {
                  return <LinkTableRow key={link.code} link={link} />
                })}
              </div>
            </>
          )}
        </div>
      )}

      {modalToOpen === 'NewLink' && (
        <LinkSelectTypeModal
          closeModal={handleCloseModal}
          isParentClosing={isParentClosing}
        />
      )}
      {modalToOpen === 'UTM' && (
        <LinkUTMModal
          closeModal={handleCloseModal}
          isParentClosing={isParentClosing}
        />
      )}
      {modalToOpen === 'Discount' && (
        <LinkDiscountModal
          closeModal={handleCloseModal}
          isParentClosing={isParentClosing}
        />
      )}
      {modalToOpen === 'TicketSpecPromo' && (
        <LinkTicketModal
          closeModal={handleCloseModal}
          isParentClosing={isParentClosing}
        />
      )}
      {modalToOpen === 'AnalyticsUsers' && (
        <LinkAnalyticsUsersModal
          closeModal={handleCloseModal}
          isParentClosing={isParentClosing}
        />
      )}
      {modalToOpen === 'Url' && (
        <LinkUrlModal
          closeModal={handleCloseModal}
          isParentClosing={isParentClosing}
        />
      )}
      {modalToOpen === 'Delete' && (
        <LinkConfirmDeleteModal
          closeModal={handleCloseModal}
          isParentClosing={isParentClosing}
        />
      )}
    </div>
  )
}
