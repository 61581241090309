import { useState, type ReactElement } from 'react'
import { useAnimate } from 'framer-motion'

import { Spinner } from '@/components/Global/Spinner'

import { BsChevronRight, BsThreeDotsVertical } from 'react-icons/bs'
import { HiOutlinePencil } from 'react-icons/hi2'
import { TbTrash } from 'react-icons/tb'

interface ExpandableItemsProps {
  canDelete: boolean
  isLoading?: boolean
  onEdit: () => void
  onDelete: () => void
}

export function ExpandableItems({
  canDelete,
  isLoading,
  onEdit,
  onDelete,
}: ExpandableItemsProps): ReactElement {
  const [runAnimation, setRunAnimation] = useState(true)
  const [scope, animate] = useAnimate()

  function handleOnClick(): void {
    if (runAnimation) {
      void animate(
        '.buttons',
        { width: '100%', marginLeft: '0.5rem' },
        { duration: 0.4, ease: 'easeInOut' },
      )
      void animate(
        '.dots',
        { opacity: 0, display: 'none' },
        { duration: 0.2, ease: 'easeInOut' },
      )
      void animate(
        '.chevron',
        { opacity: 1, display: 'flex' },
        { duration: 0.2, ease: 'easeInOut' },
      )

      setRunAnimation(false)
      return
    }
    void animate(
      '.buttons',
      { marginLeft: 0, width: '0%' },
      { duration: 0.4, ease: 'easeInOut' },
    )

    void animate(
      '.chevron',
      { opacity: 0, display: 'none' },
      { duration: 0.2, ease: 'easeInOut' },
    )

    void animate(
      '.dots',
      { opacity: 1, display: 'flex' },
      { duration: 0.2, ease: 'easeInOut' },
    )
    setRunAnimation(true)
  }

  return (
    <div className="flex justify-end" ref={scope}>
      <button
        className="dots flex items-end justify-center"
        onClick={() => {
          handleOnClick()
        }}
      >
        <BsThreeDotsVertical size={16} />
      </button>
      <button
        className="chevron hidden items-end justify-center opacity-0"
        onClick={() => {
          handleOnClick()
        }}
      >
        <BsChevronRight size={16} />
      </button>
      <div className="buttons flex w-0 items-end gap-2 overflow-hidden">
        {isLoading !== undefined && isLoading && (
          <Spinner
            borderWidth="border-2"
            borderColor="border-primary-main/50"
            bottomBorderColor="border-b-primary-main"
            width="w-4"
            height="h-4"
          />
        )}
        {canDelete && (
          <button onClick={onDelete}>
            <TbTrash
              size={18}
              style={{
                strokeWidth: '1.5px',
              }}
            />
          </button>
        )}

        <button
          onClick={() => {
            onEdit()
            handleOnClick()
          }}
        >
          <HiOutlinePencil size={16} />
        </button>
      </div>
    </div>
  )
}
