import { type ReactElement } from 'react'

interface SvgComponentProps {
  className?: string
  strokeColor: string
  size?: number
}

export function CopyIcon({
  strokeColor,
  size = 20,
  ...props
}: SvgComponentProps): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g stroke={strokeColor} strokeWidth={1.3125}>
        <path d="M15.75 9.625c0-2.474 0-3.713-.77-4.48-.767-.77-2.005-.77-4.48-.77H7.875c-2.474 0-3.713 0-4.48.77-.77.767-.77 2.006-.77 4.48V14c0 2.474 0 3.713.77 4.48.767.77 2.006.77 4.48.77H10.5c2.475 0 3.713 0 4.48-.77.77-.767.77-2.006.77-4.48V9.625z" />
        <path d="M15.75 16.625A2.625 2.625 0 0018.375 14V8.75c0-3.3 0-4.95-1.026-5.974-1.024-1.026-2.674-1.026-5.974-1.026h-3.5A2.625 2.625 0 005.25 4.375" />
      </g>
    </svg>
  )
}
