import {
  useState,
  useRef,
  type ReactElement,
  type Dispatch,
  type SetStateAction,
  useEffect,
} from 'react'
import { BsChevronDown } from 'react-icons/bs'

import { cropImageStore } from '@/store/cropImage'

import { datesToFormat } from '@/utils/formatData'

import { DeleteIcon } from '@/components/Icons/DeleteIcon'
import { UploadIcon } from '@/components/Icons/UploadIcon'

import {
  type ICreateEventStoreState,
  type IUpdateEventStoreState,
} from '@/types/CreateUpdateEvent'
import { isTruthy } from '@/utils/validation'

interface CreateEventBriefProps {
  setSelectDateModalIsOpen: (state: boolean) => void
  setSelectLocationModalIsOpen: (state: boolean) => void
  setStartCrop: () => void
  formStates: ICreateEventStoreState | IUpdateEventStoreState
  updateAnyState:
    | ((update: Partial<ICreateEventStoreState>) => void)
    | ((update: Partial<IUpdateEventStoreState>) => void)
  openDeleteItemModal: () => void
  setExecuteDeleteFunction: Dispatch<SetStateAction<() => void>>
  openTitleImageInfoModal: () => void
}

export function CreateEventBrief({
  setSelectDateModalIsOpen,
  setSelectLocationModalIsOpen,
  setStartCrop,
  formStates,
  updateAnyState,
  openDeleteItemModal,
  setExecuteDeleteFunction,
  openTitleImageInfoModal,
}: CreateEventBriefProps): ReactElement {
  const { setCurrentImage, setParamsOfCrop, setCurrentModalOfImage } =
    cropImageStore()

  const [eventName, setEventName] = useState(formStates.eventName)
  // const [showInfo, setShowInfo] = useState(true)

  const ref = useRef<(HTMLInputElement & HTMLImageElement) | null>(null)

  function handleOnSubmit(e: React.ChangeEvent<HTMLInputElement>): void {
    e.preventDefault()

    if (e.target.files !== null) {
      const image = e.target.files[0]
      setCurrentImage(URL.createObjectURL(image))
      setCurrentModalOfImage('titleImage')
      setParamsOfCrop(false, 100, 100, null)
      setStartCrop()
    }
  }

  function handleRemoveTitleImage(): void {
    updateAnyState({ titleImage: null })
  }

  useEffect(() => {
    setEventName(formStates.eventName)
  }, [formStates.eventName])
  return (
    <div className="flex flex-col rounded-lg bg-dark-black p-4">
      <input
        className="appearence-none h-5 bg-dark-black text-lg font-bold outline-none placeholder:text-[#656565]"
        value={eventName ?? formStates.eventName ?? ''}
        placeholder="Nome do evento *"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.currentTarget.value === '') {
            setEventName(e.currentTarget.value)
            updateAnyState({ eventName: null })
            return
          }
          setEventName(e.currentTarget.value)
        }}
        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.currentTarget.value === '') {
            updateAnyState({ eventName: null })
            return
          }
          updateAnyState({ eventName: e.currentTarget.value })
        }}
      />
      <div className="relative">
        <label
          htmlFor="titleImage"
          className="flex w-[200px] cursor-pointer items-center justify-center gap-2.5 "
        >
          <div className="size-full">
            {formStates.titleImage === null ? (
              <div className="my-4 flex size-full items-center justify-center gap-2.5 rounded-lg border-2 border-dashed px-4 py-6 ">
                <UploadIcon iconColor="#ffffff" className="size-6" />
                <span className=" text-center text-xs">
                  Adicione uma foto do título do seu evento
                </span>
              </div>
            ) : (
              <div className="relative my-2 w-fit">
                <img
                  className={`aspect-auto h-12`}
                  src={
                    formStates.titleImage.isLocalImage
                      ? formStates.titleImage.url
                      : `${import.meta.env.VITE_S3 as string}/${
                          formStates.titleImage.url
                        }`
                  }
                  alt="Imagem de título"
                  ref={ref}
                />
                {formStates.titleImage !== null && (
                  <DeleteIcon
                    className="absolute -right-4 -top-2"
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      openDeleteItemModal()
                      setExecuteDeleteFunction(() => () => {
                        handleRemoveTitleImage()
                      })
                    }}
                  />
                )}
              </div>
            )}
          </div>

          <input
            id="titleImage"
            className="hidden"
            type="file"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleOnSubmit(e)
            }}
            onClick={(e: React.FormEvent<HTMLInputElement>) => {
              if (formStates.titleImage === null) {
                e.preventDefault()
                openTitleImageInfoModal()
              }
              const target = e.target as HTMLButtonElement
              target.value = ''
            }}
          />
        </label>
      </div>

      <div
        className="flex cursor-pointer items-center gap-1"
        onClick={() => {
          setSelectDateModalIsOpen(true)
        }}
      >
        <span className="text-sm font-bold text-primary-main">
          {isTruthy(formStates.startDate) ? (
            <>{datesToFormat(formStates.startDate!, formStates.endDate!)}</>
          ) : (
            'Selecione a data do seu evento *'
          )}
        </span>
        <BsChevronDown size={12} />
      </div>
      <span
        className="cursor-pointer text-xs text-text-terciary"
        onClick={() => {
          setSelectLocationModalIsOpen(true)
        }}
      >
        {formStates?.location?.name ?? 'Escolha o local do evento'}
      </span>
    </div>
  )
}
