import { type ReactElement } from 'react'

import { SlArrowRight } from 'react-icons/sl'
import { twMerge } from 'tailwind-merge'

import { type IUser } from '@/types/Team'

interface TeamMemberCardProps {
  isMobile: boolean
  userData: IUser
  isClickable?: boolean
  onClick?: () => void
  events?: string[]
}

export function TeamMemberCard({
  isMobile,
  userData,
  isClickable = true,
  onClick,
  events,
}: TeamMemberCardProps): ReactElement {
  return (
    <div
      className={twMerge(
        'relative mb-2 flex w-full min-w-[300px] break-inside-avoid-column flex-col gap-2 rounded-md border border-[#1e1e1e] bg-[#1e1e1e] p-2',
        isClickable &&
          'transition-colors duration-100 ease-in-out desktop:hover:cursor-pointer desktop:hover:border-primary-main desktop:hover:bg-dark-black',
      )}
      onClick={() => {
        isClickable && onClick !== undefined && onClick()
      }}
    >
      <div className="flex w-full items-center gap-4">
        <img
          src={`${import.meta.env.VITE_S3 as string}/${userData.imageKey}`}
          alt="User profile image"
          className="aspect-square size-16 rounded-full"
        />
        <div className="flex w-full flex-col gap-1.5">
          <span className="text-base font-bold leading-4">
            {userData.firstName} {userData.lastName}
          </span>
          <span className="text-sm leading-3">@{userData.username}</span>
        </div>
      </div>
      {events !== undefined && (
        <div className="ml-4 flex w-full">
          {events?.map((event, index) => {
            return (
              <img
                key={index}
                src={`${import.meta.env.VITE_S3 as string}/${event}`}
                alt="Event image"
                className="-ml-4 aspect-square size-10 rounded-lg border-2 border-[#1e1e1e]"
              />
            )
          })}
        </div>
      )}
      {isMobile && (
        <div className="absolute right-2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <SlArrowRight color="#7c7c7c" size={12} />
        </div>
      )}
    </div>
  )
}
