import { useEffect, type ReactElement, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Button } from '@/components/Global/Button'
import { Modal } from '@/components/Global/Modal'
import { Spinner } from '@/components/Global/Spinner'
import { TicketSelector } from '@/components/Global/TicketSelector'

import { usePostCreateGrant } from '@/hooks/Grant'
import { useGetTickets } from '@/hooks/Tickets'

import { errorStore, useCurrentError } from '@/store/error'
import { useCurrentEvent } from '@/store/eventMenu'
import {
  grantStore,
  useAllEmailOrPhoneInList,
  useAllUsersWithGrant,
} from '@/store/grant'

import { type GranteesData, type ProductsData } from '@/types/Grant'
import { validateEmail } from '@/utils/validation'
import { deviceStore } from '@/store/device'

interface IFullTicketSpec {
  title: string
  id: number
  price: number
  quantity?: number
  limit?: number
  numberOfAvailableTickets?: number
}

interface GrantSelectTicketModalProps {
  closeModal: () => void
  handleModalClose: () => void
  isParentClosing?: boolean
  isLoading?: boolean
}

export function GrantSelectTicketModal({
  closeModal,
  isParentClosing,
  handleModalClose,
}: GrantSelectTicketModalProps): ReactElement {
  const [ticketSpecs, setTicketSpecs] = useState<IFullTicketSpec[]>()

  const { alias } = useParams()
  const { isError, errorMessage } = useCurrentError()
  const { emailOrPhoneList } = useAllEmailOrPhoneInList()

  const { tickets } = useGetTickets(alias!)
  const { createGrant, isLoading: isLoadingCreateGrant } = usePostCreateGrant()

  const { setAllUsers, resetEmailOrPhoneList } = grantStore()
  const { allUsers } = useAllUsersWithGrant()
  const { showError } = errorStore()
  const { currentEvent } = useCurrentEvent()
  const { isMobile } = deviceStore()

  useEffect(() => {
    if (tickets?.managementType === 'SIMPLE') {
      const result = tickets?.ticketsSpecs?.map((ticketSpec) => {
        return {
          title: ticketSpec.description ?? '',
          id: ticketSpec.id ?? 0,
          price: ticketSpec.price ?? 0,
          limit: ticketSpec.limit ?? 0,
          numberOfAvailableTickets: ticketSpec.numberOfAvailableTickets ?? 0,
        }
      })
      setTicketSpecs(result ?? [])
    } else if (tickets?.managementType === 'FULL') {
      const result = tickets?.batches?.flatMap((batch) => {
        const ticketSpecsWithBatchDescription = batch.ticketSpecs?.map(
          (ticketSpec) => {
            return {
              title: `${batch.description} - ${ticketSpec.description}`,
              id: ticketSpec.id ?? 0,
              price: ticketSpec.price ?? 0,
              limit: ticketSpec.limit ?? 0,
              numberOfAvailableTickets:
                ticketSpec.numberOfAvailableTickets ?? 0,
            }
          },
        )

        return [...ticketSpecsWithBatchDescription!]
      })
      setTicketSpecs(result ?? [])
    }
  }, [tickets])

  function updateQuantity(ticketSpecId: number, quantity: number): void {
    if (ticketSpecs !== undefined) {
      const ticketSpecsUpdated = ticketSpecs.map((ticketSpec) => {
        if (ticketSpec.id === ticketSpecId) {
          ticketSpec.quantity = quantity
          return ticketSpec
        }
        return ticketSpec
      })
      setTicketSpecs(ticketSpecsUpdated)
    }
  }

  async function makeRequestBody(): Promise<void> {
    const ticketSpecSelected = getTicketSpecsSelected()
    if (ticketSpecSelected.length < 1) {
      showError(
        'Voce precisa selecionar pelo menos um ingresso. Tente novamente.',
      )

      return
    }

    if (ticketSpecs !== undefined) {
      const result = ticketSpecs.reduce<ProductsData[]>(
        (acc: ProductsData[], prev: IFullTicketSpec): ProductsData[] => {
          if (prev.quantity === 0) {
            return acc
          }
          return [
            ...acc,
            {
              quantity: prev.quantity!,
              ticketSpecId: prev.id,
            },
          ]
        },
        [],
      )

      const grantees = [] as GranteesData[]

      emailOrPhoneList.forEach((item) => {
        if (validateEmail(item)) {
          grantees.push({
            email: item,
          })
        } else {
          if (item.slice(0, 3) !== '+55') {
            item = '+55' + item
          }
          grantees.push({
            number: item,
          })
        }
      })

      const response = await createGrant({
        grantees,
        products: result.filter((grant) => grant.quantity > 0),
        eventId: currentEvent!.id,
      })

      if (response.status && response.data !== undefined) {
        if (allUsers !== null) {
          const newAllusers = [...allUsers, ...response.data]
          setAllUsers(newAllusers)
        } else {
          setAllUsers(response.data)
        }
        resetEmailOrPhoneList()
        handleModalClose()
      }
    }
  }

  function getTicketSpecsSelected(): IFullTicketSpec[] {
    if (ticketSpecs !== undefined) {
      const arrayOfTicketSpecSelected = ticketSpecs?.reduce<IFullTicketSpec[]>(
        (acc: IFullTicketSpec[], curr: IFullTicketSpec) => {
          if (curr.quantity! > 0) {
            return [...acc, curr]
          }
          return acc
        },
        [],
      )
      return arrayOfTicketSpecSelected
    }
    return []
  }

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      isError={isError}
      errorMessage={errorMessage}
      isMobile={isMobile}
    >
      <div className="flex h-fit w-full flex-col items-center gap-8 p-4 text-black">
        <div className="relative w-full px-4">
          <ul className="relative flex max-h-72 flex-col gap-2 overflow-scroll py-2">
            {ticketSpecs !== undefined ? (
              ticketSpecs.map((ticketSpec, index) => {
                return (
                  <li key={index}>
                    <TicketSelector
                      ticketSpecId={ticketSpec.id}
                      title={ticketSpec.title}
                      price={ticketSpec.price}
                      limit={
                        ticketSpec.numberOfAvailableTickets === 0
                          ? undefined
                          : ticketSpec.numberOfAvailableTickets!
                      }
                      quantitySelected={ticketSpec.quantity ?? 0}
                      finalPrice={ticketSpec.price ?? 0}
                      hasFee={false}
                      variant="light"
                      setQuantity={(ticketSpecId: number, quantity: number) => {
                        updateQuantity(ticketSpecId, quantity)
                      }}
                    />
                  </li>
                )
              })
            ) : (
              <div className="flex items-center justify-center">
                <Spinner
                  borderWidth="border-4"
                  borderColor="border-background-main/50"
                  bottomBorderColor="border-b-background-main"
                />
              </div>
            )}
          </ul>
          {ticketSpecs !== undefined && ticketSpecs?.length > 2 && (
            <>
              <div className="absolute left-0 top-0 z-50 h-2 w-full bg-gradient-to-b  from-[#ffffff] to-transparent" />
              <div className="absolute bottom-0 left-0 z-50 h-2 w-full bg-gradient-to-b  from-transparent to-[#ffffff]" />
            </>
          )}
        </div>
        <div className="h-12 w-full">
          <Button
            enabled={
              !isLoadingCreateGrant && !(getTicketSpecsSelected().length < 1)
            }
            text="Enviar"
            onClick={makeRequestBody}
            isLoading={isLoadingCreateGrant}
          />
        </div>
      </div>
    </Modal>
  )
}
