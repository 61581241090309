import { type ReactElement } from 'react'

import { EventThumbnail } from '@/components/Event/EventThumbnail'
import { Button } from '@/components/Global/Button'
import { Modal } from '@/components/Global/Modal'
import { TicketCounter } from '@/components/Global/TicketCounter'

import { useDeleteGrant } from '@/hooks/Grant'

import { useCurrentError } from '@/store/error'
import { grantStore, useCurrentUserWithGrant } from '@/store/grant'
import { useUserPermissions } from '@/store/user'

import { datesToFormat } from '@/utils/formatData'
import { numberToReais } from '@/utils/formatNumber'
import { formatPhoneNumber } from '@/utils/formatString'
import { deviceStore } from '@/store/device'

import { hasClearence } from '@/utils/rbac'
import { isTruthy } from '@/utils/validation'

interface GrantInfoModalProps {
  closeModal: () => void
  handleModalClose: () => void
  isParentClosing?: boolean
  isLoading?: boolean
}

export function GrantInfoModal({
  closeModal,
  isParentClosing,
  handleModalClose,
}: GrantInfoModalProps): ReactElement {
  const { isError, errorMessage } = useCurrentError()

  const { currentUser } = useCurrentUserWithGrant()
  const { isMobile } = deviceStore()
  const { eventPermissions } = useUserPermissions()
  const { removeUser } = grantStore()

  const { deleteGrant, isLoading } = useDeleteGrant(currentUser!.id)

  const canEditGrant = hasClearence(eventPermissions.grant, 'EDITOR')

  async function handleOnClick(): Promise<void> {
    const response = await deleteGrant()

    if (response.status) {
      handleModalClose()
      removeUser(currentUser!.id)
    }
  }

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      isError={isError}
      errorMessage={errorMessage}
      isMobile={isMobile}
    >
      <div className="flex h-fit w-full flex-col items-center gap-8 p-4 text-black">
        <div className="flex w-full flex-col gap-4">
          <strong className="break-words text-2xl font-black">
            {currentUser?.user !== undefined
              ? `${currentUser?.user.firstName} ${currentUser?.user?.lastName}`
              : `${
                  currentUser?.communication.email ??
                  formatPhoneNumber(currentUser!.communication.number!)
                }`}
          </strong>

          {currentUser?.user !== undefined && (
            <div className="flex items-center gap-4 rounded-lg bg-[#f4f4f4] p-3">
              <img
                src={`${import.meta.env.VITE_S3 as string}/${
                  currentUser?.user?.imageKey
                }`}
                alt="Foto perfil"
                className="aspect-square w-12 min-w-[48px] rounded-full"
              />
              <div className="flex w-[calc(100%-7rem)] grow flex-col">
                <span className="truncate font-bold text-black">
                  @{currentUser?.user?.username}
                </span>
                <span className="truncate text-black">
                  {currentUser?.user?.email}
                </span>
              </div>
            </div>
          )}
          <div className="flex flex-col gap-3 rounded-lg bg-[#f4f4f4] px-4 pb-3 pt-4">
            <EventThumbnail
              imageKey={currentUser?.event.eventImage ?? ''}
              title={currentUser?.event.name}
              date={datesToFormat(
                currentUser!.event.date,
                currentUser!.event.endDate,
              )}
              location={currentUser?.event.address ?? ''}
              briefVariant="dark"
              imageVariant="small"
              highlighted={false}
            />
            <div className="relative">
              <div className="flex max-h-44 flex-col gap-1 overflow-scroll py-2">
                {currentUser?.products.map((item, index) => {
                  const ticketTitle = isTruthy(item.batchDescription)
                    ? `${item.batchDescription} - ${item.ticketSpecDescription}`
                    : item.ticketSpecDescription
                  return (
                    <div key={index} className="flex max-w-full gap-4">
                      <div className="size-12">
                        <TicketCounter
                          value={item.quantity}
                          direction={1}
                          variant="dark"
                          isInfinite={false}
                        />
                      </div>
                      <div className="flex max-w-[80%] flex-col justify-center">
                        <span className="truncate text-base font-bold">
                          {ticketTitle}
                        </span>
                        <span className="text-xs text-[#a3a3a3]">
                          {numberToReais(item.price, 2)}
                        </span>
                      </div>
                    </div>
                  )
                })}
              </div>

              <div className="absolute left-0 top-0 z-50 h-2 w-full bg-gradient-to-b  from-[#f4f4f4] to-transparent" />
              <div className="absolute bottom-0 left-0 z-50 h-2 w-full bg-gradient-to-b  from-transparent to-[#f4f4f4]" />
            </div>
          </div>
          {canEditGrant && (
            <div className="mt-4 h-12">
              <Button
                className={
                  currentUser?.user !== undefined || isLoading
                    ? 'bg-[#f4f4f4]'
                    : 'bg-tonal-red text-white'
                }
                enabled={currentUser?.user === undefined && !isLoading}
                isLoading={isLoading}
                text={
                  currentUser?.user !== undefined
                    ? 'Ingressos Recebidos'
                    : 'Cancelar envio'
                }
                onClick={handleOnClick}
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}
