import { type ReactElement } from 'react'

import { useCurrentOrganization } from '@/store/organization'
import { userStore } from '@/store/user'

import { isTruthy } from '@/utils/validation'

interface HomeHeaderProps {
  handleOnClick: () => void
}

export function HomeHeader({ handleOnClick }: HomeHeaderProps): ReactElement {
  function handleButtonClick(): void {
    handleOnClick()
  }

  const { currentOrganization: currentOrganzationStore } =
    useCurrentOrganization()
  const { username, imageKey } = userStore()

  return (
    <header className="flex items-center justify-between px-7 py-4">
      <div className="flex flex-col gap-2">
        <strong className="text-base font-bold text-white">
          {currentOrganzationStore?.organizer.name}
        </strong>
        <div className="flex gap-2">
          {isTruthy(imageKey) && (
            <img
              src={`${import.meta.env.VITE_S3 as string}/${imageKey}`}
              alt="profile image"
              className="size-6 rounded-full"
            />
          )}

          <span>@{username}</span>
        </div>
      </div>
      <div>
        <button onClick={handleButtonClick}>
          {isTruthy(
            currentOrganzationStore?.organizer.backgroundArtImageKey,
          ) && (
            <img
              src={`${
                import.meta.env.VITE_S3 as string
              }/${currentOrganzationStore?.organizer.backgroundArtImageKey}`}
              alt="Imagem da organização"
              className="size-12 rounded-full"
            />
          )}
        </button>
      </div>
    </header>
  )
}
