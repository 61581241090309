import { fetcher } from '@/services/axios'
import { useState, useEffect } from 'react'
import useSWR from 'swr'

import { type IGetRevenueByEventAlias } from '@/types/Event'

export const useGetRevenueByEventAlias = (
  alias: string,
): {
  getRevenueByEventAlias: IGetRevenueByEventAlias | undefined
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(true)
  const shouldFetch = alias !== undefined
  const { data, error } = useSWR<IGetRevenueByEventAlias, string>(
    shouldFetch && `/admin/analytics/${alias}/peek`,
    fetcher,
  )

  useEffect(() => {
    setIsLoading(!(data !== undefined || error !== undefined))
  }, [data, error])

  return {
    getRevenueByEventAlias: data,
    isLoading,
  }
}
