import { motion } from 'framer-motion'
import { useEffect, type ReactElement, useState } from 'react'
import { useParams } from 'react-router-dom'

import { errorStore } from '@/store/error'
import { linkStore, useAllLinks } from '@/store/link'
import { useCurrentEvent } from '@/store/eventMenu'

import { useCreateManyLinks } from '@/hooks/Link'
import { useGetTickets } from '@/hooks/Tickets'

import { Button } from '@/components/Global/Button'
import { LinkMultipleCodesInput } from '../../LinkMultipleCodesInput'
import { Spinner } from '@/components/Global/Spinner'
import { TicketSelector } from '@/components/Global/TicketSelector'

import { type CreateManyLinks } from '@/types/Links'

interface IFullTicketSpec {
  title: string
  id: number
  price: number
  quantity?: number
  limit?: number
}

export function LinkTicketsFormCreate(): ReactElement {
  const [newCodes, setNewCodes] = useState<string[]>([])
  const [ticketSpecs, setTicketSpecs] = useState<IFullTicketSpec[]>([])

  const { alias } = useParams()

  const { createManyLinks, isLoading: isLoadingCreateLink } =
    useCreateManyLinks()
  const { showError } = errorStore()
  const { currentEvent } = useCurrentEvent()
  const { allLinks } = useAllLinks()
  const { addLink, closeLinkModal } = linkStore()

  const { tickets } = useGetTickets(alias!)

  useEffect(() => {
    if (tickets?.managementType === 'SIMPLE') {
      const result = tickets?.ticketsSpecs?.map((ticketSpec) => {
        return {
          title: ticketSpec.description ?? '',
          id: ticketSpec.id ?? 0,
          price: ticketSpec.price ?? 0,
          limit: ticketSpec.limit ?? 0,
        }
      })
      setTicketSpecs(result ?? [])
    } else if (tickets?.managementType === 'FULL') {
      const result = tickets?.batches?.flatMap((batch) => {
        const ticketSpecsWithBatchDescription = batch.ticketSpecs?.map(
          (ticketSpec) => {
            return {
              title: `${batch.description} - ${ticketSpec.description}`,
              id: ticketSpec.id ?? 0,
              price: ticketSpec.price ?? 0,
              limit: ticketSpec.limit ?? 0,
            }
          },
        )

        return [...ticketSpecsWithBatchDescription!]
      })
      setTicketSpecs(result ?? [])
    }
  }, [tickets])

  function updateQuantity(ticketSpecId: number, quantity: number): void {
    if (ticketSpecs !== undefined) {
      const ticketSpecsUpdated = ticketSpecs.map((ticketSpec) => {
        if (ticketSpec.id === ticketSpecId) {
          ticketSpec.quantity = quantity
          return ticketSpec
        }
        return ticketSpec
      })
      setTicketSpecs(ticketSpecsUpdated)
    }
  }

  async function handleCreateBatchLink(): Promise<void> {
    const body: CreateManyLinks = {
      eventId: currentEvent!.id,
      codes: newCodes,
      linkType: 'TicketSpecPromo',
      ticketSpecsPromo: ticketSpecs
        .filter((item) => item.quantity! > 0)
        .map((item) => {
          return {
            ticketSpecId: item.id,
            limit: item.quantity!,
          }
        }),
    }
    const response = await createManyLinks(body)

    if (!response.status) {
      showError('Não foi possivel criar o link. Tente novamente')

      return
    }

    if (response.links !== undefined) {
      response.links.forEach((link) => {
        addLink(link)
      })
    }
    closeLinkModal()
  }

  return (
    <div className="flex w-full flex-col gap-4 px-4">
      <span className="text-3xl font-bold">Criação de Links de Liberação</span>
      <LinkMultipleCodesInput
        placeholder="Ex.: Aniversariante_Luis, Aniversariante_Maria"
        existingCodes={allLinks.map((link) => link.code)}
        newCodes={newCodes}
        setNewCodes={setNewCodes}
      />
      <div className="relative">
        <ul className="relative flex max-h-72 flex-col gap-2 overflow-scroll py-2">
          {ticketSpecs !== undefined ? (
            ticketSpecs.map((ticketSpec, index) => {
              return (
                <motion.li key={index}>
                  <TicketSelector
                    ticketSpecId={ticketSpec.id}
                    title={ticketSpec.title}
                    price={ticketSpec.price}
                    quantitySelected={
                      ticketSpec?.quantity ?? ticketSpec.limit ?? 0
                    }
                    finalPrice={ticketSpec.price}
                    hasFee={false}
                    variant="light"
                    setQuantity={(ticketSpecId: number, quantity: number) => {
                      updateQuantity(ticketSpecId, quantity)
                    }}
                  />
                </motion.li>
              )
            })
          ) : (
            <div className="flex items-center justify-center">
              <Spinner
                borderWidth="border-4"
                borderColor="border-background-main/50"
                bottomBorderColor="border-b-background-main"
              />
            </div>
          )}
        </ul>
        <div className="absolute left-0 top-0 z-50 h-2 w-full bg-gradient-to-b  from-[#ffffff] to-transparent" />
        <div className="absolute bottom-0 left-0 z-50 h-2 w-full bg-gradient-to-b  from-transparent to-[#ffffff]" />
      </div>

      <div className="mt-4 h-12 w-full max-w-[474px] self-center">
        <Button
          type="submit"
          enabled={!isLoadingCreateLink && newCodes.length > 0}
          text="Criar"
          isLoading={isLoadingCreateLink}
          onClick={handleCreateBatchLink}
        />
      </div>
    </div>
  )
}
