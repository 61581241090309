import { type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

import { EventBrief } from '@/components/Event/EventBrief'

import { teamStore } from '@/store/team'

interface EventSelectorProps {
  id: number
  imageKey: string
  title: string
  startDate: string
  alias: string
}

export function EventSelector({
  id,
  imageKey,
  title,
  startDate,
  alias,
}: EventSelectorProps): ReactElement {
  const { selectedEvents, setSelectedEvents } = teamStore()

  const isSelected =
    selectedEvents.find((event) => event.id === id) !== undefined

  return (
    <div
      className={
        'relative mb-2 flex w-full flex-row rounded-lg  border border-secondary-gray bg-dark-black bg-transparent p-1 transition-colors duration-100 ease-in-out  hover:cursor-pointer hover:bg-dark-black hover:shadow-md'
      }
      onClick={() => {
        if (isSelected) {
          setSelectedEvents(selectedEvents.filter((event) => event.id !== id))
        } else {
          setSelectedEvents([
            ...selectedEvents,
            {
              id,
              imageKey,
              alias,
            },
          ])
        }
      }}
    >
      <img
        src={`${import.meta.env.VITE_S3 as string}/${imageKey}`}
        alt="Event image"
        className="mr-1 aspect-square h-full w-16 rounded object-cover"
      />
      <div className="flex w-full max-w-full items-center justify-between rounded bg-transparent p-2">
        <div className="flex max-w-[93%] flex-col">
          <h1 className="text-elipsis line-clamp-1 max-w-full text-xs text-white">
            {title}
          </h1>
          <EventBrief
            date={startDate}
            location={''}
            padding="0"
            variant="white"
          />
        </div>
        <div
          className={twMerge(
            'transition-color h-6 w-6 rounded-lg border-2  border-dark-light-gray duration-100 ease-in-out hover:cursor-pointer',
            isSelected ? 'bg-primary-main' : 'bg-transparent',
          )}
        />
      </div>
    </div>
  )
}
