import { useState, type ReactElement } from 'react'

import { Modal } from '@/components/Global/Modal'
import { Button } from '@/components/Global/Button'

import { useCurrentError, errorStore } from '@/store/error'
import { deviceStore } from '@/store/device'

import { validatePixKey } from '@/utils/validation'

import { DUPLICATED_PIX_KEY, INVALID_PIX_KEY } from '@/errors'

import { type IPIX } from '@/types/CreateEditOrganization'

interface CreateEditOrganizationCreatePixModalProps {
  isParentClosing?: boolean
  pixKeys: IPIX[]
  pixKeyToEdit: IPIX | null
  closeModal: () => void
  addPixKey: (pixKeys: IPIX) => void
  updatePixKey: (pixKeys: IPIX) => void
}

export function CreateEditOrganizationPixModal({
  isParentClosing,
  pixKeys,
  pixKeyToEdit,
  closeModal,
  addPixKey,
  updatePixKey,
}: CreateEditOrganizationCreatePixModalProps): ReactElement {
  const id = pixKeyToEdit?.id ?? 0
  const [name, setName] = useState(pixKeyToEdit?.name ?? '')
  const [key, setKey] = useState(pixKeyToEdit?.key ?? '')

  const { isError, errorMessage } = useCurrentError()
  const { showError } = errorStore()
  const { isMobile } = deviceStore()

  function handleAddkey(): void {
    if (!validatePixKey(key)) {
      showError(INVALID_PIX_KEY)
      return
    }

    if (pixKeyToEdit !== null) {
      updatePixKey({ id: pixKeyToEdit.id, name, key })
    } else {
      if (pixKeys.map((pix) => pix.key).includes(key)) {
        showError(DUPLICATED_PIX_KEY)
        return
      }

      addPixKey({ id, name, key })
    }

    closeModal()
  }

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      isError={isError}
      errorMessage={errorMessage}
      isMobile={isMobile}
    >
      <div className="flex size-full flex-col items-start gap-10 px-8 py-4 text-black">
        <div className="flex flex-col gap-1">
          <span className="text-2xl font-black">Nova Chave PIX</span>
          <span>
            Dê um nome para ficar mais fácil de identificar a sua chave PIX.
          </span>
        </div>
        <div className="mb-4 flex w-full flex-col gap-4">
          <div className="flex w-full flex-col">
            <span className="text-sm font-bold">Nome</span>
            <input
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value)
              }}
              placeholder="Chave principal"
              className="border-2 border-x-white border-b-primary-main border-t-white py-2 outline-none transition-all duration-200 ease-in-out focus:rounded-lg focus:border-2 focus:border-primary-main focus:px-2"
            />
          </div>
          <div className="flex w-full flex-col">
            <span className="text-sm font-bold">Chave PIX</span>
            <input
              type="text"
              value={key}
              onChange={(e) => {
                setKey(e.target.value)
              }}
              placeholder="123.456.789-10"
              className="border-2 border-x-white border-b-primary-main border-t-white  py-2 outline-none transition-all duration-200 ease-in-out focus:rounded-lg focus:border-2 focus:border-primary-main focus:px-2"
            />
          </div>
        </div>
        <div className="h-12 w-3/5 self-center">
          <Button
            text={pixKeyToEdit === null ? 'Adicionar' : 'Atualizar'}
            enabled={name !== '' && key !== ''}
            onClick={handleAddkey}
          />
        </div>
      </div>
    </Modal>
  )
}
