import { type ReactElement } from 'react'
import { Link, useParams } from 'react-router-dom'

import { type IFinancialsData } from '@/types/Event'

import { numberToReais } from '@/utils/formatNumber'

interface FinanceCardProps {
  data: IFinancialsData
}

export function FinanceCard({ data }: FinanceCardProps): ReactElement {
  const { alias } = useParams()

  const balance = data.revenue * (1 + data.cashback) - data.withdrawals

  return (
    <Link
      to={`/events/${alias}/finance`}
      className="flex w-full items-center justify-between pl-4"
    >
      <div className="flex flex-col gap-4">
        <header className="text-base font-bold">Financeiro</header>
        <div className="flex flex-wrap justify-start gap-4">
          <div className="flex flex-col gap-1">
            <span className="text-[0.625rem] text-[#959595]">Saldo</span>
            <span className="text-xl font-bold">
              {numberToReais(balance, 2)}
            </span>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[0.625rem] text-[#959595]">Saque</span>
            <span className="text-xl font-bold">
              {numberToReais(data.withdrawals, 2)}
            </span>
          </div>
        </div>
      </div>
    </Link>
  )
}
