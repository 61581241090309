import { fetcher } from '@/services/axios'
import { type IAccountKey } from '@/types/Finance'
import { useState, useEffect } from 'react'
import useSWR from 'swr'

export const useGetOrganizationAccountKeys = (
  organizationId: number,
): {
  organizationAccountKeys: IAccountKey[] | undefined
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(true)
  const shouldFetch = organizationId !== undefined
  const { data, error } = useSWR<IAccountKey[], string>(
    shouldFetch && `/organizations/${organizationId}/accountKeys`,
    fetcher,
  )

  useEffect(() => {
    setIsLoading(!(data !== undefined || error !== undefined))
  }, [data, error])

  return { organizationAccountKeys: data, isLoading }
}
