import { type ReactElement } from 'react'

interface CustomLabelChartProps {
  x: string | number | undefined
  y: string | number | undefined
  width: string | number | undefined
  height: string | number | undefined
  value: string | number | undefined
}

export function CustomLabelChart({
  x,
  y,
  width,
  height,
  value,
}: CustomLabelChartProps): ReactElement {
  if (value !== undefined) {
    const xValue = Number(x!)
    const yValue = Number(y!)
    const widthValue = Number(width!)
    const heightValue = Number(height!)

    return (
      <g
        transform={`translate(${xValue + widthValue},${
          yValue + heightValue
        }) rotate(-90)`}
      >
        <text
          x={2}
          y={-8}
          fill="#181818"
          textAnchor="start"
          dominantBaseline="middle"
          className="text-xs font-black"
        >
          {value}
        </text>
      </g>
    )
  }

  return <div></div>
}
