import { useState } from 'react'
import { type AxiosResponse } from 'axios'

import { api } from '@/services/axios'

interface PostRespondeProps {
  code: string
}

interface PostReturnProps {
  status: boolean
  code?: string
  message?: string
}

export const usePostGenerateCheckInCode = (
  alias: string,
): {
  generateCode: () => Promise<PostReturnProps>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function generateCode(): Promise<PostReturnProps> {
    setIsLoading(true)
    try {
      const response: AxiosResponse<PostRespondeProps> = await api.post(
        `/admin/events/${alias}/check-in-code`,
      )

      setIsLoading(false)

      return {
        status: true,
        code: response.data.code,
      }
    } catch (error) {
      setIsLoading(false)
      return {
        status: false,
        message: 'Novo código não pôde ser gerado.',
      }
    }
  }

  return {
    generateCode,
    isLoading,
  }
}
