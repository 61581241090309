import React, { type ReactElement } from 'react'

import { UploadIcon } from '@/components/Icons/UploadIcon'

import {
  type ICreateEventStoreState,
  type IUpdateEventStoreState,
} from '@/types/CreateUpdateEvent'

import { errorStore } from '@/store/error'

import { isTruthy } from '@/utils/validation'

import {
  BACKGROUND_COVER_IMAGE_SQUARE,
  BACKGROUND_COVER_IMAGE_MIN_RES,
} from '@/errors'

interface CreateEventUploadBackgroundArtImageProps {
  formStates: ICreateEventStoreState | IUpdateEventStoreState
  updateAnyState:
    | ((update: Partial<ICreateEventStoreState>) => void)
    | ((update: Partial<IUpdateEventStoreState>) => void)
}

export function CreateEventUploadBackgroundArtImage({
  formStates,
  updateAnyState,
}: CreateEventUploadBackgroundArtImageProps): ReactElement {
  const { showError } = errorStore()

  function handleOnSubmit(e: React.ChangeEvent<HTMLInputElement>): void {
    e.preventDefault()

    if (e.target.files !== null) {
      const image = e.target.files[0]
      const reader = new FileReader()

      reader.onloadend = () => {
        const imageUrl = reader.result?.toString()
        if (imageUrl !== undefined) {
          const imageElement = new Image()

          imageElement.src = imageUrl

          imageElement.onload = () => {
            const { width, height } = imageElement

            const ratio = width / height

            if (ratio <= 0.975 || ratio >= 1.025) {
              showError(BACKGROUND_COVER_IMAGE_SQUARE)
              return
            }
            if (width < 500 || height < 500) {
              showError(BACKGROUND_COVER_IMAGE_MIN_RES)
              return
            }
            updateAnyState({
              backgroundArtImage: {
                key: image,
                url: imageUrl,
                isLocalImage: true,
              },
            })
          }
        } else {
          updateAnyState({
            backgroundArtImage: null,
          })
        }
      }
      reader.readAsDataURL(image)
    }
  }

  return (
    <form className="relative flex flex-col items-center justify-center p-4">
      <label
        htmlFor="backgroundArtImage"
        className={
          'flex w-full cursor-pointer flex-col items-center justify-center '
        }
      >
        <div
          className={`flex size-full flex-col items-center justify-center gap-4 rounded-lg`}
        >
          {!isTruthy(formStates.backgroundArtImage) ? (
            <div className="flex aspect-square w-full flex-col items-center justify-center gap-4 rounded-lg border-2 border-[#3C3C3C]">
              <UploadIcon iconColor="#d5ff5c" className="size-8" />
              <span className="w-3/5 text-center text-xs">
                Selecione a imagem do seu evento. Você pode usar imagens nos
                formatos png, jpg ou GIFs. *
              </span>
              <span className="text-xs font-medium">
                A imagem deve ter o aspecto 1:1 (quadrada)
              </span>
            </div>
          ) : (
            <>
              <img
                className=" aspect-square w-full rounded-lg"
                src={
                  formStates.backgroundArtImage!.isLocalImage
                    ? formStates.backgroundArtImage!.url
                    : `${import.meta.env.VITE_S3 as string}/${
                        formStates.backgroundArtImage!.url
                      }`
                }
                alt="Foto de capa do evento"
              />
            </>
          )}
          <input
            id="backgroundArtImage"
            className="hidden"
            type="file"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleOnSubmit(e)
            }}
            onClick={(e: React.FormEvent<HTMLInputElement>) => {
              const target = e.target as HTMLButtonElement
              target.value = ''
            }}
            accept="image/*"
          />
        </div>
      </label>
    </form>
  )
}
