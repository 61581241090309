import { type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

import { SwitchButton } from '@/components/Global/SwitchButton'

interface PermissionSwitchProps {
  title: string
  active: boolean
  setActive: (state: boolean) => void
  isLoading?: boolean
  canEdit?: boolean
}

export function PermissionSwitch({
  title,
  active,
  setActive,
  isLoading = false,
  canEdit = false,
}: PermissionSwitchProps): ReactElement {
  return (
    <div
      className={twMerge(
        'h-11 w-full break-inside-avoid-column flex-col items-center justify-center overflow-hidden rounded-lg bg-dark-black px-2',
        isLoading && 'h-11 animate-pulse',
      )}
    >
      {!isLoading && (
        <div className="flex size-full items-center justify-between">
          <span className="ml-1.5 font-bold">{title}</span>
          <SwitchButton
            active={active}
            disable={!canEdit}
            handleOnClick={() => {
              setActive(!active)
            }}
          />
        </div>
      )}
    </div>
  )
}
