/* eslint-disable @typescript-eslint/no-explicit-any */
import { type InputHTMLAttributes, type ReactElement, useState } from 'react'
import { type UseFormRegister } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'
import { tv } from 'tailwind-variants'

interface FormTextAreaProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label?: string
  error?: string
  register?: UseFormRegister<any>
  isInputTitle?: boolean
  wrapperDivClassname?: string
}

const inputVariant = tv({
  base: 'flex w-full items-center transition-color duration-200 ease-in-out border-2 px-2 rounded-md',
  variants: {
    style: {
      focusWithoutError: 'border-primary-main',
      focusWithError: 'border-tonal-red',
      withoutError: 'border-primary-main',
      withError: 'border-tonal-red',
      title:
        'text-3xl placeholder:text-3xl placeholder:font-black font-bold border-none focus:border',
      common: 'font-light',
    },
  },
})

export function FormTextArea({
  label,
  register,
  name,
  error,
  isInputTitle = false,
  wrapperDivClassname,
  ...props
}: FormTextAreaProps): ReactElement {
  const [inputOnFocus, setInputOnFocus] = useState(false)

  let inputErrorVariation: 'withError' | 'withoutError' = 'withoutError'
  let inputStyleOnFocus: 'focusWithError' | 'focusWithoutError' =
    'focusWithoutError'

  if (error !== undefined) {
    inputErrorVariation = 'withError'
    if (inputOnFocus) {
      inputStyleOnFocus = 'focusWithError'
    }
  }

  const inputTitleStyle = isInputTitle ? 'title' : 'common'

  return (
    <div
      className={twMerge(
        'relative flex flex-col gap-1 pb-4',
        wrapperDivClassname,
      )}
    >
      {label != null && (
        <label htmlFor={label} className="text-sm font-black">
          {label}
        </label>
      )}
      {register !== undefined && (
        <div
          className={twMerge(
            inputVariant({ style: inputTitleStyle }),
            inputVariant({ style: inputErrorVariation }),
            inputOnFocus && inputVariant({ style: inputStyleOnFocus }),
          )}
          onClick={() => {
            setInputOnFocus(true)
          }}
        >
          <textarea
            className={twMerge(
              'h-16 w-full resize-none bg-white py-1.5 font-normal leading-5 outline-none placeholder:font-bold placeholder:text-text-terciary placeholder:opacity-50',
            )}
            {...register(name, {
              onBlur: () => {
                setInputOnFocus(false)
              },
            })}
            placeholder={props.placeholder}
          />
        </div>
      )}

      {error !== null && (
        <span className="absolute -bottom-1 left-1 whitespace-nowrap text-xs font-extrabold text-tonal-red">
          {error}
        </span>
      )}
    </div>
  )
}
