import { create } from 'zustand'
import { type IBatch, type ITicketSpec } from '@/types/Tickets'

interface TicketStoreProps {
  managementType: 'SIMPLE' | 'FULL' | undefined
  setManagementType: (managementType: 'SIMPLE' | 'FULL' | undefined) => void

  currentBatch: IBatch | undefined
  setCurrentBatch: (batch: IBatch | undefined) => void
  currentTicketSpec: ITicketSpec | undefined
  setCurrentTicketSpec: (ticketSpec: ITicketSpec | undefined) => void

  ticketsSpecs: ITicketSpec[]
  setTicketsSpecs: (ticketsSpecs: ITicketSpec[]) => void
  batches: IBatch[]
  setBatches: (batches: IBatch[]) => void

  updateBatch: (updatedBatch: IBatch) => void
  updateTicketSpec: (updatedTicketSpec: ITicketSpec) => void

  addBatch: (newBatch: IBatch) => void
  addTicketSpec: (newTicketSpec: ITicketSpec) => void

  deleteBatch: (batchId: number) => void
  deleteTicketSpec: (ticketSpecId: number) => void
}

export const ticketStore = create<TicketStoreProps>((set, get) => {
  return {
    managementType: undefined,
    currentBatch: undefined,
    currentTicketSpec: undefined,
    ticketsSpecs: [],
    batches: [],
    setManagementType: (managementType: 'SIMPLE' | 'FULL' | undefined) => {
      set({
        managementType,
      })
    },
    setCurrentBatch: (batch: IBatch | undefined) => {
      set({
        currentBatch: batch,
      })
    },
    setCurrentTicketSpec: (ticketSpec: ITicketSpec | undefined) => {
      set({
        currentTicketSpec: ticketSpec,
      })
    },
    setTicketsSpecs: (ticketsSpecs: ITicketSpec[]) => {
      set({
        ticketsSpecs,
      })
    },
    setBatches: (batches: IBatch[]) => {
      set({
        batches,
      })
    },
    updateBatch: (updatedBatch: IBatch) => {
      const { batches } = get()
      const updatedBatches = batches.map((batch) => {
        if (batch.id === updatedBatch.id) {
          return updatedBatch
        }
        return batch
      })

      set({
        batches: updatedBatches,
      })
    },
    updateTicketSpec(updatedTicketSpec: ITicketSpec) {
      const { ticketsSpecs } = get()
      const updatedTicketSpecs = ticketsSpecs.map((ticketSpec) => {
        if (ticketSpec.id === updatedTicketSpec.id) {
          return updatedTicketSpec
        }
        return ticketSpec
      })

      set({
        ticketsSpecs: updatedTicketSpecs,
      })
    },
    addBatch: (newBatch: IBatch) => {
      const { batches } = get()
      batches.push(newBatch)
      set({
        batches,
      })
    },
    addTicketSpec: (newTicketSpec: ITicketSpec) => {
      const { ticketsSpecs } = get()
      ticketsSpecs.push(newTicketSpec)
      set({
        ticketsSpecs,
      })
    },
    deleteBatch: (batchId: number) => {
      const { batches } = get()

      const filteredBatches = batches.filter((batch) => batch.id !== batchId)

      set({
        batches: filteredBatches,
      })
    },
    deleteTicketSpec: (ticketSpecId: number) => {
      const { ticketsSpecs } = get()

      const filteredTicketSpecs = ticketsSpecs.filter(
        (ticketSpec) => ticketSpec.id !== ticketSpecId,
      )

      set({
        ticketsSpecs: filteredTicketSpecs,
      })
    },
  }
})
