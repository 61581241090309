import { useEffect, useState, type ReactElement } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { twMerge } from 'tailwind-merge'

import { SwitchButton } from '@/components/Global/SwitchButton'
import { Spinner } from '@/components/Global/Spinner'

import { type ILink } from '@/types/Links'

import { numberToReais, numberToPercent } from '@/utils/formatNumber'
import { isTruthy } from '@/utils/validation'

import { SlArrowUp } from 'react-icons/sl'
import { CopyIcon } from '@/components/Icons/CopyIcon'
import { LuPenSquare } from 'react-icons/lu'
import { PiChartDonutLight } from 'react-icons/pi'
import { TbTrash } from 'react-icons/tb'

import { useUpdateLink } from '@/hooks/Link'

import { errorStore } from '@/store/error'
import { linkStore } from '@/store/link'

interface LinkTableRowProps {
  link: ILink
}

export function LinkTableRow({ link }: LinkTableRowProps): ReactElement {
  const [showTickets, setShowTickets] = useState(false)
  const [linkIsActive, setLinkIsActive] = useState(link.active)

  const linkType =
    link.discount === undefined && link.ticketSpecs === undefined
      ? 'Track'
      : link.discount !== undefined
        ? link.discount.amount !== undefined
          ? 'Amount'
          : 'Percentage'
        : 'TicketSpecPromo'

  const linkLabel =
    linkType === 'Track'
      ? 'UTM'
      : linkType === 'Amount'
        ? `Desconto de `
        : linkType === 'Percentage'
          ? `Desconto de `
          : 'Liberação de ingressos'

  const linkDiscount =
    linkType === 'Amount'
      ? numberToReais(link.discount!.amount, 2)
      : linkType === 'Percentage'
        ? numberToPercent(link.discount!.percentage * 100, 2)
        : undefined

  const { showError } = errorStore()
  const { editLink, setModalToOpen, setCurrentLink } = linkStore()

  const { updateLink, isLoading: isUpdatingLink } = useUpdateLink()

  async function handleOnSwitch(): Promise<void> {
    setLinkIsActive((prev) => !prev)
    const response = await updateLink({
      linkId: link.id,
      linkType:
        linkType === 'Amount' || linkType === 'Percentage'
          ? 'Discount'
          : linkType,
      code: link.code,
      discount: link.discount,
      ticketSpecsPromo: link.ticketSpecs,
      active: !linkIsActive,
    })
    if (!response.status) {
      setLinkIsActive(response.link!.active)
      showError('Erro ao atualizar o ingresso. Tente novamente!')
    }
  }

  function handleEditLink(): void {
    const editLinkType =
      link.discount === undefined && link.ticketSpecs === undefined
        ? 'UTM'
        : link.discount !== undefined
          ? 'Discount'
          : 'TicketSpecPromo'
    editLink(link, editLinkType)
  }

  useEffect(() => {
    setLinkIsActive(link.active)
  }, [link])

  return (
    <div className="flex h-min w-full flex-col rounded-md bg-dark-black p-2 pb-0">
      <div className="flex w-full items-center justify-start pb-2">
        <div className="flex min-w-[80px] max-w-[80px] items-center gap-2">
          <SwitchButton
            active={linkIsActive}
            handleOnClick={() => {
              void handleOnSwitch()
            }}
            disable={isUpdatingLink}
          />
          {isUpdatingLink && (
            <Spinner
              borderWidth="border-[3px]"
              borderColor="border-primary-main/50"
              bottomBorderColor="border-b-primary-main"
              width="w-4"
              height="h-4"
            />
          )}
        </div>

        <div className="flex size-full items-center">
          <div className="w-2/5">
            <span className="line-clamp-1 text-sm">{link.code}</span>
          </div>

          <div className="w-2/5">
            <span className="line-clamp-1 text-sm">
              {`${linkLabel}`} <strong>{linkDiscount}</strong>
            </span>
          </div>

          <div className="w-1/6">
            <span className="line-clamp-1 text-sm">{link.usage}</span>
          </div>
        </div>

        <div className="flex h-full min-w-[160px] items-center justify-end gap-2">
          <button
            className="flex size-7 items-center justify-center rounded-sm border border-[#2A2A2A] bg-[#2A2A2A] transition-colors duration-100 ease-in-out hover:border-primary-main"
            onClick={() => {
              setCurrentLink(link)
              setModalToOpen('AnalyticsUsers')
            }}
          >
            <PiChartDonutLight size={20} style={{ strokeWidth: '4px' }} />
          </button>
          <button
            className="flex size-7 items-center justify-center rounded-sm border border-[#2A2A2A] bg-[#2A2A2A] transition-colors duration-100 ease-in-out hover:border-primary-main"
            onClick={handleEditLink}
          >
            <LuPenSquare size={16} />
          </button>
          <button
            className="flex size-7 items-center justify-center rounded-sm border border-[#2A2A2A] bg-[#2A2A2A] transition-colors duration-100 ease-in-out hover:border-primary-main"
            onClick={() => {
              setCurrentLink(link)
              setModalToOpen('Url')
            }}
          >
            <CopyIcon strokeColor="#fff" size={18} />
          </button>
          {link.usage === 0 && (
            <button
              className="flex size-7 items-center justify-center rounded-sm border border-[#2A2A2A] bg-[#2A2A2A] transition-colors duration-100 ease-in-out hover:border-primary-main"
              onClick={() => {
                setCurrentLink(link)
                setModalToOpen('Delete')
              }}
            >
              <TbTrash
                color="#fff"
                size={20}
                style={{ strokeWidth: '1.5px' }}
              />
            </button>
          )}
          <motion.button
            className={twMerge(
              'flex size-4 items-center justify-center',
              linkType !== 'TicketSpecPromo' && 'invisible',
            )}
            onClick={() => {
              setShowTickets(!showTickets)
            }}
            initial={{ rotate: 180 }}
            animate={!showTickets ? { rotate: 180 } : { rotate: 0 }}
            transition={{ duration: 0.25, ease: 'easeInOut' }}
          >
            <SlArrowUp size={10} className="w-5 stroke-[100px]" />
          </motion.button>
        </div>
      </div>
      <AnimatePresence mode="wait">
        {showTickets && (
          <motion.div
            className="flex w-full flex-col overflow-hidden rounded-md bg-[#2a2a2a]"
            initial={{ height: 0, marginBottom: 0 }}
            animate={{ height: 'auto', marginBottom: 10 }}
            exit={{ height: 0, marginBottom: 0 }}
            transition={{ duration: 0.25, ease: 'easeInOut' }}
          >
            <div className="flex w-full py-2 pl-8">
              <div className="flex size-full">
                <div className="w-1/2">
                  <span className="line-clamp-1 text-base font-bold">
                    Ingresso
                  </span>
                </div>
                <div className="w-1/6">
                  <span className="line-clamp-1 text-base font-bold">
                    Valor
                  </span>
                </div>
                <div className="w-1/6">
                  <span className="line-clamp-1 text-base font-bold">
                    Vendas
                  </span>
                </div>
                <div className="w-1/6">
                  <span className="line-clamp-1 text-base font-bold">
                    Liberações
                  </span>
                </div>
              </div>
            </div>
            <div className="flex w-full flex-col gap-1 pb-2">
              {link?.ticketSpecs
                ?.filter(
                  (ticketSpecPromo) =>
                    ticketSpecPromo.limit > 0 || ticketSpecPromo.usage > 0,
                )
                .map((ticketSpec, index) => {
                  const title = isTruthy(ticketSpec.batchDescription)
                    ? `${ticketSpec.batchDescription} - ${ticketSpec.ticketSpecDescription}`
                    : ticketSpec.ticketSpecDescription
                  return (
                    <div key={index} className="flex w-full pl-8">
                      <div className="flex size-full">
                        <div className="w-1/2">
                          <span className="line-clamp-1 text-sm">{title}</span>
                        </div>
                        <div className="w-1/6">
                          <span className="line-clamp-1 text-sm">
                            {ticketSpec.price === 0
                              ? 'Grátis'
                              : numberToReais(ticketSpec.price, 2)}
                          </span>
                        </div>
                        <div className="w-1/6">
                          <span className="line-clamp-1 text-sm">
                            {ticketSpec.usage}
                          </span>
                        </div>

                        <div className="w-1/6">
                          <span className="line-clamp-1 text-sm">
                            {ticketSpec.limit}
                          </span>
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
