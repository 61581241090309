import { type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { SideBarOrganizationButton } from './SideBarOrganizationButton'

import { useAllOrganization } from '@/store/organization'
import { userStore } from '@/store/user'

import { FaPlus } from 'react-icons/fa6'

export function SideBarOrganizations(): ReactElement {
  const { allOrganizations } = useAllOrganization()
  const { imageKey: userImageKey } = userStore()

  const navigate = useNavigate()

  return (
    <div className="flex h-full flex-col items-center justify-between bg-transparent pr-2">
      <div className="relative h-full max-h-full overflow-hidden pb-4">
        <div className="absolute bottom-4 left-0 z-50 h-4 w-full bg-gradient-to-t from-dark-black to-transparent" />
        <div className="flex max-h-full min-w-[64px] flex-col items-center gap-2 overflow-scroll pb-4">
          {allOrganizations?.map((organization) => {
            return (
              <SideBarOrganizationButton
                organization={organization}
                key={organization.organizer.organizationId}
              />
            )
          })}
          <div>
            <button
              className="flex size-16 items-center justify-center rounded-md border border-transparent bg-background-main text-4xl text-white transition-colors duration-100 ease-in-out hover:cursor-pointer hover:border-primary-main"
              onClick={() => {
                navigate('/create-organization')
              }}
            >
              <FaPlus size={20} />
            </button>
          </div>
        </div>
      </div>

      <img
        src={`${import.meta.env.VITE_S3 as string}/${userImageKey}`}
        alt="Organization image"
        className={twMerge(
          'aspect-square h-16 w-16 rounded-full border-2 border-transparent',
        )}
      />
    </div>
  )
}
