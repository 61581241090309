import { type ReactElement, useEffect, useRef, useState } from 'react'

import {
  type ICreateEventStoreState,
  type IUpdateEventStoreState,
} from '@/types/CreateUpdateEvent'
import { isTruthy } from '@/utils/validation'

interface CreateEventTermsCardProps {
  formStates: ICreateEventStoreState | IUpdateEventStoreState
  updateAnyState:
    | ((update: Partial<ICreateEventStoreState>) => void)
    | ((update: Partial<IUpdateEventStoreState>) => void)
}

export function CreateEventTermsCard({
  formStates,
  updateAnyState,
}: CreateEventTermsCardProps): ReactElement {
  function handleChange(evt: React.ChangeEvent<HTMLTextAreaElement>): void {
    const val = evt.target?.value

    if (val === '') {
      setEventTerms(val)
      updateAnyState({ terms: null })
      return
    }
    setEventTerms(val)
  }

  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  const [eventTerms, setEventTerms] = useState(formStates.terms)

  // useAutosizeTextArea(textAreaRef.current, value ?? '')

  useEffect(() => {
    if (textAreaRef !== null) {
      textAreaRef.current!.style.height = '0px'
      const scrollHeight = textAreaRef.current!.scrollHeight

      textAreaRef.current!.style.height = scrollHeight + 'px'
    }
  }, [textAreaRef, eventTerms, formStates.terms])

  useEffect(() => {
    setEventTerms(formStates.terms)
  }, [formStates.terms])

  return (
    <div className="flex w-full flex-col rounded-lg bg-dark-black p-4">
      <span className="mb-1 text-sm font-bold text-[#D0D0D0]">Termos</span>
      <textarea
        className="resize-none appearance-none bg-dark-black text-sm outline-none placeholder:text-[#656565] "
        placeholder="Adicione os termos do evento"
        ref={textAreaRef}
        value={eventTerms ?? formStates.terms ?? ''}
        onChange={handleChange}
        onBlur={() => {
          if (isTruthy(eventTerms)) {
            updateAnyState({ terms: eventTerms })
          }
        }}
      />
    </div>
  )
}
