import { type ReactElement } from 'react'
import { TicketsCard } from './TicketsCard'
import { LinkCard } from './LinkCard'
import { AnalyticsCard } from './AnalyticsCard'
import { FinanceCard } from './FinanceCard'
import { type GetMenuAnalyticsByEventAlias } from '@/types/Event'

import { useUserPermissions } from '@/store/user'
import { hasClearence } from '@/utils/rbac'

interface EventCardsMenuProps {
  alias: string
  cardsData: GetMenuAnalyticsByEventAlias
}

export function EventCardsMenu({
  cardsData,
}: EventCardsMenuProps): ReactElement {
  const { eventPermissions } = useUserPermissions()

  const canViewFinance = hasClearence(eventPermissions.finance, 'VIEWER')
  const canViewAnalytics = hasClearence(eventPermissions.analytics, 'VIEWER')
  const canViewBatchs = hasClearence(eventPermissions.tickets, 'VIEWER')
  const canViewLinks = hasClearence(eventPermissions.link, 'VIEWER')

  return (
    <div className="flex w-full max-w-full flex-col gap-2 px-4">
      <ul className="grid w-full max-w-full grid-cols-1 gap-2 desktop:grid-cols-2">
        {canViewBatchs && (
          <li className="relative flex min-h-[120px] w-full items-start justify-between rounded-lg bg-dark-black py-4">
            <TicketsCard data={cardsData.tickets} key="tickets" />
          </li>
        )}
        {canViewLinks && (
          <li className="relative flex min-h-[120px] w-full items-start justify-between rounded-lg bg-dark-black py-4 pr-4">
            <LinkCard data={cardsData.links} key="links" />
          </li>
        )}
        {canViewAnalytics && (
          <li className="relative flex min-h-[120px] w-full items-start justify-between rounded-lg bg-dark-black py-4 pr-4">
            <AnalyticsCard data={cardsData.analytics} key="analytics" />
          </li>
        )}
        {canViewFinance && (
          <li className="relative flex min-h-[120px] w-full items-start justify-between rounded-lg bg-dark-black py-4 pr-4">
            <FinanceCard data={cardsData.financial} key="finance" />
          </li>
        )}
      </ul>
    </div>
  )
}
