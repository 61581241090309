import { type ReactElement, type ElementType } from 'react'
import { twMerge } from 'tailwind-merge'

interface EventImageProps {
  imageKey: string | undefined
  expanded?: boolean
  rounded?: boolean
  buttonPosition?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight'
  icon?: ElementType
  onClick?: () => void
  imgClassName?: string
}

export function EventImage({
  imageKey,
  rounded = false,
  buttonPosition = 'topLeft',
  icon: Icon,
  onClick,
  imgClassName,
}: EventImageProps): ReactElement {
  const variants = {
    topLeft: ' top-6 left-6',
    topRight: ' top-6 right-6',
    bottomLeft: ' bottom-6 left-6',
    bottomRight: ' bottom-6 right-6',
  }

  return (
    <>
      {imageKey !== undefined && (
        <div
          className={twMerge(
            `relative z-20 flex h-full max-h-[400px] w-full items-center overflow-hidden `,
            rounded ? 'rounded-md' : '',
            imgClassName,
          )}
        >
          {Icon !== undefined && (
            <div
              className={
                'absolute z-10 flex h-8 w-8 items-center justify-center rounded-full bg-dark-white hover:cursor-pointer' +
                variants[buttonPosition]
              }
              onClick={onClick}
            >
              <Icon size={24} />
            </div>
          )}
          <img
            src={`${import.meta.env.VITE_S3 as string}/${imageKey}`}
            alt="Event image"
            className="aspect-square size-full object-cover drop-shadow-2xl"
          />
        </div>
      )}
    </>
  )
}
