import { useState, type ReactElement } from 'react'
import { tv } from 'tailwind-variants'

import { TicketSpecCardHeader } from './TicketSpecCardHeader'
import { LinkIcon } from '@/components/Icons/LinkIcon'
import { Spinner } from '@/components/Global/Spinner'
import { DropDown } from '@/components/Global/DropDown'

import { errorStore } from '@/store/error'
import { useUserPermissions } from '@/store/user'
import { ticketStore } from '@/store/tickets'

import { usePutUpdateTicketSpec } from '@/hooks/Tickets/TicketSpec'

import { numberToReais } from '@/utils/formatNumber'
import { isTruthy } from '@/utils/validation'
import { hasClearence } from '@/utils/rbac'

import { type IFollower, type ITicketSpec } from '@/types/Tickets'

import chainLinkSvg from '@/assets/chainLink.svg'
import { twMerge } from 'tailwind-merge'

interface TicketSpecCardProps {
  ticketSpec: ITicketSpec
  openTicketSpecModal: () => void
  deleteTicketSpec: () => void
  dropDownData: IFollower[]
  variant: 'small' | 'large'
  isConnectedToNext?: boolean
  isConnectedToPrevious?: boolean
}

export function TicketSpecCard({
  ticketSpec,
  openTicketSpecModal,
  deleteTicketSpec,
  dropDownData,
  variant,
  isConnectedToNext = false,
  isConnectedToPrevious = false,
}: TicketSpecCardProps): ReactElement {
  const [nextTicketSpec, setNextTicketSpec] = useState(
    dropDownData.find((follower) => follower.id === ticketSpec.follower.id),
  )

  // Styling variants
  const outterDivVariant = tv({
    base: 'flex h-fit w-full flex-col rounded-md ',
    variants: {
      size: {
        small: 'p-4 gap-4 bg-background-main',
        large: 'p-6 gap-12 bg-dark-black',
      },
    },
  })

  const innerDivVariant = tv({
    base: 'flex w-full flex-wrap justify-stretch  ',
    variants: {
      size: {
        small: 'flex-col gap-0',
        large: 'flex-row items-center gap-5',
      },
    },
  })

  const followerTicketSpecVariant = tv({
    base: twMerge('flex items-center gap-2', variant === 'small' && 'mt-4'),
    variants: {
      size: {
        small: '',
        large: 'flex-col items-start',
      },
    },
  })

  const dropDownDivVariant = tv({
    base: '',
    variants: {
      size: {
        small: '',
        large: 'ml-[18px]',
      },
    },
  })

  // Stores
  const { showError } = errorStore()
  const { eventPermissions } = useUserPermissions()
  const { updateTicketSpec: updateTicketSpecStore } = ticketStore()

  const canEditTicketSpec = hasClearence(eventPermissions.tickets, 'EDITOR')

  // Text to be displayed in the card
  const ticketSpecUsage = `${ticketSpec.numberOfSoldTickets} ${ticketSpec.numberOfSoldTickets === 1 ? 'venda' : 'vendas'}`

  const ticketSpecLimit =
    ticketSpec.numberOfAvailableTickets === 0
      ? 'Sem limite de vendas'
      : `${ticketSpec.numberOfAvailableTickets} ${ticketSpec.numberOfAvailableTickets === 1 ? 'venda' : 'vendas'}`

  const ticketSpecUserLimit =
    ticketSpec.userLimit === 0
      ? 'Sem limite por usuário'
      : `${ticketSpec.userLimit} un. por usuário`

  const ticketSpecExtraDescription = isTruthy(ticketSpec.extraDescription)
    ? ticketSpec.extraDescription
    : 'Sem informações adicionais'

  const ticketSpecNextDescription = isTruthy(nextTicketSpec?.batchDescription)
    ? `${nextTicketSpec?.batchDescription} - ${nextTicketSpec?.ticketSpecDescription}`
    : `${nextTicketSpec?.ticketSpecDescription}`

  const mappedDropDownData = dropDownData.map((dropDown) => {
    if (dropDown.batchDescription !== undefined) {
      return {
        id: dropDown.id,
        value: `${dropDown.batchDescription} - ${dropDown.ticketSpecDescription}`,
      }
    } else {
      return { id: dropDown.id, value: `${dropDown.ticketSpecDescription}` }
    }
  })

  // Hooks
  const { updateTicketSpec, isLoading: isLoadingUpdateTicketSpec } =
    usePutUpdateTicketSpec()

  // Functions
  async function handleSetNextTicketSpecClick(
    nextTicketSpec: IFollower,
  ): Promise<void> {
    const response = await updateTicketSpec({
      ticketSpecId: ticketSpec.id,
      followerId: nextTicketSpec.id ?? null,
    })

    if (!response.status) {
      showError('Erro ao atualizar o ingresso. Tente novamente!')
      return
    }
    updateTicketSpecStore({
      ...ticketSpec,
      follower: response.ticketSpec?.follower ?? {},
    })

    setNextTicketSpec(nextTicketSpec)
  }

  function handleDropDownOnChange(nextTicketSpecId: number | undefined): void {
    nextTicketSpecId = nextTicketSpecId === -1 ? undefined : nextTicketSpecId
    const nextFollower = dropDownData.find(
      (ticketSpec) => ticketSpec.id === nextTicketSpecId,
    )
    void handleSetNextTicketSpecClick(nextFollower!)
  }

  return (
    <div className="relative h-fit w-full">
      <div className={outterDivVariant({ size: variant })}>
        <TicketSpecCardHeader
          ticketSpec={ticketSpec}
          openTicketSpecModal={openTicketSpecModal}
          deleteTicketSpec={deleteTicketSpec}
          variant={variant}
        />

        <div className={innerDivVariant({ size: variant })}>
          <div className="flex flex-col gap-2">
            {variant === 'large' && (
              <span className="text-xs font-bold">Valor</span>
            )}
            <span className="text-nowrap text-xs font-semibold">
              {numberToReais(ticketSpec.price, 2)}
            </span>
          </div>

          <div className="flex flex-col gap-2">
            {variant === 'large' && (
              <span className="text-xs font-bold">Vendas</span>
            )}
            <span className="text-nowrap text-xs font-semibold text-primary-main ">
              {ticketSpecUsage}
            </span>
          </div>

          <div className="flex flex-col gap-2">
            {variant === 'large' && (
              <span className="text-xs font-bold">Limite de vendas</span>
            )}
            <span className="text-nowrap text-xs">{ticketSpecLimit}</span>
          </div>

          <div className="flex flex-col gap-2">
            {variant === 'large' && (
              <span className="text-xs font-bold">Limite por usuário</span>
            )}
            <span className="text-nowrap text-xs">{ticketSpecUserLimit}</span>
          </div>

          {variant === 'large' && (
            <div className="flex flex-col gap-2">
              <span className="text-xs font-bold">Informações adicionais</span>
              <span className="line-clamp-1 max-w-[130px] text-ellipsis text-xs">
                {ticketSpecExtraDescription}
              </span>
            </div>
          )}

          <div className={followerTicketSpecVariant({ size: variant })}>
            <div className="flex items-center gap-2">
              <LinkIcon />
              <span className="text-nowrap text-xs font-bold">
                Próximo ingresso{variant === 'small' && ':'}
              </span>
              {variant === 'large' && (
                <div className="size-4">
                  {isLoadingUpdateTicketSpec && (
                    <div className="ml-2">
                      <Spinner
                        borderWidth="border-[3px]"
                        borderColor="border-primary-main/50"
                        bottomBorderColor="border-b-primary-main"
                        width="w-4"
                        height="h-4"
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className={dropDownDivVariant({ size: variant })}>
              {canEditTicketSpec ? (
                <DropDown
                  dropDownData={mappedDropDownData}
                  handleOnChange={handleDropDownOnChange}
                  value={ticketSpecNextDescription}
                />
              ) : (
                <span className="text-xs font-bold">
                  {ticketSpecNextDescription}
                </span>
              )}
            </div>
            {variant === 'small' && (
              <div className="size-4">
                {isLoadingUpdateTicketSpec && (
                  <div className="ml-2">
                    <Spinner
                      borderWidth="border-[3px]"
                      borderColor="border-primary-main/50"
                      bottomBorderColor="border-b-primary-main"
                      width="w-4"
                      height="h-4"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {isConnectedToNext && (
        <div className="h-fit w-full">
          <img
            src={chainLinkSvg}
            alt="Chain link"
            className="absolute -bottom-10 my-1 ml-2 h-8"
          />
        </div>
      )}
      {isConnectedToPrevious && (
        <>
          <img
            src={chainLinkSvg}
            alt="Chain link"
            className="absolute -top-10 right-2 my-1 ml-2 h-8 rotate-180"
          />
        </>
      )}
    </div>
  )
}
