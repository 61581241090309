import { fetcher } from '@/services/axios'
import { type GetRevenuesByGenderByEventAlias } from '@/types/Analytics'
import { useState, useEffect } from 'react'
import useSWR from 'swr'

export const useGetRevenuesByGenderByEventAlias = (
  alias: string,
): {
  getRevenuesByGenderByEventAlias: GetRevenuesByGenderByEventAlias | undefined
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(true)
  const shouldFetch = alias !== undefined
  const { data, error } = useSWR<GetRevenuesByGenderByEventAlias, string>(
    shouldFetch && `/admin/analytics/${alias}/genderAndAge`,
    fetcher,
  )

  useEffect(() => {
    setIsLoading(!(data !== undefined || error !== undefined))
  }, [data, error])

  return { getRevenuesByGenderByEventAlias: data, isLoading }
}
