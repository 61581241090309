import { create } from 'zustand'

export type pageOptions =
  | 'home'
  | 'createEvent'
  | 'createEventPreview'
  | 'communicationCampaigns'
  | 'createCommunicationCampaign'
  | 'event'
  | 'tickets'
  | 'links'
  | 'list'
  | 'grant'
  | 'editEvent'
  | 'analytics'
  | 'editEventPreview'
  | 'finance'
  | 'checkIn'
  | 'createOrganization'
  | 'editOrganization'
  | 'pageNotFound'
  | 'team'
  | 'createOrganizationRole'
  | 'createEventRole'
  | 'userGlobalPermissions'
  | 'userEventPermissions'
  | 'coHosts'
  | 'other'

interface headerProps {
  title: string
  subtitle: string
  titleImageKey?: string
  button?: React.ReactNode
  backButtonPath: string
  dynamicContent: boolean
}

interface WrapperProps {
  currentPage: pageOptions
  setCurrentPage: (newState: pageOptions) => void

  showHeader: boolean
  setShowHeader: (s: boolean) => void

  showSideBarOrganizations: boolean
  setShowSideBarOrganizations: (s: boolean) => void

  showSideBarEvents: boolean
  setShowSideBarEvents: (s: boolean) => void

  showSideBarEvent: boolean
  setShowSideBarEvent: (s: boolean) => void

  showScrollToTop: boolean
  setShowScrollToTop: (s: boolean) => void

  showHeaderButton: boolean
  setShowHeaderButton: (s: boolean) => void

  fullWidth: boolean
  setFullWidth: (s: boolean) => void

  showAll: () => void
  hideAll: () => void

  header: headerProps
  setTitle: (s: string) => void
  setSubtitle: (s: string) => void
  setTitleImageKey: (s: string) => void
  setTo: (s: string) => void
  setHeaderButton: (s: React.ReactNode) => void
}

const wrapperConfigs = {
  home: {
    header: {
      active: false,
      title: '',
      subtitle: '',
      hasButton: false,
      backButtonPath: '',
      dynamicContent: false,
    },
    sideBarOrganizations: true,
    sideBarEvents: true,
    sideBarEvent: false,
    scrollToTop: false,
    fullWidth: false,
  },
  createEvent: {
    header: {
      active: true,
      title: 'Criar um evento',
      subtitle: '',
      hasButton: false,
      backButtonPath: '/',
      dynamicContent: false,
    },
    sideBarOrganizations: true,
    sideBarEvents: true,
    sideBarEvent: false,
    scrollToTop: false,
    fullWidth: false,
  },
  createEventPreview: {
    header: {
      active: true,
      title: 'Preview da criação do evento',
      subtitle: '',
      hasButton: false,
      backButtonPath: '/create-event',
      dynamicContent: false,
    },
    sideBarOrganizations: true,
    sideBarEvents: true,
    sideBarEvent: false,
    scrollToTop: false,
    fullWidth: true,
  },
  communicationCampaigns: {
    header: {
      active: true,
      title: 'Campanhas de comunicação',
      subtitle: '',
      hasButton: false,
      backButtonPath: '/',
      dynamicContent: false,
    },
    sideBarOrganizations: true,
    sideBarEvents: true,
    sideBarEvent: false,
    scrollToTop: false,
    fullWidth: false,
  },
  createCommunicationCampaign: {
    header: {
      active: true,
      title: 'Criar campanha',
      subtitle: '',
      hasButton: false,
      backButtonPath: '/communication-campaigns',
      dynamicContent: false,
    },
    sideBarOrganizations: true,
    sideBarEvents: true,
    sideBarEvent: false,
    scrollToTop: false,
    fullWidth: false,
  },
  event: {
    header: {
      active: true,
      title: '',
      subtitle: '',
      hasButton: false,
      backButtonPath: '/',
      dynamicContent: true,
    },
    sideBarOrganizations: true,
    sideBarEvents: true,
    sideBarEvent: true,
    scrollToTop: false,
    fullWidth: false,
  },
  tickets: {
    header: {
      active: true,
      title: 'Ingressos',
      subtitle: '',
      hasButton: true,
      backButtonPath: '/events/:alias',
      dynamicContent: false,
    },
    sideBarOrganizations: true,
    sideBarEvents: true,
    sideBarEvent: true,
    scrollToTop: true,
    fullWidth: false,
  },
  links: {
    header: {
      active: true,
      title: 'Links',
      subtitle: '',
      hasButton: false,
      backButtonPath: '/events/:alias',
      dynamicContent: false,
    },
    sideBarOrganizations: true,
    sideBarEvents: true,
    sideBarEvent: true,
    scrollToTop: true,
    fullWidth: false,
  },
  list: {
    header: {
      active: true,
      title: 'Lista',
      subtitle: '',
      hasButton: false,
      backButtonPath: '/events/:alias',
      dynamicContent: false,
    },
    sideBarOrganizations: true,
    sideBarEvents: true,
    sideBarEvent: true,
    scrollToTop: true,
    fullWidth: false,
  },
  grant: {
    header: {
      active: true,
      title: 'Cortesias',
      subtitle: '',
      hasButton: false,
      backButtonPath: '/events/:alias',
      dynamicContent: false,
    },
    sideBarOrganizations: true,
    sideBarEvents: true,
    sideBarEvent: true,
    scrollToTop: true,
    fullWidth: false,
  },
  editEvent: {
    header: {
      active: true,
      title: 'Editar evento',
      subtitle: '',
      hasButton: false,
      backButtonPath: '/events/:alias',
      dynamicContent: false,
    },
    sideBarOrganizations: true,
    sideBarEvents: true,
    sideBarEvent: true,
    scrollToTop: false,
    fullWidth: false,
  },
  analytics: {
    header: {
      active: true,
      title: 'Analíticos',
      subtitle: '',
      hasButton: false,
      backButtonPath: '/events/:alias',
      dynamicContent: false,
    },
    sideBarOrganizations: true,
    sideBarEvents: true,
    sideBarEvent: true,
    scrollToTop: false,
    fullWidth: false,
  },
  editEventPreview: {
    header: {
      active: true,
      title: 'Preview da edição do evento',
      subtitle: '',
      hasButton: false,
      backButtonPath: '/events/:alias/edit',
      dynamicContent: false,
    },
    sideBarOrganizations: true,
    sideBarEvents: true,
    sideBarEvent: true,
    scrollToTop: false,
    fullWidth: true,
  },
  finance: {
    header: {
      active: true,
      title: 'Finanças',
      subtitle: '',
      hasButton: false,
      backButtonPath: '/events/:alias',
      dynamicContent: false,
    },
    sideBarOrganizations: true,
    sideBarEvents: true,
    sideBarEvent: true,
    scrollToTop: false,
    fullWidth: false,
  },
  checkIn: {
    header: {
      active: true,
      title: 'Check-in',
      subtitle: '',
      hasButton: false,
      backButtonPath: '/events/:alias',
      dynamicContent: false,
    },
    sideBarOrganizations: true,
    sideBarEvents: true,
    sideBarEvent: true,
    scrollToTop: false,
    fullWidth: false,
  },
  createOrganization: {
    header: {
      active: false,
      title: 'Criar organização',
      subtitle: '',
      hasButton: false,
      backButtonPath: '',
      dynamicContent: false,
    },
    sideBarOrganizations: false,
    sideBarEvents: false,
    sideBarEvent: false,
    scrollToTop: false,
    fullWidth: false,
  },
  editOrganization: {
    header: {
      active: false,
      title: 'Editar organização',
      subtitle: '',
      hasButton: false,
      backButtonPath: '',
      dynamicContent: false,
    },
    sideBarOrganizations: false,
    sideBarEvents: false,
    sideBarEvent: false,
    scrollToTop: false,
    fullWidth: false,
  },
  pageNotFound: {
    header: {
      active: false,
      title: '',
      subtitle: '',
      hasButton: false,
      backButtonPath: '',
      dynamicContent: false,
    },
    sideBarOrganizations: false,
    sideBarEvents: false,
    sideBarEvent: false,
    scrollToTop: false,
    fullWidth: false,
  },
  team: {
    header: {
      active: true,
      title: 'Gerenciar equipe',
      subtitle: '',
      hasButton: false,
      backButtonPath: '/',
      dynamicContent: false,
    },
    sideBarOrganizations: true,
    sideBarEvents: true,
    sideBarEvent: false,
    scrollToTop: false,
    fullWidth: false,
  },
  createOrganizationRole: {
    header: {
      active: true,
      title: 'Adicionar membro à organização',
      subtitle: '',
      hasButton: false,
      backButtonPath: '',
      dynamicContent: false,
    },
    sideBarOrganizations: true,
    sideBarEvents: true,
    sideBarEvent: false,
    scrollToTop: false,
    fullWidth: false,
  },
  createEventRole: {
    header: {
      active: true,
      title: 'Adicionar membro a eventos',
      subtitle: '',
      hasButton: false,
      backButtonPath: '/team',
      dynamicContent: false,
    },
    sideBarOrganizations: true,
    sideBarEvents: true,
    sideBarEvent: false,
    scrollToTop: false,
    fullWidth: false,
  },
  userGlobalPermissions: {
    header: {
      active: true,
      title: 'Permissões do usuário',
      subtitle: '',
      hasButton: false,
      backButtonPath: '/team',
      dynamicContent: false,
    },
    sideBarOrganizations: true,
    sideBarEvents: true,
    sideBarEvent: false,
    scrollToTop: false,
    fullWidth: false,
  },
  userEventPermissions: {
    header: {
      active: true,
      title: 'Permissões específicas do usuário',
      subtitle: '',
      hasButton: false,
      backButtonPath: '/team',
      dynamicContent: false,
    },
    sideBarOrganizations: true,
    sideBarEvents: true,
    sideBarEvent: false,
    scrollToTop: false,
    fullWidth: false,
  },
  coHosts: {
    header: {
      active: true,
      title: 'Co-hosts',
      subtitle: '',
      hasButton: false,
      backButtonPath: '/events/:alias',
      dynamicContent: false,
    },
    sideBarOrganizations: true,
    sideBarEvents: true,
    sideBarEvent: true,
    scrollToTop: true,
    fullWidth: false,
  },
  other: {
    header: {
      active: false,
      title: '',
      subtitle: '',
      hasButton: false,
      backButtonPath: '',
      dynamicContent: false,
    },
    sideBarOrganizations: false,
    sideBarEvents: false,
    sideBarEvent: false,
    scrollToTop: false,
    fullWidth: false,
  },
}

export const wrapperStore = create<WrapperProps>()((set) => ({
  currentPage: 'home',
  setCurrentPage: (newState: pageOptions) => {
    console.log('NOVO ESTADO: ', newState)
    const tempSideBarConfig = wrapperConfigs[newState]
    set((state) => ({
      currentPage: newState,
      showHeader: tempSideBarConfig.header.active,
      showSideBarOrganizations: tempSideBarConfig.sideBarOrganizations,
      showSideBarEvents: tempSideBarConfig.sideBarEvents,
      showSideBarEvent: tempSideBarConfig.sideBarEvent,
      showScrollToTop: tempSideBarConfig.scrollToTop,
      showHeaderButton: tempSideBarConfig.header.hasButton,
      fullWidth: tempSideBarConfig.fullWidth,
      header: {
        title: tempSideBarConfig.header.dynamicContent
          ? state.header.title
          : tempSideBarConfig.header.title,
        subtitle: tempSideBarConfig.header.dynamicContent
          ? state.header.subtitle
          : tempSideBarConfig.header.subtitle,
        titleImageKey: '',
        backButtonPath: tempSideBarConfig.header.backButtonPath,
        dynamicContent: tempSideBarConfig.header.dynamicContent,
      },
    }))
  },
  showHeader: true,
  setShowHeader: (s: boolean) => {
    set(() => ({ showHeader: s }))
  },
  showSideBarOrganizations: true,
  setShowSideBarOrganizations: (s: boolean) => {
    set(() => ({ showSideBarOrganizations: s }))
  },
  showSideBarEvents: true,
  setShowSideBarEvents: (s: boolean) => {
    set(() => ({ showSideBarEvents: s }))
  },
  showSideBarEvent: true,
  setShowSideBarEvent: (s: boolean) => {
    set(() => ({ showSideBarEvent: s }))
  },
  showScrollToTop: false,
  setShowScrollToTop: (s: boolean) => {
    set(() => ({ showScrollToTop: s }))
  },
  showHeaderButton: false,
  setShowHeaderButton: (s: boolean) => {
    set(() => ({ showHeaderButton: s }))
  },
  fullWidth: false,
  setFullWidth: (s: boolean) => {
    set(() => ({ fullWidth: s }))
  },
  showAll: () => {
    set(() => ({
      showHeader: true,
      showSideBarOrganizations: true,
      showSideBarEvents: true,
      showSideBarEvent: true,
    }))
  },
  hideAll: () => {
    set(() => ({
      showHeader: false,
      showSideBarOrganizations: false,
      showSideBarEvents: false,
      showSideBarEvent: false,
    }))
  },
  header: {
    title: '',
    subtitle: '',
    titleImageKey: '',
    backButtonPath: '',
    dynamicContent: false,
  },
  setTitle: (s: string) => {
    set((state) => ({ header: { ...state.header, title: s } }))
  },
  setSubtitle: (s: string) => {
    set((state) => ({ header: { ...state.header, subtitle: s } }))
  },
  setTitleImageKey: (s: string) => {
    set((state) => ({ header: { ...state.header, titleImageKey: s } }))
  },
  setTo: (s: string) => {
    set((state) => ({ header: { ...state.header, to: s } }))
  },
  setHeaderButton: (children: React.ReactNode) => {
    set((state) => ({ header: { ...state.header, button: children } }))
  },
}))
