import { useState } from 'react'
import { type AxiosResponse } from 'axios'

import { api } from '@/services/axios'

import { type ITicketSpec } from '@/types/Tickets'

interface UpdateTicketSpec {
  ticketSpecId: number | undefined
  numberOfAvailableTickets?: number
  description?: string
  price?: number
  userLimit?: number
  active?: boolean
  followerId?: number | null
  extraDescription?: string
}

interface PutReturnProps {
  status: boolean
  ticketSpec?: ITicketSpec
}

export const usePutUpdateTicketSpec = (): {
  updateTicketSpec: (body: UpdateTicketSpec) => Promise<PutReturnProps>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function updateTicketSpec({
    ticketSpecId,
    ...body
  }: UpdateTicketSpec): Promise<PutReturnProps> {
    setIsLoading(true)
    try {
      const response: AxiosResponse<ITicketSpec> = await api.put(
        `/admin/ticketSpecs/${ticketSpecId}`,
        body,
      )
      setIsLoading(false)

      return {
        status: true,
        ticketSpec: response.data,
      }
    } catch (error) {
      setIsLoading(false)
      return {
        status: false,
      }
    }
  }

  return {
    updateTicketSpec,
    isLoading,
  }
}
