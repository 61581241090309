import { useState, type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import { PermissionSelector } from '@/components/Team/PermissionSelector'
import { PermissionSwitch } from '@/components/Team/PermissionSwitch'
import { PermissionsPreset } from '@/components/Team/PermissionsPreset'
import { EventSelector } from '@/components/Team/EventSelector'
import { Button } from '@/components/Global/Button'

import { teamStore } from '@/store/team'
import { organizationStore } from '@/store/organization'
import { errorStore } from '@/store/error'
import { deviceStore } from '@/store/device'

import { eventPermissionsDescriptions } from '@/utils/teamPermissionsDescriptions'
import { datesToFormat } from '@/utils/formatData'

import { usePostCreateEventRole } from '@/hooks/Team'

import { CANT_CREATE_EVENT_ROLE } from '@/errors'
import { twMerge } from 'tailwind-merge'

export function CreateEventRole(): ReactElement {
  const [state, setState] = useState<'SELECT_EVENTS' | 'SELECT_PERMISSIONS'>(
    'SELECT_EVENTS',
  )

  const { user, permissions, setPermissions, selectedEvents } = teamStore()
  const { currentOrganization, refreshOrganizations } = organizationStore()
  const { showError } = errorStore()
  const { isMobile } = deviceStore()

  const { createEventRole, isLoading } = usePostCreateEventRole()

  const navigate = useNavigate()

  const canCreateEventRole =
    permissions.event.finance !== '' &&
    permissions.event.analytics !== '' &&
    permissions.event.list !== '' &&
    permissions.event.tickets !== '' &&
    permissions.event.grant !== '' &&
    permissions.event.link !== ''

  async function handleCreateEventRole(): Promise<void> {
    const response = await createEventRole({
      userId: user?.id ?? 0,
      eventsAlias: selectedEvents?.map((event) => event.alias) ?? [],
      roles: {
        event: permissions.event,
      },
    })

    if (response.status !== 200) {
      showError(CANT_CREATE_EVENT_ROLE)
      return
    }

    if (refreshOrganizations !== undefined) void refreshOrganizations()
    navigate(`/team`)
  }

  return (
    <div className="relative flex size-full flex-col items-start gap-6 p-4">
      <div className="flex w-full flex-col justify-between gap-4 desktop:flex-row desktop:items-center desktop:gap-0">
        {user !== undefined && (
          <div className="flex w-fit items-center gap-4">
            <img
              src={`${import.meta.env.VITE_S3 as string}/${user.imageKey}`}
              alt="User profile image"
              className="aspect-square size-20 rounded-full"
            />
            <div className="flex w-full flex-col gap-1.5">
              <span className="text-2xl font-bold leading-6">
                {user.firstName} {user.lastName}
              </span>
              <span className="text-sm leading-3">@{user.username}</span>
            </div>
          </div>
        )}
        {state === 'SELECT_PERMISSIONS' && (
          <div className="w-fit">
            <div className="ml-4 flex w-full">
              {selectedEvents?.map((event, index) => {
                return (
                  <img
                    key={index}
                    src={`${import.meta.env.VITE_S3 as string}/${
                      event.imageKey
                    }`}
                    alt="Event image"
                    className={twMerge(
                      '-ml-4 aspect-square w-10 rounded-lg border-4 desktop:w-12',
                      isMobile ? 'border-[#2A2A2A]' : 'border-[#232323]',
                    )}
                  />
                )
              })}
            </div>
          </div>
        )}
      </div>
      {state === 'SELECT_PERMISSIONS' && (
        <div
          className={twMerge(
            'flex w-full',
            isMobile ? 'justify-start' : 'justify-end',
          )}
        >
          <PermissionsPreset
            permissions={permissions}
            setPermissions={setPermissions}
            isGlobalPermission={false}
          />
        </div>
      )}
      {state === 'SELECT_EVENTS' && (
        <div className="flex w-full flex-col gap-2">
          <span className="text-2xl font-bold">Eventos</span>
          <div className="w-full columns-1 desktop:columns-2">
            {currentOrganization?.events.map((event) => {
              return (
                <div
                  className="w-full break-inside-avoid-column"
                  key={event.alias}
                >
                  <EventSelector
                    id={event.eventId}
                    imageKey={event.backgroundArtImageKey}
                    title={event.name}
                    startDate={datesToFormat(event.date, event.endDate)}
                    alias={event.alias}
                  />
                </div>
              )
            })}
          </div>
        </div>
      )}
      {state === 'SELECT_PERMISSIONS' && (
        <div className="w-full">
          <div className="mb-6 flex w-full  flex-col gap-2">
            <div>
              <span className="text-lg font-black">
                {selectedEvents.length === 1
                  ? 'Permissões no evento'
                  : 'Permissões nos eventos'}
              </span>
            </div>
            <div className="w-full columns-1 desktop:columns-2 ">
              <div className="mb-2 flex break-inside-avoid-column flex-col gap-2">
                <PermissionSelector
                  permissions={eventPermissionsDescriptions.FINANCE}
                  title="Financeiro"
                  currentKey={permissions.event.finance}
                  setCurrentKey={(p) => {
                    setPermissions({
                      ...permissions,
                      event: { ...permissions.event, finance: p },
                    })
                  }}
                  canEdit
                />
                <PermissionSelector
                  permissions={eventPermissionsDescriptions.ANALYTICS}
                  title="Analíticos"
                  currentKey={permissions.event.analytics}
                  setCurrentKey={(p) => {
                    setPermissions({
                      ...permissions,
                      event: { ...permissions.event, analytics: p },
                    })
                  }}
                  canEdit
                />
                <PermissionSelector
                  permissions={eventPermissionsDescriptions.LIST}
                  title="Lista"
                  currentKey={permissions.event.list}
                  setCurrentKey={(p) => {
                    setPermissions({
                      ...permissions,
                      event: { ...permissions.event, list: p },
                    })
                  }}
                  canEdit
                />
                <PermissionSelector
                  permissions={eventPermissionsDescriptions.TICKETS}
                  title="Lotes"
                  currentKey={permissions.event.tickets}
                  setCurrentKey={(p) => {
                    setPermissions({
                      ...permissions,
                      event: { ...permissions.event, tickets: p },
                    })
                  }}
                  canEdit
                />
              </div>
              <div className="flex break-inside-avoid-column flex-col gap-2">
                <PermissionSelector
                  permissions={eventPermissionsDescriptions.GRANT}
                  title="Cortesias"
                  currentKey={permissions.event.grant}
                  setCurrentKey={(p) => {
                    setPermissions({
                      ...permissions,
                      event: { ...permissions.event, grant: p },
                    })
                  }}
                  canEdit
                />
                <PermissionSelector
                  permissions={eventPermissionsDescriptions.LINK}
                  title="Links"
                  currentKey={permissions.event.link}
                  setCurrentKey={(p) => {
                    setPermissions({
                      ...permissions,
                      event: { ...permissions.event, link: p },
                    })
                  }}
                  canEdit
                />
                <PermissionSwitch
                  title="Validação de ingressos"
                  active={permissions.event.validation}
                  setActive={(state) => {
                    setPermissions({
                      ...permissions,
                      event: { ...permissions.event, validation: state },
                    })
                  }}
                  canEdit
                />
                <PermissionSwitch
                  title="Editar evento"
                  active={permissions.event.edit}
                  setActive={(state) => {
                    setPermissions({
                      ...permissions,
                      event: { ...permissions.event, edit: state },
                    })
                  }}
                  canEdit
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="w-full max-w-[300px] self-center py-6 ">
        <div className="h-12">
          {state === 'SELECT_EVENTS' ? (
            <Button
              text="Selecionar permissões"
              enabled={selectedEvents?.length > 0}
              onClick={() => {
                setState('SELECT_PERMISSIONS')
              }}
            />
          ) : (
            <Button
              text="Confirmar"
              enabled={canCreateEventRole && !isLoading}
              onClick={() => {
                void handleCreateEventRole()
              }}
              isLoading={isLoading}
            />
          )}
        </div>
      </div>
    </div>
  )
}
