import {
  type IUpdateEventStoreState,
  type FAQs,
  type LineUp,
  type Partners,
} from '@/types/CreateUpdateEvent'
import { create } from 'zustand'
import {
  type StateStorage,
  createJSONStorage,
  devtools,
  persist,
} from 'zustand/middleware'
import localForage from 'localforage'

interface IUpdateEventStoreActions {
  getCompletePercentual: () => number
  reset: () => void
  resetCurrentLineup: () => void
  updateAnyState: (update: Partial<IUpdateEventStoreState>) => void
  deleteArtistOfLineUp: (artist: LineUp) => void
  deleteFaq: (faq: FAQs) => void
  deletePartner: (partner: Partners) => void
  deleteLocation: () => void
}

const initialState: IUpdateEventStoreState = {
  alias: null,
  eventName: null,
  backgroundArtImage: null,
  titleImage: null,
  startDate: undefined,
  maxEntranceDate: undefined,
  endDate: undefined,
  location: {
    name: null,
    address: null,
    latitude: null,
    longitude: null,
  },
  type: null,
  eventDescription: undefined,
  lineUp: null,
  spotifyPlaylist: null,
  spotifyPlaylistId: null,
  terms: null,
  FAQs: null,
  partners: null,
  sticker: null,
  currentArtistImage: null,
  currentArtistImageUrl: null,
  currentArtistName: null,
  typeOperation: 'Update',
  canFetchData: true,
  canAddArtist: false,
}

export const updateEventStore = create<
  IUpdateEventStoreState & IUpdateEventStoreActions
>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        updateAnyState: (update: Partial<IUpdateEventStoreState>) => {
          set((prevState) => ({
            ...prevState,
            ...update,
          }))
        },
        getCompletePercentual: () => {
          const states = get()

          const onlyStatesArray = Object.entries(states).filter((item) => {
            if (
              item[0] === 'currentArtistName' ||
              item[0] === 'currentArtistImage' ||
              item[0] === 'currentArtistImageUrl' ||
              item[0] === 'typeOperation' ||
              item[0] === 'canReset' ||
              item[0] === 'canAddArtist' ||
              item[0] === 'canFetchData'
            ) {
              return false
            }

            if (typeof item[1] !== 'function') {
              return item
            }

            return false
          })

          const total = onlyStatesArray.length

          const notNulls = onlyStatesArray.reduce((acc, prev) => {
            if (prev[1] !== null && prev[1] !== undefined) {
              return (acc = acc + 1)
            }

            return acc
          }, 0)

          return notNulls / total
        },
        reset: () => {
          set(initialState)
        },
        resetCurrentLineup: () => {
          set({
            currentArtistImage: null,
            currentArtistName: null,
          })
        },
        deleteArtistOfLineUp: (artist: LineUp) => {
          const allStates = get()
          const currentLineUp = allStates.lineUp ?? []
          const lineUpFiltered = currentLineUp?.filter((item) => {
            if (
              item.artistImage === artist.artistImage &&
              item.artistImageUrl === artist.artistImageUrl
            ) {
              return false
            }
            return item
          })
          set({
            lineUp: lineUpFiltered.length < 1 ? null : lineUpFiltered,
          })
        },
        deleteFaq: (faq: FAQs) => {
          const allStates = get()
          const currentFAQs = allStates.FAQs ?? []
          const FAQsFiltered = currentFAQs?.filter((item) => {
            if (item.question === faq.question) {
              return false
            }
            return item
          })
          set({
            FAQs: FAQsFiltered?.length < 1 ? null : FAQsFiltered,
          })
        },
        deletePartner: (partner: Partners) => {
          const allStates = get()
          const currentPartners = allStates.partners ?? []
          const partnersFiltered = currentPartners?.filter((item) => {
            if (item.key === partner.key && item.url === partner.url) {
              return false
            }
            return item
          })
          set({
            partners: partnersFiltered,
          })
        },
        deleteLocation: () => {
          set({
            location: undefined,
          })
        },
      }),
      {
        name: 'updateEvent-storage',
        storage: createJSONStorage(() => {
          return localForage as StateStorage
        }),
      },
    ),
  ),
)

export const useAllStatesOfUpdateEvent = (): IUpdateEventStoreState => {
  return updateEventStore((state) => {
    const onlyStatesArray = Object.entries(state).filter((item) => {
      if (typeof item[1] !== 'function') {
        return item
      }

      return false
    })

    return Object.fromEntries(onlyStatesArray) as IUpdateEventStoreState
  })
}
