import { type Dispatch, type SetStateAction, type ReactElement } from 'react'
import { BsPlusCircle } from 'react-icons/bs'

import { DeleteIcon } from '@/components/Icons/DeleteIcon'

import {
  type FAQs,
  type ICreateEventStoreState,
  type IUpdateEventStoreState,
} from '@/types/CreateUpdateEvent'

interface CreateEventFAQCardProps {
  setCreateFAQsModalIsOpen: () => void
  formStates: ICreateEventStoreState | IUpdateEventStoreState
  deleteFaq: (faq: FAQs) => void
  openDeleteItemModal: () => void
  setExecuteDeleteFunction: Dispatch<SetStateAction<() => void>>
}

export function CreateEventFAQCard({
  setCreateFAQsModalIsOpen,
  formStates,
  deleteFaq,
  openDeleteItemModal,
  setExecuteDeleteFunction,
}: CreateEventFAQCardProps): ReactElement {
  return (
    <div className="flex w-full items-center justify-between rounded-lg bg-dark-black p-4">
      <div className="flex w-full flex-col">
        <span className="mb-1 text-sm font-bold text-[#D0D0D0]">FAQ</span>
        <div className="flex w-full flex-col gap-3">
          {formStates.FAQs !== null && (
            <>
              {formStates.FAQs.map((faq, index) => {
                return (
                  <div
                    key={index}
                    className="flex w-full items-center justify-between gap-2"
                  >
                    <div className="flex flex-col gap-0.5">
                      <span className="text-sm leading-4 text-[#ffffff]">
                        {faq.question}
                      </span>
                      <span className="break-words text-xs text-[#a3a3a3]">
                        {faq.answer}
                      </span>
                    </div>
                    <DeleteIcon
                      onClick={() => {
                        openDeleteItemModal()
                        setExecuteDeleteFunction(() => () => {
                          deleteFaq(faq)
                        })
                      }}
                    />
                  </div>
                )
              })}
            </>
          )}
          <div className="flex w-full items-center justify-center">
            <BsPlusCircle
              className="size-7"
              onClick={() => {
                setCreateFAQsModalIsOpen()
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
