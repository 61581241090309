import { type IEvent } from '@/types/Event'
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

interface IEventMenuStoreState {
  currentEvent: IEvent | null
}

interface IEventMenuStoreAction {
  setCurrentEvent: (event: IEvent) => void
  reset: () => void
}

const initialState: IEventMenuStoreState = {
  currentEvent: null,
}

export const eventMenuStore = create<
  IEventMenuStoreState & IEventMenuStoreAction
>()(
  devtools(
    persist(
      (set) => {
        return {
          ...initialState,
          setCurrentEvent: (event: IEvent) => {
            set({
              currentEvent: event,
            })
          },
          reset: () => {
            set(initialState)
          },
        }
      },
      {
        name: 'eventMenu-storage',
      },
    ),
  ),
)

export const useCurrentEvent = (): {
  currentEvent: IEvent | null
  setCurrentEvent: (event: IEvent) => void
} => {
  return eventMenuStore((state) => {
    const { currentEvent, setCurrentEvent } = state

    return { currentEvent, setCurrentEvent }
  })
}
