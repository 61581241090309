import { type Dispatch, type SetStateAction, type ReactElement } from 'react'

import { DeleteIcon } from '@/components/Icons/DeleteIcon'
import { UploadIcon } from '@/components/Icons/UploadIcon'

import {
  type Partners,
  type ICreateEventStoreState,
  type IUpdateEventStoreState,
} from '@/types/CreateUpdateEvent'
import { isTruthy } from '@/utils/validation'

interface CreateEventPartnersProps {
  handlePartnerPhotoUpdate: (e: React.ChangeEvent<HTMLInputElement>) => void
  formStates: ICreateEventStoreState | IUpdateEventStoreState
  deletePartner: (partner: Partners) => void
  openDeleteItemModal: () => void
  setExecuteDeleteFunction: Dispatch<SetStateAction<() => void>>
}

export function CreateEventPartners({
  handlePartnerPhotoUpdate,
  formStates,
  deletePartner,
  openDeleteItemModal,
  setExecuteDeleteFunction,
}: CreateEventPartnersProps): ReactElement {
  function handleRemovePartner(partner: Partners): void {
    deletePartner(partner)
  }
  return (
    <div className="flex w-full flex-col">
      <div className="flex flex-col px-4">
        <span className="text-2xl font-bold">Parceiros</span>
      </div>
      <div className="relative overflow-visible">
        <div className="flex w-full overflow-y-visible overflow-x-scroll scroll-smooth">
          {isTruthy(formStates.partners) && (
            <div className="flex gap-4 px-4 pt-4">
              {formStates.partners!.map((partner, index) => {
                return (
                  <div key={index} className="relative w-40">
                    <img
                      src={
                        partner.isLocalImage
                          ? partner.url
                          : `${import.meta.env.VITE_S3 as string}/${
                              partner.url
                            }`
                      }
                      alt="Imagem do parceiro do evento"
                      className={`aspect-video h-[100px] rounded-lg`}
                    />
                    {formStates.partners !== null && (
                      <DeleteIcon
                        className="absolute -right-3 -top-3"
                        onClick={() => {
                          openDeleteItemModal()
                          setExecuteDeleteFunction(() => () => {
                            handleRemovePartner(partner)
                          })
                        }}
                      />
                    )}
                  </div>
                )
              })}
              <div className="flex items-center">
                <label
                  htmlFor="inputPartnerPhoto"
                  className="flex h-[100px] w-40 flex-col items-center justify-center gap-2.5 rounded-lg border-2 border-dashed p-2"
                >
                  <UploadIcon iconColor="#ffffff" className="size-5" />
                  <span className=" text-center text-xs">
                    Adicione um parceiro
                  </span>
                  <input
                    id="inputPartnerPhoto"
                    type="file"
                    className="hidden"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handlePartnerPhotoUpdate(e)
                    }}
                    onClick={(e: React.FormEvent<HTMLInputElement>) => {
                      const target = e.target as HTMLButtonElement
                      target.value = ''
                    }}
                  />
                </label>
              </div>
            </div>
          )}
          {!isTruthy(formStates.partners) && (
            <div className="px-4 pt-4">
              <label
                htmlFor="inputPartnerPhoto"
                className="flex h-[6.25rem] w-40 flex-col items-center justify-center gap-2.5 rounded-lg border-2 border-dashed p-2 hover:cursor-pointer"
              >
                <UploadIcon iconColor="#ffffff" className="size-5" />
                <span className=" text-center text-xs">
                  Adicione um parceiro
                </span>
                <input
                  id="inputPartnerPhoto"
                  type="file"
                  className="hidden"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handlePartnerPhotoUpdate(e)
                  }}
                  onClick={(e: React.FormEvent<HTMLInputElement>) => {
                    const target = e.target as HTMLButtonElement
                    target.value = ''
                  }}
                />
              </label>
            </div>
          )}
        </div>
        <div className="absolute left-0 top-0 z-10 h-full w-5 bg-gradient-to-l from-transparent to-[#232323] desktop:to-[#2A2A2A]" />
        <div className="absolute right-0 top-0 z-10 h-full w-5 bg-gradient-to-r from-transparent to-[#232323] desktop:to-[#2A2A2A]" />
      </div>
    </div>
  )
}
