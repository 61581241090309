import { useState } from 'react'
import { api } from '@/services/axios'
import { type AxiosError } from 'axios'

interface PostReturnProps {
  status: number
}

export const usePostAddCoHost = (): {
  addCoHost: (userId: number, eventAlias: string) => Promise<PostReturnProps>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function addCoHost(
    userId: number,
    eventAlias: string,
  ): Promise<PostReturnProps> {
    setIsLoading(true)
    try {
      const body = {
        userId,
        eventsAlias: [eventAlias],
        roles: {
          event: {
            finance: 'VIEWER',
            analytics: 'VIEWER',
            list: 'EDITOR',
            tickets: 'EDITOR',
            grant: 'EDITOR',
            link: 'EDITOR',
            validation: true,
            edit: true,
          },
        },
      }
      await api.post(`/admin/events/roles/many`, body)

      setIsLoading(false)

      return {
        status: 200,
      }
    } catch (error) {
      setIsLoading(false)
      const err = error as AxiosError
      return {
        status: err.response?.status ?? 500,
      }
    }
  }

  return {
    addCoHost,
    isLoading,
  }
}
