import { type ReactElement } from 'react'

import { useCurrentError } from '@/store/error'
import { deviceStore } from '@/store/device'

import { Modal } from '@/components/Global/Modal'
import { TicketSpecForm } from '../TicketSpecForm'

interface TicketSpecModalProps {
  closeModal: () => void
  isParentClosing?: boolean
}

export function TicketSpecModal({
  closeModal,
  isParentClosing,
}: TicketSpecModalProps): ReactElement {
  const { isError, errorMessage } = useCurrentError()
  const { isMobile } = deviceStore()

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      isError={isError}
      errorMessage={errorMessage}
      isMobile={isMobile}
    >
      <div className="flex h-fit w-full flex-col items-center px-4 py-5 text-black">
        <TicketSpecForm closeModal={closeModal} />
      </div>
    </Modal>
  )
}
