import { useState, type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button } from '@/components/Global/Button'
import { SupportModal } from '@/components/Global/SupportModal'

import { BsArrowLeftShort } from 'react-icons/bs'
import { IoIosWarning } from 'react-icons/io'

export function AccessDenied(): ReactElement {
  const [showCommunicationModal, setCommunicationModal] = useState(false)

  const navigate = useNavigate()

  return (
    <div className="relative flex min-h-full w-full flex-col items-center justify-center gap-8 bg-transparent p-4">
      <div
        className={
          'absolute left-6 top-6 z-10 flex size-8 items-center justify-center rounded-full bg-dark-white hover:cursor-pointer'
        }
        onClick={() => {
          navigate(-1)
        }}
      >
        <BsArrowLeftShort size={24} color="black" />
      </div>
      <div className="mb-12 flex flex-col items-center gap-4">
        <div className="flex flex-col items-center">
          <IoIosWarning color={'yellow'} size={40} />
          <h1 className="text-2xl font-bold text-white">Acesso negado.</h1>
        </div>
        <span className="text-center text-sm text-white">
          Você não possui permissão para acessar esta página.
        </span>
      </div>
      <footer className="absolute bottom-5 left-1/2 h-14 w-4/5 max-w-[400px] -translate-x-1/2">
        <Button
          enabled={true}
          text="Entrar em contato com o suporte"
          onClick={() => {
            setCommunicationModal(true)
          }}
        />
      </footer>
      {showCommunicationModal && (
        <SupportModal
          closeModal={() => {
            setCommunicationModal(false)
          }}
        />
      )}
    </div>
  )
}
