import { useState, type ReactElement, useEffect } from 'react'

import { DropDown } from '@/components/Global/DropDown'

import { type IPermissions } from '@/types/Team'
import { permissionsDeepEqual } from '@/utils/objects'

interface PermissionsPresetProps {
  permissions: IPermissions
  setPermissions: (p: IPermissions) => void
  isGlobalPermission: boolean
}

interface IPreset {
  value: string
  id: number
  permissions: IPermissions
}

export function PermissionsPreset({
  permissions,
  setPermissions,
  isGlobalPermission,
}: PermissionsPresetProps): ReactElement {
  const [selectedPreset, setSelectedPreset] = useState('')

  const presets: IPreset[] = [
    {
      value: 'Admin',
      id: 1,
      permissions: {
        event: {
          finance: isGlobalPermission ? 'EDITOR' : 'VIEWER',
          analytics: 'VIEWER',
          list: 'EDITOR',
          tickets: 'EDITOR',
          grant: 'EDITOR',
          link: 'EDITOR',
          validation: true,
          edit: true,
        },
        organization: {
          finance: 'EDITOR',
          analytics: 'VIEWER',
          access: 'EDITOR',
          communicationCampaign: 'EDITOR',
          eventCreation: true,
          settings: true,
        },
      },
    },
    {
      value: 'Visualizador',
      id: 2,
      permissions: {
        event: {
          finance: 'VIEWER',
          analytics: 'VIEWER',
          list: 'VIEWER',
          tickets: 'VIEWER',
          grant: 'VIEWER',
          link: 'VIEWER',
          validation: false,
          edit: false,
        },
        organization: {
          finance: 'VIEWER',
          analytics: 'VIEWER',
          access: 'VIEWER',
          communicationCampaign: 'VIEWER',
          eventCreation: false,
          settings: false,
        },
      },
    },
  ]

  useEffect(() => {
    const presetPermissionFound = presets.find((p) =>
      permissionsDeepEqual(p.permissions, permissions),
    )
    if (presetPermissionFound === undefined) {
      setSelectedPreset('Customizada')
    } else {
      setSelectedPreset(presetPermissionFound.value)
    }
  }, [permissions])

  return (
    <div className="flex size-fit items-center gap-4 rounded-md bg-[#1e1e1e] p-2 pl-4">
      <span className="text-sm font-bold">Predefinições</span>
      <div className="rounded-md border border-primary-main px-2 py-1">
        <DropDown
          value={selectedPreset}
          dropDownData={presets}
          handleOnChange={(v) => {
            const currentPreset = presets.find((p) => p.id === v)
            if (currentPreset !== undefined) {
              setSelectedPreset(currentPreset.value)
              setPermissions(currentPreset.permissions)
            }
          }}
        />
      </div>
    </div>
  )
}
