import { useState, useEffect } from 'react'
import useSWR from 'swr'
import { fetcher } from '@/services/axios'

import { type IFinance } from '@/types/Finance'

export const useGetWithdrawalsDataByEventAlias = (
  alias: string,
): {
  financeDataByEventAlias: IFinance | undefined
  isLoading: boolean
  refresh: () => Promise<IFinance | undefined>
} => {
  const [isLoading, setIsLoading] = useState(true)
  const shouldFetch = alias !== undefined
  const { data, error, mutate } = useSWR<IFinance, string>(
    shouldFetch && `/admin/withdrawals/${alias}`,
    fetcher,
  )

  useEffect(() => {
    setIsLoading(!(data !== undefined || error !== undefined))
  }, [data, error])

  return { financeDataByEventAlias: data, isLoading, refresh: mutate }
}
