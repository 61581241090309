import { type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

interface ToggleGroupProps {
  items: string[]
  current: string
  setCurrent: (s: string) => void
}

export function ToggleGroup({
  items,
  current,
  setCurrent,
}: ToggleGroupProps): ReactElement {
  return (
    <div className="flex items-center gap-1 rounded-full bg-dark-light-gray p-2">
      {items.map((item, index) => {
        const isSelected = item === current
        return (
          <div
            key={index}
            onClick={() => {
              setCurrent(item)
            }}
            className={twMerge(
              'rounded-full px-4 py-1 transition-colors duration-200 ease-in-out hover:cursor-pointer',
              isSelected && ' bg-dark-dark-gray text-white',
            )}
          >
            <span className="text-nowrap text-sm">{item}</span>
          </div>
        )
      })}
    </div>
  )
}
