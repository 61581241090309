import { type ReactNode, type ReactElement, useRef, useState } from 'react'

import { ScrollToTop } from '@/components/Global/ScrollToTop'
import { Header } from '@/components/Global/Header'
import { SnackBar } from '@/components/Global/SnackBar'

import { useCurrentError } from '@/store/error'
import { wrapperStore } from '@/store/wrapper'

interface MobileWrapperProps {
  children: ReactNode
}

export function MobileWrapper({ children }: MobileWrapperProps): ReactElement {
  const [scrolled, setScrolled] = useState(false)

  const mainDivRef = useRef<HTMLDivElement>(null)

  const { isError, errorMessage, showErrorWrapper } = useCurrentError()
  const { showHeader, showScrollToTop } = wrapperStore()

  return (
    <div
      ref={mainDivRef}
      className="relative flex h-svh max-h-svh w-full max-w-full flex-col items-center overflow-y-scroll bg-[#2A2A2A]"
      onScroll={() => {
        if (mainDivRef.current?.scrollTop !== undefined) {
          setScrolled(mainDivRef.current?.scrollTop > 0)
        }
      }}
    >
      {showHeader && (
        <div className="w-full">
          <Header variant="mobile" />
        </div>
      )}
      {children}
      {showScrollToTop && (
        <ScrollToTop
          divRef={mainDivRef.current!}
          scrolled={scrolled}
          marginBottom="bottom-16 right-4"
        />
      )}
      {showErrorWrapper && (
        <div className="fixed bottom-0 z-20 h-0 w-full px-4">
          <SnackBar isMobile={true} isError={isError} message={errorMessage} />
        </div>
      )}
    </div>
  )
}
