import { useState, useEffect } from 'react'
import useSWR from 'swr'

import { fetcher } from '@/services/axios'

import { type ITickets } from '@/types/Tickets'

export const useGetTickets = (
  alias: string,
): {
  tickets: ITickets | undefined
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(true)
  const shouldFetch = alias !== undefined
  const { data, error } = useSWR<ITickets, string>(
    shouldFetch && `/admin/events/${alias}/tickets`,
    fetcher,
  )

  useEffect(() => {
    setIsLoading(!(data !== undefined || error !== undefined))
  }, [data, error])

  return {
    tickets: data,
    isLoading,
  }
}
