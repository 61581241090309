import { useState, type ReactElement } from 'react'
import { type Crop } from 'react-image-crop'

import { useCurrentError } from '@/store/error'
import { deviceStore } from '@/store/device'

import { Button } from '@/components/Global/Button'
import { CropImage } from '@/components/Global/CropImage'
import { Modal } from '@/components/Global/Modal'

interface CreateEditOrganizationCropModalProps {
  closeModal: () => void
  isParentClosing?: boolean
  isLoading?: boolean
  uploadedImageUrl: string
  setCroppedImage: (croppedImage: File) => void
  setCroppedImageUrl: (croppedImageUrl: string) => void
}

export function CreateEditOrganizationCropModal({
  closeModal,
  isParentClosing,
  uploadedImageUrl,
  setCroppedImage,
  setCroppedImageUrl,
}: CreateEditOrganizationCropModalProps): ReactElement {
  const { isError, errorMessage } = useCurrentError()
  const { isMobile } = deviceStore()

  const [crop, setCrop] = useState<Crop>()

  function handleSubmit(uploadedImageUrl: string): void {
    // Crop the image and save it somewhere
    if (crop !== undefined) {
      const cropped = crop.width !== undefined && crop.height !== undefined
      if (cropped) {
        const image = new Image()
        image.src = uploadedImageUrl
        image.onload = () => {
          const canvas = document.createElement('canvas')
          canvas.width = (crop.width / 100) * image.width
          canvas.height = (crop.height / 100) * image.height
          const ctx = canvas.getContext('2d')

          ctx?.drawImage(
            image,
            (crop.x / 100) * image.width,
            (crop.y / 100) * image.height,
            (crop.width / 100) * image.width,
            (crop.height / 100) * image.height,
            0,
            0,
            (crop.width / 100) * image.width,
            (crop.height / 100) * image.height,
          )

          const croppedImageUrl = canvas.toDataURL('image/png')
          canvas.toBlob((blob) => {
            const croppedImage = new File([blob!], 'croppedImage.png', {
              type: 'image/png',
            })
            setCroppedImage(croppedImage)
            setCroppedImageUrl(croppedImageUrl)
          })
          closeModal()
        }
      }
    }
  }

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      isError={isError}
      errorMessage={errorMessage}
      isMobile={isMobile}
    >
      <div className="flex h-fit w-full flex-col items-center gap-4 p-4 text-black">
        <div className="flex w-full flex-col items-center justify-center">
          <div className="mt-4 flex size-full flex-col items-center justify-center gap-4">
            <CropImage
              uploadedImageUrl={uploadedImageUrl}
              setCrop={(crop: Crop): void => {
                setCrop(crop)
              }}
              crop={crop}
              isCircularCrop={false}
              minHeight={200}
              minWidth={200}
            />
            <div className="mt-4 h-12 w-full">
              <Button
                text="Salvar"
                enabled
                onClick={() => {
                  handleSubmit(uploadedImageUrl)
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
