import { useEffect, type ReactElement } from 'react'

import { Loading } from '@/components/Global/Loading/Loading'

import { usePing } from '@/hooks/Auth'

import { BaseRouter } from '@/routes/BaseRouter'
import { AuthRouter } from '@/routes/AuthRouter'

import { userStore } from '@/store/user'
import { routingStore } from '@/store/routing'

export function Router(): ReactElement {
  const { loginUser, logoutUser, setUserIsLogged, userIsLogged } = userStore()

  const { ping, isLoading } = usePing()
  const { setIsPinging, isPinging } = routingStore()

  async function handlePing(): Promise<void> {
    setIsPinging(true)
    const pong = await ping()

    if (pong.status === 200 && pong.user !== undefined) {
      loginUser(pong.user)
      setUserIsLogged(true)
    } else {
      logoutUser()
      setUserIsLogged(false)
    }
    setIsPinging(false)
  }

  useEffect(() => {
    void handlePing()
  }, [])

  if (isLoading || isPinging) {
    return <Loading hidden={!isLoading} />
  }

  if (!userIsLogged) return <AuthRouter />
  return <BaseRouter />
}
