import { useState, useEffect } from 'react'
import useSWR from 'swr'

import { fetcher } from '@/services/axios'

import { type IValidations } from '@/types/Analytics'

export const useGetValidations = (
  alias: string,
): {
  validations: IValidations | undefined
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(true)
  const shouldFetch = alias !== undefined
  const { data, error } = useSWR<IValidations, string>(
    shouldFetch && `/admin/analytics/${alias}/validations`,
    fetcher,
  )

  useEffect(() => {
    setIsLoading(!(data !== undefined || error !== undefined))
  }, [data, error])

  return { validations: data, isLoading }
}
