import { type ReactElement, memo, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'

import { isTruthy } from '@/utils/validation'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface RenderPDFProps {
  file?: File
  url?: string
  height?: number
}

function RenderPDF({ file, url, height = 75 }: RenderPDFProps): ReactElement {
  const [isLoading, setIsLoading] = useState(true)
  const fileUrl = isTruthy(file) ? URL.createObjectURL(file!) : url

  return (
    <div>
      {isLoading && <div className="animation-pulse size-full"></div>}
      <Document
        file={fileUrl}
        onLoad={() => {
          setIsLoading(false)
        }}
        className="flex items-center justify-center"
        loading={''}
      >
        <Page
          pageNumber={1}
          height={height}
          renderTextLayer={false}
          renderAnnotationLayer={false}
        />
      </Document>
    </div>
  )
}

const MemoizedRenderPDF = memo(RenderPDF)

export { MemoizedRenderPDF }
