export function timeMask(input: string): string {
  let value = input
  value = value.replace(/\D/g, '')
  // Garanta que o valor esteja dentro do intervalo 0-2359
  if (Number(value) < 0) {
    value = String(0)
  } else if (Number(value) > 2359) {
    value = String(value).slice(0, 4)
  }
  // Formate o valor para o formato hh:mm
  const hours = Math.floor(Number(value) / 100)
  const minutes = Number(value) % 100

  const formattedValue = `${String(hours).padStart(2, '0')}:${String(
    minutes,
  ).padStart(2, '0')}`

  return formattedValue
}
