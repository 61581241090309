import { type ReactElement } from 'react'
import {
  Cell,
  Label,
  Pie,
  PieChart as PieChartRecharts,
  ResponsiveContainer,
} from 'recharts'
import { type PolarViewBox } from 'recharts/types/util/types'

interface RadialChartProps {
  progress: number
  progressColor: string
  barColor: string
  customStartAngle: number
  isPercent: boolean
  innerRadius?: number
  outerRadius?: number
}

interface Data {
  name: string
  value: number
}

export function PieChart({
  progress,
  progressColor,
  barColor,
  customStartAngle,
  isPercent,
  innerRadius = 17,
  outerRadius = 20,
}: RadialChartProps): ReactElement {
  let data: Data[]
  if (isPercent) {
    data = [
      { name: 'Remaining', value: 100 - progress },
      { name: 'Completed', value: progress },
    ]
  } else {
    data = [
      { name: 'Remaining', value: 0 },
      { name: 'Completed', value: progress },
    ]
  }

  const colors = [barColor, progressColor]

  const startAngle = customStartAngle
  const totalValue = data.reduce((acc, item) => acc + item.value, 0)
  const endAngle = startAngle - (360 * totalValue) / 100

  return (
    <div className="size-full">
      <ResponsiveContainer width="100%" height="100%">
        <PieChartRecharts>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            dataKey="value"
            startAngle={startAngle}
            endAngle={endAngle}
          >
            {data.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index % colors.length]}
                stroke="transparent"
              />
            ))}
            <Label
              position="center"
              content={({ viewBox }) => {
                if (viewBox !== undefined) {
                  const { cx, cy } = viewBox as PolarViewBox
                  return (
                    <text x={cx} y={cy} dy={5} textAnchor="middle">
                      {isPercent ? (
                        <>
                          <tspan
                            style={{
                              fontSize: '14px',
                              fill: 'white',
                              fontWeight: 'bold',
                            }}
                          >
                            {progress}
                          </tspan>
                          <tspan
                            style={{
                              fontSize: '6px',
                              fill: 'white',
                              fontWeight: 'bold',
                            }}
                          >
                            %
                          </tspan>
                        </>
                      ) : (
                        <tspan
                          style={{
                            fontSize: '10px',
                            fill: 'white',
                            fontWeight: 'bold',
                          }}
                        >{`${data[1].value}`}</tspan>
                      )}
                    </text>
                  )
                }
              }}
            />
          </Pie>
        </PieChartRecharts>
      </ResponsiveContainer>
    </div>
  )
}
